export const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('Database1', 1);

    request.onerror = (event) => {
      reject(`IndexedDB error: ${event.target.errorCode}`);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore('permissionData', { keyPath: 'id' });
      // Create indexes if needed
    };
  });
};