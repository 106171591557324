import React, { useEffect, useState } from "react";
import "./TextBox.css";

function TextBox({
  name,
  value,
  id,
  setCoArrow,
  File,
  page,
  setUpdatedFile,
  color,
  changePage,
}) {
  const [textValue, setTextValue] = useState("");
  const [con_level, setConLevel] = useState("");

  useEffect(() => {
    setTextValue(value === null ? "" : value);
  }, [value, File.data_std.key_value_pairs, name, page]);

  useEffect(() => {
    if (color === "high") {
      setConLevel("#01BD4C");
    } else if (color === "mid") {
      setConLevel("#FFD43D");
    } else if (color === "low") {
      setConLevel("#DA0000");
    } else {
      setConLevel("#a0ccf5");
    }
  }, [color]);

  const handleArrow = () => {
    changePage(id);
    if (con_level == "#a0ccf5") {
      setCoArrow({
        isActive: false,
        Start: `textArea-${name}`,
        end: `boundingBox-${name}`,
        color: con_level,
      });
    } else {
      setCoArrow({
        isActive: true,
        Start: `textArea-${name}`,
        end: `boundingBox-${name}`,
        color: con_level,
      });
    }
  };

  const handleHideArrow = () => {
    setCoArrow({
      isActive: false,
    });
  };

  const handleChange = (event) => {
    setTextValue(event.target.value);
    let result = File.data_std.key_value_pairs.map((e) => {
      return {
        ...e,
        key_value_pairs: e.key_value_pairs.map((e2) => {
          return {
            ...e2,
            value: e2.key === name ? event.target.value : e2.value,
          };
        }),
      };
    });

    setUpdatedFile({
      id: File.id,
      date_modified: File.date_modified,
      doc_type: File.doc_type,
      data_std: {
        ...File.data_std,
        key_value_pairs: result,
      },
    });
  };

  return (
    <div className="text_field_input">
      <label htmlFor={name}>
        {name == "providers address"
          ? "PROVIDER ADDRESS"
          : name == "history discription"
          ? "HISTORY DESCRIPTION"
          : name.toUpperCase()}
      </label>

      <div className="text_field_input_box">
        <input
          placeholder={`Enter ${name}`}
          id={`textArea-${name}`}
          name={name}
          value={textValue}
          onChange={handleChange}
          onFocus={handleArrow}
          onBlur={handleHideArrow}
          style={{
            outline: `1px solid ${con_level}`,
          }}
        />
      </div>
    </div>
  );
}

export default TextBox;
