import Tooltip from "@mui/material/Tooltip";
import Axios from "axios";
import Cryptojs from "crypto-js";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack } from '@mui/material';
import { getLoading, setClaimProcess, setCustomCollapse, setTimelineAudit, setViewFile } from "../../../Redux/features/AlertsSlice";
import { setDocumentsList, changeBillName } from "../../../Redux/features/DocumentsSlice";
import CompletedImage from "../../../assets/Icons/Completed_icon.svg";
import ExtractedImage from "../../../assets/Icons/Extractdata_icon.svg";
import NavNext from "../../../assets/Icons/VectorArrowRight.svg";
import NavPrev from "../../../assets/Icons/Vectorarrowleft_active.svg";
import ViewImage from "../../../assets/Icons/View.svg";
import WIPImage from "../../../assets/Icons/WIP.svg";
import OnBoardImage from "../../../assets/Icons/onboard_icon_diabled.svg";
import "./DataExtractionDocTable.css";
import axios from "axios";
import refresh from "../../../assets/Gifs/refresh.gif";
import SetCookie from "../../../HIPCookies/setCookie";
import DocumentImage from "../../../assets/documentlistimage.png";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";


function DataExtractionDocTable({ columns, documents, setDocuments, navigateTo }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const SECRET_KEY_PARAMS = "JEOFH-JFOEH-EOFDH-KHIEO-OADOE-PIADD";

  // const [numberOfRows, setNumberOfRows] = useState(10);
  // const [page, setPage] = useState(0);
  // const [rows, setRows] = useState(0);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({
    documentName: 'asc',
    status: 'asc',
    datemodified: 'asc',
    dateadded: 'asc',
  });
  const [isActiveFilter, setIsActiveFilter] = useState({
    documentName: false,
    status: false,
    modifiedDate: false,
    addedDate: false
  });

  // useEffect(() => {
  //   setRows(Number.parseInt(numberOfRows));
  //   setTotalPages(Math.ceil(documents.length / Number.parseInt(numberOfRows)));
  // }, [documents, numberOfRows, getLoading]);

  // const handleNext = () => {
  //   if (pageNumber < totalPages) {
  //     setPage(page + Number.parseInt(numberOfRows));
  //     setRows(rows + Number.parseInt(numberOfRows));
  //     setPageNumber(pageNumber + 1);
  //   }
  // };

  // const handlePrev = () => {
  //   if (page !== 0) {
  //     setPage(page - Number.parseInt(numberOfRows));
  //     setRows(rows - Number.parseInt(numberOfRows));
  //     setPageNumber(pageNumber - 1);
  //   }
  // };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    const sortedData = [...documents];
    sortedData.sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      // Handling DateModified and Onboarded_time for sorting
      if (key === 'DateModified' || key === 'Onboarded_time') {
        aValue = new Date(aValue) || '' || null;
        bValue = new Date(bValue) || '' || null;
        return sortOrder[key] === 'asc' ? aValue - bValue : bValue - aValue;
      }
      else {
        aValue = a[key] || '' || null;
        bValue = b[key] || '' || null;
        return sortOrder[key] === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });

    setSortOrder(prevSortOrder => ({
      ...prevSortOrder,
      [key]: prevSortOrder[key] === 'asc' ? 'desc' : 'asc',
    }));
    setDocuments(sortedData);
    // Update isActiveFilter
    setIsActiveFilter({
      ...isActiveFilter,
      [key]: true
    });
  };

  const handleNavigate = (id, filename, docType, goTo, status) => {
    dispatch(changeBillName(filename))
    SetCookie("pdfName", filename);
    if (goTo === "view") {
      dispatch(
        setViewFile({
          isActive: true,
          currentId: id,
          currentFileType: docType,
          fileName: filename,
        })
      );
    } else {
      let values = {
        Id: id,
        filename: filename,
        file_type: docType,
        Status: status,
        navigateTo: navigateTo.goBack,
      };

      // let result = Cryptojs.AES.encrypt(
      //   JSON.stringify(values),
      //   SECRET_KEY_PARAMS
      // )
      //   .toString()
      //   .split("/")
      //   .join("|");

      if ("/hip-operations/mbr" === navigateTo.goto) {
        dispatch(setCustomCollapse(true));
        dispatch(setClaimProcess(true));
      }
      navigate(`${navigateTo.goto}/review-file`);
    }
  };

  const handleTimeline = (id) => {
    dispatch(
      setTimelineAudit({
        isActive: true,
        Id: id,
      })
    );
  };

  const baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;

  const RefershAllDocuments = async () => {
    let result = await Axios.get(`${baseURL}/list_of_Documents`);
    dispatch(setDocumentsList(result.data));
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, documents.length);

  return (
    <>
      <div className="mbw_table_doc_container">
        <table>
          <thead>
            <tr >
              {columns.map((col, index) => {
                if (col.headerName === "Actions") {
                  return (
                    <td style={{ width: col.width, textAlign: col.alignment }} key={index}>
                      <div className="header-content">
                        <span>{col.headerName}</span>
                      </div>
                    </td>
                  );
                } else {
                  return (
                    <td style={{ width: col.width, textAlign: col.alignment }} key={index} onClick={() => handleSort(col.key)}>
                      <div className="header-content">
                        <span>{col.headerName}</span>
                        <div className="sort-icons">
                          {isActiveFilter[col.key] && sortOrder[col.key] === 'asc' ? (
                            <>
                              <ArrowDropUpIcon style={{ marginBottom: -5, width: '16px', height: '16px' }} />
                              <ArrowDropDownIcon style={{ marginTop: -5, opacity: 0.5, width: '16px', height: '16px' }} />
                            </>
                          ) : isActiveFilter[col.key] && sortOrder[col.key] === 'desc' ? (
                            <>
                              <ArrowDropUpIcon style={{ marginBottom: -5, opacity: 0.5, width: '16px', height: '16px' }} />
                              <ArrowDropDownIcon style={{ marginTop: -5, width: '16px', height: '16px' }} />
                            </>
                          ) : (
                            <>
                              <ArrowDropUpIcon style={{ marginBottom: -5, width: '16px', height: '16px' }} />
                              <ArrowDropDownIcon style={{ marginTop: -5, opacity: 0.5, width: '16px', height: '16px' }} />
                            </>
                          )}
                        </div>
                      </div>
                    </td>
                  );
                }
              })}
            </tr>
          </thead>
          {documents.length === 0 ? (
            <></>
          ) : (
            <tbody>
              {documents.slice(startIndex, endIndex).map((row, index) => {
                return (
                  <tr key={index} className={index % 2 === 0 ? "mbw_row_even" : "mbw_row_odd"} >
                    {<td className="mbw_document_names">
                      <img src={DocumentImage} alt="document" />
                      {row.Name}
                    </td>}
                    <td>
                      {row.Status !== "WIP" ? (
                        <span
                          className={
                            row.Status === "Onboarded"
                              ? "mbw_document_status document_status_onboarded"
                              : row.Status === "Extracted"
                                ? "document_status document_status_extracted"
                                : row.Status === "Completed"
                                  ? "mbw_document_status document_status_Completed"
                                  : "mbw_document_status document_status_WIP"
                          }
                        >
                          {row.Status}
                        </span>
                      ) : (
                        <span
                          className={
                            row.Status === "Onboarded"
                              ? "mbw_document_status document_status_onboarded"
                              : row.Status === "Extracted"
                                ? "document_status document_status_extracted"
                                : row.Status === "Completed"
                                  ? "mbw_document_status document_status_Completed"
                                  : "mbw_document_status document_status_WIP"
                          }
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "23px"
                          }}
                        >
                          <>
                            <img style={{ height: "16px", marginRight: "3px" }} src={refresh} alt="loading" />
                            Processing
                          </>
                        </span>
                      )}
                    </td>
                    <td>{row.DateModified === null ? '\u00A0NA' : (
                      <>
                        {row.DateModified.split(' ')[0]}<br />
                        {row.DateModified.split(' ')[1]}
                      </>
                    )}
                    </td>
                    <td>{row.Onboarded_time === null ? "NA" : (
                      <>
                        {row.Onboarded_time.split(' ')[0]}<br />
                        {row.Onboarded_time.split(' ')[1]}
                      </>
                    )}</td>
                    <td>
                      <div className="document_table_last_child_bill_worksheet_operations">
                        {row.Status === "Onboarded" ? (
                          <Tooltip placement="top" title="Extracting Data">
                            <div className="mbw_document_table_icon_span"
                              // onClick={RefershAllDocuments}
                              style={{
                                opacity: 0.5,
                                pointerEvents: "none",
                              }}
                            >
                              <img style={{ width: '28px', height: '28px' }} src={ViewImage} alt="view" />
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip placement="top" title="View">
                            <div className="mbw_document_table_icon_span"
                              onClick={() => handleNavigate(row.id, row.Name, row.Type, "view")}
                            >
                              <img style={{ width: '28px', height: '28px' }} src={ViewImage} alt="view" />
                            </div>
                          </Tooltip>
                        )}
                        {row.Status === "Onboarded" ? (
                          <>
                            <Tooltip placement="top" title="Extracting Data">
                              <div className="mbw_document_table_icon_span"
                                // onClick={RefershAllDocuments}
                                style={{
                                  opacity: 0.5,
                                }}
                              >
                                <img src={OnBoardImage} alt="extracting-data" />
                              </div>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            {row.Status === "Completed" ? (
                              <Tooltip placement="top" title="Verified">
                                <div className="mbw_document_table_icon_span"
                                  onClick={() =>
                                    handleNavigate(
                                      row.id,
                                      row.Name,
                                      row.Type,
                                      "review",
                                      row.Status
                                    )
                                  }
                                >
                                  <img src={CompletedImage} alt="verified" />
                                </div>
                              </Tooltip>
                            ) : row.Status === "Extracted" ? (
                              <Tooltip placement="top" title="Verify">
                                <div className="mbw_document_table_icon_span"
                                  onClick={() =>
                                    handleNavigate(
                                      row.id,
                                      row.Name,
                                      row.Type,
                                      "review",
                                      row.Status
                                    )
                                  }
                                >
                                  <img src={ExtractedImage} alt="verify" />
                                </div>
                              </Tooltip>
                            ) : row.Status === "WIP" ? (
                              <Tooltip placement="top" title="Verify">
                                <div className="mbw_document_table_icon_span"
                                  onClick={() =>
                                    handleNavigate(
                                      row.id,
                                      row.Name,
                                      row.Type,
                                      "review",
                                      row.Status
                                    )
                                  }
                                >
                                  <img src={WIPImage} alt="verify" />
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip placement="top" title="Verify">
                                <div className="mbw_document_table_icon_span"
                                  onClick={() =>
                                    handleNavigate(
                                      row.id,
                                      row.Name,
                                      row.Type,
                                      "review",
                                      row.Status
                                    )
                                  }
                                >
                                  <img src={OnBoardImage} alt="verify" />
                                </div>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>

      {/* Pagination */}
      {/* <div className="mbw_document_table_pagination_container">
        <div className="mbw_document_table_pagination_span">
          <div className="mbw_document_table_pagination_select_box">
            <b>Row per pages: </b>
            <select
              value={numberOfRows}
              onChange={(e) => {
                setPageNumber(1);
                setPage(0);
                setNumberOfRows(e.target.value);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
          <div className="mbw_document_table_pagination_pages">
            <b>
              {pageNumber !== totalPages
                ? `${page + 1} - ${rows}`
                : `${page + 1} - ${documents.length}`}{" "}
              of {documents.length}
            </b>
          </div>
          <div className="mbw_document_table_pagination_navigation">
            <Tooltip placement="top" title="Previous">
              <div className="mbw_table_nav_btn" onClick={handlePrev}>
                <img src={NavPrev} alt="prev" />
              </div>
            </Tooltip>
            <Tooltip placement="top" title="Next">
              <div className="mbw_table_nav_btn" onClick={handleNext}>
                <img src={NavNext} alt="next" />
              </div>
            </Tooltip>
          </div>
        </div>
      </div> */}
      <Stack sx={{ display: " flex", justifyContent: "space-between", mt: "1rem" }}>
        <CustomPagination
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          claims={documents}
          totalItems={documents.length}
        />
      </Stack>
    </>
  );
}

export default DataExtractionDocTable;
