import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { addMessage } from "../../../Redux/features/convMessage";
import { setDataInfo, setSession } from "../../../Redux/features/sessionsSlice";
import { startLoading, stopLoading } from "../../../Redux/features/themeSlice";
import HipOneLogo from "../../../assets/HIP Logo Horizontal.svg";
import securityLogo from "../../../assets/securitylogo.svg";
import ChatBar from "../ChatBar/ChatBar";
import Messages from "../ChatBox/Messages";
import TryAsking from "../TryAsking/TryAsking";
import "./ChatboxInterface.css";
import HipLogoLight from "../../../assets/HIP Logo_Horizontal_Light.svg";
import { Container } from "@mui/material";

const ChatboxInterface = () => {
  const [usedSuggestedQuestion, setUsedSuggestedQuestion] = useState(false);
  const baseUrl = `${process.env.REACT_APP_CONNECT}/conv-web`;
  const [suggestedQuestions] = useState([
    "What are the services offered by HIP One?",
    "How is data privacy ensured by HIP One?",
  ]);
  const darkMode = useSelector((state) => state.theme.darkMode);
  const isLoading = useSelector((state) => state.theme.isLoading);
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.convMessages);
  const [IPaddress, setIPaddress] = useState(""); // Use state to manage IP address
  const [sessionId, setSessionId] = useState(""); // Use state to manage session ID

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ipResponse = await axios.get("https://ipinfo.io/ip");
        setIPaddress(ipResponse.data);

        generateSessionId();
        dispatch(setSession(sessionId));
      } catch (error) {}
    };

    fetchData(); // Call the async function to fetch IP and set session
  }, [dispatch]);

  function generateSessionId() {
    setSessionId(uuidv4());
  }

  const handleFeedback = (backend, isLike) => {
    // Find the index of the backend's response
    const backendIndex = messages.findIndex(
      (msg) => msg.id === "backend" && msg.text === backend
    );

    if (backendIndex !== -1 && backendIndex > 0) {
      // Extract the user's message just before the backend's response
      const userMessage = messages[backendIndex - 1].text;

      // Create the feedback data
      const feedbackData = {
        session_id: sessionId,
        ip_address: IPaddress,
        question: userMessage,
        answer: backend,
        feedback: isLike, // Assuming isLike is a boolean value
      };

      try {
        const response = axios.post(
          `${baseUrl}/dashboard_feedback_update`,
          feedbackData
        );
      } catch (error) {}

      dispatch(setDataInfo(feedbackData));
    } else {
      console.error("Backend's response not found in the messages.");
    }
  };

  // Assuming you have a "messages" slice in your Redux store
  const addMessageToConversation = async (message) => {
    const userMessage = { id: message.id, text: message.text };
    dispatch(addMessage(userMessage));
    dispatch(startLoading());

    try {
      const response = await axios.post(`${baseUrl}/openai/get_answer`, {
        query: message.text,
        session_id: sessionId,
        ip_address: IPaddress,
      });

      if (response.data) {
        const backendResponse = {
          id: "backend",
          text: response.data,
        };
        dispatch(addMessage(backendResponse));
      } else {
        throw new Error("No data received from the API");
      }
    } catch (error) {
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSuggestedQuestionClick = (question) => {
    setUsedSuggestedQuestion(true);

    const message = { id: "user", text: question };
    addMessageToConversation(message);
  };

  return (
    <>
      <div className="hip-one-chatbox-container">
        <Grid>
          <div
            className={
              darkMode ? "chatbox_dark-mode" : "chatbox-interface__container"
            }
          >
            {messages.length <= 0 && (
              <img
                src={darkMode ? HipLogoLight : HipOneLogo}
                alt="Logo"
                className="hip-one-logo"
              />
            )}

            <Grid
              className={`chatbox-interface__messages-container ${
                messages.length > 0 ? "has-messages" : ""
              }`}
              data-message-count={messages.length}
            >
              <Messages
                messages={messages}
                isMessageLoading={isLoading}
                handleFeedback={handleFeedback}
              />
            </Grid>
            <div
              className={
                messages.length === 0
                  ? "chatbox-interface__chat-bar_no-message"
                  : "chatbox-interface__chat-bar"
              }
            >
              <ChatBar onSendMessage={addMessageToConversation} />
            </div>
            <div className="chatbox-interface-text-description">
              {darkMode && (
                <div className="chatbox-interface__dark-mode-content">
                  <img
                    src={securityLogo}
                    alt="Security Logo"
                    className="chatbox-interface__security-logo"
                  />
                  Your Data. Your Innovation. Secure. Enabled by HIP One Enabled
                  by HIP One
                </div>
              )}

              <div className="chatbox-interface__description">
                HIP One is the leading intelligence platform powered by Gen AI
                tailored specifically for healthcare built securely to converse
                with built-in privacy.
              </div>
            </div>

            {!usedSuggestedQuestion && messages.length === 0 && (
              <div className="chatbox-interface-try-asking">
                <TryAsking
                  darkMode={darkMode}
                  suggestedQuestions={suggestedQuestions}
                  onQuestionClick={handleSuggestedQuestionClick}
                />
              </div>
            )}
          </div>
        </Grid>
      </div>
    </>
  );
};

export default ChatboxInterface;
