import { styled } from "@mui/system";
import Switch from "@mui/material/Switch";

// Define a custom-styled IOSSwitch component based on MUI's Switch component
const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 40, // Increased width of the switch
  height: 20, // Set the height of the switch
  padding: 0, // Remove any padding

  // Styles for the base of the switch
  "& .MuiSwitch-switchBase": {
    padding: 1,

    // Styles when the switch is checked (i.e., toggled ON)
    "&.Mui-checked": {
      transform: "translateX(20px)", // Move the thumb to the right when checked
      color: theme.palette.common.white, // Set thumb color when checked

      // Styles for the track immediately after the switch base when checked
      "& + .MuiSwitch-track": {
        backgroundColor: "#01BD4C", // Set the background color to green when checked
        opacity: 1,
        border: "none", // Remove any borders
      },
    },

    // Styles for the thumb when the switch is focused
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#01BD4C", // Set thumb color when focused
      border: "6px solid #fff", // Set a white border around the thumb when focused
    },
  },

  // Styles for the switch thumb (the circular toggle part)
  "& .MuiSwitch-thumb": {
    width: 15, // Set thumb width
    height: 15, // Set thumb height
    margin: 1.5,
  },

  // Styles for the track (the background part) of the switch
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2, // Set border radius for the track
    border: `1px solid ${theme.palette.grey[400]}`, // Set a border for the track
    backgroundColor: theme.palette.grey[10], // Set the track's background color
    opacity: 0.2,
    transition: theme.transitions.create(["background-color", "border"]), // Apply a transition effect for these properties
  },

  "@media (max-width:600px)": {
    "& .MuiSwitch-thumb": {
      width: 18, // Reduced thumb width for mobile
      height: 18, // Reduced thumb height for mobile
    },
    width: 42, // Reduced width of the switch for mobile
    height: 22, // Reduced height of the switch for mobile
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(20px)", // Adjusted movement of the thumb when checked for mobile
    },
  },
}));

export default IOSSwitch;
