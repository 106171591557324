import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Paper,
} from "@mui/material";
import allStates from "./allstates.json";
import "./USAMap.css";
import LinearWithValueLabel from "./LinearWithValueLabel.js";
import { Box, Grid, Stack } from "@mui/material";
import LoadingScreen from "../../../../Billing/Pages/LoadingScreen/LoadingScreen.js";
const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const USAMap = (props) => {
  const mapData = useSelector((state) => state.startDashBoard.responseDashData);
  const [states, setStates] = useState([]);
  const [maximumEnrollment, setMaximumEnrollment] = useState();

  // console.log("mapData", mapData)

  useEffect(() => {
    if (mapData && mapData.map_result) {
      setStates(mapData.map_result);
    }
  }, [mapData]);

  useEffect(() => {
    if (states.length > 0) {
      const maxEnrollment = getMaxEnrollment(states);
      setMaximumEnrollment(maxEnrollment);
    }
  }, [states, mapData]);

  const colors = ["#097F54", "#25B771", "#DA0000"]; // Example colors array

  const getMaxEnrollment = (statesData) => {
    let maxEnrollment = 0;

    // Loop through each state data object to find the max enrollment
    statesData.forEach((state) => {
      const enrollment = state.enrollment_count;

      // If the current enrollment is greater than the current max, update maxEnrollment
      if (enrollment > maxEnrollment) {
        maxEnrollment = enrollment;
      }
    });

    return maxEnrollment;
  };

  function getStateName(stateCode) {
    const stateInfo = allStates.find(state => state.id === stateCode);
    return stateInfo ? stateInfo.stateName.toUpperCase() : "NA";
}

  const getColorForState = (stateId) => {
    const state = allStates.find((s) => s.val === stateId);
    const coloredState = states.find((s) => s.state_code === state.id);

    if (coloredState && coloredState["enrollment_count"]) {
      const state_enrollment_count = coloredState["enrollment_count"];

      // Calculate percentage enrollment
      const percentage = (state_enrollment_count / maximumEnrollment) * 100;

      // Determine color based on percentage
      if (percentage >= 75) {
        return colors[0];
      } else if (percentage >= 33) {
        return colors[1]
      } else {
        return colors[2];
      }
    }
    return "#DDD";
  };


  const FilteredStateObject = (states ?? [])
    .map((state) => ({
      STCD: state?.state_code,
      statename:
        state?.state_name?.charAt(0).toUpperCase() +
        state?.state_name?.slice(1),
      enrollment: state?.enrollment_count ?? 0,
      percentage: (
        ((state?.enrollment_count ?? 0) / maximumEnrollment) *
        100
      ).toFixed(2),
    }))
    .sort((a, b) => b.percentage - a.percentage);

  return props.isLoading ? (
    <LoadingScreen />
  ) : (
    <Paper
      elevation={3}
      sx={{
        borderRadius: "4px",
        border: "1px solid #E0E3E8",
        background: "#fff",
        boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
        overflow: "hidden",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ position: "relative", height: "355px", overflow: "hidden" }}
        >
          <Stack
            sx={{ width: "100%", height: "100%" }}
            className="map-container"
          >
            <ComposableMap projection="geoAlbersUsa">
              <Geographies geography={geoUrl}>
                {({ geographies }) => (
                  <>
                    {geographies?.map((geo, index) => (
                      <Geography
                        key={geo.rsmKey}
                        stroke="#FFF"
                        geography={geo}
                        fill={getColorForState(geo.id)}
                        style={{ outline: "none" }}
                      />
                    ))}

                    {geographies?.map((geo) => {
                      const centroid = geoCentroid(geo);
                      const cur = allStates.find((s) => s.val === geo.id);
                      return (
                        <g key={geo.rsmKey + "-name"}>
                          {cur &&
                            centroid[0] > -160 &&
                            centroid[0] < -67 &&
                            (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                              <Marker coordinates={centroid}>
                                <text
                                  y="2"
                                  fontSize={12}
                                  textAnchor="middle"
                                  fill="#fff"
                                >
                                  {cur.id}
                                </text>
                              </Marker>
                            ) : (
                              <Annotation
                                subject={centroid}
                                dx={offsets[cur.id][0]}
                                dy={offsets[cur.id][1]}
                              >
                                <text
                                  x={4}
                                  fontSize={12}
                                  alignmentBaseline="middle"
                                  fill="#211A1D"
                                >
                                  {cur.id}
                                </text>
                              </Annotation>
                            ))}
                        </g>
                      );
                    })}
                  </>
                )}
              </Geographies>
            </ComposableMap>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: { xs: "45px", md: "60px" },
                paddingRight: "20px",
                justifyContent: "flex-start",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  color: "#1A1B21",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Enrollment
              </Typography>
              <Box
                sx={{
                  width: "8px",
                  height: "8px",
                  backgroundColor: "#097F54",
                  marginLeft: "20px",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Inter",
                  color: "#444",
                  fontSize: "12px",
                  fontWeight: 400,
                  marginLeft: "6px",
                }}
              >
                High
              </Typography>
              <Box
                sx={{
                  width: "8px",
                  height: "8px",
                  backgroundColor: "#25B771",
                  marginLeft: "20px",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Inter",
                  color: "#444",
                  fontSize: "12px",
                  fontWeight: 400,
                  marginLeft: "6px",
                }}
              >
                Moderate
              </Typography>
              <Box
                sx={{
                  width: "8px",
                  height: "8px",
                  backgroundColor: "#DA0000",
                  marginLeft: "20px",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Inter",
                  color: "#444",
                  fontSize: "12px",
                  fontWeight: 400,
                  marginLeft: "6px",
                }}
              >
                Low
              </Typography>
            </Box>
          </Stack>
        </Grid>

        {/* State Data Section */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{ position: "relative", height: "370px", overflowY: "auto" }}
        >
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              padding: "22px 24px",
              boxSizing: "border-box",
            }}
            className="statedataouter-container"
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
              className="statedataheader-container"
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                State wise Contracts
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  color: "#008AED",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                View more
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
              className="statedata-container"
            >
              {FilteredStateObject &&
                FilteredStateObject?.slice(0, 6).map((state) => (
                  <Box
                    key={state.STCD}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: "34px",
                      flexDirection: "column",
                    }}
                    className="state-info-container"
                  >
                    {/* Box for Total Enrollment */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          color: "#211A1D",
                          fontSize: "12px",
                          fontWeight: 500,
                          marginRight: "10px",
                        }}
                      >
                        Total Enrollment
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          color: "#211A1D",
                          fontSize: "12px",
                          fontWeight: 500,
                          marginRight: "10px",
                        }}
                      >
                        {state.enrollment?.toLocaleString("en-US")}
                      </Typography>
                    </Box>
                    {/* Inner Box containing state abbreviation, name, and progress bar */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      className="state-info-wrapper"
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            color: "#211A1D",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginRight: "10px",
                          }}
                          className="state-info"
                        >
                          {state.STCD}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            color: "#5A03EE",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                          className="state-info"
                        >
                          {/* {state.statename} */}
                          {getStateName(state.STCD)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="progress-bar-wrapper"
                      >
                        <Box className="progress-bar">
                          <LinearWithValueLabel percentage={state.percentage} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default USAMap;
