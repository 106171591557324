import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import userIcon from "../../../../assets/Icons/Message-icon.svg";
import "./UserMessage.css";

export default function UserMessage(props) {
  const ref = useRef();
  const [timestamp, setTimestamp] = useState("");
  const darkMode = useSelector((state) => state.theme.darkMode);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });

    const date = new Date();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    setTimestamp(`${String(hours).padStart(2, "0")}:${minutes} ${ampm}`);
  }, [props.message]);

  return (
    <Grid
      className={`userMsgContainer ${darkMode ? "dark-mode" : ""}`}
      ref={ref}
    >
      <img className="userMsgIcon" src={userIcon} alt="User Icon" />
      <div className="userMsgBox">
        <div className="userMsgContent">
          <p className={`userMsgText ${darkMode ? "dark-text" : ""}`}>
            {props.message}
          </p>
        </div>
        <span className={`userMsgTimestamp ${darkMode ? "dark-text" : ""}`}>
          {timestamp}
        </span>
      </div>
    </Grid>
  );
}
