import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import answerDarkLogo from "../../../../assets/answerdarklogo.svg";
import thumbsUp from "../../../../assets/like.svg";
import thumbsDown from "../../../../assets/dislike.svg";
import filledThumbDown from "../../../../assets/dislike_filled.svg";
import filledThumbsUp from "../../../../assets/filledlike.svg";
import genAnswerDownLogo from "../../../../assets/genasnwerdownlogo.svg";
import userIcon from "../../../../assets/Hip-One-Logo.svg";
import "./GeneratedMessage.css";
import { Grid, Paper } from "@material-ui/core";

export default function GeneratedMessage(props) {
  const ref = useRef();
  const [timestamp, setTimestamp] = useState("");
  const [likeActive, setLikeActive] = useState(false);
  const [dislikeActive, setDislikeActive] = useState(false);
  const darkMode = useSelector((state) => state.theme.darkMode);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });

    const date = new Date();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    setTimestamp(`${String(hours).padStart(2, "0")}:${minutes} ${ampm}`);
  }, [props.message, props.typing]);

  const handleLikeClick = () => {
    if (likeActive) {
      setLikeActive(false);
    } else {
      setLikeActive(true);
      setDislikeActive(false); // Deactivate dislike
      const backend = props.message;
      props.handleFeedback(backend, true);
    }
  };
  const handleDislikeClick = () => {
    if (dislikeActive) {
      setDislikeActive(false);
    } else {
      setDislikeActive(true);
      setLikeActive(false); // Deactivate like
      // Call the handleFeedback function with isLike set to false
      const backend = props.message;
      props.handleFeedback(backend, false);
    }
  };

  let messageContent;
  if (props.typing) {
    messageContent = (
      <div
        className={`custom-typing-indicator ${props.typing ? "show" : ""}`}
      />
    );
  } else if (props.message.trim().includes("I don't know")) {
    messageContent = (
      <p className={`gen-message-p ${darkMode ? "dark-text" : ""}`}>
        We apologize, but we couldn't find the information you're looking for at
        the moment. It's possible that the information may not be available in
        our database or there was a problem with your search query. Please
        contact info@genzeon.com for further assistance.
      </p>
    );
  } else {
    // Split the message into paragraphs based on "\n\n" delimiter and display in columns
    const paragraphs = props.message.split("\n\n");
    messageContent = (
      <div className="column-layout">
        {paragraphs.map((paragraph, index) => (
          <p
            key={index}
            className={`gen-message-p ${darkMode ? "dark-text" : ""}`}
          >
            {paragraph}
          </p>
        ))}
      </div>
    );
  }

  return (
    <Grid container className="custom-responsiveContainer">
      <Grid item xs={12}>
        <div
          className={`custom-message-container ${darkMode ? "dark-mode" : ""}`}
        >
          <img className="custom-gen-chat-img" src={userIcon} alt="User Icon" />
          <div className="custom-message-box">
            <div className="custom-answer-header">
              <img
                src={darkMode ? answerDarkLogo : genAnswerDownLogo}
                alt="Answer Logo"
                className="custom-answer-logo"
              />
              <span className="custom-answer-text">Answer</span>
            </div>
            <div className="message-content">
              <div className="message" ref={ref}>
                {messageContent}
                <div className="custom-message-actions">
                  <button
                    className="custom-like-button"
                    onClick={handleLikeClick}
                  >
                    {darkMode ? (
                      ""
                    ) : (
                      <img
                        src={likeActive ? filledThumbsUp : thumbsUp}
                        alt="Thumbs Up Logo"
                        className="custom-like-button"
                      />
                    )}
                  </button>
                  <button
                    className="custom-dislike-button"
                    onClick={handleDislikeClick}
                  >
                    {darkMode ? (
                      ""
                    ) : (
                      <img
                        src={dislikeActive ? filledThumbDown : thumbsDown}
                        alt="Thumbs Down Logo"
                        className="custom-dislike-button"
                      />
                    )}
                  </button>
                  <span
                    className={`custom-timestamp ${
                      darkMode ? "dark-text" : ""
                    }`}
                  >
                    {timestamp}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
