import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Rating,
  Typography,
  Button,
  Stack,
  styled,
} from "@mui/material";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import LoadingScreen from "../../../../Billing/Pages/LoadingScreen/LoadingScreen";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";
import { lineHeight } from "@mui/system";
import { setContractId } from "../../../../Redux/features/StarSlice";

// const useStyles = makeStyles({
//   contractDataTableHeader: {
//     color: '#1A1B21',
//     fontFamily: 'Inter',
//     fontSize: '12px',
//     fontStyle: 'normal',
//     fontWeight: '500',
//     lineHeight: "14px",
//     letterSpacing: '0.168px'
//     // Add more styles as needed
//   },

//   contractDataTableBody : {
//     color: '#211A1D',
//     fontFamily: 'Inter',
//     fontSize: '12px',
//     fontStyle: 'normal',
//     fontWeight: '400',
//     lineHeight: "14px",
//   }
// });

const StyledTableHeaderTypography = styled(Typography)`
  color: #1a1b21;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.168px;
`;

const StyledTableBodyTypography = styled(Typography)`
  color: #211a1d;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export default function ContractDataTable(props) {
  const encryptionKey = "SecureKey";
  const decryptionKey = "SecureKey";

  const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value, encryptionKey).toString();
  };

  // Function to decrypt the value
  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, decryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  // const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortedData, setSortedData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState({
    contractNames: "asc",
    overallRating: "asc",
    offersPartD: "asc",
    specialNeedsPlan: "asc",
    enrollmentCount: "asc",
  });

  const handleSortContractNames = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a["contract_name"] || "";
      const bValue = b["contract_name"] || "";
      return sortOrder["contractNames"] === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
    setSortOrder((prevSortOrder) => ({
      ...prevSortOrder,
      contractNames: prevSortOrder["contractNames"] === "asc" ? "desc" : "asc",
    }));
    setData(sortedData);
  };
  function SetContractId(contractId) {
    // console.log('contract id dispatch',contractId)

    dispatch(setContractId(contractId));

    const contract = encryptValue(contractId);
    // Cookies.set("parent", firstParentt);
    localStorage.setItem("contract", contract);
  }

  const handleSortOverallRating = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a["overall_star_current"];
      const bValue = b["overall_star_current"];
      return sortOrder["overallRating"] === "asc"
        ? aValue - bValue
        : bValue - aValue;
    });
    setSortOrder((prevSortOrder) => ({
      ...prevSortOrder,
      overallRating: prevSortOrder["overallRating"] === "asc" ? "desc" : "asc",
    }));
    setData(sortedData);
    // console.log("Sorted Data1", sortedData)
  };

  const handleSortOffersPartD = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a["offers_part_d"] || false;
      const bValue = b["offers_part_d"] || false;
      return sortOrder["offersPartD"] === "asc"
        ? aValue - bValue
        : bValue - aValue;
    });
    setSortOrder((prevSortOrder) => ({
      ...prevSortOrder,
      offersPartD: prevSortOrder["offersPartD"] === "asc" ? "desc" : "asc",
    }));
    setData(sortedData);
  };

  const handleSortSpecialNeedsPlan = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a["snp"] || false;
      const bValue = b["snp"] || false;
      return sortOrder["specialNeedsPlan"] === "asc"
        ? aValue - bValue
        : bValue - aValue;
    });
    setSortOrder((prevSortOrder) => ({
      ...prevSortOrder,
      specialNeedsPlan:
        prevSortOrder["specialNeedsPlan"] === "asc" ? "desc" : "asc",
    }));
    setData(sortedData);
  };

  const handleSortEnrollmentCount = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a["enrollment_count_current"];
      const bValue = b["enrollment_count_current"];
      return sortOrder["enrollmentCount"] === "asc"
        ? aValue - bValue
        : bValue - aValue;
    });
    setSortOrder((prevSortOrder) => ({
      ...prevSortOrder,
      enrollmentCount:
        prevSortOrder["enrollmentCount"] === "asc" ? "desc" : "asc",
    }));
    setData(sortedData);
  };

  const storedObject = useSelector(
    (state) => state.startDashBoard.responseDashData
  );

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    const newData = storedObject.distinct_contract_info || [];
    setData(newData);
    setOriginalData(newData); // Update originalData with fetched data
  }, [storedObject.distinct_contract_info]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    setSortedData(data.slice(startIndex, endIndex)); // Use data instead of originalData here
  }, [rowsPerPage, currentPage, data]);

  const tableContainerHeight = rowsPerPage * 65 + 140;

  // console.log("Table data", sortedData)

  return props.isLoading ? (
    <LoadingScreen />
  ) : (
    <Box>
      <Box
        elevation={3}
        className="paper-container"
        sx={{
          // height: `${tableContainerHeight}px`,
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #E0E3E8",
          background: "#fff",
          boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
        }}
      >
        <Box sx={{ padding: "20px" }}>
          <TableContainer>
            <Table
              style={{ borderCollapse: "collapse", borderSpacing: "0 8px" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "10%",borderBottom: "none" }}>
                    <StyledTableHeaderTypography>
                      Contract ID
                    </StyledTableHeaderTypography>
                  </TableCell>
                  <TableCell
                    style={{ width: "20%", borderBottom: "none" }}
                    onClick={handleSortContractNames}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <StyledTableHeaderTypography>
                        Contract Name
                      </StyledTableHeaderTypography>
                      <Box sx={{ marginLeft: "8px" }}>
                        {sortOrder["contractNames"] === "asc" ? (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{ width:'20%', borderBottom: "none" }}
                    onClick={handleSortOverallRating}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <StyledTableHeaderTypography>
                        Overall Rating
                      </StyledTableHeaderTypography>
                      <Box sx={{ marginLeft: "8px" }}>
                        {sortOrder["overallRating"] === "asc" ? (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{ borderBottom: "none" }}
                    onClick={handleSortOffersPartD}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <StyledTableHeaderTypography>
                        Offers Part D
                      </StyledTableHeaderTypography>
                      <Box sx={{ marginLeft: "8px" }}>
                        {sortOrder["offersPartD"] === "asc" ? (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{ borderBottom: "none" }}
                    onClick={handleSortSpecialNeedsPlan}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <StyledTableHeaderTypography>
                        SNP(Special Needs Plan)
                      </StyledTableHeaderTypography>
                      <Box sx={{ marginLeft: "8px" }}>
                        {sortOrder["specialNeedsPlan"] === "asc" ? (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{ borderBottom: "none" }}
                    onClick={handleSortEnrollmentCount}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <StyledTableHeaderTypography>
                        Enrollment Count
                      </StyledTableHeaderTypography>
                      <Box sx={{ marginLeft: "8px" }}>
                        {sortOrder["enrollmentCount"] === "asc" ? (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDropUpIcon
                                style={{
                                  marginBottom: -5,
                                  opacity: 0.5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <ArrowDropDownIcon
                                style={{
                                  marginTop: -5,
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <StyledTableHeaderTypography>
                      Action
                    </StyledTableHeaderTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{ height: "60px", borderBottom: "none !important" }}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#FFF8EF',
                      },
                      '&:active': {
                        backgroundColor: '#FFF8EF',
                      }
                    }}
                  >
                    <TableCell style={{ borderBottom: "none" }}>
                      <StyledTableBodyTypography>
                        {row.contract_id}
                      </StyledTableBodyTypography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <StyledTableBodyTypography>
                        {row.contract_name
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </StyledTableBodyTypography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Box sx={{ display: "flex" }}>
                        <StarRating
                          value={parseFloat(row.overall_star_current)}
                        />
                        <Box marginLeft={"5px"}>
                          <StyledTableBodyTypography
                            style={{ fontWeight: "500", lineHeight: "24px" }}
                          >
                            {row.overall_star_current}
                          </StyledTableBodyTypography>
                        </Box>
                        {row.overall_star_current !== null &&
                          row.overall_star_previous !== null &&
                          row.overall_star_current !== 0 &&
                          row.overall_star_previous !== 0 && 
                          row.overall_star_current != row.overall_star_previous && (
                            <Box marginLeft={"10px"}>
                              {row.overall_star_current >
                              row.overall_star_previous ? (
                                <TrendingUpIcon sx={{ fill: "#179B62" }} />
                              ) : (
                                <TrendingDownIcon sx={{ fill: "#FF0000" }} />
                              )}
                            </Box>
                          )}
                      </Box>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <StyledTableBodyTypography>
                        {row.offers_part_d !== null
                          ? row.offers_part_d
                            ? "Yes"
                            : "No"
                          : "NA"}
                      </StyledTableBodyTypography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <StyledTableBodyTypography>
                        {row.snp ? "Yes" : "No"}
                      </StyledTableBodyTypography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Box sx={{ display: "flex" }}>
                        <StyledTableBodyTypography
                          style={{ lineHeight: "16px", marginTop: "7px" }}
                        >
                          {row.enrollment_count_current !== null
                            ? row.enrollment_count_current?.toLocaleString(
                                "en-US"
                              )
                            : "NA"}
                        </StyledTableBodyTypography>
                        {row.enrollment_count_current !== null &&
                          row.enrollment_count_previous !== null &&
                          row.enrollment_count_current !==
                            row.enrollment_count_previous && (
                            <Box marginLeft={"10px"} sx={{ marginTop: "3px" }}>
                              {row.enrollment_count_current >
                              row.enrollment_count_previous ? (
                                <TrendingUpIcon sx={{ fill: "#179B62" }} />
                              ) : (
                                <TrendingDownIcon sx={{ fill: "#FF0000" }} />
                              )}
                            </Box>
                          )}
                      </Box>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Link to={`/hip-operations/contract-details`}>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#5A03EE",
                            borderRadius: "4px",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            textAlign: "center",
                            width: "60px",
                            height: "28px",
                            marginTop: "-18px",
                          }}
                          onClick={() => SetContractId(row.contract_id)}
                        >
                          View
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box>
        <Stack
          sx={{
            display: " flex",
            justifyContent: "space-between",
            mt: "0.5rem",
          }}
        >
          <CustomPagination
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            claims={data}
          />
        </Stack>
      </Box>
    </Box>
  );
}

function StarRating(props) {
  const { value } = props;
  return (
    <Rating
      name="read-only"
      value={value}
      precision={0.5}
      readOnly
      sx={{
        "& .MuiRating-icon": {
          width: "18px",
          height: "17.25px",
          marginRight: "4px",
          // marginTop: "-5px",
        },
      }}
    />
  );
}
