import React from 'react';
import "../../../Theme/HIPBreadCrumb.css";
import { useLocation, useNavigate, NavLink as Link } from 'react-router-dom';
import BackImage from '../../../assets/Back.svg';
import HomeImage from '../../../assets/home.svg';
import RightArrowImage from '../../../assets/RightArrow.svg';

function HiPSettingBreadCrumb() {

    const location = useLocation();
    const navigate = useNavigate();

    return (

        <div className='bread_crumb_navigations'>
            <div className="bread_crumb_back_container" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <img src={BackImage} alt="" style={{ width: '18px' }} />
            </div>
            <div className='bread_crumb_navigations_content' >
                <a href='/dashboard'>
                    <img src={HomeImage} alt='home' />
                </a>
                <div className='bread_crumb_arrow_container'>
                    <img src={RightArrowImage} alt='arrow right' className='bread_crumb_arrow' />
                </div>
            </div>
            {
                Routes.filter(item => item.currentroute === location.pathname || item.currentroute.split('/')[1] === location.pathname.split('/')[2]).map((e, index) => {
                    return (
                        <div className="bread_crumb_navigation_sub_container" key={index}>
                            {
                                e.currentroute === '/settings/user-management' ?
                                    <>
                                        <Link to='/settings/user-management'>Settings</Link>
                                        <div className="bread_crumb_navigation_sub_content" >
                                            <img src={RightArrowImage} alt='arrow right' className='bread_crumb_nav_img' />
                                            <Link className="bread_crumb_nav_item_bold" to='/settings/user-management'>User Management</Link>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <Link to='/settings/user-management'>Settings</Link>
                                        {
                                            e.title.map((e2, index1) => {
                                                return (
                                                    <div className="bread_crumb_navigation_sub_content" key={index1}>
                                                        <img src={RightArrowImage} alt='arrow right' className='bread_crumb_nav_img' />
                                                        <Link
                                                            className={e.title.length - 1 === index1 && "bread_crumb_nav_item_bold"}
                                                            to={e.routes[index1]}
                                                            key={index1}
                                                        >{e.title[index1]}</Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                            }
                        </div>
                    )
                })
            }
        </div >
    )
}

export default HiPSettingBreadCrumb


const Routes = [
    {
        title: ["General Settings", "Customize Organization"],
        currentroute: "/settings/customize-organization",
        routes: ["/settings/customize-organization", '/settings/customize-organization']
    },
    {
        title: ["User Management"],
        currentroute: "/settings/user-management",
        routes: ["/settings/user-management", '/settings/user-management']
    }
]