import { NavigateBefore, NavigateNext, ZoomIn, ZoomOut, Download } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BoundingBox from '../BoundingBox/BoundingBox'
import PDFReader from '../PdfReader/PdfReader'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "./FileDisplay.css"

function FileDisplay(props) {

  const initialValue = 600

  useEffect(() => {
    setZoom(initialValue)
  }, [])

  const [zoom, setZoom] = useState(600)
  const [imageWidth, setImageWidth] = useState(0)
  const [offSet, setOffSet] = useState(0.345)

  const handleZoom = () => {
    if (zoom < 1300) {
      setZoom(zoom + 100)
      setOffSet(Number.parseFloat(((zoom + 100) / imageWidth).toFixed(3)))
    }
  }

  const handleZoomOut = () => {
    if (zoom > 400) {
      setZoom(zoom - 100)
      setOffSet(Number.parseFloat(((zoom - 100) / imageWidth).toFixed(3)))
    }
  }

  return (
    <div className='file_display_container'>
      {/* Pagination */}
      < div className='file_display_pagination' >
        {/* Zooming btns */}
        < div className='file_display_zoom_btn' >
          <Stack spacing={2}>
            <Pagination count={props.totalPages} color="primary" onClick={props.handleNext} />
          </Stack>
        </div >
        {
          props.status === 'Completed' && (
            <a className='file_display_btns' href={props.downloadLink} target='blank' >
              <Tooltip placement='bottom' title='Download'>
                <Download sx={{ color: "black" }} />
              </Tooltip>
            </a>
          )
        }
        {/* Next and Pre btns */}
        < div className='file_display_next_pre_btn' >
          <div className='file_display_btns' onClick={props.handlePrev}>
            <Tooltip placement='bottom' title='Previous'>
              <NavigateBefore fontSize='large' />
            </Tooltip>
          </div>
          {/* Page Number */}
          <div className='file_display_page_number'>
            <span>{props.pageNumber}</span>
            <span>-</span>
            <span>{props.totalPages}</span>
          </div>
          <div className='file_display_btns' onClick={props.handleNext}>
            <Tooltip placement='bottom' title='Next'>
              <NavigateNext fontSize='large' />
            </Tooltip>
          </div>
        </div >
      </div >
      {/* Image Display */}
      < div className='file_display_image_display' style={{
        height: props.isActive ? "450px" : "780px"
      }}
        onScroll={() => props.setCoArrow({ isActive: false })}
      >
        {
          props.FileImages.slice(props.page, props.pageNumber).map((e, index) => {
            return (
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                  <div className='file_display_btns'>
                    <Tooltip placement='bottom' title='Zoom In'>
                      <ZoomIn fontSize='large' onClick={handleZoom} />
                    </Tooltip>
                    <Tooltip placement='bottom' title='Zoom out'>
                      <ZoomOut fontSize='large' onClick={handleZoomOut} />
                    </Tooltip>
                  </div>
                </div>
                <div key={index}>
                  <PDFReader
                    zoom={zoom}
                    imageURI={e.img_url}
                    name={e.image}
                    setImageWidth={setImageWidth}
                    width={e.img_res === null ? 2000 : e.img_res[0]}
                    setOffSet={setOffSet}
                  />
                  {
                    props.fileType === 'mb' ?
                      <>
                        {
                          e.key_value_pairs?.map((bbox, index) => {
                            return (
                              <BoundingBox
                                id={`boundingBox-${bbox.key}`}
                                key={index}
                                left={(props.offSet * bbox.x) + 5}
                                top={(props.offSet * bbox.y)}
                                height={20}
                                width={200}
                              />
                            )
                          })
                        }
                      </>
                      :
                      <>
                        {
                          e.key_value_pairs?.map((bbox, index) => {
                            return (
                              <BoundingBox
                                id={`boundingBox-${bbox.key}`}
                                key={index}
                                height={(offSet * bbox.bbox.height)}
                                width={(offSet * bbox.bbox.width)}
                                top={(offSet * bbox.bbox.top)}
                                left={(offSet * bbox.bbox.left)}
                                color={bbox.con_level}
                              />
                            )
                          })
                        }
                      </>
                  }
                </div>
              </div>
            )
          })
        }

      </div >
    </div>
  )
}

export default FileDisplay