import React, { useEffect, useState } from 'react';
import { Typography } from "@mui/material";
import "./Audit.css";
import { useSelector, useDispatch } from 'react-redux';
import { getTimeLine, setLoadingScreen, setTimelineAudit } from '../../../../Redux/features/AlertsSlice';
import Axios from 'axios';
import { MdClose } from 'react-icons/md';
import UserImage from "../../../../assets/user.svg";

function Audit() {

    const timeline = useSelector(getTimeLine);
    const dispatch = useDispatch();
    const [auditData, setAuditData] = useState({});

    useEffect(() => {
        if (timeline.isActive) {
            setLoadingScreen(true)
            Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/audit?file_id=${timeline.Id}`).then((res) => {
                setAuditData(res.data.response)
                setLoadingScreen(false)
            })
        }
        else {
            setAuditData({})
        }

    }, [timeline])

    const handleClose = () => {
        dispatch(setTimelineAudit({
            isActive: false
        }))
    }

    return (

        <>
            {auditData.onboard !== undefined && (
                <div className='document_audit_container'>
                    <div className='document_audit_card'>
                        <div className='document_audit_header'>
                            <span>Document Audit</span>
                            <MdClose style={{ fontSize: "20px", cursor: 'pointer' }} onClick={handleClose} />
                        </div>
                        <div className='document_audit_content'>
                            <table>
                                <tbody>
                                    {/* onBoading */}
                                    <tr>
                                        <td>
                                            {(() => {
                                                const auditDataInfo = (auditData.onboard.user).split(' ').map(word => word[0]).join('');

                                                return auditDataInfo.length === 2 ? (
                                                    <div className='document_audit_initial_circle_content'>
                                                        <Typography className='document_audit_intial_text'>{auditDataInfo}</Typography>
                                                    </div>
                                                ) : (
                                                    <img className={`document_audit_content_image`} src={UserImage} alt="User" />
                                                );
                                            })()}
                                        </td>
                                        <td>
                                            <span className='document_audit_content_status_onboarding'>onboarding</span>
                                        </td>
                                        <td style={{ textAlign: 'left', width: '100%' }}>
                                            <b className='document_audit_content_username'>{auditData.onboard.user}</b>
                                        </td>
                                        <td>
                                            <span className='document_audit_content_date'>{auditData.onboard.time.split(' ')[0]}</span>
                                        </td>
                                        <td>
                                            <span className='document_audit_content_time'>{auditData.onboard.time.split(' ')[1]}</span>
                                        </td>
                                    </tr>
                                    {/* extracted */}
                                    {
                                        auditData.extract !== null ?
                                            <>
                                                <tr>
                                                    <td>
                                                        {(() => {
                                                            const auditDataInfo = (auditData.onboard.user.split(' ').map(word => word[0]).join(''));

                                                            return auditDataInfo.length === 2 ? (
                                                                <div className='document_audit_initial_circle_content'>
                                                                    <Typography className='document_audit_intial_text'>{auditDataInfo}</Typography>
                                                                </div>
                                                            ) : (
                                                                <img className={`document_audit_content_image`} src={UserImage} alt="User" />
                                                            );
                                                        })()}
                                                    </td>
                                                    <td>
                                                        <span className='document_audit_content_status_extracted'>Extracted</span>
                                                    </td>
                                                    <td style={{ textAlign: 'left', width: '100%' }}>
                                                        <b className='document_audit_content_username'>{auditData.onboard.user}</b>
                                                    </td>
                                                    <td>
                                                        <span className='document_audit_content_date'>{auditData.extract.split(' ')[0]}</span>
                                                    </td>
                                                    <td>
                                                        <span className='document_audit_content_time'>{auditData.extract.split(' ')[1]}</span>
                                                    </td>
                                                </tr>
                                            </>
                                            :
                                            <></>
                                    }
                                    {/* WIP */}
                                    {
                                        auditData.modify !== null ?
                                            <>
                                                {
                                                    auditData.modify.map((e, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {(() => {
                                                                        const auditDataInfo = (e.user.split(' ').map(word => word[0]).join(''));

                                                                        return auditDataInfo.length === 2 ? (
                                                                            <div className='document_audit_initial_circle_content'>
                                                                                <Typography className='document_audit_intial_text'>{auditDataInfo}</Typography>
                                                                            </div>
                                                                        ) : (
                                                                            <img className={`document_audit_content_image`} src={UserImage} alt="User" />
                                                                        );
                                                                    })()}
                                                                </td>
                                                                <td>
                                                                    <span className='document_audit_content_status_wip'>W I P</span>
                                                                </td>
                                                                <td style={{ textAlign: 'left', width: '100%' }}>
                                                                    <b className='document_audit_content_username'>{e.user}</b>
                                                                </td>
                                                                <td>
                                                                    <span className='document_audit_content_date'>{e.time.split(' ')[0]}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='document_audit_content_time'>{e.time.split(' ')[1]}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                    {/* Completed */}
                                    {
                                        auditData.submit !== null && (
                                            <tr>
                                                <td>
                                                    {(() => {
                                                        const auditDataInfo = (auditData.submit.user.split(' ').map(word => word[0]).join(''));

                                                        return auditDataInfo.length === 2 ? (
                                                            <div className='document_audit_initial_circle_content'>
                                                                <Typography className='document_audit_intial_text'>{auditDataInfo}</Typography>
                                                            </div>
                                                        ) : (
                                                            <img className={`document_audit_content_image`} src={UserImage} alt="User" />
                                                        );
                                                    })()}
                                                </td>
                                                <td>
                                                    <span className='document_audit_content_status_completed'>Completed</span>
                                                </td>
                                                <td>
                                                    <b className='document_audit_content_username'>{auditData.submit.user}</b>
                                                </td>
                                                <td>
                                                    <span className='document_audit_content_date'>{auditData.submit.time.split(' ')[0]}</span>
                                                </td>
                                                <td>
                                                    <span className='document_audit_content_time'>{auditData.submit.time.split(' ')[1]}</span>
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
            }
        </>

    )
}

export default Audit