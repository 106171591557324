import React from 'react'
import "./ComingSoon.css"

function ComingSoon() {
    return (
        <div className = 'coming_soon' style={{ padding: "10px 30px", color: "#142952" }}>
            <h1 style={{ fontSize: "20px", fontWeight: "500", color: "#142952" }}>Coming Soon</h1>
        </div >
    )
}

export default ComingSoon