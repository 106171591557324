import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import { toggleDarkMode } from "../../Redux/features/themeSlice";
import { Container } from "@material-ui/core";

const Master = () => {
  const dispatch = useDispatch();

  const darkMode = useSelector((state) => state.theme.darkMode);

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  const handleToggleTheme = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar onToggleTheme={handleToggleTheme} darkMode={darkMode} />
        <Outlet />
      <Footer />
    </MuiThemeProvider>
  );
};

export default Master;
