import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBoundingBoxes,
  getExtractedInfo,
  setExtractedInfoValue,
  setSelectedFieldBoundingBoxes,
} from "../../../Redux/features/DocumentsSlice";
import "./ShowSectionField.css";
import _debounce from 'lodash/debounce';

function ShowSectionField({ name, value: initialValue, color, offSet }) {
  const [textValue, setTextValue] = useState(initialValue || "");
  const [isValueChanged, setIsValueChanged] = useState(false); // Track if value has changed
  const dispatch = useDispatch();
  const boundingBoxData = useSelector(getBoundingBoxes);
  const [conLevel, setConLevel] = useState("");
  const [initialValueRef, setInitialValueRef] = useState(initialValue);
  const extractedInfo = useSelector(getExtractedInfo);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    searchBoundingBoxData(newValue);
  };

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    setTextValue(newValue);
  };

  const handleBlur = () => {
    dispatch(setExtractedInfoValue({ name, initialValueRef, newValue: textValue }));
    setIsValueChanged(true)
  };

  const handleHideData = () => {
    dispatch(setSelectedFieldBoundingBoxes(null));
    handleBlur(); // Trigger handleBlur on onBlur event
  };

  const searchBoundingBoxData = (newValue) => {
    var matchingItem;
    if (boundingBoxData && Array.isArray(boundingBoxData)) {
      matchingItem = boundingBoxData.find((item) =>
        item.text.includes(newValue)
      );
    }
    if (matchingItem && matchingItem.bounding_box) {
      dispatch(setSelectedFieldBoundingBoxes(matchingItem.bounding_box));
    }

  };
  useEffect(() => {
    setInitialValueRef(initialValue); // Update initialValueRef when initialValue changes
  }, [initialValue]);

  useEffect(() => {
    if (color === "high") {
      setConLevel("#01BD4C");
    } else if (color === "mid") {
      setConLevel("#FFD43D");
    } else if (color === "low") {
      setConLevel("#DA0000");
    } else {
      setConLevel("#A0CCF5");
    }
  }, []);

  return (
    <div className="text_box_container" style={{ borderColor: color }}>
      <label className="text_box_label">{name}</label>

      <input
        type="text"
        value={textValue}
        className="text_box_input"
        placeholder={`Enter ${name}`}
        id={`textArea-${name}`}
        name={name}
        onClick={handleInputChange}
        onChange={handleValueChange}
        onBlur={handleHideData}
        style={{ borderColor: conLevel }}
      />
    </div>
  );
}

export default ShowSectionField;
