import React, { useState, useEffect } from "react";
import "./MBWShowSectionField.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getBoundingBoxes,
  setPageNumber,
  setSelectedFieldBoundingBoxes,
  setUpdateFields,
} from "../../../Redux/features/DocumentsSlice";

function MBWShowSectionField({ name, value: initialValue, color, offSet, boundingBox, pageNumber, sectionTitle, changeSectiondata }) {
  const [textValue, setTextValue] = useState(initialValue || "");
  const dispatch = useDispatch();
  const boundingBoxData = useSelector(getBoundingBoxes);
  const [conLevel, setConLevel] = useState("");

  const handleInputChange = (event) => {

    const newValue = event.target.value;
    setTextValue(newValue);
    dispatch(setSelectedFieldBoundingBoxes(boundingBox))

    dispatch(setPageNumber(pageNumber))
    
  };
  const handleHideData = () => {
    dispatch(setSelectedFieldBoundingBoxes(null));
  };
  const searchBoundingBoxData = (newValue) => {
    const matchingItem = boundingBoxData.find((item) =>
      item.text.includes(newValue)
    );
    if (matchingItem && matchingItem.bounding_box) {
      dispatch(setSelectedFieldBoundingBoxes(matchingItem.bounding_box));
    }
  };

  useEffect(() => {
    if (color === "high") {
      setConLevel("#007a56");
    } else if (color === "mid") {
      setConLevel("#cea100");
    } else if (color === "low") {
      setConLevel("#f05802");
    } else {
      setConLevel("#820000");
    }
  }, [color]);

  return (
    <div className="text_box_container" style={{ borderColor: color }}>
      <label className="text_box_label">{name}</label>

      <input
        type="text"
        value={textValue}
        className="text_box_input"
        placeholder={`Enter ${name}`}
        id={`textArea-${name}`}
        name={name}
        onClick={handleInputChange}
        onChange={(event) => {
          setTextValue(event.target.value);
          changeSectiondata(sectionTitle, name, event.target.value);
          dispatch(setUpdateFields(true))
        }}
        onBlur={handleHideData}
        style={{ borderColor: conLevel }}
      />
    </div>
  );
}

export default MBWShowSectionField;
