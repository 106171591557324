import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  GenzeonAimessages: [],
  OpenAimessages: [],
  PalmAimessages: [],
  AutogeneratedQuetions: [],
  chatHistory: {},
  currentpdf: "",
  Theme: "",
  privacyModeEnabled: false,
  AutogeneratedQuetionsLoading: false,
  isPdfUploadedState: false,
  isPdfUploadedOneTimeState: false,
  isPdfBeingUploadedState: false,
  isXmlUploaded: false,
  privacyPopup: false,
  responseNumber: 0,
  GenzeonAimessagesDark: [],
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    addMessageToGenzeonAi: (state, action) => {
      const pdfname = state.currentpdf;
      state.chatHistory[pdfname][0].push(action.payload);
      state.GenzeonAimessages.push(action.payload);
    },
    addMessageToOpenAi: (state, action) => {
      const pdfname = state.currentpdf;
      state.chatHistory[pdfname][1].push(action.payload);
      state.OpenAimessages.push(action.payload);
    },
    addMessageToPalmAi: (state, action) => {
      const pdfname = state.currentpdf;
      state.chatHistory[pdfname][2].push(action.payload);
      state.PalmAimessages.push(action.payload);
    },
    addMessageToGenzeonAiDark: (state, action) => {
      state.GenzeonAimessagesDark.push(action.payload);
    },
    addToAutogeneratedQuetions: (state, action) => {
      const pdfname = state.currentpdf;
      state.AutogeneratedQuetions = action.payload;
    },
    clearState: (state, action) => {
      state.GenzeonAimessages = [];
      state.OpenAimessages = [];
      state.PalmAimessages = [];
      state.AutogeneratedQuetions = [];
    },
    clearAutogeneratedQuetions: (state) => {
      state.AutogeneratedQuetions = [];
    },
    changeChat: (state, action) => {
      let pdfname = state.currentpdf;
      pdfname = action.payload;
      const msgstate = state.chatHistory[pdfname];
      state.GenzeonAimessages = msgstate[0];
      state.OpenAimessages = msgstate[1];
      state.PalmAimessages = msgstate[2];
    },
    addCurrentPdf: (state, action) => {
      const pdfname = action.payload;
      state.currentpdf = pdfname;
      if (!state.chatHistory[pdfname]) {
        state.chatHistory[pdfname] = [[], [], []];
      }
      const msgstate = state.chatHistory[pdfname];
      state.GenzeonAimessages = msgstate[0];
      state.OpenAimessages = msgstate[1];
      state.PalmAimessages = msgstate[2];
    },
    clearHistory: (state) => {
      state.chatHistory = {};
    },
    setTheme: (state, action) => {
      if (action.payload === true) {
        state.Theme = "dark_";
        state.privacyModeEnabled = true;
      } else if (action.payload === false) {
        state.Theme = "";
        state.privacyModeEnabled = false;
      }
    },
    setAutogeneratedQuetionsLoading: (state, action) => {
      state.AutogeneratedQuetionsLoading = action.payload
    },
    setIsPdfUploadedState: (state, action) => {
      state.isPdfUploadedState = action.payload
    },
    setIsPdfUploadedOneTimeState: (state, action) => {
      state.isPdfUploadedOneTimeState = action.payload
    },
    setIsPdfBeingUploadedState: (state, action) => {
      state.isPdfBeingUploadedState = action.payload
    },
    setIsXmlUploaded: (state, action) => {
      state.isXmlUploaded = action.payload
    },
    setPrivacyPopup: (state, action) => {
      state.privacyPopup = action.payload
    },
    setResponseNumber: (state, action) => {
      state.responseNumber = action.payload
    },
    clearHistoryDark: (state, action) => {
      state.GenzeonAimessagesDark = [];
    },
  },
});

export const {
  addMessageToGenzeonAi,
  addMessageToOpenAi,
  addMessageToPalmAi,
  addToAutogeneratedQuetions,
  clearState,
  clearAutogeneratedQuetions,
  changeChat,
  addCurrentPdf,
  setTheme,
  clearHistory,
  setAutogeneratedQuetionsLoading,
  setIsPdfUploadedState,
  setIsPdfUploadedOneTimeState,
  setIsPdfBeingUploadedState,
  setIsXmlUploaded,
  setPrivacyPopup,
  setResponseNumber,
  addMessageToGenzeonAiDark,
  clearHistoryDark
} = messageSlice.actions;

export default messageSlice.reducer;
