import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import "./Invoice.css"
import { Checkbox, ListItemText, Typography, Select, MenuItem, Button, FormControl, FormHelperText } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadImage from "../../../assets/Icons/Import_light.svg";
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import InvoiceUI from './InvoiceUI';
import html2canvas from 'html2canvas';
import GetCookie from '../../../HIPCookies/getCookie';
import { toast } from 'react-toastify';

function Invoice() {

    const [clients, setClients] = useState([]);
    const [modules, setModules] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [clientError, setClientError] = useState(false);
    const [moduleError, setModuleError] = useState(false);
    const [monthError, setMonthError] = useState(false);
    const [yearError, setYearError] = useState(false);
    const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;

    useEffect(() => {
        getClients()
        getModules()
    }, []);

    // Function to compare selected year and month with the current date
    const isFutureMonth = (selectedYear, selectedMonth) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Note: Months are zero-based

        return selectedYear === currentYear && selectedMonth > currentMonth;
    };

    const handleClientChange = (event) => {
        setSelectedClient(event.target.value);
        setClientError(false);
    }
    const handleModuleChange = (event) => {
        setSelectedModule(event.target.value);
        setModuleError(false);
    }

    const handleMonthChange = (event) => {
        const selectedMonthName = event.target.value;
        if (selectedMonthName !== "") {
            const selectedMonthObject = getMonthNamesWithIndexes().find((month) => month.name === selectedMonthName);
            setSelectedMonth(selectedMonthObject.index);
        } else {
            setSelectedMonth('');
        }
        setMonthError(false);
    };


    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
        setYearError(false);
    };
    function getClients() {
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Client`, {
            headers: {
                "Authorization": `Bearer ${jWTToken}`
            }
        })
            .then((response) => {
                setClients(response.data)
            })
            .catch((error) => {
                console.error("An error occured while fetching the clients: ", error)
            })

    }
    function getModules() {
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/PermissionModule`, {
            headers: {
                "Authorization": `Bearer ${jWTToken}`
            }
        })
            .then((response) => {
                setModules(response.data)
            })
            .catch((error) => {
                console.error("An error occured while fetching the modules: ", error)
            })
    }

    function getMonthNamesWithIndexes() {
        const monthNamesWithIndexes = [];
        for (let month = 0; month < 12; month++) {
            const date = new Date(0, month);
            const monthName = date.toLocaleString('default', { month: 'long' });
            monthNamesWithIndexes.push({ name: monthName, index: month + 1 });
        }
        return monthNamesWithIndexes;
    }
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2023 + 1 }, (_, index) => 2023 + index);

    const handleGenerateInvoice = async (event) => {
        event.preventDefault();
        if (!selectedClient || !selectedModule || !selectedMonth || !selectedYear) {
            setClientError(!selectedClient);
            setModuleError(!selectedModule);
            setMonthError(!selectedMonth);
            setYearError(!selectedYear);
        } // Check if the selected year and month are in the future
        else if (isFutureMonth(selectedYear, selectedMonth)) {
            toast.warning(`Invoices for future months are not yet available for download.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            try {
                const response = await axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Bundle/GetInvoiceData`, {
                    headers: {
                        "Authorization": `Bearer ${jWTToken}`,
                        clientIDs: selectedClient,
                        month: selectedMonth,
                        year: selectedYear,
                    }
                });
                if (response.status !== 200) {
                    toast.error(`No data available for selected month`, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    return;
                }
                const data = response.data;
                const doc = new jsPDF();
                const recordsPerPage = 12;
                const numPages = Math.ceil(data.length / recordsPerPage);

                let totalAmount = 0;

                for (let i = 0; i < numPages; i++) {
                    const startIndex = i * recordsPerPage;
                    const endIndex = Math.min(startIndex + recordsPerPage, data.length);
                    const recordsOnPage = data.slice(startIndex, endIndex);

                    if (i > 0) {
                        doc.addPage();
                    }

                    const isLastPage = i === numPages - 1;

                    for (const item of recordsOnPage) {
                        totalAmount += item.successCount * item.rate;
                    }

                    const footerContent = `Page ${i + 1} of ${numPages}`;

                    const invoiceUI = document.createElement('div');
                    document.body.appendChild(invoiceUI);
                    const root = createRoot(invoiceUI);
                    root.render(<InvoiceUI invoiceData={recordsOnPage} isLastPage={isLastPage} totalAmount={totalAmount} footerContent={footerContent} />);

                    await new Promise(resolve => setTimeout(resolve, 1500)); // Wait for the rendering to complete.

                    const canvas = await html2canvas(invoiceUI);
                    const pdfWidth = doc.internal.pageSize.width;
                    const pdfHeight = pdfWidth * (canvas.height / canvas.width);
                    doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pdfWidth, pdfHeight);

                    document.body.removeChild(invoiceUI);
                }
                doc.save('Invoice.pdf');
            } catch (error) {
                console.error("An error occurred while fetching the invoices: ", error);
            }
        }
    }

    return (

        <div className='billing_invoice_main_container'>
            <div className='billing_invoice_container'>
                <div className='billing_invoice_heading'>Invoice</div>
                <form onSubmit={handleGenerateInvoice}>
                    <div className='invoice_options_container'>
                        <div className='invoice_select_container'>
                            <FormControl className='select_option_container' error={clientError}>
                                <Typography className="invoice_select_option_heading" > Select Client </Typography>
                                <Select
                                    name="category"
                                    IconComponent={ExpandMoreIcon}
                                    className="invoice_select_option"
                                    fullWidth
                                    displayEmpty
                                    value={selectedClient}
                                    onChange={handleClientChange}
                                    renderValue={(selected) => (clients.find((option) => option.clientId === selected)?.clientName || 'Select Client')}
                                >
                                    {clients.map((option, index) => (
                                        <MenuItem className="invoice_select_content" key={index} value={option.clientId}>
                                            {option.clientName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {clientError && <FormHelperText id="client-error">Please select a client.</FormHelperText>}
                            </FormControl>
                            <FormControl className='select_option_container' error={moduleError}>
                                <Typography className="invoice_select_option_heading" > Select Module </Typography>
                                <Select
                                    name="category"
                                    IconComponent={ExpandMoreIcon}
                                    className="invoice_select_option"
                                    fullWidth
                                    displayEmpty
                                    value={selectedModule}
                                    onChange={handleModuleChange}
                                    renderValue={(selected) => {
                                        if (selected) {
                                            return "HIP One " + modules[0]?.displayName;
                                        } else {
                                            return 'Select Module';
                                        }
                                    }}
                                >
                                    <MenuItem className="invoice_select_content" value={modules[0]?.id}> {/* Use the first entry */}
                                        {"HIP One " + modules[0]?.displayName}
                                    </MenuItem>
                                </Select>
                                {moduleError && <FormHelperText id="module-error">Please select a module.</FormHelperText>}
                            </FormControl>
                        </div>
                        <div className='invoice_select_container'>
                            <FormControl className='select_option_container' error={monthError}>
                                <Typography className="invoice_select_option_heading" > month </Typography>
                                <Select
                                    name="category"
                                    IconComponent={ExpandMoreIcon}
                                    className="invoice_select_option"
                                    fullWidth
                                    displayEmpty
                                    value={selectedMonth}
                                    onChange={handleMonthChange}

                                    renderValue={(value) => {
                                        if (value !== "") {
                                            const selectedMonthObject = getMonthNamesWithIndexes().find((month) => month.index === value);
                                            return selectedMonthObject.name;
                                        }
                                        return "Select Month";
                                    }}
                                >

                                    {getMonthNamesWithIndexes().map((month, index) => (
                                        <MenuItem className="invoice_select_content" key={index} value={month.name}>
                                            {month.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {monthError && <FormHelperText id="month-error">Please select a month.</FormHelperText>}
                            </FormControl>
                            <FormControl className='select_option_container' error={yearError}>
                                <Typography className="invoice_select_option_heading" > year </Typography>
                                <Select
                                    name="category"
                                    IconComponent={ExpandMoreIcon}
                                    className="invoice_select_option"
                                    fullWidth
                                    displayEmpty
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    renderValue={(value) => (value !== "" ? value : "Select Year")}
                                >
                                    {years.map((year) => (
                                        <MenuItem className="invoice_select_content" key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {yearError && <FormHelperText id="year-error">Please select a year.</FormHelperText>}
                            </FormControl>
                        </div>
                    </div>
                    <div className='invoice_buttons_container'>
                        <Button type="submit" className="invoice_download_button">
                            <img src={DownloadImage} alt="download icon" />
                            Download
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Invoice