import React from 'react';
import { Tooltip } from '@mui/material';
import TextBox from '../TextBox/TextBox'
import "./DocumentTextFields.css"

export function DocumentTextFields({ Con_Level, setCoArrow, FileData, File, page, setUpdatedFile, changePage }) {

    return (
        <>
            {/* Indication of Confidence level */}
            <div className='doc_text_indication_container'>
                <div className='doc_text_indication_container_header'>
                    <span>Accuracy Level</span>
                </div>
                <div className='doc_text_field_indicators'>
                    <div className='doc_text_field_indicator_span'>
                        <Tooltip placement='top' title='80-100%'>
                            <div className='indicator_high'></div>
                        </Tooltip>
                        <p className='doc_acc_level'>{Con_Level.High}</p>
                    </div>
                    <div className='doc_text_field_indicator_span'>
                        <Tooltip placement='top' title='60-80%'>
                            <div className='indicator_medium'></div>
                        </Tooltip>
                        <p className='doc_acc_level'>{Con_Level.Medium}</p>
                    </div>
                    <div className='doc_text_field_indicator_span'>
                        <Tooltip placement='top' title='< 60%'>
                            <div className='indicator_low'></div>
                        </Tooltip>
                        <p className='doc_acc_level'>{Con_Level.Low}</p>
                    </div>
                    <div className='doc_text_field_indicator_span'>
                        <Tooltip placement='top' title='No Data Extracted'>
                            <div className='indicator_null'></div>
                        </Tooltip>
                        <p className='doc_acc_level'>{Con_Level.Null}</p>
                    </div>
                </div>
            </div>

            {/*  Form for editing the data */}
            <div
                className='doc_text_field_container'
                onScroll={() => setCoArrow({
                    isActive: false
                })}
            >
                <div className='doc_text_field_container_span'>
                    {FileData.length === 0 ?
                        <></>
                        :
                        <>
                            {
                                FileData.map((arr, index) => {
                                    return (
                                        <>
                                            <div className='pageName'>Page  {index + 1}</div>
                                            {
                                                arr.map((e, index1) => {
                                                    return (
                                                        <>
                                                            <TextBox
                                                                key={index1}
                                                                name={e.key}
                                                                value={e.value}
                                                                id={index + 1}
                                                                setCoArrow={setCoArrow}
                                                                File={File}
                                                                page={page}
                                                                setUpdatedFile={setUpdatedFile}
                                                                color={e.con_level}
                                                                changePage={changePage} />
                                                        </>
                                                    );
                                                })
                                            }
                                        </>
                                    );
                                })
                            }

                        </>}
                        
                </div>
            </div>
        </>
    )
}
