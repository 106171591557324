import { configureStore } from "@reduxjs/toolkit";
import DocumentsReducer from "./features/DocumentsSlice";
import AlertsReducer from "./features/AlertsSlice";
import MessageReducer from "./features/MessageSlice";
import DbMessageReducer from "./features/DbMessageSlice";
import UrlMessageReducer from "./features/UrlMessageSlice";
import DataGenieReducer from "./features/DataGenieSlice";
import ThemeReducer from "./features/themeSlice";
import SessionReducer from "./features/sessionsSlice";
import ConvMessageReducer from "./features/convMessage";
import StarSlice from "./features/StarSlice";
export const store = configureStore({
  reducer: {
    documents: DocumentsReducer,
    alerts: AlertsReducer,
    messages: MessageReducer,
    dbMessages: DbMessageReducer,
    urlMessages: UrlMessageReducer,
    datagmessages: DataGenieReducer,
    theme: ThemeReducer,
    sessions: SessionReducer,
    convMessages: ConvMessageReducer,
    startDashBoard: StarSlice,
  },
});
