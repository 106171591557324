import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getSelectedFieldBoundingBoxes
} from "../../../Redux/features/DocumentsSlice";
import MedicalReviewBoundingBox from "../BoundingBox/MedicalReviewBoundingBox";
import PdfReader from "../PdfReader/PdfReader";
import "./FileDisplayNoridian.css";

function FileDisplayNoridian(props) {
  const initialValue = 500;
  const selectedFieldBoundingBox = useSelector(getSelectedFieldBoundingBoxes);

  useEffect(() => {
    setZoom(initialValue);
  }, []);

  const [zoom, setZoom] = useState(500);
  const [imageWidth, setImageWidth] = useState(0);
  const [offSet, setOffSet] = useState(0.345);

  const handleZoom = () => {
    if (zoom < 1300) {
      setZoom(zoom + 100);
      setOffSet(Number.parseFloat(((zoom + 100) / imageWidth).toFixed(3)));
    }
  };

  const handleZoomOut = () => {
    if (zoom > 400) {
      setZoom(zoom - 100);
      setOffSet(Number.parseFloat(((zoom - 100) / imageWidth).toFixed(3)));
    }
  };




  return (
    <div
      className="file_display_image_display_noridian"
      style={{
        height: props.isActive ? "450px" : "780px",
      }}
      onScroll={() => props.setCoArrow({ isActive: false })}
    >
      {props.FileImages.length > 0 &&
        props.FileImages.filter(image => image.page_number === props.pageNumber).map((e, index) => (
          <div style={{ display: "flex" }} key={index}>
            <div style={{ display: "flex" }}>
              <div className="file_display_btns">
                <Tooltip placement="bottom" title="Zoom In">
                  <ZoomIn fontSize="large" onClick={handleZoom} />
                </Tooltip>
                <Tooltip placement="bottom" title="Zoom out">
                  <ZoomOut fontSize="large" onClick={handleZoomOut} />
                </Tooltip>
              </div>
            </div>
            <div>
              <PdfReader
                zoom={zoom}
                imageURI={e.img_url === null ? "" : e.img_url}
                name={e.image}
                setImageWidth={setImageWidth}
                width={e.img_res === null ? 2000 : e.img_res[0]}
                setOffSet={setOffSet}
              />
              {selectedFieldBoundingBox !== null &&
                <MedicalReviewBoundingBox
                  coordinates={selectedFieldBoundingBox}
                  color="high" // You can set the appropriate color here
                  offSet={offSet}
                />
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default FileDisplayNoridian;
