import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutWithBreadCrumb from "../Theme/LayoutWithBreadCrumb";
import Datagenie from "./Screens/HipConnectScreen/DataGenie/DataGenie";
import ConnectDashboard from "./Screens/Dashboard";
import CreateChatBot from "./Screens/HipConnectScreen/HomeScreen/CreateChatBot/index";
import CreateDatabaseChatBot from "./Screens/HipConnectScreen/HomeScreen/CreateDatabaseChatbot";
import DeleteUrlChatbot from "./Screens/HipConnectScreen/HomeScreen/DeleteUrlChatbot";
import InteractWithUrl from "./Screens/HipConnectScreen/IntaractWithUrl";
import InteractWithPdf from "./Screens/HipConnectScreen/InteractWithPdf";
import Logshifters from "./Screens/HipConnectScreen/Logshifters/Logshifters";

function App() {
  return (
    <Routes>
      <Route element={<LayoutWithBreadCrumb type="connect" />}>
        <Route path="/" element={<ConnectDashboard />} />
        <Route path="/interactive-document" element={<InteractWithPdf />} />
        {/* <Route path="/summarize-document" element={<SummarizeComponent />} /> */}
        <Route path="/hip-connection-database" element={<CreateDatabaseChatBot />} />
        <Route path="/hip-connection-database-create-chatbot" element={<Datagenie />} />
        <Route path="/hip-connection-url" element={<InteractWithUrl />} />
        <Route path="/hip-create-chatbot" element={<CreateChatBot />} />
        <Route path="/hip-modify-chatbot" element={<DeleteUrlChatbot />} />
        <Route path="/hip-logsifters" element={<Logshifters />} />
      </Route>
    </Routes>
  );
}

export default App;