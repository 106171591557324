import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "../DbMessages/DbMessages.css";
import UrlBotMessage from "../UrlBotMessage/UrlBotMessage";
import UrlBotMessageLoading from "../UrlMessgesLoding/UrlMessageLoading";
import UrlUserMessage from "../UrlUserMessage/UrlUserMessage";
import ChartMessage from "../ChartMessage/Chartmessage";
export default function LogshiftersMessages({ isLoading }) {
  const dbMessages = useSelector((state) => state.datagmessages.LogshiftersMessages);
  const filesUploaded = useSelector(
    (state) => state.datagmessages.filesUploaded
  );
  const theme = useSelector((state) => state.messages.Theme) || "";

  const [scrollAtBottom, setScrollAtBottom] = useState(true); 
  const myref = useRef();

  useEffect(() => {
    const container = myref.current;
    const handleScroll = () => {
      setScrollAtBottom(container.scrollTop + container.clientHeight >= container.scrollHeight - 10);
    };
   container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [dbMessages]);
  
  const scrollToBottom = () => {
    const container = myref.current;
    const scrollHeight = container.scrollHeight - container.clientHeight;
  
    container.scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    });
  
    setScrollAtBottom(true);
  };


  return (
    <div className={`${theme} db_messages`} ref={myref}>

      <div>
        {dbMessages.map((obj, index) =>
         {
          if (obj.id === "user") {
            return <UrlUserMessage key={index} message={obj.text} />;
          } else if (obj.id === "chart") {
            return (
              <ChartMessage
                key={index}
                data={obj.data}
                options={obj.options}
                type={obj.type}
                text={obj.text}
                title={obj.title}
              />
            );
          } else if (obj.id === "bot") {
            return <UrlBotMessage key={index} message={obj.text} />;
          }
          return null; // Handle other cases or unknown IDs as needed
        })}
        {isLoading && <UrlBotMessageLoading />}
      </div>

      {!scrollAtBottom && (
        <button className="down-arrow-button" onClick={scrollToBottom}>
          ↓
        </button>
      )}
    </div>
  );
}
