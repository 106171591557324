import React from "react";
import "./UrlUserMessage.css";
import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import GetCookie from "../../../HIPCookies/getCookie";
import { Typography } from "@mui/material";

export default function UrlUserMessage(props) {
  const profileImage = GetCookie('userProfileImage');

  const theme = useSelector((state) => state.messages.Theme);
  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [props.message]);

  return (
    <div className={`${theme}url_user_message`} ref={ref}>
      <div className={`${theme}url_user_messageInfo`}></div>
      <div className={`${theme}url_user_messageContent`}>
        <p className={`${theme}url_user_gen_message-p`}>{props.message}</p>
        {(profileImage && profileImage.length > 2) ?
          <img className="url_user_image"
            src={profileImage}
            alt="User"
          />
          : (profileImage && profileImage.length === 2) ?
            <div className='url_initial_circle_content'>
              <Typography className='url_intial_text'> {profileImage} </Typography>
            </div>
            : null
        }
      </div>
    </div>
  );
}
