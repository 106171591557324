import React, { useState, useEffect } from 'react';
import { Checkbox, Typography, Select, ListItemText, MenuItem } from '@mui/material';
import "./PatientDataRequestSummary.css";
import Back from '../../../assets/Icons/Back.svg';
import TotalRequest from '../../../assets/Icons/TotalRequest.svg';
import SuccessfulRequest from '../../../assets/Icons/SuccessfulRequest.svg';
import UnSuccessfulRequest from '../../../assets/Icons/UnsuccessfulRequest.svg';
import InProgressRequest from '../../../assets/Icons/InprogressRequest.svg';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import GetCookie from '../../../HIPCookies/getCookie';

function PatientDataRequestSummary() {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateDifference, setDateDifference] = useState(null);
    const [clients, setClients] = useState([]);
    const [sources, setSources] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedSource, setSelectedSource] = useState([]);
    const [bundle, setBundle] = useState([]);
    const totalCount = bundle.reduce((total, item) => total + item.count, 0)
    const SuccessfulCount = getCountByMultipleBundleStatus(bundle, ["OK", "CCDA Returned"]);
    const InProgress = getCountByMultipleBundleStatus(bundle, ["Initiated Search", "Received Document(s)", "In Progress"]);
    const UnsuccessfulCount = totalCount - SuccessfulCount - InProgress;
    const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;

    function calculateDateDifference(startDate, endDate) {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        const timeDifference = endDateObj - startDateObj;
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
        return daysDifference;
    }
    const handleClientChange = (event) => {
        const value = event.target.value;
        setSelectedClient(value);
    }

    const handleSourceChange = (event) => {
        const value = event.target.value;
        setSelectedSource([...value]);
    }

    const getClients = () => {
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Client`, {
            headers: {
                'Authorization': `Bearer ${jWTToken}`,
            }
        })
            .then((response) => {
                setClients(response.data)
            })
    }

    const getSources = () => {
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Source`, {
            headers: {
                'Authorization': `Bearer ${jWTToken}`,
            }
        })
            .then((response) => {
                setSources(response.data)
            })
    }

    useEffect(() => {
        if (startDate && endDate) {
            getBundle();
        }
    }, [startDate, endDate, selectedClient, selectedSource]);

    useEffect(() => {
        getClients()
        getSources()
    }, []);

    function handleDateChange(date, identifier) {
        if (identifier === 'start_date') {
            setStartDate(date.format('MM/DD/YYYY'));
            setEndDate(null);
        } else if (identifier === 'end_date') {
            setEndDate(date.format('MM/DD/YYYY'));
        }
        setBundle([]);

    }

    useEffect(() => {
        if (startDate && endDate) {
            const difference = calculateDateDifference(startDate, endDate);
            setDateDifference(difference);
            if (difference < 0) {
                setDateDifference(0);
            }
        }
        else {
            setDateDifference(0);
        }
    }, [startDate, endDate]);

    const getBundle = () => {
        if (selectedClient !== "" && selectedSource.length !== 0) {
            axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Bundle/GetBundleCountByStatus`, {
                headers: {
                    'Authorization': `Bearer ${jWTToken}`,
                    startDate: startDate,
                    endDate: endDate,
                    clientIDs: selectedClient,
                    sourceIDs: selectedSource
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        setBundle(response.data);
                    }
                    else if (response.status === 204) {
                        setBundle([]);
                    }

                })
                .catch((error) => {
                    console.error("An error occured while fetching the bundle: ", error);
                })
        } else {
            setBundle([]);
        }

    }

    function getCountByBundleStatus(data, status) {
        const matchingItem = data.find(item => item.bundleStatus === status);
        return matchingItem ? matchingItem.count : 0;
    }

    function getCountByMultipleBundleStatus(data, statusArray) {
        let totalCount = 0;
        totalCount = data.reduce((total, item) => {
            if (statusArray.some(status => item.bundleStatus === status)) {
                return total + item.count;
            }
            return total;
        }, 0);

        return totalCount;
    }

    function calculatePercentage(part, total) {
        if (total === 0) {
            return 0;
        }
        const percentage = (part / total) * 100;
        return percentage.toFixed(2);
    }

    return (

        <div className="billing_transactions_container">
            <Typography variant='h3' className='billing_transactions_heading'>  Patient Data Requests Summary </Typography>
            <div className='transaction_info_container'>
                <div className='transaction_timeline_container'>
                    <Typography variant='h3' className='timeline_heading' > Timeline </Typography>
                    <div className='timeline_contents'>
                        <div className='timeline_calender'>
                            <div className='date_input'>
                                <Typography className="label_heading" id="heading_select" > From </Typography>
                                <div className='date_picker_container'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker className="select_transaction_date"
                                            value={startDate ? new Date(startDate) : null}
                                            onChange={(date) => handleDateChange(date, 'start_date')}
                                            openTo='day'
                                            views={['year', 'month', 'day']}
                                            disableFuture={true}
                                            format="MM/DD/YYYY"
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className='date_input'>
                                <Typography className="label_heading" id="heading_select" > To </Typography>
                                <div className='date_picker_container'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} id="date_picker_container">
                                        <DatePicker className="select_transaction_date"
                                            value={endDate ? new Date(endDate) : null}
                                            onChange={(date) => handleDateChange(date, 'end_date')}
                                            openTo='day'
                                            views={['year', 'month', 'day']}
                                            disableFuture={true}
                                            shouldDisableDate={(date) =>
                                                startDate ? new Date(date) < new Date(startDate) : false
                                            }
                                            format="MM/DD/YYYY"
                                            disabled={!startDate}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className='timeline_display'>
                            <h1>{dateDifference !== null ? dateDifference : 0}</h1>
                            <p>Days</p>
                        </div>
                    </div>

                </div>
                <div className='transaction_select'>
                    <Typography variant='h7' className='client_headings'> Client Name </Typography>
                    <div className='options_container'>
                        <div className='option_box'>
                            <Typography className='label_heading' id="heading_select">Select Client</Typography>
                            <Select
                                name="select_clients"
                                IconComponent={ExpandMoreIcon}
                                className="select_options"
                                value={selectedClient}
                                onChange={handleClientChange}
                                renderValue={(selected) => (clients.find((option) => option.clientId === selected)?.clientName || 'Select Client Name')}
                                fullWidth
                                displayEmpty
                            >
                                {clients.map((option, index) => (
                                    <MenuItem className="options_content" key={index} value={option.clientId}>
                                        {option.clientName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div className='option_box'>
                            <Typography className='label_heading' id="heading_select"> Select Source </Typography>
                            <Select
                                name="select_sources"
                                IconComponent={ExpandMoreIcon}
                                multiple
                                className="select_options"
                                value={selectedSource}
                                onChange={handleSourceChange}
                                renderValue={(selected) => (
                                    selected.length > 0
                                        ? selected.map((sourceId, index) => (
                                            sources.find((option) => option.sourceId === sourceId)?.sourceName || ''
                                        )).join(', ')
                                        : 'Select Source Name'
                                )}
                                fullWidth
                                displayEmpty
                            >
                                {sources.map((option, index) => (
                                    <MenuItem className="options_content" key={index} value={option.sourceId}>
                                        <Checkbox style={{ color: "#49454F" }} checked={selectedSource.includes(option.sourceId)} />
                                        <ListItemText primary={option.sourceName} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>

                </div>
            </div>
            <div className='transaction_request_container'>
                <div className='request_info_container'>
                    <img id='total_request' src={TotalRequest} alt="Total Request" />
                    <div className='request_info_content'>
                        <h1>{totalCount}</h1>
                        <p>Total Requests</p>
                    </div>
                </div>
                <div className='request_info_container'>
                    <img id='successful_request' src={SuccessfulRequest} alt="Total Request" />
                    <div className='request_info_content'>
                        <h1>{SuccessfulCount}</h1>
                        <p>Successful Requests</p>
                    </div>
                </div>
                <div className='request_info_container'>
                    <img id='unsuccessful_request' src={UnSuccessfulRequest} alt="Total Request" />
                    <div className='request_info_content'>
                        <h1>{UnsuccessfulCount}</h1>
                        <p>Unsuccessful Requests</p>
                    </div>
                </div>
                <div className='request_info_container'>
                    <img id='inprogress_request' src={InProgressRequest} alt="Total Request" />
                    <div className='request_info_content'>
                        <h1>{InProgress}</h1>
                        <p>In-progress Requests</p>
                    </div>
                </div>
            </div>
            <div className='table_main_container'>
                {startDate && endDate ? (
                    <Typography variant='h7' className='table_info_heading'> From {startDate} to {endDate} </Typography>
                ) : ''}
                <div className='table_data_container'>
                    <TableContainer component={Paper} className='table-container' >
                        <Typography variant='h7' className='table_main_heading'> Status of Requests</Typography>
                        <div className='table_outer_container'>
                            <Table aria-label="simple table" className='table_data'>
                                <TableHead>
                                    <TableRow className='table-header-row'>
                                        <TableCell className='table-heading'>Transaction Status</TableCell>
                                        <TableCell className='table-heading'>Count</TableCell>
                                        <TableCell className='table-heading'>Percentage (%)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> Successful Requests </TableCell>
                                        <TableCell className='table-cell'>{SuccessfulCount}</TableCell>
                                        <TableCell className='table-cell'>{calculatePercentage(SuccessfulCount, totalCount)}</TableCell>
                                    </TableRow>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> Unsuccessful Requests </TableCell>
                                        <TableCell className='table-cell'>{UnsuccessfulCount}</TableCell>
                                        <TableCell className='table-cell'>{calculatePercentage(UnsuccessfulCount, totalCount)}</TableCell>
                                    </TableRow>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> In-progress Requests </TableCell>
                                        <TableCell className='table-cell'>{InProgress}</TableCell>
                                        <TableCell className='table-cell'>{calculatePercentage(InProgress, totalCount)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                    <TableContainer component={Paper} className='table-container'>
                        <Typography variant='h7' className='table_main_heading'>Breakdown of Status Requests</Typography>
                        <div className='table_outer_container'>
                            <Table aria-label="simple table" className='table_data'>
                                <TableHead>
                                    <TableRow className='table-header-row'>
                                        <TableCell className='table-heading'>Transaction Status</TableCell>
                                        <TableCell className='table-heading'>Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> Multiple Patients Found </TableCell>
                                        <TableCell className='table-cell'>{getCountByBundleStatus(bundle, "Multiple Patients Found")} </TableCell>
                                    </TableRow>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> No Document(s) Found </TableCell>
                                        <TableCell className='table-cell'> {getCountByBundleStatus(bundle, "No Document(s) Found")} </TableCell>
                                    </TableRow>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> No Response From Source System </TableCell>
                                        <TableCell className='table-cell'> {getCountByBundleStatus(bundle, "No Response From Source System")} </TableCell>
                                    </TableRow>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> CCDA Returned </TableCell>
                                        <TableCell className='table-cell'> {getCountByBundleStatus(bundle, "CCDA Returned")} </TableCell>
                                    </TableRow>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> Patient Not Found </TableCell>
                                        <TableCell className='table-cell'> {getCountByBundleStatus(bundle, "Patient Not Found")} </TableCell>
                                    </TableRow>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> Initiated Search</TableCell>
                                        <TableCell className='table-cell'> {getCountByBundleStatus(bundle, "Initiated Search")} </TableCell>
                                    </TableRow>
                                    <TableRow className='table-cell' >
                                        <TableCell className='table-cell'> Invalid Request </TableCell>
                                        <TableCell className='table-cell'> {getCountByBundleStatus(bundle, "Invalid Request")} </TableCell>
                                    </TableRow>
                                    <TableRow className='table-cell-total' >
                                        <TableCell className='table-cell-total'> Total </TableCell>
                                        <TableCell className='table-cell-total'> {totalCount} </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                </div>
            </div>
        </div >
    )
}

export default PatientDataRequestSummary
