import Cookie from 'js-cookie';

const decodeBase64 = (str) => {
  try {
    if (str === "") {
      return null; // or handle as appropriate
    }
    return decodeURIComponent(atob(str));
  } catch (error) {
    console.error("Error decoding base64:", error);
    return null;
  }
};

const GetCookie = (cookieName) => {
  const encodedCookieValue = Cookie.get(cookieName);
  if (cookieName === 'userInfo' && !encodedCookieValue) {
    return JSON.stringify({ isAuth: false });
  }
  return decodeBase64(encodedCookieValue);
};

export default GetCookie;