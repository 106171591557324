import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import HIPConnect from '../../../assets/HIP Connect.svg';
import SendOutlineIcon from '../../../assets/sendOuline.svg';
import { openDatabase } from '../../../IndexedDBUtility/IndexedDBUtility';
import "../../../Theme/Dashboard.css";

function Dashboard() {
    const [permissionData, setPermissionData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = await openDatabase();

                const transaction = db.transaction(['permissionData'], 'readonly');
                const objectStore = transaction.objectStore('permissionData');

                const getRequest = objectStore.getAll();

                getRequest.onsuccess = (event) => {
                    const storedData = event.target.result;

                    const data = storedData[0].value;
                    const clinicalModule = data.find((module) => module.moduleName === 'Connect')
                    const displayNamesList = clinicalModule.permissionsDataArray.map((permission) => permission.displayName);
                    setPermissionData(displayNamesList)
                };

                getRequest.onerror = (error) => {
                    console.error('Error getting data from IndexedDB:', error.target.error);
                };


            } catch (error) {
                console.error(`Error opening IndexedDB: ${error}`);
            }

        };

        fetchData();
    }, []);
    return (
        <>
            <Grid container className="landing_screen_menu_container" >
                <Grid item xs={12}>
                    <Stack>
                        <Box className="landing_screen_scale">
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <img className="landing_screen_scale_icon" src={HIPConnect} alt="hipConnect" />
                            </Box>
                            <Box className="landing_screen_heading" >
                                <Typography variant="h3" >Hip One Connect</Typography>
                            </Box>
                            <Box className="landing_screen_heading" sx={{ margin: 4 }} >
                                <Typography variant="h4">Intelligent Conversation Enabled</Typography>
                            </Box>

                            <Box className="landing_screen_heading" sx={{ gap: 5, p: 2 }}>
                                {permissionData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {item === "Interactive Document" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" href="/hip-connect/interactive-document" >
                                                <p className="landing_screen_button_text"> Interactive Document </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "URL" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" href="/hip-connect/hip-connection-url">
                                                <p className="landing_screen_button_text"> URL </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "Business Intelligence" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" href="/hip-connect/hip-connection-database">
                                                <p className="landing_screen_button_text"> Business Intelligence </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "Logsifters" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" href="/hip-connect/hip-logsifters">
                                                <p className="landing_screen_button_text"> Logsifters </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                    </React.Fragment>

                                ))
                                }

                            </Box>

                            <Box className="landing_screen_menu_sub_content" >
                                <Typography variant="h4" className="landing_screen_content_text" >
                                Through our HIP One Connect platform, we facilitate intelligent conversations that have been successfully <br/>
                                implemented across numerous use cases within the healthcare ecosystem.
                                </Typography>
                            </Box>
                            <Box className="landing_screen_menu_sub_content" >
                                <Typography variant="h4" className="landing_screen_content_text" >
                                HIP One Connect, our revolutionary solution, enhances service operations and empowers leaders by providing <br/>
                                actionable insights. By harnessing the power of Gen AI specifically tailored for healthcare, organizations <br/>
                                can experience a significant boost in productivity, with improvements ranging from 20% to 25%.
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default Dashboard