import React, { useEffect, useState } from 'react'
import "./UserManagement.css"
import Tabs from './UserTabs'
import UserProfile from '../UserProfile/UserProfile'
import RolesPermissions from '../RolesPermissions/RolesPermissions'
import AddUser from '../AddUser/AddUser'
import Manage from '../Manage/Manage'
import GetCookie from '../../../HIPCookies/getCookie'

function UserManagement() {
    const [activeTab, setActiveTab] = useState("User Profiles");
    const roleData = JSON.parse(GetCookie('roleInfo'));
    const isSuperAdmin = roleData.some(role => role.roleName === 'Super Administrator');
    const tabs = ['User Profiles', 'Roles & Permissions', 'Add New User', 'Manage']
    const visibleTabs = isSuperAdmin ? tabs : ['User Profiles', 'Add New User']
    const tabComponents = {
        'User Profiles': <UserProfile />,
        'Roles & Permissions': <RolesPermissions />,
        'Add New User': <AddUser navigateTo={setActiveTab} />,
        'Manage': <Manage />,
    };

    return (
        <>
            <div className='user_management_main_container'>
                <div className='user_configuration_btns'>
                    <div className='user_configuration_document_title'>
                        <span>User Management</span>
                    </div>
                    <Tabs tabs={visibleTabs} activeTab={activeTab} setActiveTab={setActiveTab} defaultTab="User Profiles" content={tabComponents} />
                </div>
            </div>
        </>
    )
}

export default UserManagement