import Axios from "axios";
import { Close, HighlightOffRounded } from "@mui/icons-material";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GetCookie from "../../../../HIPCookies/getCookie";
import { getUploadAlert, setLoadingScreen, setNormalAlert, setToastAlert, setUploadAlert } from "../../../../Redux/features/AlertsSlice";
import { setDocumentsList, setImageUrlList, setOriginalUrlList, setUpdateBillList, setUploadedFilename } from "../../../../Redux/features/DocumentsSlice";
import UploadImage from "../../../../assets/Icons/Upload icon.svg";
import "./UploadFile.css";

import AddRing1 from "../../../assets/plusAdd.svg";
import CloseRoundLight from "../../../assets/Close_round_light.svg";
import LoadingIcon from "../../../assets/loading.svg";
import deleteIcon from "../../../assets/Trash_light.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, LinearProgress, Stack, Typography, Box } from "@mui/material";
import { useDropzone } from "react-dropzone";


function UploadFile() {
  const uploadAlert = useSelector(getUploadAlert);
  const userName = (JSON.parse(GetCookie("userInfo")) || {}).userNameDisplay;
  const dispatch = useDispatch();

  const [uploading, setUploading] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);

  // const pdfList = [
  //   {
  //     Name: "Pdf Name",
  //     Status: "Onboarding",
  //     "Date Modified": "05/08/2023",
  //     "Date Added": "05/06/2023",
  //   },
  // ];

  const navigate = useNavigate();
  const [Files, setFiles] = useState([]);
  const [config, setConfig] = useState("");
  const userId = (JSON.parse(GetCookie("userInfo")) || {}).userId;

  useEffect(() => {
    if (uploadAlert.configuration !== null) {
      setConfig(uploadAlert.configuration);
    }
  }, [uploadAlert]);

  const handleSetFiles = (files) => {
    if (files.length > 0) {
      const invalidFiles = [];
      const validFiles = Array.from(files).filter((file) => {
        const fileType = file.type;
        const fileName = file.name.toLowerCase();
        const isValid = fileType === "application/pdf" ||
          fileType === "image/tiff" ||
          fileType === "image/tif" ||
          fileName.endsWith(".pdf") ||
          fileName.endsWith(".tiff") ||
          fileName.endsWith(".tif");

        if (!isValid) {
          invalidFiles.push(file);
        }
        return isValid;
      });

      if (invalidFiles.length > 0) {
        // Display error message for invalid files
        setFiles([]);
        setUploading(false);
        dispatch(setToastAlert({
          isactive: true,
          type: 'error',
          exp: 10000,
          message: `Invalid file type. Please select a .pdf, .tiff, or .tif file.`
        }));
      } else {
        setFiles(validFiles);
      }
    }
  };

  const handleRemoveFiles = (name) => {
    setFiles(Files.filter((item) => item.name !== name));
  };

  const formatFileSize = (sizeInBytes) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let fileSize = sizeInBytes;
    let unitIndex = 0;

    while (fileSize >= 1024 && unitIndex < units.length - 1) {
      fileSize /= 1024;
      unitIndex++;
    }
    return `${fileSize.toFixed(2)} ${units[unitIndex]}`;
  }

  const handleUpload = async () => {
    var formData = new FormData();
    if (config !== "" || Files.length === 0) {
      for (let i = 0; i < Files.length; i++) {
        dispatch(
          setLoadingScreen({
            isActive: true,
          })
        );

        if (uploadAlert.isClaimActive) {
          formData.append("file", Files[i]);
          try {
            let claim_process = await Axios.post(
              `${process.env.REACT_APP_CONNECT}/med-review-process/upload`,
              formData,
              {
                headers: {
                  accept: "application/json",
                  "Content-Type": "multipart/form-data",
                  userId: userId
                },
              }
            );

            if (claim_process.data.filename) {
              dispatch(setUploadedFilename(claim_process.data.filename));
              dispatch(setOriginalUrlList(claim_process.data.data));
              dispatch(setImageUrlList(claim_process.data.data));
            }

            if (claim_process.data.message) {
              if (claim_process.data.message === "PDF already existed") {
                dispatch(setToastAlert({
                  isactive: true,
                  type: 'warning',
                  exp: 10000,
                  message: claim_process.data.message
                }))

              } else {
                dispatch(setToastAlert({
                  isactive: true,
                  type: 'success',
                  exp: 10000,
                  message: claim_process.data.message,
                }));
              }
            }
            else if (claim_process.data.error) {
              dispatch(setToastAlert({
                isactive: true,
                type: 'warning',
                exp: 10000,
                message: claim_process.data.error
              }))
              dispatch(
                setLoadingScreen({
                  isActive: false,
                })
              );
            }
          } catch (error) {
            dispatch(setToastAlert({
              isactive: true,
              type: 'error',
              exp: 10000,
              message: error.message
            }))
            dispatch(
              setLoadingScreen({
                isActive: false,
              })
            );
          }
        } else if (uploadAlert.isMbr) {
          formData.append("file", Files[0]);
          setTimeout(() => {
            dispatch(setUpdateBillList());
            dispatch(
              setLoadingScreen({
                isActive: false,
              })
            );
            dispatch(setUploadAlert(false));
          }, 1000);
          try {
            let resp = await Axios.post(
              `${process.env.REACT_APP_CONNECT}/med-bill-worksheet/upload_extract`,
              formData,
              {
                headers: {
                  userId: userId,
                },
              }
            );
            dispatch(setUpdateBillList());
          } catch (e) {
            dispatch(setUpdateBillList());
            if (e.response.status === 400) {
              dispatch(
                setToastAlert({
                  isActive: true,
                  message: "This file is already uploaded",
                  type: 'error',
                  exp: 10000
                })
              );
            } else {
              dispatch(
                setToastAlert({
                  isActive: true,
                  message: "error while processing file",
                  type: 'error',
                  exp: 10000
                })
              );
              toast.error("error while processing file", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        } else {
          formData.append("input_file", Files[i]);
          let result2 = await Axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/data/upload_doc?use_case=${config}&user=${userName}`,
            formData,
            {
              headers: {
                accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (result2.data.status) {
            dispatch(
              setToastAlert({
                isActive: true,
                message: result2.data.msg,
                type: 'success',
                exp: 10000
              })
            );
          } else {
            dispatch(
              setToastAlert({
                isActive: true,
                message: "Invalid document type.",
                type: 'error',
                exp: 10000
              })
            );
          }
          var result = await Axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/list_of_Documents`
          );

          setTimeout(() => {
            // auto refresh
            Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/list_of_Documents`
            ).then((res) => {
              dispatch(setDocumentsList(res.data));
            });
          }, 60000);
          dispatch(setDocumentsList(result.data));
        }
      }

      setConfig("");
      navigate(`/${uploadAlert.goto}`);
      handleClose();
      dispatch(
        setLoadingScreen({
          isActive: false,
        })
      );
    } else {
      dispatch(
        setToastAlert({
          isActive: true,
          message: "Please Configure the Documents....",
          type: 'error',
          exp: 10000
        })
      );
    }
  };

  const handleClose = () => {
    setFiles([]);
    setConfig("");
    setUploading(false);
    dispatch(setUploadAlert(false));
  };

  const onDragandDrop = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      setFiles((oldarray) => [...oldarray, e.dataTransfer.files[i]]);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
  };

  const onDrop = useCallback((acceptedFiles) => {
    setUploading(true);
    handleSetFiles(acceptedFiles)

    const filesData = acceptedFiles.map((file) => ({
      name: file.name,
      size: file.size,
      progress: 0,
    }));

    setUploadingFiles(filesData);
    // Simulate file uploads with a timer
    filesData.forEach((fileData, index) => {
      setTimeout(() => {
        // Update progress for each file (simulated)
        setProgressForFile(index, 100);
      }, (index + 1) * 2000); // Simulating a 2-second delay for each file
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [".tif", ".pdf", ".tiff"],
    multiple: true,
    disabled: uploading, // Disable Dropzone when uploading
  });

  const setProgressForFile = (index, progress) => {
    setUploadingFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index].progress = progress;
      return newFiles;
    });
  };

  const handleDelete = (index) => {
    // Simulate clearing uploading files
    setUploadingFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });

    // Check if all files have been deleted
    if (uploadingFiles.length === 1) {
      setUploading(false);
    }
  };

  return (
    <>
      {uploadAlert.isActive && (
        <div className="hip_clinical_upload_file_overlay_bg">
          <Box className="hip-one-upload-box">
            <Box className="hip-one-upload-pop-up">
              <Box className="hip-one-upload-overlap-group">
                <Box {...getRootProps()} className="hip-one-upload-group">
                  <input {...getInputProps()} />
                  <Box className={`hip-one-upload ${isDragActive ? "drag-active" : ""}`}  >
                    {uploading ? (
                      <>
                        <Box className="hip-one-upload-file-list">
                          {uploadingFiles.map((file, index) => (
                            <Box key={index}>
                              <p variant="subtitle1" className="hip-one-upload-filename-text" >
                                {file.name}
                              </p>
                              <div className="hip-one-upload-container">
                                <p variant="subtitle1" className="hip-one-upload-filesize-text" >
                                  {file.size ? formatFileSize(file.size) : ""}
                                </p>
                                <LinearProgress thickness={4} variant="determinate" value={file.progress}
                                  sx={{
                                    width: 200,
                                    backgroundColor: "#ffffff",
                                    "& .MuiLinearProgress-bar": {
                                      backgroundColor: "#004BEE",
                                    },
                                  }}
                                  className="hip-one-upload-file-progress-bar"
                                />
                                <p variant="subtitle2" className="hip-one-upload-filesize-percentage" >
                                  {file.progress ? `${Math.round(file.progress)}%` : ""}
                                </p>
                                {file.progress === 100 ? (
                                  <Box sx={{ minWidth: 0, padding: 0, width: 24, height: 24 }} >
                                    <CheckCircleIcon sx={{ color: "#17e7a1" }} />
                                  </Box>
                                ) : (
                                  <Box sx={{ minWidth: 0, padding: 0, width: 24, height: 24 }} >
                                    <img style={{ animation: "spin 1s linear infinite" }} src={LoadingIcon} alt="Loading" />
                                  </Box>
                                )}
                                <Button onClick={() => handleDelete(index)} sx={{ minWidth: 0, padding: 0, width: 24, height: 24 }} >
                                  <img src={deleteIcon} alt="Delete" />
                                </Button>
                              </div>
                            </Box>
                          ))}
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box className="hip-one-upload-drag-drop-file">
                          Drag &amp; Drop File
                        </Box>
                        <Box className="hip-one-upload-text-wrapper">Or</Box>
                        <Box className="hip-one-upload-add-ring-wrapper">
                          <img src={AddRing1} alt="Add Ring" />
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography className="hip-one-upload-title"> Upload New Claim </Typography>
                  </Box>
                  <Box className="hip-one-upload-close-round-light" color="#333333" style={{ cursor: "pointer" }} >
                    <img src={CloseRoundLight} onClick={handleClose} alt="Close Round Light" className="hip-one-close-round-icon" />
                  </Box>
                  <Box className="hip-one-upload-text-wrapper-2"> Maximum limit 10MB </Box>
                  <Typography className="hip-one-upload-p"> Supported formats .tif, .pdf, .tiff </Typography>
                  {uploading ? (
                    <>
                      <Box className="hip-one-upload-would-you-like-to-button">
                        <Button className="hip-one-upload-would-you-like-to-button-color" variant="contained" onClick={handleUpload} >
                          Done
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
}

export default UploadFile;
