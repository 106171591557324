import React, { useEffect, useState } from 'react'
import "./DocConfig.css"
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { getDocConfig, setDocumentConfig, setLoadingScreen, setNormalAlert } from '../../../../Redux/features/AlertsSlice';
import Axios from 'axios'
import { setDocumentsList } from '../../../../Redux/features/DocumentsSlice';
import { Close } from '@mui/icons-material';



function DocConfig() {

    const docConfig = useSelector(getDocConfig)

    const [configData, setConfigData] = useState({})
    const [updatedData, setUpdatedData] = useState({})

    const baseURL = `${process.env.REACT_APP_API_ENDPOINT}`

    useEffect(() => {

        Axios.get(`${baseURL}/use_case_configs`).then((result) => {
            setConfigData(result.data.filter(item => item.use_case === docConfig.useCase)[0])
        })

    }, [docConfig, baseURL])

    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setDocumentConfig({
            isActive: false,
            config: {}
        }))
    }

    const handleConfigData = (e, useCase, name) => {
        if (useCase === 'Medical Billing') {

            let columns = { ...configData.configuration.tables.columns }
            columns[name] = e.target.checked

            setConfigData({
                id: configData.id,
                approved: configData.approved,
                review_pending: configData.review_pending,
                uploaded: configData.uploaded,
                use_case: useCase,
                configuration: { "key_value_pairs": null, "tables": { "columns": columns } }
            })
            setUpdatedData({
                use_case: useCase,
                configuration: { "key_value_pairs": null, "tables": { "columns": columns } }
            })

        }
        else {
            let key_value_pairs = { ...configData.configuration.key_value_pairs }
            key_value_pairs[name] = e.target.checked

            setConfigData({
                id: configData.id,
                approved: configData.approved,
                review_pending: configData.review_pending,
                uploaded: configData.uploaded,
                use_case: useCase,
                configuration: { "key_value_pairs": key_value_pairs, "tables": null }
            })
            setUpdatedData({
                use_case: useCase,
                configuration: { "key_value_pairs": key_value_pairs, "tables": null }
            })
        }

    }

    const handleUpdateDocumentConfiguration = async () => {

        if (updatedData.use_case === undefined) {
            dispatch(setNormalAlert({
                isActive: true,
                message: "Please Try again! Nothing is Edited...",
                variant: "success"
            }))
            setTimeout(() => {
                dispatch(setNormalAlert({
                    isactive: false
                }))
            }, 3000)
        }
        else {

            dispatch(setLoadingScreen({
                isActive: true
            }))

            let result = await Axios.post(`${baseURL}/update_usecase_config`, updatedData)

            if (result.data.status) {

                Axios.get(`${baseURL}/use_case_configs`).then((result) => {
                    dispatch(setConfigData(result.data))
                })

                dispatch(setDocumentConfig({
                    isActive: false,
                    config: {}
                }))

                dispatch(setLoadingScreen({
                    isActive: false
                }))

                dispatch(setNormalAlert({
                    message: result.data.msg,
                    variant: "success",
                    isActive: true
                }))
                setTimeout(() => {
                    dispatch(setNormalAlert({
                        isActive: false
                    }))
                }, 3000)
            }
            else {
                dispatch(setNormalAlert({
                    message: result.message,
                    variant: "success",
                    isActive: true
                }))
                setTimeout(() => {
                    dispatch(setNormalAlert({
                        isActive: false
                    }))
                }, 3000)
            }
        }

    }

    return (
        <>{
            docConfig.isActive && (
                <div className='document_config_container'>
                    <div className='document_config_span'>
                        <div className='document_config_header'>
                            <span>{docConfig.name}</span>
                            <Close onClick={handleClose} style={{ cursor: "pointer" }} />
                        </div>
                        <div className='document_config_body'>
                            <FormGroup>
                                {
                                    configData === undefined ?
                                        <div style={{
                                            height: "30vh",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontWeight: "bold",
                                            background: "#fff",
                                        }}>No Data Found</div>
                                        :
                                        <>
                                            {
                                                configData.use_case === 'Medical Billing' ?
                                                    <>
                                                        {
                                                            Object.keys(configData.configuration.tables.columns).map((e, index) => {
                                                                return (
                                                                    <FormControlLabel key={index} control={
                                                                        <Checkbox
                                                                            onChange={(event) => handleConfigData(event, configData.use_case, e)}
                                                                            checked={configData.configuration.tables.columns[e]}
                                                                        />
                                                                    } label={e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e} />
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            Object.keys(configData.configuration.key_value_pairs).map((e, index) => {
                                                                return (
                                                                    <FormControlLabel key={index} control={
                                                                        <Checkbox
                                                                            onChange={(event) => handleConfigData(event, configData.use_case, e)}
                                                                            checked={configData.configuration.key_value_pairs[e]}

                                                                        />
                                                                    } label={e.toLowerCase()
                                                                        .split(' ')
                                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                        .join(' ')} />
                                                                )
                                                            })
                                                        }
                                                    </>
                                            }

                                        </>
                                }
                            </FormGroup>
                        </div>
                        <div className='document_config_actions'>
                            <div className='document_config_btns'>
                                <button onClick={handleClose}>Cancel</button>
                                <button onClick={handleUpdateDocumentConfiguration}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }</>
    )
}

export default DocConfig