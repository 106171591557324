import RemoveCookie from "../HIPCookies/removeCookie";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TokenExpiration = (tokenExpiration) => {
  const clearIndexedDB = () => {
    window.indexedDB.deleteDatabase('Database1');
  };

  try {
    // Convert tokenExpiration to milliseconds
    let tokenExpire = tokenExpiration * 60000;
    const currentTime = new Date().getTime();
    tokenExpire += currentTime;
    const timeToExpire = tokenExpire - currentTime;

    if (timeToExpire > 0) {
      const warningTime = 60000; // Set the toast to appear 1 minute before expiration
      let warningShown = false;

      const intervalId = setInterval(() => {
        const remainingTime = tokenExpire - new Date().getTime();

        if (remainingTime <= warningTime && !warningShown) {
          // Show a warning toast notification: JWT Token is about to expire in 30 seconds. You will be logged out.
          toast.warning(`Logging out in 30 seconds due to JWT Token expiration.`, {
            position: toast.POSITION.TOP_CENTER,
          });
          warningShown = true;

          setTimeout(() => {
            // Clear user data from cookies and clear database in IndexedDB
            RemoveCookie();
            clearIndexedDB();

            // Clear the interval once the warning is shown
            clearInterval(intervalId);
            // Redirect user to the login screen
            window.open("/login", "_self");
          }, 30000);
        }
      }, 1000); // Check every second
    }
  } catch (error) {
    console.error('Error in TokenExpiration:', error);
  }
};

export default TokenExpiration;
