import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const initialState = {
  loading: {
    isActive: false,
  },

  normalAlert: {
    isActive: false,
  },

  uploadAlert: {
    isActive: false,
    isClaimActive: false,
    configuration: null,
    goto: null,
  },

  timeLine: {
    isActive: false,
  },

  docConfig: {
    isActive: false,
    config: {},
  },

  viewFile: {
    isActive: false,
  },

  menu_Collapse: -1,
  customCollapse: false,
  claimProcess: false,
  filter: "Choose Filter",
  toastAlert: {
    isactive: false,
    message: "",
    type: "info",
    exp: 10000
  }
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setLoadingScreen: (state, action) => {
      state.loading = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setClaimProcess: (state, action) => {
      state.loading = action.payload;
    },
    setNormalAlert: (state, action) => {
      state.normalAlert = action.payload;
    },

    setUploadAlert: (state, action) => {
      state.uploadAlert = action.payload;
    },

    setTimelineAudit: (state, action) => {
      state.timeLine = action.payload;
    },

    setDocumentConfig: (state, action) => {
      state.docConfig = action.payload;
    },

    setViewFile: (state, action) => {
      state.viewFile = action.payload;
    },

    setCollapse: (state, action) => {
      state.menu_Collapse = action.payload;
    },
    setCustomCollapse: (state, action) => {
      state.customCollapse = action.payload;
    },
    setToastAlert: (state, action) => {
      state.toastAlert = action.payload
    }
  },
});

export const {
  setLoadingScreen,
  setNormalAlert,
  setUploadAlert,
  setViewFile,
  setTimelineAudit,
  setDocumentConfig,
  setCollapse,
  setCustomCollapse,
  setClaimProcess,
  setFilter,
  setToastAlert
} = alertsSlice.actions;



export const getLoading = (state) => state.alerts.loading;

export const getNormalAlert = (state) => state.alerts.normalAlert;

export const getUploadAlert = (state) => state.alerts.uploadAlert;

export const getTimeLine = (state) => state.alerts.timeLine;

export const getDocConfig = (state) => state.alerts.docConfig;

export const getViewFile = (state) => state.alerts.viewFile;

export const getCollapse = (state) => state.alerts.menu_Collapse;

export const getCustomeCollapse = (state) => state.alerts.customCollapse;

export const getClaimProcess = (state) => state.alerts.claimProcess;

export const getFilter = (state) => state.alerts.filter;

export const toastAlert = (state) => state.alerts.toastAlert;

export default alertsSlice.reducer;
