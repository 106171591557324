import React, { useEffect, useState } from "react";
import axios from "axios";
import { Paper } from "@material-ui/core";
import { Box, Button, Grid, Rating, Typography } from "@mui/material";
import LoadingScreen from "../../../../Billing/Pages/LoadingScreen/LoadingScreen";
import { useSelector, useDispatch } from "react-redux";

const Details = (props) => {
  const parent = useSelector(
    (state) => state.startDashBoard.parentOrganization
  );
  const year = useSelector((state) => state.startDashBoard.year);
  // console.log(props, "details page prop");

  const dataForYear = props.data.national_avg?.find(
    (item) => item.year === year
  );

  const overallStarForYear = dataForYear
    ? dataForYear.national_average_star
    : 0;
  // console.log("overrrrrrrrr", overallStarForYear);
  // console.log("props", props);

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return props.isLoading ? (
    <LoadingScreen />
  ) : (
    <Box
      sx={{
        width: " 95%",
        height: "388px",
        flexShrink: 0,
        marginLeft: "24px",
        boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
        background: "#fff",
        border: "1px solid #E0E3E8",
        borderRadius: "4px",
        padding: "0px 15px 15px 15px",
        marginTop: "40px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "right",
          marginTop: "24px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              color: "#444",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "18px",
            }}
          >
            Contract ID
          </Typography>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              color: "#1A1B21",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "18px",
            }}
          >
            {props.data?.contract_data?.[0]?.contract_id ?? "NA"}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", marginLeft: "24px" }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              color: "#444",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "18px",
            }}
          >
            Organization Type
          </Typography>

          {props.data.contract_data && props.data.contract_data.length > 0 ? (
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#1A1B21",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "18px",
              }}
            >
              {capitalizeWords(
                props.data.contract_data[0]?.organization_type
              ) ?? "NA"}
            </Typography>
          ) : (
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#1A1B21",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "18px",
              }}
            >
              NA
            </Typography>
          )}
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", marginLeft: "24px" }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              color: "#444",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "18px",
            }}
          >
            Contract Name
          </Typography>
          {props.data.contract_data && props.data.contract_data.length > 0 ? (
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#1A1B21",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "18px",
              }}
            >
              {capitalizeWords(props.data.contract_data[0]?.contract_name) ??
                "NA"}
            </Typography>
          ) : (
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#1A1B21",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "18px",
              }}
            >
              NA
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            ...(props.data.high_performing_present &&
              props.data.high_performing_present !== null && {
                backgroundColor: "#D6FFE6",
              }),
            display: "flex",
            padding: "4px 10px",
            alignItems: "center",
            gap: "10px",
            width: "154px",
            height: "28px",
            borderRadius: "200px",
          }}
        >
          {props.data.high_performing_present &&
            props.data.high_performing_present !== null && (
              <Box display={"flex"} sx={{ marginLeft: "10px" }}>
                <Typography
                  sx={{
                    color: "#211A1D",
                    // fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "12px",
                    marginTop: "4px",
                  }}
                >
                  High performance
                </Typography>
                <Box sx={{ marginLeft: "10px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M13.3332 5L15.2415 6.90833L11.1748 10.975L7.8415 7.64167L1.6665 13.825L2.8415 15L7.8415 10L11.1748 13.3333L16.4248 8.09167L18.3332 10V5H13.3332Z"
                      fill="#179B62"
                    />
                  </svg>
                </Box>
              </Box>
            )}
        </Box>
      </Box>
      <Box sx={{ marginTop: "24px", width: "100%" }}>
        <Typography
          sx={{
            whiteSpace: "nowrap",
            color: "#444",
            fontFamily: "Inter",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "18px",
          }}
        >
          Star Rating
        </Typography>
      </Box>

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              color: "#211A1D",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "14px",
            }}
          >
            Part C{" "}
          </Typography>
        </Box>

        <Box
          sx={{
            height: "30px",
            width: "52px",
            flexDirection: "row",
            display: "flex",
            marginLeft: "8px",
            alignItems: "center",
            border: "0.5px solid #FFEAB0",
            borderRadius: "4px",
            background: "#FFFAF2",
            justifyContent: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
          >
            <g clip-path="url(#clip0_461_13824)">
              <path
                d="M5.76932 8.43108C5.60347 8.33574 5.3955 8.33575 5.22965 8.43111L2.68603 9.89359C2.29177 10.1203 1.80438 9.78489 1.90915 9.359L2.5835 6.61755C2.62731 6.43945 2.56379 6.25297 2.4184 6.13287L0.179445 4.28326C-0.167367 3.99676 0.0190734 3.4556 0.47731 3.41869L3.42793 3.181C3.62007 3.16552 3.78746 3.05057 3.86295 2.88225L5.02092 0.300523C5.20065 -0.100175 5.79935 -0.100174 5.97908 0.300524L7.13705 2.88225C7.21254 3.05057 7.37993 3.16552 7.57207 3.181L10.5227 3.41869C10.9809 3.4556 11.1674 3.99676 10.8206 4.28326L8.5816 6.13287C8.43621 6.25297 8.37269 6.43945 8.4165 6.61755L9.0909 9.35919C9.19565 9.78505 8.70833 10.1204 8.31407 9.89382L5.76932 8.43108Z"
                fill="#FFC500"
              />
            </g>
            <defs>
              <clipPath id="clip0_461_13824">
                <rect width="11" height="10" fill="white" />
              </clipPath>
            </defs>
          </svg>

          {props.data.contract_data &&
          props.data.contract_data?.length > 0 &&
          props.data.contract_data[0]["Type C Star"] ? (
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#211A1D",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "18px",
                marginLeft: "5px",
              }}
            >
              {props.data.contract_data[0]["Type C Star"]}
            </Typography>
          ) : (
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#211A1D",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "18px",
                marginLeft: "5px",
              }}
            >
              NA
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <Box>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#211A1D",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "14px",
              }}
            >
              Part D{" "}
            </Typography>
          </Box>

          <Box
            sx={{
              height: "30px",
              width: "52px",
              flexDirection: "row",
              display: "flex",
              marginLeft: "8px",
              alignItems: "center",
              border: "0.5px solid #FFEAB0",
              borderRadius: "4px",
              background: "#FFFAF2",
              justifyContent: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
            >
              <g clip-path="url(#clip0_461_13824)">
                <path
                  d="M5.76932 8.43108C5.60347 8.33574 5.3955 8.33575 5.22965 8.43111L2.68603 9.89359C2.29177 10.1203 1.80438 9.78489 1.90915 9.359L2.5835 6.61755C2.62731 6.43945 2.56379 6.25297 2.4184 6.13287L0.179445 4.28326C-0.167367 3.99676 0.0190734 3.4556 0.47731 3.41869L3.42793 3.181C3.62007 3.16552 3.78746 3.05057 3.86295 2.88225L5.02092 0.300523C5.20065 -0.100175 5.79935 -0.100174 5.97908 0.300524L7.13705 2.88225C7.21254 3.05057 7.37993 3.16552 7.57207 3.181L10.5227 3.41869C10.9809 3.4556 11.1674 3.99676 10.8206 4.28326L8.5816 6.13287C8.43621 6.25297 8.37269 6.43945 8.4165 6.61755L9.0909 9.35919C9.19565 9.78505 8.70833 10.1204 8.31407 9.89382L5.76932 8.43108Z"
                  fill="#FFC500"
                />
              </g>
              <defs>
                <clipPath id="clip0_461_13824">
                  <rect width="11" height="10" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {props.data.contract_data &&
            props.data.contract_data.length > 0 &&
            props.data.contract_data[0]["Type D Star"] ? ( // Check if "Type D Star" exists
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "18px",
                  marginLeft: "5px",
                }}
              >
                {props.data.contract_data[0]["Type D Star"]}
              </Typography>
            ) : (
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "18px",
                  marginLeft: "5px",
                }}
              >
                NA
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <Box>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#211A1D",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "14px",
              }}
            >
              Overall{" "}
            </Typography>
          </Box>

          <Box
            sx={{
              height: "30px",
              width: "52px",
              flexDirection: "row",
              display: "flex",
              marginLeft: "8px",
              alignItems: "center",
              border: "0.5px solid #FFEAB0",
              borderRadius: "4px",
              background: "#FFFAF2",
              justifyContent: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
            >
              <g clip-path="url(#clip0_461_13824)">
                <path
                  d="M5.76932 8.43108C5.60347 8.33574 5.3955 8.33575 5.22965 8.43111L2.68603 9.89359C2.29177 10.1203 1.80438 9.78489 1.90915 9.359L2.5835 6.61755C2.62731 6.43945 2.56379 6.25297 2.4184 6.13287L0.179445 4.28326C-0.167367 3.99676 0.0190734 3.4556 0.47731 3.41869L3.42793 3.181C3.62007 3.16552 3.78746 3.05057 3.86295 2.88225L5.02092 0.300523C5.20065 -0.100175 5.79935 -0.100174 5.97908 0.300524L7.13705 2.88225C7.21254 3.05057 7.37993 3.16552 7.57207 3.181L10.5227 3.41869C10.9809 3.4556 11.1674 3.99676 10.8206 4.28326L8.5816 6.13287C8.43621 6.25297 8.37269 6.43945 8.4165 6.61755L9.0909 9.35919C9.19565 9.78505 8.70833 10.1204 8.31407 9.89382L5.76932 8.43108Z"
                  fill="#FFC500"
                />
              </g>
              <defs>
                <clipPath id="clip0_461_13824">
                  <rect width="11" height="10" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {props.data.contract_data &&
            props.data.contract_data?.length > 0 &&
            props.data.contract_data[0].overall_star !== undefined ? (
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "18px",
                  marginLeft: "5px",
                }}
              >
                {props.data.contract_data[0].overall_star}
              </Typography>
            ) : (
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "18px",
                  marginLeft: "5px",
                }}
              >
                NA
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          width: "100%",
        }}
      >
        {props.data.contract_data &&
          props.data.contract_data?.length > 0 &&
          props.data.contract_data[0].offers_part_d && (
            <Box>
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center", // Center the items vertically
                  justifyContent: "center", // Center the items horizontally
                  height: "28px",
                  width: "125px",
                  backgroundColor: "#D6FFE6",
                  borderRadius: "200px",
                  marginTop: "24px",
                }}
              >
                <Typography
                  sx={{
                    // marginLeft: "5px",
                    whiteSpace: "nowrap",
                    color: "#211A1D",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "12px",
                  }}
                >
                  Offers Part D
                </Typography>
                <Box sx={{ marginLeft: "7px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M8.99984 0.666748C4.4165 0.666748 0.666504 4.41675 0.666504 9.00008C0.666504 13.5834 4.4165 17.3334 8.99984 17.3334C13.5832 17.3334 17.3332 13.5834 17.3332 9.00008C17.3332 4.41675 13.5832 0.666748 8.99984 0.666748ZM12.5832 7.00008L8.74984 12.0001C8.58317 12.1667 8.33317 12.3334 8.08317 12.3334C7.83317 12.3334 7.58317 12.2501 7.4165 12.0001L5.4165 9.41675C5.1665 9.08341 5.1665 8.50008 5.58317 8.25008C5.99984 8.00008 6.49984 8.00008 6.74984 8.41675L8.08317 10.1667L11.2498 6.00008C11.4998 5.66675 12.0832 5.58341 12.4165 5.83341C12.8332 6.08341 12.8332 6.58342 12.5832 7.00008Z"
                      fill="#02BC7D"
                    />
                  </svg>
                </Box>
              </Box>
            </Box>
          )}

        {props.data.contract_data &&
          props.data.contract_data?.length > 0 &&
          props.data.contract_data[0].snp && (
            <Box>
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "28px",
                  width: "75px",
                  backgroundColor: "#D6FFE6",
                  borderRadius: "200px",
                  marginTop: "24px",
                  marginLeft: "16px",
                }}
              >
                <Typography
                  sx={{
                    // marginLeft: "5px",
                    whiteSpace: "nowrap",
                    color: "#211A1D",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "12px",
                  }}
                >
                  SNP
                </Typography>
                <Box sx={{ marginLeft: "10px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M8.99984 0.666748C4.4165 0.666748 0.666504 4.41675 0.666504 9.00008C0.666504 13.5834 4.4165 17.3334 8.99984 17.3334C13.5832 17.3334 17.3332 13.5834 17.3332 9.00008C17.3332 4.41675 13.5832 0.666748 8.99984 0.666748ZM12.5832 7.00008L8.74984 12.0001C8.58317 12.1667 8.33317 12.3334 8.08317 12.3334C7.83317 12.3334 7.58317 12.2501 7.4165 12.0001L5.4165 9.41675C5.1665 9.08341 5.1665 8.50008 5.58317 8.25008C5.99984 8.00008 6.49984 8.00008 6.74984 8.41675L8.08317 10.1667L11.2498 6.00008C11.4998 5.66675 12.0832 5.58341 12.4165 5.83341C12.8332 6.08341 12.8332 6.58342 12.5832 7.00008Z"
                      fill="#02BC7D"
                    />
                  </svg>
                </Box>
              </Box>
            </Box>
          )}
      </Box>

      <Grid sx={{ marginTop: "25px" }} container>
        <Grid
          item
          xs={3.5}
          sx={{
            width: "212px",
            height: "auto",
            backgroundColor: "white",
            flexShrink: 0,
            borderRadius: "4px",
            border: "1px solid #E0E3E8",
            marginBottom: "16px",
            background: " #FFF",
            marginRight: "20px",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              marginTop: "25px",
              paddingLeft: "16px",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#444",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "18px",
              }}
            >
              {" "}
              National Avg Star{" "}
            </Typography>
            <Box
              sx={{
                height: "30px",
                width: "52px",
                flexDirection: "row",
                display: "flex",
                // marginLeft: "8px",
                alignItems: "center",
                marginTop: "18px",
                border: "0.5px solid #FFEAB0",
                borderRadius: "4px",
                background: "#FFFAF2",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
              >
                <g clip-path="url(#clip0_461_13824)">
                  <path
                    d="M5.76932 8.43108C5.60347 8.33574 5.3955 8.33575 5.22965 8.43111L2.68603 9.89359C2.29177 10.1203 1.80438 9.78489 1.90915 9.359L2.5835 6.61755C2.62731 6.43945 2.56379 6.25297 2.4184 6.13287L0.179445 4.28326C-0.167367 3.99676 0.0190734 3.4556 0.47731 3.41869L3.42793 3.181C3.62007 3.16552 3.78746 3.05057 3.86295 2.88225L5.02092 0.300523C5.20065 -0.100175 5.79935 -0.100174 5.97908 0.300524L7.13705 2.88225C7.21254 3.05057 7.37993 3.16552 7.57207 3.181L10.5227 3.41869C10.9809 3.4556 11.1674 3.99676 10.8206 4.28326L8.5816 6.13287C8.43621 6.25297 8.37269 6.43945 8.4165 6.61755L9.0909 9.35919C9.19565 9.78505 8.70833 10.1204 8.31407 9.89382L5.76932 8.43108Z"
                    fill="#FFC500"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_461_13824">
                    <rect width="11" height="10" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography
                sx={{
                  marginLeft: "5px",
                  whiteSpace: "nowrap",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "18px",
                }}
              >
                {overallStarForYear ?? ""}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={3.5}
          sx={{
            width: "212px",
            height: "auto",
            backgroundColor: "white",
            flexShrink: 0,
            borderRadius: "4px",
            border: "1px solid #E0E3E8",
            marginBottom: "16px",
            background: " #FFF",
            marginRight: "20px",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              marginTop: "25px",
              paddingLeft: "16px",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "nowrap",
                color: "#444",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "18px",
              }}
            >
              {" "}
              Effective Date
            </Typography>
            <Box
              sx={{
                height: "30px",
                width: "52px",
                flexDirection: "row",
                display: "flex",
                // marginLeft: "8px",
                alignItems: "center",
                marginTop: "18px",
              }}
            >
              <Typography>
                {props.data.contract_data &&
                props.data.contract_data?.length > 0 &&
                props.data.contract_data[0]["Effective Date"] !== undefined ? (
                  <Typography
                    sx={{
                      // marginLeft: "5px",
                      whiteSpace: "nowrap",
                      color: "#211A1D",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "18px",
                    }}
                  >
                    {props.data.contract_data[0]["Effective Date"]}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      // marginLeft: "5px",
                      whiteSpace: "nowrap",
                      color: "#211A1D",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "18px",
                    }}
                  >
                    NA
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            width: "276px",
            height: "auto",
            backgroundColor: "white",
            flexShrink: 0,
            borderRadius: "4px",
            border: "1px solid #E0E3E8",
            background: "#FFF",
            marginBottom: "16px",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              marginTop: "21px",
              paddingLeft: "16px",
              color: "#444",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "18px",
            }}
          >
            Disaster %
          </Typography>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              marginTop: "15px",
              //   borderTop: "1px solid #E0E3E8", // Add border between the boxes
              //   paddingTop: "12px", // Add padding to maintain spacing
            }}
          >
            <Box
              sx={{
                flex: 1, // Divide the space equally
                height: "77px",
                borderRight: "1px solid #E0E3E8", // Add right border
                paddingRight: "8px", // Add padding to maintain spacing
              }}
            >
              <Typography
                sx={{
                  marginLeft: "5px",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  color: "#444",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                {" "}
                {year - 3}
              </Typography>
              <Typography
                sx={{
                  marginLeft: "5px",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "18px",
                  marginTop: "20px",
                }}
              >
                {props.data.contract_data?.[0]?.["Disaster Old"] ?? "NA"}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1, // Divide the space equally
                height: "77px",
                paddingLeft: "8px", // Add padding to maintain spacing
              }}
            >
              <Typography
                sx={{
                  marginLeft: "5px",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  color: "#444",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                {year - 2}
              </Typography>
              <Typography
                sx={{
                  marginLeft: "5px",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "18px",
                  marginTop: "20px",
                }}
              >
                {props.data.contract_data?.[0]?.["Disaster New"] ?? "NA"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Details;
