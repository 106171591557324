import React from 'react';
import "../../../Theme/HIPBreadCrumb.css";
import { useLocation, useNavigate, NavLink as Link } from 'react-router-dom';
import BackImage from '../../../assets/Back.svg';
import HomeImage from '../../../assets/home.svg';
import RightArrowImage from '../../../assets/RightArrow.svg';

function HiPClinicalBreadCrumb() {

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className='bread_crumb_navigations'>
            <div className="bread_crumb_back_container" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <img src={BackImage} alt="" style={{ width: '18px' }} />
            </div>
            <div className='bread_crumb_navigations_content' >
                <a href='/dashboard'>
                    <img src={HomeImage} alt='home' />
                </a>
                <div className='bread_crumb_arrow_container'>
                    <img src={RightArrowImage} alt='arrow right' className='bread_crumb_arrow' />
                </div>
            </div>
            {
                Routes.filter(item => item.currentroute === location.pathname || item.currentroute.split('/')[1] === location.pathname.split('/')[2]).map((e, index) => {
                    return (
                        <div className="bread_crumb_navigation_sub_container" key={index}>
                            {
                                e.currentroute === '/hip-clinical' ?
                                    <Link to='/hip-clinical' >HIP One Clinical</Link>
                                    :
                                    <>
                                        <Link to='/hip-clinical'>HIP One Clinical</Link>
                                        {
                                            e.title.map((e2, index1) => {
                                                return (
                                                    <div className="bread_crumb_navigation_sub_content" key={index1}>
                                                        <img src={RightArrowImage} alt='arrow right' className='bread_crumb_nav_img' />
                                                        <Link
                                                            className={e.title.length - 1 === index1 && "bread_crumb_nav_item_bold"}
                                                            to={e.routes[index1]}
                                                            key={index1}
                                                        >{e.title[index1]}</Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default HiPClinicalBreadCrumb


const Routes = [
    {
        title: ["Clinical Interportability"],
        currentroute: "/hip-clinical/single-patient-search",
        routes: ["/hip-clinical", '/hip-clinical/single-patient-search']
    },
    {
        title: ["Workflow", "Bulk Patient Research"],
        currentroute: "/hip-clinical/bulk-patient-search",
        routes: ["/hip-clinical", '/hip-clinical/bulk-patient-search']
    },
    {
        title: ["Workflow", "Bulk Dowloads"],
        currentroute: "/hip-clinical/bulk-downloads",
        routes: ["/hip-clinical", '/hip-clinical/bulk-downloads']
    },

    {
        title: ["Clinical Interportability", "Results"],
        currentroute: "/hip-clinical/single-patient-search/results",
        routes: ["/hip-clinical/single-patient-search", "/hip-clinical/single-patient-search/results"]
    },

    {
        title: ["Dashboard", "All Patient Data Request"],
        currentroute: "/hip-clinical/all-patient-data-request",
        routes: ["/hip-clinical/all-patient-data-request", "/hip-clinical/all-patient-data-request"]
    },

    {
        title: ["Dashboard", "Bulk Patient Data Request"],
        currentroute: "/hip-clinical/bulk-patient-data-request",
        routes: ["/hip-clinical/all-patient-data-request", "/hip-clinical/bulk-patient-data-request"]
    },

    {
        title: ["Dashboard", "Bulk Downloads Summary"],
        currentroute: "/hip-clinical/bulk-downloads-summary",
        routes: ["/hip-clinical/all-patient-data-request", "/hip-clinical/bulk-downloads-summary"]
    },

    {
        title: ["Dashboard", "Operational Intelligence"],
        currentroute: "/hip-clinical/operational-intelligence",
        routes: ["/hip-clinical/all-patient-data-request", "/hip-clinical/operational-intelligence"]
    },

    {
        title: ["HIP Clinical"],
        currentroute: "/hip-clinical"
    },
    {
        title: ["Medical Review"],
        currentroute: "/hip-clinical/medical-review",
        routes: ["/hip-clinical", "/hip-clinical/medical-review"]
    },
    {
        title: ["Clinical summarization"],
        currentroute: "/hip-clinical/ccda-summarization",
        routes: ["/hip-clinical", "/hip-clinical/ccda-summarization"]
    },
    {
        title: ["Medical Review", "Document Verify"],
        currentroute: "/cp",
        routes: ["/hip-clinical/medical-review"],
    }
]