import React from 'react'
import "./RolesPermissions.css"
import SecurityToUserGroup from './SecurityToUserGroup/SecurityToUserGroup'
import PermissionToSecurity from './PermissionToSecurity/PermissionToSecurity'
import UserRoleToUserGroup from './UserRoleToUserGroup/UserRoleToUserGroup'

function RolesPermissions() {
    return (
        <div className='main_container_roles_permissions'>
            <PermissionToSecurity />
            <SecurityToUserGroup />
            <UserRoleToUserGroup />
        </div>
    )
}

export default RolesPermissions