import React, { useState, useEffect } from 'react';
import "./NavigationBar.css";
import HIPOperations from '../../assets/NewIcons/HIP Operations icon.svg';
import HIPClinical from '../../assets/NewIcons/HIP Clinical icon.svg';
import HIPConnect from '../../assets/NewIcons/HIP Connect icon.svg';
import HIPFinance from '../../assets/NewIcons/HIP Assist icon.svg';
import Settings from '../../assets/NewIcons/Setting_fill icon.svg';
import Billing from '../../assets/NewIcons/Billing_fill.svg';
import ContactUs from '../../assets/NewIcons/ContactUs_fill.svg';
import Feedback from '../../assets/NewIcons/Feedback_fill.svg';
import Help from '../../assets/NewIcons/Help_fill.svg';
import HIPOperationsFill from '../../assets/NewIcons/HIP Operations Coloured.svg';
import HIPConnectFill from '../../assets/NewIcons/HIP Connect Module Coloured.svg';
import HIPClinicalFill from '../../assets/NewIcons/HIP Clinical Module Coloured.svg';
import HIPFinanceFill from '../../assets/NewIcons/HIP Finance Module Coloured.svg';
import SettingsFill from '../../assets/NewIcons/Setting_Coloured.svg';
import BillingFill from '../../assets/NewIcons/Billing_Coloured.svg';
import FeedbackFill from '../../assets/NewIcons/Feedback_Coloured.svg';
import ContactFill from '../../assets/NewIcons/Contact_Coloured.svg';
import HelpFill from '../../assets/NewIcons/Help_Coloured.svg';
import { NavLink as Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import GetCookie from '../../HIPCookies/getCookie';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { clearState, setIsPdfBeingUploadedState, setIsPdfUploadedOneTimeState, setIsPdfUploadedState, setPrivacyPopup } from '../../Redux/features/MessageSlice';
import { Box, Typography } from '@mui/material';
import MenuNavBar from './MenuNavBar';

function NavigationBar({ setIsNavActive, isNavActive }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(window.location.pathname)
    const theme = useSelector((state) => state.messages.Theme) || "";
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const location = useLocation();
    const moduleData = JSON.parse(GetCookie('moduleInfo'));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const privacyPopupState = useSelector((state) => state.messages.privacyPopup);

    const chatHistoryState = useSelector((state) => state.messages.chatHistory);

    const [activeIcon, setActiveIcon] = useState(null);
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const roleDataFromCookie = GetCookie('roleInfo');
    const roleData = roleDataFromCookie ? JSON.parse(roleDataFromCookie) : [];

    // Check if "Super Administrator" is present using some()
    const isSuperAdmin = roleData.some(role => role.roleName === 'Super Administrator');
    // Check if "Administrator" is present using some()
    const isAdmin = roleData.some(role => role.roleName === 'Administrator');


    useEffect(() => {
        // Check if the current location matches any module path and set the activeIcon accordingly
        if (moduleData) {
            moduleData.forEach(item => {
                if (item.displayName === "Operations" && location.pathname.includes('/hip-operations')) {
                    setActiveIcon('HIPOperations');
                } else if (item.displayName === "Clinical" && location.pathname.includes('/hip-clinical')) {
                    setActiveIcon('HIPClinical');
                } else if (item.displayName === "Connect" && location.pathname.includes('/hip-connect')) {
                    setActiveIcon('HIPConnect');
                } else if (location.pathname.includes('/settings/user-management')) {
                    setActiveIcon('Settings');
                } else if (location.pathname.includes('/billing/invoice')) {
                    setActiveIcon('Billing');
                } else if (location.pathname.includes('/Feedback')) {
                    setActiveIcon('Feedback');
                } else if (location.pathname.includes('/ContactUs')) {
                    setActiveIcon('ContactUs');
                } else if (location.pathname.includes('/Help')) {
                    setActiveIcon('Help');
                }
            });
        }
    }, [location.pathname, moduleData]);

    const handleImageMouseEnter = (icon) => {
        setActiveIcon(icon);
        if (!isNavActive.isActive || hoveredIcon === null) {
            setHoveredIcon(icon);
        }
        setIsTooltipVisible(true);
    };

    const handleImageMouseLeave = () => {
        if (!isNavActive.isActive) {
            setHoveredIcon(null);
        } else {
            setActiveIcon(null); // Reset to the default icon when leaving only if navigation is not active
        }
        setIsTooltipVisible(false);
    };

    function handleActiveNavBar(value) {

        if (value === isNavActive.name) {
            setIsNavActive({
                isActive: !isNavActive.isActive,
                name: value
            });
        }
        else {
            setIsNavActive({
                isActive: true,
                name: value
            });
        }
    }

    function handleMenuClick(menu) {
        setSelectedMenu(menu);
        if (privacyPopupState) {
            setIsDialogOpen(true);
            return;
        } else {
            setSelectedMenu(menu);
        }
    };

    const handleIconClick = (icon) => {
        setActiveIcon(icon);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setIsNavActive({
            isActive: false,
            name: ""
        });
    };

    const handleConfirmNavigation = () => {
        // if (cancelUpload) {
        //     cancelUpload("Request canceled by the user.");
        // }
        dispatch(setIsPdfBeingUploadedState(false));
        dispatch(setIsPdfUploadedState(false));
        if (Object.keys(chatHistoryState).length === 0) {
            dispatch(setIsPdfUploadedOneTimeState(false));
        } else {
            dispatch(setIsPdfUploadedOneTimeState(true));
        }
        dispatch(clearState());
        dispatch(setPrivacyPopup(false));
        navigateTo(selectedMenu);
        handleDialogClose();
    };

    const navigateTo = (path) => {
        navigate(path);
    };

    return (
        <div className={`${theme}hip_navigation_main_container`}>
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogContent>
                    <DialogContentText>
                        Leaving this screen will result in the loss of your interactive data.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmNavigation} color="primary">
                        OK
                    </Button>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <div className={`${theme}hip_navigation_container`}>
                {moduleData && moduleData.map(item => (
                    <React.Fragment key={item.displayName} >
                        {item.displayName === "Operations" && (

                            <div className='hip_navigation_item'
                                onMouseEnter={() => handleImageMouseEnter('HIPOperations')}
                                onMouseLeave={handleImageMouseLeave}
                                onClick={() => handleActiveNavBar('HiPOperations')}
                            >
                                <Link className="tooltip">
                                    <img src={activeIcon === 'HIPOperations' || hoveredIcon === 'HIPOperations' ? HIPOperationsFill : HIPOperations}
                                        alt='HIP Operations'
                                    />
                                    {isTooltipVisible && <Typography variant="h6" className="tooltiptext">HIP One Operations</Typography>}
                                    {(isNavActive.isActive && isNavActive.name === 'HiPOperations') && <MenuNavBar
                                        handleActiveNavBar={() => handleActiveNavBar('HiPOperations')}
                                        privacyPopupState={privacyPopupState}
                                        name='HiPOperations'
                                    />}
                                </Link>
                            </div>
                        )}
                    </React.Fragment>
                ))}
                {moduleData && moduleData.map(item => (
                    <React.Fragment key={item.displayName} >
                        {item.displayName === "Clinical" && (
                            <div className='hip_navigation_item'
                                onMouseEnter={() => handleImageMouseEnter('HIPClinical')}
                                onMouseLeave={handleImageMouseLeave}
                                onClick={() => handleActiveNavBar('HiPClinical')}
                            >
                                <Link className="tooltip">
                                    <img src={activeIcon === 'HIPClinical' || hoveredIcon === 'HIPClinical' ? HIPClinicalFill : HIPClinical}
                                        alt='HIP Clinical'
                                    />
                                    {isTooltipVisible && <Typography variant="h6" className="tooltiptext">HIP One Clinical</Typography>}
                                    {(isNavActive.isActive && isNavActive.name === 'HiPClinical') && <MenuNavBar
                                        handleActiveNavBar={() => handleActiveNavBar('HiPClinical')}
                                        privacyPopupState={privacyPopupState}
                                        name='HiPClinical'
                                    />}
                                </Link>
                            </div>
                        )}
                    </React.Fragment>
                ))}
                {moduleData && moduleData.map(item => (
                    <React.Fragment key={item.displayName} >
                        {item.displayName === "Connect" && (

                            <div className='hip_navigation_item'
                                onMouseEnter={() => handleImageMouseEnter('HIPConnect')}
                                onMouseLeave={handleImageMouseLeave}
                                onClick={() => handleActiveNavBar('HiPConnect')}
                            >
                                <Link className="tooltip">
                                    <img src={activeIcon === 'HIPConnect' || hoveredIcon === 'HIPConnect' ? HIPConnectFill : HIPConnect}
                                        alt='HIP Connect'
                                    />
                                    {isTooltipVisible && <Typography variant="h6" className="tooltiptext">HIP One Connect</Typography>}
                                    {(isNavActive.isActive && isNavActive.name === 'HiPConnect') && <MenuNavBar
                                        handleActiveNavBar={() => handleActiveNavBar('HiPConnect')}
                                        privacyPopupState={privacyPopupState}
                                        name='HiPConnect'
                                    />}
                                </Link>
                            </div>
                        )}
                    </React.Fragment>
                ))}
                {false && <div className='hip_navigation_item'
                    onClick={() => handleMenuClick('/hip-finance')}
                > //US32695 - interim change - To be reverted later
                    <Link to={privacyPopupState ? null : '/hip-finance'} className="tooltip">
                        <img src={HIPFinance}
                            alt='HIP Finance'
                            onMouseEnter={handleImageMouseEnter}
                            onMouseLeave={handleImageMouseLeave}
                        />
                        {isTooltipVisible && <Typography variant="h6" className="tooltiptext">HIP One Finance</Typography>}
                    </Link>
                </div>}
            </div>
            <div className={`${theme}hip_navigation_down_container`}>

                {(isSuperAdmin || isAdmin) && (
                    <div className='hip_navigation_item'>
                        <Link className="tooltip"
                            onMouseEnter={() => handleImageMouseEnter('Settings')}
                            onMouseLeave={handleImageMouseLeave}
                            onClick={() => handleActiveNavBar('Settings')}
                        >
                            <img src={activeIcon === 'Settings' || hoveredIcon === 'Settings' ? SettingsFill : Settings}
                                alt='Settings'
                            />
                            {isTooltipVisible && <Typography variant="h6" className="tooltiptext">Settings</Typography>}
                            {(isNavActive.isActive && isNavActive.name === 'Settings') && <MenuNavBar
                                handleActiveNavBar={() => handleActiveNavBar('Settings')}
                                privacyPopupState={privacyPopupState}
                                name='Settings'
                            />}
                        </Link>
                    </div>
                )}

                {(isSuperAdmin || isAdmin) && (
                    <div className='hip_navigation_item'
                        onMouseEnter={() => handleImageMouseEnter('Billing')}
                        onMouseLeave={handleImageMouseLeave}
                        onClick={() => handleActiveNavBar('Billing')}>
                        <Link className="tooltip">
                            <img src={activeIcon === 'Billing' || hoveredIcon === 'Billing' ? BillingFill : Billing}
                                alt='Billing'
                            />
                            {isTooltipVisible && <Typography variant="h6" className="tooltiptext">Billing</Typography>}
                            {(isNavActive.isActive && isNavActive.name === 'Billing') && <MenuNavBar
                                handleActiveNavBar={() => handleActiveNavBar('Billing')}
                                privacyPopupState={privacyPopupState}
                                name='Billing'
                            />}
                        </Link>
                    </div>
                )}

                <div className='hip_navigation_item' onClick={() => {
                    handleMenuClick('/feedback')
                    handleIconClick('Feedback')
                }}
                    onMouseEnter={() => handleImageMouseEnter('Feedback')}
                    onMouseLeave={handleImageMouseLeave}>
                    <Link to={privacyPopupState ? null : '/feedback'} className="tooltip">
                        <img src={activeIcon === 'Feedback' || hoveredIcon === 'Feedback' ? FeedbackFill : Feedback}
                            alt='Feedback'
                        />
                        {isTooltipVisible && <Typography variant="h6" className="tooltiptext">Feedback</Typography>}
                    </Link>
                </div>
                <div className='hip_navigation_item'
                    onClick={() => {
                        handleMenuClick('/ContactUs')
                        handleIconClick('ContactUs')
                    }}
                    onMouseEnter={() => handleImageMouseEnter('ContactUs')}
                    onMouseLeave={handleImageMouseLeave}
                >
                    <Link to={privacyPopupState ? null : '/ContactUs'} className="tooltip">
                        <img src={activeIcon === 'ContactUs' || hoveredIcon === 'ContactUs' ? ContactFill : ContactUs}
                            alt='ContactUs'
                        />
                        {isTooltipVisible && <Typography variant="h6" className="tooltiptext">Contact Us</Typography>}
                    </Link>
                </div>
                <div className='hip_navigation_item'
                    onClick={() => {
                        handleMenuClick('/Help')
                        handleIconClick('Help')
                    }}
                    onMouseEnter={() => handleImageMouseEnter('Help')}
                    onMouseLeave={handleImageMouseLeave}
                >
                    <Link to={privacyPopupState ? null : '/Help'} className="tooltip">
                        <img src={activeIcon === 'Help' || hoveredIcon === 'Help' ? HelpFill : Help}
                            alt='Help'
                        />
                        {isTooltipVisible && <Typography variant="h6" className="tooltiptext">Help</Typography>}
                    </Link>
                </div>
            </div>
        </div >
    )
}

export default NavigationBar