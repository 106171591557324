import React, { useEffect, useState } from "react";
import "./ViewFile.css";
import Axios from "axios";
import { Close, ZoomIn, ZoomOut } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingScreen,
  setViewFile,
} from "../../../Redux/features/AlertsSlice";
import { getOriginalUrlList } from "../../../Redux/features/DocumentsSlice";
import GetCookie from "../../../HIPCookies/getCookie";
import axios from "axios";

function ViewFile({ currentId, currentFileType, fileName }) {
  const dispatch = useDispatch();
  const imageUrlList = useSelector(getOriginalUrlList);
  const [fileImages, setFileImages] = useState([]);
  const [offSet, setOffset] = useState(100);
  const urlList = useSelector((state) => state.documents.billUrlList)
  const pdfName = useSelector((state) => state.documents.billname)
  useEffect(() => {
    dispatch(
      setLoadingScreen({
        isActive: true,
      })
    );
    const currentPath = window.location.pathname;
    const specificPath = "/hip-operations/claim-process";
    let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;
    if (currentPath === specificPath) {
      axios.post(
        `${process.env.REACT_APP_CONNECT}/med-review-process/get_pdf_images`,
        {
          pdf_filename: fileName,
        },
        {
          headers: {
            userId: userId,
          },
        }
      )
        .then((response) => {
          // Handle the API response here
          if (response.status === 200) {

            const images = response.data.data;

            const sortedImages = images.sort((a, b) => a.page_number - b.page_number);

            const blobUrls = sortedImages.map((images) => images.blob_url)
            setFileImages(blobUrls);

          } else {
            console.error("Error fetching PDF images:", response.data.error);
          }
        })
      dispatch(
        setLoadingScreen({
          isActive: false,
        })
      );
    } else if (currentPath === "/hip-operations/medical-bill-worksheet") {
      let userId = (JSON.parse(GetCookie("userInfo")) || {}).userId;
      let url = `${process.env.REACT_APP_CONNECT}/med-bill-worksheet/get_images`

      Axios.post(
        url,
        {
          pdf_name: pdfName,
        },
        {
          headers: {
            userId: userId,
            // Other headers if needed
          },
        }
      )
        .then((response) => {
          const data = response.data;
          const urlList = data.images.map((item) => item.image_blob_url);
          setFileImages(urlList);
          dispatch(
            setLoadingScreen({
              isActive: false,
            })
          );
        })
        .catch((e) => {
          dispatch(
            setLoadingScreen({
              isActive: false,
            })
          );
        });

    }
    else {
      // Fetch data if imageUrlList is empty
      Axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/ocr/man_rev_sc?file_id=${currentId}`
      ).then((result) => {
        if (result.status) {
          let images = [];
          if (currentFileType === "mr" || currentFileType === "ph") {
            result.data.response.data_std.key_value_pairs.forEach((image) => {
              images.push(image.img_url);
            });
            setFileImages(images);
          } else {
            result.data.response.data_std.tables.tb_std_array.forEach((e) => {
              images.push(e.img_url);
            });
            setFileImages(images);
          }
          dispatch(
            setLoadingScreen({
              isActive: false,
            })
          );
        }
      });
    }
  }, [currentFileType, currentId, dispatch, imageUrlList]);

  const handleClose = () => {
    dispatch(
      setViewFile({
        isActive: false,
      })
    );
  };

  const handleZoomIn = () => {
    if (offSet < 500) {
      setOffset(offSet + 50);
    }
  };

  const handleZoomOut = () => {
    if (offSet > 100) {
      setOffset(offSet - 50);
    }
  };

  return (
    <div className="view_file_container">
      <div className="view_file_container_center">
        <div className="view_file_header">
          <span>
            <b style={{ marginRight: "10px" }}>Filename </b>
            {fileName}
          </span>
          <Close onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
        <div className="view_file_content">
          <div className="view_file_span">
            <div className="view_file_zoom_option">
              <div className="view_file_zoom">
                <ZoomIn
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={handleZoomIn}
                />
                <div className="view_fie_zoom_divider"></div>
                <ZoomOut
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={handleZoomOut}
                />
              </div>
            </div>
            <div className="view_file_document">
              {fileImages.length === 0 ? (
                <p>No images to display.</p>
              ) : (
                <>
                  {fileImages.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        alt={`Image ${index}`}
                        style={{ width: `${offSet}%` }}
                      />
                      <hr />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewFile;
