import React from "react";
import ArrowDown from "../../../assets/Remove_light.svg";
import ArrowRight from "../../../assets/Add_light.svg";
import "./MBWCollapsibleSection.css";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

import MBWShowSectionField from "../MBWShowSectionField/MBWShowSectionField";
const colors = (percentage) => { 
  percentage = percentage || 0;

  if (percentage >= 80) {
    return "high";
  } else if (percentage >= 60) {
    return "mid";
  } else if (percentage >= 0) {
    return "low";
  } else {
    return "NA";
  }
};


function MBWCollapsibleSection({
  title,
  isActive,
  isLoading,
  onToggle,
  sectionData,
  sectionTitle,
  changeSectiondata
}) {
  const extractedInfo = sectionData[title];
 
  return (
    <div className="collapsible_section">
      <div
        className={isActive ? "section_header active" : "section_header"}
        onClick={onToggle}
      >
        <span>{title}</span>
        <div>
          {isActive ? (
            <img src={ArrowDown} alt="arrow-down" />
          ) : (
            <img src={ArrowRight} alt="arrow-right" />
          )}
        </div>
      </div>

      {isActive && (
        <div className="section_content">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            Object.entries(extractedInfo).map(([key, value], index) => (
              
              <MBWShowSectionField
                key={index}
                name={key}
                boundingBox={value.length != 1 ? [...value.slice(1,5)]:null}
                pageNumber={value[6]||1}
                sectionTitle={title}
                changeSectiondata={changeSectiondata}
                // Using value[0] for arrays to get the value at the 0th index
                value={value[0] }
                color={colors(value[5] || 0)}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default MBWCollapsibleSection;
