import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import emailIcon from "../../../assets/Message_light.svg";
import backIcon from "../../../assets/back-arrow-icon.svg";
import darkEmailIcon from "../../../assets/darkEmailIcon.svg";
import "./ContactUs.css";
import axios from "axios";
import {
  Container,
  Grid,
  Stack,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useTheme,
  createTheme,
} from "@mui/material";
import { Box } from "@mui/material";
import NotificationDialog from "./NotificationDialog";

const ContactUs = () => {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.theme.darkMode);
  const [notification, setNotification] = useState("");
  const [isNotificationOpen, setNotificationOpen] = useState(false);

  const goBack = () => {
    navigate("/");
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    comment: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "contact") {
      const numericValue = value.replace(/\D/g, "");
      setFormData((prevState) => ({
        ...prevState,
        [name]: numericValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const clearAllFields = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      comment: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!formData.email.match(emailPattern)) {
      alert("Please enter a valid email address.");
      return;
    }

    const phonePattern = /^\d{10}$/;
    if (formData.contact && !formData.contact.match(phonePattern)) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }

    const postData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      contactNumber: formData.contact || "",
      feedback: formData.comment || "",
    };

    const baseUrl = `${process.env.REACT_APP_CONNECT}/conv-web`;
    setNotification(
      `We appreciate you reaching out to us. 
      Our team will be in touch with you shortly.`
    );
    setNotificationOpen(true);
    axios
      .post(`${baseUrl}/send_email`, postData)
      .then(() => {
        clearAllFields();
      })
      .catch((error) => {});
  };

  const closeNotification = () => {
    setNotificationOpen(false);
  };

  return (
    <Stack direction="row" sx={{ mt: "60px" }} className="stack-for-mobile">
      <Grid container>
        <Grid item xs={1} className="hip_one-back-button" onClick={goBack}>
          <Stack direction="row">
            <img
              src={backIcon}
              alt="back-arrow"
              className="hip_one-back-button-logo"
            />
            <Box className="hip_one-back-button-text">Back</Box>
          </Stack>
        </Grid>
        <Container>
          <Grid item xs={11} spacing={0.5}>
            <Box
              className={`hip_one-contact-container ${
                darkMode ? "dark-mode" : ""
              }`}
            >
              <Box className="hip_one-contact-sidebar">
                <h5 className="hip_one-contact-title1">Contact Us</h5>
                <p className="hip_one-contact-sidebar-text">
                  Have anything to discuss? Send us an email
                </p>

                <a
                  href="mailto:Support@Hip.one"
                  className="hip_one-contact-email"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={darkMode ? darkEmailIcon : emailIcon}
                    alt="Email Icon"
                    className="hip_one-email-icon"
                  />
                  Support@Hip.one
                </a>
              </Box>

              <Box className="hip_one-contact-content">
                <h5 className="hip_one-contact-title">Quick Contact</h5>

                <form className="hip_one-contact-form" onSubmit={handleSubmit}>
                  <Box className="hip_one-contact-horizontal-row">
                    <Box className="hip_one-contact-vertical-field">
                      <label
                        className="hip_one-contact-label"
                        htmlFor="firstName"
                      >
                        First Name*
                      </label>

                      <input
                        id="firstName"
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="hip_one-contact-input"
                        required
                      />
                    </Box>

                    <Box className="hip_one-contact-vertical-field">
                      <label
                        className="hip_one-contact-label"
                        htmlFor="lastName"
                      >
                        Last Name*
                      </label>

                      <input
                        id="lastName"
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="hip_one-contact-input"
                      />
                    </Box>
                  </Box>

                  <Box className="hip_one-contact-horizontal-row">
                    <Box className="hip_one-contact-vertical-field">
                      <label className="hip_one-contact-label" htmlFor="email">
                        Email*
                      </label>

                      <input
                        id="email"
                        type="email"
                        placeholder="Enter Email Id"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="hip_one-contact-input"
                        required
                      />
                    </Box>

                    <Box className="hip_one-contact-vertical-field">
                      <label
                        className="hip_one-contact-label"
                        htmlFor="contact"
                      >
                        Contact
                      </label>

                      <input
                        id="contact"
                        type="tel"
                        placeholder="Enter Phone Number"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        className="hip_one-contact-input"
                        pattern="[0-9]*"
                        inputMode="numeric"
                      />
                    </Box>
                  </Box>

                  <Box className="hip_one-contact-vertical-field">
                    <Box className="hip_one-contact-fixed-textarea">
                      <label
                        className="hip_one-contact-label"
                        htmlFor="comment"
                      >
                        Comment
                      </label>

                      <textarea
                        id="comment"
                        placeholder="Type your comments"
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        className="hip_one-contact-textarea"
                      ></textarea>
                    </Box>
                  </Box>

                  <Box className="hip_one-contact-buttons">
                    <button
                      type="button"
                      onClick={clearAllFields}
                      className="hip_one-contact-clear-btn"
                    >
                      Clear All
                    </button>

                    <button
                      type="submit"
                      className="hip_one-contact-submit-btn"
                    >
                      Submit
                    </button>
                  </Box>
                </form>
                <div id="hubspot-form-container"></div>
              </Box>
            </Box>
          </Grid>
        </Container>
        <NotificationDialog
          isNotificationOpen={isNotificationOpen}
          closeNotification={closeNotification}
          notification={notification}
        />
      </Grid>
    </Stack>
  );
};

export default ContactUs;
