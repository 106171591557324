// DotLoadingAnimation.js
import React, { useState, useEffect, useRef } from 'react';
import "./DotLoading.css";
import { useSelector } from 'react-redux';

const DotLoadingAnimation = () => {
  const [activeDot, setActiveDot] = useState(1);
  const containerRef = useRef();
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    const interval = setInterval(() => {
      setActiveDot((prevDot) => (prevDot % 3) + 1);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  // Define custom CSS class names based on the darkMode condition
  const containerClassName = isDarkMode
    ? "custom-dark-bouncing-dots-container"
    : "custom-bouncing-dots-container";

  const dotClassName = isDarkMode
    ? "custom-dark-dot"
    : "custom-dot";

  const activeDotClassName = isDarkMode
    ? "custom-dark-activedot"
    : "custom-activedot";

  return (
    <div className={containerClassName} ref={containerRef}>
      <div className={`${dotClassName} ${activeDot === 1 ? activeDotClassName : ''}`}></div>
      <div className={`${dotClassName} ${activeDot === 2 ? activeDotClassName : ''}`}></div>
      <div className={`${dotClassName} ${activeDot === 3 ? activeDotClassName : ''}`}></div>
    </div>
  );
};

export default DotLoadingAnimation;
