import React, { useEffect, useState } from "react";
import "./BoundingBox.css";

function BoundingBox({ height, width, top, left, id, color }) {
  const [con_level, setConLevel] = useState("");

  useEffect(() => {
    if (color === "high") {
      setConLevel("0,122,86");
    } else if (color === "mid") {
      setConLevel("206,161,0");
    } else if (color === "low") {
      setConLevel("240,88,2");
    } else {
      setConLevel("130,0,0");
    }
  }, [color]);
  return (
    <div
      style={{
        height: height,
        width: width,
        top: top,
        left: left,
        background: `rgba(${con_level},0.4)`,
      }}
      id={id}
      className="bounding_box"
    ></div>
  );
}

export default BoundingBox;
