import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import "./TimelineChart.css";

export default function TimelineChart(props) {
  const options = {
    noDataPattern: {
      backgroundColor: "#e6e6e6",
      color: "#d9d9d9",
    },

    calendar: {
      underYearSpace: 10,
      yearLabel: {
        fontSize: 32,
        color: "#d9d9d9",
        bold: true,
        italic: true,
      },
      cellColor: {
        stroke: "#ffffff",
        strokeOpacity: 1,
        strokeWidth: 1,
        fill: "#3182bd",
      },
      monthOutlineColor: {
        stroke: "#000000",
        strokeOpacity: 0.8,
        strokeWidth: 2,
      },
      unusedMonthOutlineColor: {
        stroke: "#666666",
        strokeOpacity: 0.8,
        strokeWidth: 1,
      },
      underMonthSpace: 10,
      cellSize: 16,
    },
    colorAxis: { minValue: 1, maxValue: 5, colors: ["#ccccff", "#000066"] },
  };
  const perdata = [
    {
      type: "date",
      id: "Date",
    },
    {
      type: "number",
      id: "Won/Loss",
    },
    {
      type: "string",
      role: "tooltip",
      p: { html: true },
    },
  ];
  const [data, setData] = useState([perdata]);
  useEffect(() => {
    let dataArray = [];
    props.section.map((data) => {
      if (data[0] !== "") {
        dataArray.push([new Date(data[0]), data[1].length, data[1]]);
      }
    });
    setData((d) => [perdata, ...dataArray]);
  }, [props.section]);

  return (
    <Chart
      chartType="Calendar"
      width="100%"
      height="250px"
      data={data}
      options={options}
    />
  );
}
