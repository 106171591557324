import React from "react";
import DotLoadingAnimation from "../DotLoadingAnimation/DotLoadingAnimation";
import GenMessage from "./GeneratedMessage/GeneratedMessage";
import "./Messages.css";
import UserMessage from "./UserMessage/UserMessage";

export default function Messages(props) {
  const { messages, isMessageLoading } = props;
  return (
    <div className="hip_one_gen_messages">
      {messages.map((message, index) => {
        if (message.id === "user") {
          return <UserMessage key={index} message={message.text} />;
        } else if (message.id === "backend") {
          return (
            <GenMessage
              key={index}
              message={message.text}
              handleFeedback={props.handleFeedback}
            />
          );
        }
        return null;
      })}
      {isMessageLoading && <DotLoadingAnimation />}
    </div>
  );
}
