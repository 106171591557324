import "./HealthWatch.css";
import TimeLineSection from "../TimeLineSection"
import { useSelector } from "react-redux";

export default function HealthWatch() {
  const theme = useSelector((state) => state.messages.Theme)
  let data = JSON.parse(sessionStorage.getItem("timeline_data"));

  return (
    <div className={`${theme}healthwatch-container`}>
      <table className={`${theme}healthwatch-table`}>
        <thead>
          <tr>
            <th colSpan={2}>
              <h2>Health Watch</h2>
              <hr />
            </th>
          </tr>
        </thead>
        {(data?.Timeline || data?.timeline || []).length === 0 ?
          <tr>
            <td style={{ textAlign: "center", textJustify: "inter-word" }}>
              Not enough data to show healthwatch.
            </td>
          </tr> :
          <div>
            {(data?.Timeline || data?.timeline).map((section, index) => (
              <TimeLineSection key={index} sectionData={section} />
            ))}
          </div>}
      </table>
    </div>
  );
}
