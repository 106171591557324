import React, { useRef, useEffect } from "react";
import "./UrlBotMessage.css";
import { useSelector } from "react-redux";
import HipOneChatbotIcon from "../../../assets/Icons/HpOneChatbotIcon.svg"

export default function UrlBotMessage(props) {
  const theme = useSelector((state) => state.messages.Theme);
  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [props.message, props.typing]);

  return (
    <div className={`${theme}url_bot_message`} ref={ref}>
      <div className={`${theme}url_bot_messageInfo`}></div>

      <div className={`${theme}url_bot_messageContent`}>
        <img
          src={HipOneChatbotIcon}
          alt=""
          width={"24px"} height={"24px"}
          style={{ marginRight: "1.5vh" }}
        ></img>
        {props.typing ? (
          <div className={`typing-indicator ${props.typing ? "show" : ""}`} />
        ) : (
          <p style={{ whiteSpace: "pre-line" }} className={`${theme}url_bot_gen_message-p`}>{props.message}</p>
        )}
      </div>
    </div>
  );
}
