import React, { useState } from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./Header.css";
import { AppBar } from "@mui/material";
import { useSelector } from "react-redux";
import RemoveCookie from "../../HIPCookies/removeCookie";
import GetCookie from "../../HIPCookies/getCookie";
import darkHipLogo from "../../assets/Dark_HIP_Logo.svg";
import bellPinFill from "../../assets/NewIcons/Bell_pin_fill.svg";

function Header({ headerLogoImage }) {
  const privacyPopupState = useSelector((state) => state.messages.privacyPopup);
  const [loginFunction, setLoginFunction] = useState(null);
  const userName = (JSON.parse(GetCookie("userInfo")) || {}).userName;
  const theme = useSelector((state) => state.messages.Theme);
  const profileImage = GetCookie("userProfileImage");
  const roleData = JSON.parse(GetCookie("roleInfo"));
  // Check if "Super Administrator" is present using some()
  const isSuperAdmin =
    Array.isArray(roleData) &&
    roleData.some((role) => role.roleName === "Super Administrator");
  // Check if "Administrator" is present using some()
  const isAdmin =
    Array.isArray(roleData) &&
    roleData.some((role) => role.roleName === "Administrator");

  const handleClick = (event) => {
    setLoginFunction(event.currentTarget);
  };
  const handleNotification = () => {};
  const handleClose = () => {
    setLoginFunction(null);
  };
  const clearIndexedDB = () => {
    window.indexedDB.deleteDatabase("Database1");
  };
  const handleMenuItemClick = (menuItem) => {
    if (menuItem === "Settings") {
      window.open("/settings/user-management", "_self");
      handleClose();
    } else if (menuItem === "Profile") {
      window.open("/profile/edit-profile", "_self");
      handleClose();
    } else if (menuItem === "Help") {
      window.open("/Help", "_self");
      handleClose();
    } else if (menuItem === "Logout") {
      RemoveCookie();
      clearIndexedDB();
      localStorage.clear();

      window.open("/login", "_self");
      handleClose();
    }
  };

  return (
    <AppBar
      position="fixed"
      className={`${theme}HeaderBar ${theme}hip_Ops_header`}
    >
      <div className={`${theme}hip_ops_header_span_1`}>
        <div className={`${theme}hip_ops_header_gen_logo`}>
          <a href={privacyPopupState ? null : "/dashboard"}>
            {theme === "dark_" ? (
              <img src={darkHipLogo} alt="Logo" />
            ) : (
              <img src={headerLogoImage} alt="Logo" />
            )}
          </a>
        </div>
      </div>

      <div className={`${theme}hip_ops_header_span_2`}>
        <Box className={`${theme}hip_ops_header_span_box`}>
          <img src={bellPinFill} alt="notification" />
        </Box>
        <div className={`${theme}hip_ops_header_span_2`} onClick={handleClick}>
          <div className={`${theme}hip_ops_header_image_container`}>
            {/* Conditionally render the profile picture or user initials */}

            {profileImage && profileImage.length > 2 ? (
              <img
                className={`${theme}hip_ops_header_user_image`}
                src={profileImage}
                alt="User"
              />
            ) : profileImage && profileImage.length === 2 ? (
              <div className="header_initial_circle_content">
                <Typography className="header_intial_text">
                  {" "}
                  {profileImage}{" "}
                </Typography>
              </div>
            ) : null}
          </div>
          <div className={`${theme}hip_ops_header_username`}>
            <Typography variant="h4">
              {userName &&
                userName.substring(0, 1).toUpperCase() +
                  userName.substring(1).toLowerCase()}
            </Typography>
          </div>
          {Boolean(loginFunction) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
      </div>
      <Menu
        className={`${theme}header_menu_list`}
        id="menu"
        anchorEl={loginFunction}
        open={Boolean(loginFunction)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          className={`${theme}logout_content`}
          onClick={() => handleMenuItemClick("Profile")}
        >
          Profile
        </MenuItem>

        {(isSuperAdmin || isAdmin) && (
          <MenuItem
            className={`${theme}logout_content`}
            onClick={() => handleMenuItemClick("Settings")}
          >
            Settings
          </MenuItem>
        )}
        <MenuItem
          className={`${theme}logout_content`}
          onClick={() => handleMenuItemClick("Help")}
        >
          Help
        </MenuItem>
        <MenuItem
          className={`${theme}logout_content`}
          onClick={() => handleMenuItemClick("Logout")}
        >
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
}

export default Header;
