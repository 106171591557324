import React from "react";
import ReactGA from "react-ga";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import Billing from "./Billing/App";
import ComingSoon from "./Components/Blank/ComingSoon";
import Dashboard from "./Dashboard";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import GetCookie from "./HIPCookies/getCookie";
import HiPClinical from "./HiPClinical/App";
import HiPConnect from "./HiPConnect/App";
import HiPFinance from "./HiPFinance/App";
import HiPOne from "./HiPOne/App";
import ContactUs from "./HiPOne/Components/ContactUs/ContactUs";
import HiPOperational from "./HiPOperational/App";
import DatabaseProvider from "./IndexedDBUtility/DatabaseProvider";
import LayoutWithHeaderAndNavigation from "./Layout/LayoutWithHeaderAndNavigation";
import LayoutWithOnlyHeader from "./Layout/LayoutWithOnlyHeader";
import Login from "./Login";
import Profile from "./Profile/App";
import SetUpPassword from "./SetUpPassword/SetUpPassword";
import Settings from "./Settings/App";
//import { Feedback } from "@mui/icons-material";
import { Toast } from "./Components/Toast/Toast";
import Feedback from "./Feedback/Feedback";

function App() {
  const isAuth = (JSON.parse(GetCookie("userInfo")) || {}).isAuth;
  const TRACKING_ID = "G-3SB5MDZ2JX";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname);

  return (
    <div className="main_route">
      {/* <ScrollToTop /> */}
      <Routes>
        <Route path="/*" element={<h2>Error 404</h2>} />
        <Route path="/" element={<HiPOne />}>
          <Route path="/contact" element={<ContactUs />} />
        </Route>

        {/* isAuth false Middleware */}
        <Route element={!isAuth ? <Outlet /> : <Navigate to={"/"} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/setup-password" element={<SetUpPassword />} />
        </Route>

        {/* Protected Route - isAuth middleware */}
        <Route element={isAuth ? <Outlet /> : <Navigate to={"/login"} />}>
          {/* Only With Header */}
          <Route element={<LayoutWithOnlyHeader />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile/*" element={<Profile />} />
          </Route>
          <Route element={<LayoutWithHeaderAndNavigation />}>
            <Route
              path="/hip-operations/*"
              element={
                <DatabaseProvider>
                  <HiPOperational />
                </DatabaseProvider>
              }
            />
            <Route
              path="/hip-clinical/*"
              element={
                <DatabaseProvider>
                  <HiPClinical />
                </DatabaseProvider>
              }
            />
            <Route path="/hip-finance/*" element={<HiPFinance />} />
            <Route
              path="/hip-connect/*"
              element={
                <DatabaseProvider>
                  <HiPConnect />
                </DatabaseProvider>
              }
            />
            <Route
              path="/settings/*"
              element={
                <DatabaseProvider>
                  <Settings />
                </DatabaseProvider>
              }
            />
            <Route
              path="/billing/*"
              element={
                <DatabaseProvider>
                  <Billing />
                </DatabaseProvider>
              }
            />
            <Route path="/feedback/*" element={<Feedback />} />
            <Route path="/ContactUs/*" element={<ComingSoon />} />
            <Route path="/Help/*" element={<ComingSoon />} />
          </Route>
        </Route>
      </Routes>
      <Toast />
    </div>
  );
}

export default App;
