import React from "react";
import UserMessage from "../UserMessage/UserMessage";
import GenMessage from "../GeneratedMessage/GenMessage";
import "./Messages.css";
import PdfMessageLoading from "./PdfMessageLoding";

export default function Messages(props) {
  return (
    <div className="gen_messages">
      {props.messages.map((obj, index) => (
        obj.id === "user" ? (
          <UserMessage key={index} message={obj.text} time={obj.time} />
        ) : (
          <GenMessage key={index} message={obj.text} time={obj.time} />
        )
      ))}
      {props.isMeesageloading && (
        <PdfMessageLoading />
      )}
    </div>
  );
}
