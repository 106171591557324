import React from "react";
import ArrowDown from "../../../assets/Remove_light.svg";
import ArrowRight from "../../../assets/Add_light.svg";
import "./CollapsibleSection.css";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { getConfidenceScoreOfExtractedFields, getExtractedInfo } from "../../../Redux/features/DocumentsSlice";
import ShowSectionField from "../../../HiPOperational/Pages/TextBox/ShowSectionField";
const colors = ["high", "mid"];

function CollapsibleSection({ title, isActive, isLoading, onToggle }) {
  const extractedInfo = useSelector(getExtractedInfo);
  const confidenceScores = useSelector(getConfidenceScoreOfExtractedFields);

  const getColor = (text) => {
    const matchingScore = confidenceScores.find(score => score.text === text);
    if (matchingScore) {
      const confidence = matchingScore.confidence;
      if (confidence >= 80) {
        return "high"; // High confidence color
      } else if (confidence >= 60) {
        return "mid"; // Medium confidence color
      } else {
        return "low"; // Low confidence color
      }
    } else {
      return "low"; // Default to low confidence color if no matching score found
    }
  };

  return (
    <div className="medical_review_collapsible_section">
      <div
        className={isActive ? "medical_review_section_header active" : "medical_review_section_header"}
        onClick={onToggle}
      >
        <span>{title}</span>
        <div>
          {isActive ? (
            <img src={ArrowDown} alt="arrow-down" className="arrowIcon" />
          ) : (
            <img src={ArrowRight} alt="arrow-right" className="arrowIcon" />
          )}
        </div>
      </div>
      {isActive && (
        <div className="medical_review_section_content">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            Object.entries(extractedInfo).map(([key, value], index) => (
              <ShowSectionField
                key={index}
                name={key}
                value={value}
                color={getColor(value)} // Pass text value to getColor function
              />
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default CollapsibleSection;