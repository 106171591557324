import React, { useRef } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import leftArrow from '../../assets/Arrow_alt_left_alt.svg';
import leftArrowDisabled from '../../assets/Arrow_alt_left_Disable.svg';
import rightArrow from '../../assets/Arrow_alt_lright_alt.svg';
import leftIcon from '../../assets/PrevActive.svg';
import leftIconDisabled from '../../assets/Prev.svg';
import rightIcon from '../../assets/Next.svg';
import rightIconDisabled from '../../assets/Next_disabel.svg';
import './CustomPagination.css';

const commonStyles = {
  borderRadius: '0.5rem',
  border: '1px solid #E0E3E8',
  background: '#FFF',
};

const buttonStyles = {
  borderRadius: '.25rem',
  padding: '.37rem 1rem',
  gap: '.5rem',
  fontFamily: 'Inter',
  fontSize: '.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.125rem',
};

const CustomPagination = ({ currentPage, rowsPerPage, onChangePage, onChangeRowsPerPage, claims }) => {
  const totalPages = claims.length > 0 ? Math.ceil(claims.length / rowsPerPage) : 1;

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onChangePage(currentPage - 1);

    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onChangePage(currentPage + 1);

    }
  };

  const handleChange = (event) => {
    onChangeRowsPerPage(event.target.value);
    // Reset the current page when rowsPerPage changes
    onChangePage(1);
    // Add any other logic you want to perform on value change
  };
  return (
    <Box textAlign="center">
      <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              color: '#444',
              fontFamily: 'Inter',
              fontSize: '.875rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '1.125rem',
            }}
          >
            Rows per page
          </Typography>
          <FormControl sx={{ ml: '.62rem' }}>
            <InputLabel id="rows-per-page-label"></InputLabel>
            <Select
              labelId="rows-per-page-label"
              id="rows-per-page-select"
              value={rowsPerPage}
              onChange={handleChange}
              sx={{
                ...commonStyles,
                color: '#444',
                width: '4.375rem',
                height: '2.625rem',
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            disabled={currentPage === 1}
            variant="outlined"
            sx={{ ...buttonStyles, color: '#444444', textTransform: 'none' }}
            onClick={handlePreviousPage}
          >
            {currentPage === 1 ? (
              <>
                <img src={leftArrowDisabled} alt="Left Arrow" />
              </>
            ) : (
              <>
                <img src={leftArrow} alt="Left Arrow" />
              </>
            )}
            Previous Page
          </Button>
          <Button
            disabled={currentPage === totalPages}
            variant="contained"
            sx={{ ...buttonStyles, textTransform: 'none', ml: '.87rem', bgcolor: '#5A03EE', color: '#FFFFFF' }}
            onClick={handleNextPage}
          >
            Next Page <img src={rightArrow} alt="Right Arrow" />
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Stack direction={'row'} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>
              Page{' '}
              <input
                type="text"
                value={currentPage}
                onChange={(e) => onChangePage(e.target.value)}
                style={{
                  ...commonStyles,
                  width: '3rem',
                  height: '2.25rem',
                  outline: 'none',
                  paddingLeft: "1rem",
                  fontSize: "14px",
                  color: "#444",
                  fontWeight: "500",
                }}
              />
              &nbsp; of {totalPages}
            </Typography>
            <Box className='custom_pagination_button' sx={{ marginLeft: '24px', marginRight: '8px' }}>
              <Button disabled={currentPage === 1}>
                {currentPage === 1 ? (
                  <>
                    <img src={leftIconDisabled} alt="Left Icon" onClick={handlePreviousPage} />
                  </>
                ) : (
                  <>
                    <img src={leftIcon} alt="Left Icon" onClick={handlePreviousPage} />
                  </>
                )}
              </Button>
            </Box>
            <Box className='custom_pagination_button'>
              <Button disabled={currentPage === totalPages}>
                {totalPages === currentPage ? (
                  <>
                    <img src={rightIconDisabled} alt="Right Icon" onClick={handleNextPage} />
                  </>
                ) : (
                  <>
                    <img src={rightIcon} alt="Right Icon" onClick={handleNextPage} />
                  </>
                )}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default CustomPagination;