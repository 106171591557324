import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Back from '../../../assets/Icons/Back.svg';
import { TextField, Typography, Button } from '@mui/material';
import './EditProfile.css';
import GetCookie from '../../../HIPCookies/getCookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const EditUserProfile = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
  });
  const userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;
  const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;
  const profileImage = GetCookie('userProfileImage');
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    dob: false,
  });
  const [formData, setFormData] = useState({
    profilePicture: null,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, profilePicture: file });
  };
  const handlePictureUpload = () => {
    // Trigger the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const fileInputRef = React.useRef(null);
  const handleSave = () => {
    if (formData.profilePicture) {
      const formDataForRequest = new FormData();
      formDataForRequest.append('FileName', userId);
      formDataForRequest.append('File', formData.profilePicture);

      axios.post(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserImage`, formDataForRequest, {
        headers: {
          "Authorization": `Bearer ${jWTToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success('Profile picture uploaded successfully.', {
              position: toast.POSITION.TOP_CENTER,
            });
            setFields({
              dob: ''
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          console.error('Error uploading profile picture:', error);
          toast.error('Error uploading profile picture.', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Users/${userId}`
    axios.get(apiUrl,
      {
        headers: {
          "Authorization": `Bearer ${jWTToken}`
        }
      })
      .then((response) => {
        const userData = response.data[0];
        setFields({
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          dob: userData.dob || '',
          email: userData.email || '',
        })
      })
      .catch((error) => {
        console.error('Error fetching user details', error);
      })
  }, [])
  const handleCancel = () => {
    setFields({
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
    });
    setFormData({
      profilePicture: null
    });
    setErrors({
      firstName: false,
      lastName: false,
      email: false,
      dob: false
    });
  }

  return (
    <div className='profile_main_container'>
      <div className="profile_container" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
        <img src={Back} alt="Go Back" style={{ marginRight: '10px', width: '13%' }} />
        Back
      </div>
      <div className="user_profile_form">
        <div className='user_profile_form_heading'>
          Edit Profile
        </div>
        <div className='user_profile_upload_form_container'>
          <div className='user_profile_upload_img' >
            <div className='user_profile_image_content'>
              {(profileImage.length > 2 && !formData.profilePicture) ?
                <img
                  src={profileImage}
                  alt="Profile Preview"
                  style={{ width: '100%', height: '100%' }}
                />
                : (profileImage.length === 2 && !formData.profilePicture) ?
                  <div className='profile_initial_circle_content'>
                    <Typography className='profile_intial_text'> {profileImage} </Typography>
                  </div>
                  : (formData.profilePicture && (
                    <img
                      src={URL.createObjectURL(formData.profilePicture)}
                      alt="Profile Preview"
                      style={{ width: '100%', height: '100%' }}
                    />
                  ))
              }
            </div>
            <div>
              <Button variant="contained" color="primary" onClick={handlePictureUpload} className="upload_profile_button">
                Upload Image
              </Button>
              <>
                <input
                  id="file_upload"
                  type="file"
                  accept="image/*"
                  onChange={handlePictureChange}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
              </>
            </div>
          </div>
          <div className='user_profile_form_page'>
            <div className='user_profile_form_names_container'>
              <div className='user_profile_form_names_firstName' style={{ marginRight: '20px' }}>
                <Typography variant="subtitle1" className="profile_patient_search_sub_heading">FIRST NAME</Typography>
                <TextField
                  className="patient_search_sub_field"
                  type="text"
                  name="firstName"
                  placeholder="Edit First Name"
                  hinttext="Enter Name"
                  value={fields.firstName}
                  onChange={handleChange}
                  helperText={errors.firstName && "Please enter First Name"}
                  error={errors.firstName}
                />
              </div>
              <div className='user_profile_form_names_lastName'>
                <Typography variant="subtitle1" className="profile_patient_search_sub_heading">LAST NAME</Typography>
                <TextField
                  className="patient_search_sub_field"
                  type="text"
                  name="lastName"
                  placeholder="Edit Last Name"
                  hinttext="Enter Name"
                  value={fields.lastName}
                  onChange={handleChange}
                  helperText={errors.firstName && "Please enter Last Name"}
                  error={errors.lastName}
                />
              </div>
            </div>
            <div className="user_profile_form_user_details">
              <div className='user_profile_form_dob' style={{ marginBottom: '20px' }}>
                <Typography variant="subtitle1" className="profile_patient_search_sub_heading">DATE OF BIRTH</Typography>
                <div className='profile_date_picker_container'>
                  <LocalizationProvider dateAdapter={AdapterDayjs} id="profile_date_picker_container">
                    <DatePicker className="select_date_of_birth"
                      value={fields.dob}
                      onChange={handleChange}
                      name="dob"
                      openTo='day'
                      views={['year', 'month', 'day']}
                      format="MM/DD/YYYY"
                    />
                  </LocalizationProvider>
                </div>
                {errors.dob && (
                  <div className="error-message">
                    Please select Date of Birth
                  </div>
                )}
              </div>
              <div className='user_profile_form_email'>
                <Typography variant="subtitle1" className="profile_patient_search_sub_heading">EMAIL</Typography>
                <TextField
                  className="profile_patient_search_sub_field"
                  type="search"
                  inputProps={{ maxLength: 50 }}
                  name="email"
                  placeholder="Edit your Email ID"
                  hinttext="Enter Name"
                  value={fields.email}
                  onChange={handleChange}
                  helperText={errors.email && "Please enter Email"}
                  error={errors.email}
                  disabled={true}
                />
              </div>
            </div>
          </div>

        </div>
        <div className='user_profile_form_button_container'>
          <div className='user_profile_form_button_savechanges'>
            <Button onClick={handleSave} disabled={!formData.profilePicture} type="submit" variant="contained" color="primary" className='profile_save_form_button'>
              Save Changes
            </Button>
          </div>
          <div className='user_profile_form_button_cancel'>
            <Button onClick={handleCancel} type="submit" variant="outlined" className='upload_profile_button'>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserProfile;