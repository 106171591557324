import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import './ForgotPassword.css';
import HIPLogo from '../assets/HIPOneLogo.svg';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import HIPBackground from "../assets/NewIcons/Logo_BG.svg";
import { useDispatch } from "react-redux";
import { setToastAlert } from "../Redux/features/AlertsSlice";

function ForgotPassword() {

    const dispatch = useDispatch();
    const [fields, setFields] = useState({
        emailId: '',
    });

    const [errors, setErrors] = useState({
        emailId: false,
        password: false,
    });

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        // Cleanup button disabled status when component unmounts
        return () => {
            setIsButtonDisabled(false);
        };
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();
        setIsButtonDisabled(true);
        let formValid = true;
        const updatedErrors = {};
        Object.keys(fields).forEach((field) => {
            if (!fields[field]) {
                updatedErrors[field] = true;
                formValid = false;
            }
            setIsButtonDisabled(false);
        });

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(fields.emailId);

        if (!isValidEmail) {
            formValid = false;
            dispatch(setToastAlert({
                isactive: true,
                type: 'error',
                exp: 10000,
                message: "Please enter a valid email address."
            }))
            setIsButtonDisabled(false);
        }
        setErrors(updatedErrors);

        if (formValid) {
            axios.post(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Security`,
                {
                    email: fields.emailId
                },
                {
                    headers: {
                        baseURL: window.location.origin,
                        Checkemail: true,
                        isForgotPwd: true,
                    },
                }).then(response => {
                    if (response.status === 200 && response.data) {
                        if (response.data?.[0].isActive === true) {
                            dispatch(setToastAlert({
                                isactive: true,
                                type: 'success',
                                exp: 10000,
                                message: "An email has been sent to user registered email id."
                            }))
                        } else {
                            dispatch(setToastAlert({
                                isactive: true,
                                type: 'error',
                                exp: 10000,
                                message: "User is inactive."
                            }))
                            setIsButtonDisabled(false);
                        }
                    } else {
                        dispatch(setToastAlert({
                            isactive: true,
                            type: 'error',
                            exp: 10000,
                            message: "Please enter valid Email Address."
                        }))
                        setIsButtonDisabled(false);
                    }
                }).catch((error) => {
                    console.error("An error occured while fetching the user data: ", error);
                    setIsButtonDisabled(false);
                })
        }
    };

    return (
        <>
            <div className="app_container">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} className="forgot_password_grid" sx={{ backgroundImage: `url(${HIPBackground})` }} >
                            <Stack direction="column" className="forgot_password_grid_container" >
                                <Box>
                                    <Typography variant="jumbotron" className="forgot_password_grid_content" >
                                        What is Health <br /> Intelligence <br />Platform?
                                    </Typography>
                                    <Typography variant="h1" className="forgot_password_grid_content_text" >
                                        Efficiency through intelligence.
                                    </Typography>
                                    <Typography variant="jumbotron" className="forgot_password_grid_content" >
                                        Why Choose Health Intelligence Platform?
                                    </Typography>
                                    <Typography variant="h1" className="forgot_password_grid_content_text" >
                                        Health Intelligence Platform helps <br /> business and
                                        clinical operations be <br /> more efficient and creates value
                                        <br /> through intelligence.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ height: "100vh", position: 'relative' }}>
                            <Box className="forgot_password_grid_box" elevation={0} >
                                <div className="forgot_password_content">
                                    <form onSubmit={handleForgotPassword} className="forgot_password_form">
                                        <div className="forgot_password_gen_logo">
                                            <img src={HIPLogo} alt="HIP One" />
                                        </div>
                                        <Typography variant="h3" className="forgot_password_heading"> Forgot Password </Typography>
                                        <Typography variant="h5" className="forgot_password_heading_subtext" >
                                            Enter your Email
                                        </Typography>
                                        <Typography variant="h5" className="forgot_password_text_heading" >
                                            Email
                                        </Typography>
                                        <TextField
                                            sx={{ marginBottom: "1.25rem" }}
                                            className="forgot_password_text_field"
                                            inputProps={{ maxLength: 100 }}
                                            name="emailId"
                                            value={fields.emailId}
                                            onChange={handleChange}
                                            error={errors.emailId}
                                            helperText={errors.emailId && "Please enter valid Email"}
                                            type="text"
                                            placeholder="Enter Email"
                                        />
                                        <div>
                                            <Button className="forgot_password_button" type="submit" variant="contained" color="primary" disabled={isButtonDisabled} >
                                                Send
                                            </Button>
                                        </div>
                                        <div className="forgot_password_login_content" >
                                            <Typography variant="h5" style={{ paddingTop: '2px', paddingRight: '5px'}}>
                                                Do you want login?
                                            </Typography>
                                            <a className="forgot_password_redirect" href="/login" >
                                                Login
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </Box>
                            <div className="forgot_password_privacy_containers">
                                <div className="forgot_password_privacy_details">
                                    <a
                                        href="https://www.genzeon.com/privacy-policy/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    <label>Copyright © Genzeon | All Rights Reserved</label>
                                </div>
                            </div>
                        </Grid>
                    </Grid >
                </Box>
            </div >
        </>
    );
};

export default ForgotPassword