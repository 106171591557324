import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import HIPClinical from '../../../assets/HIP Operations.svg';
import SendOutlineIcon from '../../../assets/sendOuline.svg';
import { openDatabase } from '../../../IndexedDBUtility/IndexedDBUtility';
import "../../../Theme/Dashboard.css";

function Dashboard() {
    const [permissionData, setPermissionData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = await openDatabase();

                const transaction = db.transaction(['permissionData'], 'readonly');
                const objectStore = transaction.objectStore('permissionData');

                const getRequest = objectStore.getAll();

                getRequest.onsuccess = (event) => {
                    const storedData = event.target.result;

                    const data = storedData[0].value;
                    const clinicalModule = data.find((module) => module.moduleName === 'Operations')
                    const displayNamesList = clinicalModule.permissionsDataArray.map((permission) => permission.displayName);
                    // console.log("displayNamesList", displayNamesList)
                    setPermissionData(displayNamesList)
                };

                getRequest.onerror = (error) => {
                    console.error('Error getting data from IndexedDB:', error.target.error);
                };


            } catch (error) {
                console.error(`Error opening IndexedDB: ${error}`);
            }

        };

        fetchData();
    }, []);
    return (
        <>
            <Grid container className="landing_screen_menu_container" >
                <Grid item xs={12}>
                    <Stack>
                        <Box className="landing_screen_scale">
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <img className="landing_screen_scale_icon" src={HIPClinical} alt="hipClinical" />
                            </Box>
                            <Box className="landing_screen_heading" >
                                <Typography variant="h3" >Hip One Operations</Typography>
                            </Box>
                            <Box className="landing_screen_heading" sx={{ margin: 4 }} >
                                <Typography variant="h4">Business Operations Innovated</Typography>
                            </Box>

                            <Box className="landing_screen_heading" sx={{ gap: 5, p: 2 }}>
                                {permissionData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {item === "Medical Bill Review" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" href="/hip-operations/medical-bills" >
                                                <p className="landing_screen_button_text"> Medical Bill Review </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "Medical Record Request" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" href="/hip-operations/medical-records">
                                                <p className="landing_screen_button_text"> Medical Record Request </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "Medical Bill Worksheet" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" href="/hip-operations/medical-bill-worksheet">
                                                <p className="landing_screen_button_text"> Medical Bill Worksheet </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "Document History" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" href="/hip-operations/document-history">
                                                <p className="landing_screen_button_text"> Document History </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "Medicare Analytics" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" href="/hip-operations/medicare-dashboard">
                                                <p className="landing_screen_button_text"> Medicare Analytics </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                    </React.Fragment>

                                ))
                                }

                            </Box>

                            <Box className="landing_screen_menu_sub_content" >
                                <Typography variant="h4" className="landing_screen_content_text" >
                                HiP Operational focuses on optimizing business operations through a lens of extracting the right data, <br />
                                leveraging the right context and building human in loop capabilities to help customers focus on higher <br />
                                cognitive decision making rather than manual tasks.
                                </Typography>
                            </Box>
                            <Box className="landing_screen_menu_sub_content" >
                                <Typography variant="h4" className="landing_screen_content_text" >
                                The product leverages OCR, NLP, AI/ML capabilities, workflow integration and automation to drive efficiency <br /> 
                                in every business process enabling productivity gains leveraging a proven framework for value intelligence.
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default Dashboard
