import React, { useEffect, useState } from 'react';
import "./NavigationBar.css";
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { openDatabase } from '../../IndexedDBUtility/IndexedDBUtility';

const MenuNavBar = ({ handleActiveNavBar, privacyPopupState, name }) => {
    const [permissionData, setPermissionData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = await openDatabase();

                const transaction = db.transaction(['permissionData'], 'readonly');
                const objectStore = transaction.objectStore('permissionData');

                const getRequest = objectStore.getAll();

                getRequest.onsuccess = (event) => {
                    const storedData = event.target.result;

                    const data = storedData[0].value;
                    const clinicalModule = data.find((module) => module.moduleName === 'Clinical')
                    const operationsModule = data.find((module) => module.moduleName === 'Operations')
                    const connectModule = data.find((module) => module.moduleName === 'Connect')
                    const displayNamesList = [...clinicalModule.permissionsDataArray.map((permission) => permission.displayName), ...operationsModule.permissionsDataArray.map((permission) => permission.displayName), ...connectModule.permissionsDataArray.map((permission) => permission.displayName)];
                    setPermissionData(displayNamesList)
                };

                getRequest.onerror = (error) => {
                    console.error('Error getting data from IndexedDB:', error.target.error);
                };


            } catch (error) {
                console.error(`Error opening IndexedDB: ${error}`);
            }

        };

        fetchData();
    }, []);
    return (
        <div className='navigation_bar_menu_container'>
            {
                name === 'HiPOperations' ?
                    <ul onClick={handleActiveNavBar}>
                        <div>
                            <Typography variant='h5'>Business Process</Typography>
                            {permissionData.map((item) => (
                                <div key={item}>
                                    {item === "Medical Bill Review" && (
                                        <li>
                                            <Link to={privacyPopupState ? null : '/hip-operations/medical-bills'}>
                                                {item}
                                            </Link>
                                        </li>
                                    )}
                                    {item === "Medical Record Request" && (
                                        <li>
                                            <Link to={privacyPopupState ? null : '/hip-operations/medical-records'}>
                                                {item}
                                            </Link>
                                        </li>
                                    )}
                                    {item === "Medical Bill Worksheet" && (
                                        <li>
                                            <Link to={privacyPopupState ? null : '/hip-operations/medical-bill-worksheet'}>
                                                {item}
                                            </Link>
                                        </li>
                                    )}
                                    {item === "Medicare Analytics" && (
                                        <li>
                                            <Link to={privacyPopupState ? null : '/hip-operations/medicare-dashboard'}>
                                                {item}
                                            </Link>
                                        </li>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div>
                            <Typography variant='h5'>Dashboard</Typography>
                            {permissionData.map((item) => (
                                <div key={item}>
                                    {item === "Document History" && (
                                        <li>
                                            <Link to={privacyPopupState ? null : '/hip-operations/document-history'}>
                                                {item}
                                            </Link>
                                        </li>
                                    )}
                                </div>
                            ))}
                        </div>
                    </ul>
                    :
                    name === 'HiPClinical' ?
                        <ul className="navigation_bar_menu_container_link" onClick={handleActiveNavBar}>
                            {permissionData.includes("Medical Review") && (
                                <>
                                    <Typography variant='h5'>Medical Review </Typography>
                                    <li key="medical_review_list">
                                        <Link to={privacyPopupState ? null : '/hip-clinical/medical-review'}>
                                            Medical Review
                                        </Link>
                                    </li>
                                    <li key="configuration">
                                        <Link>
                                            Configuration
                                        </Link>
                                    </li>
                                </>
                            )}
                            <div>
                                <Typography variant='h5'>Clinical Interoperability</Typography>
                                {permissionData.map((item, index) => {
                                    if (item === "Clinical Summarization") {
                                        return (
                                            <li key="interoperability_clinical_summarization">
                                                <Link key="summarization" to={privacyPopupState ? null : '/hip-clinical/ccda-summarization'}>
                                                    {item}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}
                                {permissionData.map((item, index) => {
                                    if (item === "Patient Search") {
                                        return (
                                            <li key="interoperability_patient_search">
                                                <Link key="patient_search" to={privacyPopupState ? null : '/hip-clinical/single-patient-search'}>
                                                    {item}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}
                                {permissionData.map((item, index) => {
                                    if (item === "Operational Intelligence") {
                                        return (
                                            <li key="interoperability_operational_intelligence">
                                                <Link key="operational_intelligence" to={privacyPopupState ? null : '/hip-clinical/operational-intelligence'}>
                                                    {item}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </ul>
                        :
                        name === 'HiPConnect' ?
                            <ul onClick={handleActiveNavBar}>
                                <div>
                                    <Typography variant='h5'>Conversational Document</Typography>
                                    {permissionData.map((item) => (
                                        <div key={item}>
                                            {item === "Interactive Document" && (
                                                <li>
                                                    <Link to={privacyPopupState ? null : '/hip-connect/interactive-document'}>
                                                        Interactive Document
                                                    </Link>
                                                </li>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <Typography variant='h5'>Real Time Data Conversation</Typography>
                                    {permissionData.map((item) => (
                                        <div>
                                            {item === "URL" && (
                                                <li>
                                                    <Link to={privacyPopupState ? null : '/hip-connect/hip-connection-url'}>
                                                        URL
                                                    </Link>
                                                </li>
                                            )}
                                            {item === "Business Intelligence" && (
                                                <li>
                                                    <Link to={privacyPopupState ? null : '/hip-connect/hip-connection-database'}>
                                                        Business Intellignece
                                                    </Link>
                                                </li>
                                            )}
                                            {item === "Logsifters" && (
                                                <li>
                                                    <Link to={privacyPopupState ? null : '/hip-connect/hip-logsifters'}>
                                                        Logsifters
                                                    </Link>
                                                </li>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </ul>
                            :
                            name === 'Settings' ?
                                <ul onClick={handleActiveNavBar}>
                                    <Typography variant='h5'>Settings</Typography>
                                    <div>
                                        <li>
                                            <Link to={privacyPopupState ? null : '/settings/user-management'}>
                                                User Management
                                            </Link>
                                        </li>
                                    </div>
                                    <Typography variant='h5'>General Settings</Typography>
                                    <div>
                                        <li>
                                            <Link to={privacyPopupState ? null : '/settings/customize-organization'}>
                                                Customize Organization
                                            </Link>
                                        </li>
                                    </div>
                                </ul>
                                :
                                name === 'Billing' ?
                                    <ul onClick={handleActiveNavBar}>
                                        <Typography variant='h5'>Billing</Typography>
                                        <div>
                                            <li>
                                                <Link to={privacyPopupState ? null : '/billing/invoice'}>
                                                    Invoice
                                                </Link>
                                            </li>
                                        </div>
                                        <Typography variant='h5'>Transactions</Typography>
                                        <div>
                                            <li>
                                                <Link to={privacyPopupState ? null : '/billing/patient-data-request-summary'}>
                                                    Patient Data
                                                </Link>
                                            </li>
                                        </div>
                                    </ul>
                                    :
                                    <></>
            }

        </div>
    );
};

export default MenuNavBar;