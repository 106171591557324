import React, { useState, useRef, useEffect } from "react";
import { TextField, Typography, Button } from '@mui/material';
import "./CustomizeOrganization.css";
import axios from "axios";
import UploadImage from '../../../assets/UploadImage.png';
import { toast } from 'react-toastify';
import EditLogo from '../../../assets/Icons/EditLogo.svg';
import DeleteLogo from '../../../assets/Icons/DeleteLogo.svg';
import GetCookie from '../../../HIPCookies/getCookie';

function CustomizeOrganization(logoFile) {

    const [orgName, setOrgName] = useState("");
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const inputRef = useRef(null);
    const [preview, setPreview] = useState();
    const userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;

    const handleOrgNameChange = (event) => {
        setOrgName(event.target.value);
    };

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
    }, [selectedFile]);

    const handleFileChange = (event) => {
        setButtonDisabled(false);
        const file = event.target.files[0];

        if (!selectedFile) {
            if (!file) {
                setSelectedFile(null);
                toast.error('Please upload a valid image file', {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.svg'];
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

            if (!allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
                setSelectedFile(null);
                toast.error('Invalid file type. Please select a .jpg, .jpeg or .png file.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }

            if (file) {
                const minSizeInBytes = 4 * 1024;
                const maxSizeInBytes = 10 * 1024 * 1024;

                if (file.size >= minSizeInBytes && file.size <= maxSizeInBytes) {
                    setSelectedFile(file);
                } else {
                    setSelectedFile(null);
                    toast.error('File size should be between 4KB to 10MB.', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        } else {
            if (!file) {
                toast.error('Please upload a valid image file', {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.svg'];
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

            if (!allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
                toast.error('Invalid file type. Please select a .jpg, .jpeg or .png file.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }

            if (file) {
                const minSizeInBytes = 4 * 1024;
                const maxSizeInBytes = 10 * 1024 * 1024;

                if (file.size >= minSizeInBytes && file.size <= maxSizeInBytes) {
                    setSelectedFile(file);
                } else {
                    toast.error('File size should be between 4KB to 10MB.', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        }
    };

    const handleDelete = async () => {
        try {
            let response = await axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/delete_logo?user_id=${userId}`
            )
            if (response.status === 200) {
                toast.success('Logo image deleted successfully.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                handlePageReload();
            }
        } catch (error) {
            toast.error(`Image delete failed. ${error}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
    }

    const handleSoftDelete = async () => {
        setButtonDisabled(false);
        if (!selectedFile) {
            return;
        } else {
            setSelectedFile(null);
        }
    }

    const handleUpload = async () => {
        setButtonDisabled(true);
        if (!selectedFile) {
            return;
        }
        const formData = new FormData();
        formData.append("input_file", selectedFile);
        try {
            let response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/upload_logo?user_id=${userId}`,
                formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'UserId': 'userId',
                },
            })
            if (response.status === 200 && response.data.status) {
                toast.success('Logo image uploaded successfully.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                handlePageReload();
            } else {
                toast.error('Invalid logo file, please upload a valid file', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            toast.error('Image upload failed.', {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
    };

    const handlePageReload = () => {
        setSelectedFile(null)
        window.location.reload();
    }

    const onDragandDrop = (e) => {
        e.preventDefault();
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            const file = e.dataTransfer.files[i];
            const allowedExtensions = [".jpg", ".jpeg", ".png"];
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();

            if (!allowedExtensions.includes("." + fileExtension)) {
                toast.error("Invalid file type. Please select a .jpg, .jpeg or .png file.", {
                    position: toast.POSITION.TOP_CENTER
                });
                return;
            }

            const minSizeInBytes = 4 * 1024;
            const maxSizeInBytes = 10 * 1024 * 1024;

            if (file.size >= minSizeInBytes && file.size <= maxSizeInBytes) {
                const objectUrl = URL.createObjectURL(file);
                setPreview(objectUrl);
                setSelectedFile(file);
            } else {
                toast.error("File size should be between 4KB and 10MB.", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }
    }

    const handleDrag = (e) => {
        e.preventDefault();
    };

    return (
        <div className="customize_organization_main_container">
            <div className="customize_organization_btns">
                <div className="customize_organization_document_title">
                    <span>Customize Organization</span>
                </div>
                <div className="lbl_org">
                    <Typography variant="subtitle1" className="lbl_org_txt">ORGANIZATION </Typography>
                </div>
                <div className="input_org">
                    <TextField
                        className="input_org_txtbox"
                        type="search"
                        inputProps={{ maxLength: 100 }}
                        name="organization"
                        placeholder="Enter company name"
                        hinttext="Enter company name"
                        value={orgName}
                        onChange={handleOrgNameChange}
                    />
                </div>
                <div className="lbl_upload_logo">
                    <label className="lbl_upload">
                        Upload the logo to place in the main header
                    </label>
                    <Button type="submit" onClick={handleDelete} className='btn_delete' variant="contained" color="primary">
                        Set Default
                    </Button>
                </div>
                {!selectedFile ?
                    <div className="logo_upload_files" onDrop={onDragandDrop} onDragOver={handleDrag}>
                        <img src={UploadImage} alt='upload_image' />
                        <span>Drag & Drop File or  <label htmlFor="upload_docs">Browse file</label></span>
                        <p>Supported formats .jpg, .jpeg, .png</p>
                        <p>Maximum limit 10MB</p>
                        <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            style={{ display: "none" }}
                            name='input_file'
                            id='upload_docs'
                            ref={inputRef}
                            onChange={handleFileChange}
                        />
                    </div>
                    :
                    <div className="logo_files_after_upload">
                        <div className="logo_files_after_upload_content">
                            <div className="logo_files_after_upload_buttons">
                                <div>
                                    <label htmlFor="upload_docs">
                                        <img src={EditLogo} alt="Edit Logo" className='btn_edit' />
                                    </label>
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        style={{ display: "none" }}
                                        name='input_file'
                                        id='upload_docs'
                                        ref={inputRef}
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div>
                                    <img src={DeleteLogo} alt="Delete Logo" className='btn_edit' onClick={handleSoftDelete} />
                                </div>
                            </div>
                            <div className="logo_files_after_upload_image">
                                <img src={preview} alt="logo" />
                            </div>
                        </div>
                        <div className="logo_files_after_upload_apply">
                            <Button type="submit" onClick={handleUpload} variant="contained" color="primary"
                                className='btn_apply' disabled={isButtonDisabled}>
                                Apply
                            </Button>
                        </div>
                        <p>Genzeon logo will be default if no logo uploaded</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default CustomizeOrganization
