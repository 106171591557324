import Axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "../Components/ErrorPage/ErrorPage";
import { setDocumentsList } from "../Redux/features/DocumentsSlice";
import MBWSectionList from "./Pages/MedicalBillWorksheetPage/MBWSectionList/MBWSectionList";
import POPUP from "./Pages/Popups/POPUP";
import VerifyMedicalBills from "./Pages/VerifyMedicalBills/VerifyMedicalBills";
import RouteWithOutMenuItems from "./RouteWithOutMenuItems/RouteWithOutMenuItems";
import ClinicalDataExtraction from "./Screens/ClinicalData/ClinicalDataExtraction";
import DocumentTypes from "./Screens/DocumentTypes/DocumentTypes";
// import HomeScreen from "./Screens/HomeScreen/HomeScreen";
import OperatiponsDashboard from "./Screens/Dashboard";
import LayoutWithBreadCrumb from "../Theme/LayoutWithBreadCrumb";
import ManualReview from "./Screens/ManualReview/ManualReview";
import MedicalBillWorksheet from "./Screens/MedicalBillWorksheetScreen/MedicalBillWorksheet";
import MedicalBills from "./Screens/MedicalBills/MedicalBills";
import MedicalRecords from "./Screens/MedicalRecord/MedicalRecords";
import Reports from "./Screens/PowerBIReports/Reports";

import MedicareAnalyticsDashboard from "./Screens/MedicareAnalytics/Pages/MedicareAnalyticsDashboard";
import DetailsDashboard from "./Screens/MedicareAnalytics/Pages/DetailsDashboard";
import MeasureDetails from "./Screens/MedicareAnalytics/Pages/MeasureDetails";

function App() {
  const dispatch = useDispatch();
  const baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;

  useEffect(() => {
    Axios.get(`${baseURL}/list_of_Documents`).then((result) => {
      dispatch(setDocumentsList(result.data));
    });
  }, [baseURL, dispatch]);

  return (
    <>
      <Routes>
        <Route element={<LayoutWithBreadCrumb type="operations" />}>
          <Route path="/" element={<OperatiponsDashboard />} />
          <Route path="/medical-records" element={<MedicalRecords />} />
          <Route path="/medical-bills" element={<MedicalBills />} />
          <Route
            path="/clinical-data-extraction"
            element={<ClinicalDataExtraction />}
          />
          {/* <Route path="/claim-process" element={<ClaimProcess />} /> */}
          <Route
            path="/medical-bill-worksheet"
            element={<MedicalBillWorksheet />}
          />
          <Route path="/reports" element={<Reports />} />
          <Route path={"/document-history"} element={<DocumentTypes />} />
          <Route
            path="/mb/review-file/:file_id"
            element={<VerifyMedicalBills />}
          />
          <Route path="/ph/review-file/:file_id" element={<ManualReview />} />
          <Route path="/mr/review-file/:file_id" element={<ManualReview />} />
          {/* <Route path="/cp/review-file/:file_id" element={<SectionList />} /> */}
          <Route path="/mbr/review-file" element={<MBWSectionList />} />
          <Route
            path="/medicare-dashboard"
            element={<MedicareAnalyticsDashboard />}
          />
          <Route path="/contract-details" element={<DetailsDashboard />} />
          <Route path="/measure-details" element={<MeasureDetails />} />
          {/* <Route
            path="/medicare-dashboard"
            element={<MedicareAnalyticsDashboard />}
          />*/}
        </Route>

        <Route element={<RouteWithOutMenuItems />}>
          <Route path="/mb/review-file/:file_id" element={<ManualReview />} />
        </Route>
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
      <POPUP />
    </>
  );
}

export default App;
