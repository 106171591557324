import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatbotMessages: {},
  Theme: "",
  createdChatbot: "",
};

const urlMessageSlice = createSlice({
  name: "urlMessage",
  initialState,
  reducers: {
    addUrlMessage: (state, action) => {
      const { chatbotName, message } = action.payload;
      if (!state.chatbotMessages[chatbotName]) {
        state.chatbotMessages[chatbotName] = [];
      }
      state.chatbotMessages[chatbotName].push(message);
    },
    setTheme: (state, action) => {
      if (action.payload === true) {
        state.Theme = "dark_";
      } else if (action.payload === false) {
        state.Theme = "";
      }
    },
    setCreatedChatbot: (state, action) => {
      state.createdChatbot = action.payload;
    },
  
  },
});

export const { addUrlMessage, setTheme, setCreatedChatbot } =
  urlMessageSlice.actions;
export default urlMessageSlice.reducer;
