import React, { useRef, useEffect, useState } from "react";
import "../UrlBotMessage/UrlBotMessage.css";
import { useSelector } from "react-redux";
import HipOneChatbotIcon from "../../../assets/Icons/HpOneChatbotIcon.svg"

export default function UrlBotMessageLoading(props) {
  const [activeDot, setActiveDot] = useState(1);
  const containerRef = useRef();
  const theme = useSelector((state) => state.messages.Theme);
  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
    const interval = setInterval(() => {
      setActiveDot((prevDot) => (prevDot % 3) + 1);
    }, 300); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`${theme}url_bot_message`} ref={containerRef}>
      <div className={`${theme}url_bot_messageInfo`}></div>

      <div className={`${theme}url_bot_messageContent`}>
        <img
          src={HipOneChatbotIcon}
          alt=""
          width={"24px"} height={"24px"}
          style={{ marginRight: "1.5vh" }}
        ></img>
        <>
          <div
            style={{
              backgroundColor: theme === "dark_" ? " #444444" : " #ccdbfd",
            }}
            className={`${theme}bouncing-dots-container`}
            ref={containerRef}
          >
            <div
              className={`${theme}dot dot1 ${activeDot === 1 ? `${theme}activedot` : ""
                }`}
            ></div>
            <div
              className={`${theme}dot dot2 ${activeDot === 2 ? `${theme}activedot` : ""
                }`}
            ></div>
            <div
              className={`${theme}dot dot3 ${activeDot === 3 ? `${theme}activedot` : ""
                }`}
            ></div>
          </div>
        </>
      </div>
    </div>
  );
}
