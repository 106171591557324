import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GetCookie from "../../../HIPCookies/getCookie";
import {
  setCustomCollapse,
  setDocumentConfig,
  setUploadAlert,
} from "../../../Redux/features/AlertsSlice";
import { setBillList } from "../../../Redux/features/DocumentsSlice";
import ConfigImage from "../../../assets/Icons/Configuration.svg";
import SearchImage from "../../../assets/Icons/Search.svg";
import UploadImage from "../../../assets/Icons/Upload.svg";
import DataExtractionDocTable from "../../Pages/DataExtractionDocTable/DataExtractionDocTable";
import LoadingScreen from "../../Pages/LoadingScreen/LoadingScreen";
import { Typography } from "@mui/material";
import "./MedicalBillWorksheet.css";
import { openDatabase } from "../../../IndexedDBUtility/IndexedDBUtility";
import { useNavigate } from "react-router-dom";

function MedicalBillWorksheet() {

  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  const userId = (JSON.parse(GetCookie("userInfo")) || {}).userId;
  const billsList = useSelector((state) => state.documents.billsList)
  const updatebillsList = useSelector((state) => state.documents.updatebillsList)
  const [isLoading, setisLoading] = useState(false)
  const [ClaimDocuments, setClaimDocuments] = useState([...billsList]);
  const componentHeading = "Medical Bill Worksheet";
  let permissionData;
  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {

    try {
      setisLoading(true)
      const resp = await axios.post(
        `${process.env.REACT_APP_CONNECT}/med-bill-worksheet/get_pdfs`,
        {
          // Your data goes here
        },
        {
          headers: {
            userId: userId,
            // Other headers if needed
          },
        }
      );
      let list = resp.data.pdfs.map((item, index) => ({
        id: index,
        Name: item.pdf_name,
        Status: item.status ? "Extracted" : "WIP",
        Onboarded_time: item.date,
        DateModified: item.last_modified
      }));
      setTimeout(() => {
        dispatch(setBillList(list))
      }, 1);
      setClaimDocuments(list)
    } catch (error) {
      // Handle errors
      console.error(error);
    }
    finally {
      setisLoading(false)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = await openDatabase();
        const transaction = db.transaction(['permissionData'], 'readonly');
        const objectStore = transaction.objectStore('permissionData');
        const getRequest = objectStore.getAll();

        getRequest.onsuccess = (event) => {
          const storedData = event.target.result;
          permissionData = storedData[0].value
          const operationsModule = permissionData.find((module) => module.moduleName === 'Operations');
          const screenDisplayName = componentHeading;
          const screenPermissions = operationsModule.permissionsDataArray.find(
            (item) => item.displayName === screenDisplayName
          );

          if (screenPermissions) {
            const readPermission = screenPermissions.allowToReadData;
            const writePermission = screenPermissions.allowToWriteData;
            setReadAccess(readPermission);
            setWriteAccess(writePermission);
          }
        };
        getRequest.onerror = (error) => {
          console.error('Error getting data from IndexedDB:', error.target.error);
        };
      } catch (error) {
        console.error(`Error opening IndexedDB: ${error}`);
      }

    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [updatebillsList]);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <>
      {/* {
        isLoading && <LoadingScreen></LoadingScreen>
      }
      {
        isLoading && <LoadingScreen></LoadingScreen>
      } */}
      <div className="medical_bills_main_container">
        <div className="hip_configuration_btns">
          <div className="hip_configuration_document_title">
            <Typography variant="h3">{componentHeading}</Typography>
          </div>
          <div className="hip_config_span_medical_bill_worksheet">
            <div className="hip_config_doc_search_medical_bill_worksheet">
              <input type={"text"} placeholder="Search by record" value={searchInput} onChange={handleSearch} />
              <img src={SearchImage} alt="search" />
            </div>
            <div className="hip_config_btns">
              <div
                className={`hip_config_btn hip_configuration_btn ${writeAccess ? "" : "disabled"}`}
                onClick={() => {
                  if (writeAccess) {
                    dispatch(
                      setDocumentConfig({
                        isActive: true,
                        useCase: "Medical Review",
                        name: "Medical Review",
                      })
                    );
                  }
                }}
              >
                <img src={ConfigImage} alt="config" />
                <span>Config</span>
              </div>
              <div
                className={`hip_config_btn hip_upload_btn ${writeAccess ? "" : "disabled"}`}
                onClick={() => {
                  if (writeAccess) {
                    dispatch(
                      setUploadAlert({
                        isActive: true,
                        isMbr: true,
                        // configuration: "cp",
                        goto: "hip-operations/medical-bill-worksheet",
                      })
                    )
                  }
                }}
              >
                <img src={UploadImage} alt="upload" />
                <span>Upload</span>
              </div>
            </div>
          </div>
        </div>

        {/* <div>

          <button
            style={{
              height: "38px",
              width: "8.35vw",
              backgroundColor: "#FFFFFF",
              borderRadius: "100px",
              border: "1px solid #99BBFF",
              marginLeft: "64.84vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center", // Center content horizontally
              fontSize: "15px",
            }}
            onClick={fetchData}
          >
            <RefreshIcon style={{ marginRight: "5px" }} /> Refresh
          </button>

        </div> */}

        <DataExtractionDocTable
          columns={columns}
          documents={ClaimDocuments.filter((document) =>
            document.Name.toLowerCase().includes(searchInput.toLowerCase())
          )}
          setDocuments={setClaimDocuments}
          navigateTo={{
            goto: "/hip-operations/mbr",
            goBack: "/medical-bill-worksheet",
          }}
        />
      </div >
    </>
  );
}

export default MedicalBillWorksheet;

const columns = [
  {
    field: "id",
    key: "Name",
    headerName: "Document Name",
    width: "43%",
    alignment: "left",
  },
  {
    field: "status",
    key: "Status",
    headerName: "Status",
    width: "15%",
    alignment: "left",
  },
  {
    field: "datemodified",
    key: "DateModified",
    headerName: "Date Modified",
    width: "15%",
    alignment: "left",
  },
  {
    field: "dateadded",
    key: "Onboarded_time",
    headerName: "Date Added",
    width: "15%",
    alignment: "left",
  },
  {
    field: "optinos",
    key: "Actions",
    headerName: "Actions",
    width: "12%",
    alignment: "left",
  },
];
