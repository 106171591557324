import React, { useEffect } from 'react';
import "./MenuBar.css";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCollapse, setCollapse } from '../../../Redux/features/AlertsSlice';
import MenuItem from './features/MenuItems';

function MenuBar() {

    const location = useLocation();
    const dispatch = useDispatch();
    const collapse = useSelector(getCollapse);

    useEffect(() => {
        // Update active state based on the current route
        const currentPath = location.pathname;
        if (currentPath.startsWith('/profile/edit-profile') && collapse !== -1) {
            dispatch(setCollapse(0));
        } else {
            dispatch(setCollapse(-1));
        }
    }, [location.pathname, dispatch]);

    const handleActivateDropDown = (menuIndex) => {
        if (collapse === menuIndex) {
            dispatch(setCollapse(-1));
        } else {
            dispatch(setCollapse(menuIndex));
        }
    };

    return (
        <div className='navigation_bar'>
            <div className='navigation_bar_title'>
                <span>Profile</span>
            </div>
            <div className='menu_container'>
                <MenuItem
                    title={"Edit Profile"}
                    navigateTo={'/profile/edit-profile'}
                    isActive={collapse === 0}
                    handleActivateDropDown={() => handleActivateDropDown(0)}
                />
                <MenuItem
                    title={"Change Password"}
                    //    navigateTo={'change-password'}
                    isActive={collapse === 1}
                // handleActivateDropDown={() => handleActivateDropDown(1)}
                />
            </div>
        </div>
    );
}

export default MenuBar;
