import React, { useState, useEffect, useRef } from "react";
import Message from "./Message";
import Chatbar from "./Chatbar";
import "./Chatbot.css";
import axios from "axios";
import ButtonList from "./ButtonList/ButtonList";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_CLAIM_PROCESS_CHATBOT}`,
});

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [isBotTyping, setIsBotTyping] = useState(false); // State to track if Genzeon is "typing"
  const chatAreaRef = useRef(null);

  const typingMessages = [
    "Genzeon is thinking...",
    "Genzeon is prescribing a dose of wisdom...",
    "Genzeon is applying a digital bandage to the message...",
    "Genzeon is diagnosing your text and preparing a treatment plan...",
    "Genzeon is administering a shot of creativity to the response...",
    "Genzeon is conducting a clinical trial of words and phrases...",
  ];

  const [currentTypingMessage, setCurrentTypingMessage] = useState(
    typingMessages[0]
  );

  useEffect(() => {
    const scrollToLatestMessage = () => {
      if (chatAreaRef.current) {
        chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
      }
    };
    scrollToLatestMessage();
  }, [messages]);

  useEffect(() => {
    let typingMessageIndex = 0;
    let interval;
    if (isBotTyping) {
      interval = setInterval(() => {
        typingMessageIndex = (typingMessageIndex + 1) % typingMessages.length;
        setCurrentTypingMessage(typingMessages[typingMessageIndex]);
      }, 1000);
    } else {
      setCurrentTypingMessage(typingMessages[0]);
    }
    return () => clearInterval(interval);
  }, [isBotTyping]);

  const sendMessage = async (messageText) => {
    const userMessage = { type: "user", content: messageText };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setIsBotTyping(true); // Set bot as "typing" when awaiting a response

    try {
      const formData = new FormData();
      formData.append("user_query", messageText);

      const response = await api.post("/chat", formData);
      setIsBotTyping(false);

      if (response.data) {
        const backendResponse = { type: "bot", content: response.data.answer };
        setMessages((prevMessages) => [...prevMessages, backendResponse]);
      } else {
        throw new Error("No data received from the API");
      }
    } catch (error) {
      setIsBotTyping(false);
      console.error("Error fetching response from backend:", error);
    }
  };

  return (
    <div className="chatbot-container">
      <div className="chat-area" ref={chatAreaRef}>
        {messages.map((message, index) => (
          <Message key={index} type={message.type} content={message.content} />
        ))}
        {isBotTyping && (
          <div className="typing-animation">{currentTypingMessage}</div>
        )}
      </div>
      <div>
        <ButtonList />
      </div>
      <Chatbar onSendMessage={sendMessage} />
    </div>
  );
};

export default Chatbot;
