import React from 'react'
import { Outlet } from 'react-router-dom'
import HiPBreadCrumb from '../Pages/HiPBreadCrumb'
import MenuBar from '../Pages/MenuBar';
import './LayoutWithMenuBar.css'

function LayoutWithMenuBar() {
    return (
        <>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <div style={{
                    display: "flex", overflowY: 'scroll',
                    width: "15%", position: 'fixed', 
                    height: '100%'
                }}>
                    <MenuBar />
                </div>
                <div className='profile_route_with_navigation_screen'>
                    <HiPBreadCrumb />
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default LayoutWithMenuBar