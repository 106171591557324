import React, { useState, useEffect } from 'react';
import { TextField, Typography, Select, MenuItem, div, Button, Checkbox, ListItemText } from '@mui/material';
import "./AddUser.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import FormHelperText from '@mui/material/FormHelperText';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GetCookie from '../../../HIPCookies/getCookie';

function AddUser({ navigateTo }) {
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    orgName: '',
    userRole: '',
    userGroup: ''
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    userRole: false
  });

  const [userRoles, setUserRoles] = useState([]); // Store API data
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const clientId = (JSON.parse(GetCookie('userInfo')) || {}).clientId;
  const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;
  const userGroupsAsString = selectedUserGroups.length > 0 ? selectedUserGroups.join('\n') : "";
  const roleData = JSON.parse(GetCookie('roleInfo'));
  const userAddRoleData = roleData.some(role => role.roleName === 'Super Administrator');

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserRole`,
      {
        headers:
        {
          'Authorization': `Bearer ${jWTToken}`,
          groupRelation: true,

        },
      }).then((resp) => {
        setUserRoles(resp.data);
      })
      .catch((error) => {
        console.error('An error occurred while getting User Roles:', error);
        toast.error(`An error occurred while getting User Roles.`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });

    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Client/${clientId}`,
      {
        headers: {
          'Authorization': `Bearer ${jWTToken}`
        },
      }).then((response) => {
        const clietName = response.data.map((item) => item.clientName);
        setFields({ orgName: clietName[0] })
      }).catch((error) => {
        console.error("Error fetching Client details:", error);
        toast.error(`Error fetching Client details`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  const handleUserRoleChange = (event) => {
    const value = event.target.value;
    setSelectedUserRoles([...value]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      userRole: false,
    }));
  };

  const handleUserRoleClose = () => {
    if (selectedUserRoles.length > 0) {
      axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserGroup/RoleIds`,
        {
          headers: {
            'Authorization': `Bearer ${jWTToken}`,
            UserRoleIds: selectedUserRoles
          }
        }).then((response) => {
          const idArrayGroup = response.data.map((item) => item.displayName);
          setSelectedUserGroups(idArrayGroup);
        })
        .catch((error) => {
          console.error("An error occurred while getting User Groups:", error);
          toast.error(`An error occurred while getting User Groups.`, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
    else {
      setSelectedUserGroups("");
    }
  };

  const handleUserGroupChange = (event) => {
    const newText = event.target.value;
    const newArray = newText ? newText.split('\n') : "";
    setSelectedUserGroups(newArray);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== "userRole" && name !== "userGroup") {
      setFields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const handleCancel = () => {
    setFields({
      firstName: '',
      lastName: '',
      email: '',
      orgName: '',
      userGroup: '',
      userRole: '',
    });
    setSelectedUserRoles([]);
    setSelectedUserGroups([]);
    setErrors([]);
    navigateTo("User Profiles")
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formValid = true;
    const updatedErrors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(fields.email);
    const fieldNames = ['firstName', 'lastName', 'email'];

    fieldNames.forEach((fieldName) => {
      if (!fields[fieldName]) {
        updatedErrors[fieldName] = true;
        formValid = false;
      } else {
        updatedErrors[fieldName] = false;
      }
    });

    if (fields.email) {
      if (!fields.email || !isValidEmail) {
        formValid = false;
        toast.error('Please enter a valid email address.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

    if (selectedUserRoles.length === 0) {
      updatedErrors.userRole = true;
      formValid = false;
    } else {
      updatedErrors.userRole = false;
    }
    setErrors(updatedErrors);

    if (formValid) {
      const data = { ...fields };
      delete data.orgName;
      delete data.userGroup;
      delete data.userRole;
      axios.post(`/api/v1/Users`, { ...data, clientId: 1, displayName: fields.firstName + " " + fields.lastName },
        {
          baseURL: process.env.REACT_APP_USER_API_ENDPOINT,
          headers: {
            'Authorization': `Bearer ${jWTToken}`,
            UserRoleId: selectedUserRoles,
            baseURL: window.location.origin,
          }
        }).then(resp => {
          if (resp.status === 200 && resp.data !== "Data already exist") {
            toast.success('Successfully added New User.', {
              position: toast.POSITION.TOP_CENTER,
            })
            setTimeout(() => {
              navigateTo("User Profiles");
            }, 1000);
          }
          else if (resp.status === 200 && resp.data === "Data already exist") {
            toast.info('User already exists.', {
              position: toast.POSITION.TOP_CENTER,
            })
          }
          else {
            toast.error('Failed to add user.', {
              position: toast.POSITION.TOP_CENTER,
            })
          };
        }).catch((error) => {
          console.error("An error occurred while adding the entry:", error);
          toast.error(`An error occurred while adding the entry.`, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <div className="add_user_main_container">
      <form onSubmit={handleSubmit}>
        <div className="add_user_form">
          <div className='add_user_form_div'>
            <Typography variant="subtitle1" className="add_user_sub_heading">FIRST NAME </Typography>
            <TextField
              className="add_user_sub_field"
              type="search"
              inputProps={{ maxLength: 50 }}
              name="firstName"
              placeholder="Enter First Name"
              hinttext="Enter Name"
              value={fields.firstName}
              onChange={handleChange}
              helperText={errors.firstName && "Please enter First Name"}
              error={errors.firstName}
            />
          </div>
          <div className='add_user_form_div'>
            <Typography variant="subtitle1" className="add_user_sub_heading">LAST NAME </Typography>
            <TextField
              className="add_user_sub_field"
              type="search"
              inputProps={{ maxLength: 50 }}
              name="lastName"
              placeholder="Enter Last Name"
              hinttext="Enter Name"
              value={fields.lastName}
              onChange={handleChange}
              helperText={errors.lastName && "Please enter Last Name"}
              error={errors.lastName}
            />
          </div>
        </div>
        <div fullWidth className="add_user_form">
          <div className='add_user_form_div'>
            <Typography variant="subtitle1" className="add_user_sub_heading">Email </Typography>
            <TextField
              className="add_user_sub_field"
              type="search"
              inputProps={{ maxLength: 100 }}
              name="email"
              placeholder="Enter Email"
              hinttext="Enter email"
              value={fields.email}
              onChange={handleChange}
              helperText={errors.email && "Please enter Email ID"}
              error={errors.email}
            />
          </div>
          <div className='add_user_form_div'>
            <Typography variant="subtitle1" className="add_user_sub_heading">ORGANIZATION </Typography>
            <TextField
              className="add_user_sub_field"
              type="search"
              inputProps={{ maxLength: 100 }}
              name="orgName"
              value={fields.orgName}
              onChange={handleChange}
              disabled={clientId !== 1}
            />
          </div>
        </div>
        <div fullWidth error={errors.userRole} className="add_user_form">
          <div className='add_user_form_div'>
            <Typography variant="subtitle1" className="add_user_sub_heading">USER ROLE</Typography>
            <Select name="userRole"
              className="add_user_sub_select"
              IconComponent={ExpandMoreIcon}
              multiple
              value={selectedUserRoles}
              onChange={handleUserRoleChange}
              onClose={handleUserRoleClose}
              renderValue={(selected) => (selected.length === 0 ? "Select" :
                <div className='add_user_selected_values_input'>
                  {selected.map((value, index) => (
                    <span key={index}>{index > 0 ? ", " : ""}{userRoles.find((option) => option.id === value)?.userRoleName}</span>
                  ))}
                </div>
              )}
              error={errors.userRole}
            >
              {userRoles.map((option, index) => (
                !userAddRoleData && option.displayName !== "Super Administrator" ? (
                  <MenuItem className="add_user_sub_select_content" key={index} value={option.id}>
                    <Checkbox style={{ color: "#49454F" }} checked={selectedUserRoles.includes(option.id)} />
                    <ListItemText primary={option.displayName} />
                  </MenuItem>) :
                  userAddRoleData ? (
                    <MenuItem className="add_user_sub_select_content" key={index} value={option.id}>
                      <Checkbox style={{ color: "#49454F" }} checked={selectedUserRoles.includes(option.id)} />
                      <ListItemText primary={option.displayName} />
                    </MenuItem>) : null
              ))}
            </Select>
            {errors.userRole && <FormHelperText style={{ color: "#D90C00" }}>Please select at least one User Role.</FormHelperText>}
          </div>
        </div>
        <div>
          <div className='add_user_form_div'>
            <Typography variant="subtitle1" className="add_user_sub_heading">USER GROUP</Typography>
            <TextField
              minRows={2}
              maxRows={4}
              className="add_user_sub_field"
              type="text"
              name="usergroup"
              multiline
              variant="outlined"
              disabled
              value={userGroupsAsString.length > 0 ? userGroupsAsString : ''}
              onChange={handleUserGroupChange}
            />
          </div>
        </div>
        <div className='add_user_buttons_form' >
          <Button type="button" onClick={handleCancel} variant="contained" color="primary" className='add_user_cancel_button'>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" style={{ marginLeft: "20px" }} className='add_user_sub_button'>
            Add User
          </Button>
        </div>
      </form>
    </div >
  )
}

export default AddUser