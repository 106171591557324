import { createTheme } from "@mui/material/styles";

// Define your theme using createTheme function
const theme = createTheme({
  palette: {
    mode: "light", // 'light' mode is the default in MUI v5
    primary: {
      main: "#5A03EE",
      light: "#6C16FF",
      dark: "#6C16FF",
    },
    secondary: {
      main: "#ffffff",
      light: "#d9d9d9",
      dark: "#d9d9d9",
    },
    background: {
      default: "#ffffff",
    },
    success: {
      main: "#48CA93",
      dark: "#48ca93",
      light: "#48ca93",
    },
    info: {
      main: "#0075FF",
      light: "#0075ff",
      dark: "#0075ff",
    },
    warning: {
      main: "#FFBC00",
      light: "#ffbc00",
      dark: "#ffbc00",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#252525",
          color: "#FFF",
          fontSize: ".75rem",
          borderRadius: ".5rem"
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + $track": {
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: "1px solid #bdbdbd",
          backgroundColor: "#fafafa",
          opacity: 1,
          transition:
            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  typography: {
    fontFamily: "Inter",
    jumbotron: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: 1,
    },
    h1: {
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.125,
    },
    h4: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 0.875,
    },
    h6: {
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#444444"
    },
    body3: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#787878"
    },
    regular: {
      fontWeight: 400,
    },
    medium: {
      fontWeight: 500,
    },
    semiBold: {
      fontWeight: 600,
    },
    bold: {
      fontWeight: 700,
    },
  },

  shape: {
    borderRadius: 4,
  },
  spacing: 4,
});

export default theme;
