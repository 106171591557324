import React, { useEffect, useState } from "react";
import "./BoundingBox.css";

function MedicalBillBoundingBox({ coordinates }) {
  const [con_level, setConLevel] = useState("0,128,0"); 
  const imageHeight = 55;
  const imageWidth = 55;

  if (coordinates) {
    const [left, right, top, bottom] = coordinates;
    const width = right - left;
    const height = bottom - top;
    

    // Convert to pixel values
    const leftInPixels = left * imageWidth ;
    const topInPixels = top * imageHeight ;
    const widthInPixels = width * imageWidth;
    const heightInPixels = height * imageHeight;

    return (
      <div
        style={{
          position: "absolute",
          // border: "2px solid red",
          left: `${leftInPixels}px`,
          top: `${topInPixels}px`,
          width: `${widthInPixels}px`,
          height: `${heightInPixels}px`,
          backgroundColor: `rgba(${con_level}, 0.4)`,
        }}
        className="bounding_box"
      ></div>
    );
  }
}

export default MedicalBillBoundingBox;
