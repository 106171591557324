import React from 'react';
import "./DocumentTables.css"

export function DocumentTables({ File, handleTablePages, setIsActive, isActive }) {

    return (
        <div className='doc_tables_container'>
            <label>Bill Data</label>
            <ul>
                {
                    File.length === 0 ?
                        <></>
                        :
                        <>
                            {
                                File.data_std.tables.tb_std_array.map((e, index) => {
                                    return (
                                        <li
                                            onClick={() => {
                                                handleTablePages(index + 1)
                                                setIsActive(true)
                                            }}>
                                            <span>Table - {index + 1}</span>
                                            <img src={"https://cdn-icons-png.flaticon.com/512/190/190411.png"} alt='checked' />
                                        </li>
                                    );
                                })
                            }
                        </>
                }
            </ul>
        </div>
    )
}
