import React, { useState } from "react";
import FileUploader from "../FileUploader/FileUploader";
import SectionListComponent from "../SummarizeSectionListComponent/SectionListComponent";
import SplitButtonComponent from "../SummarizeSplitButtonComponent/SplitButtonComponent";
import CCDAViewer from "../CCDAViewer/CCDAViewer";
import MedicalDetails from "../MedicalDetails/MedicalDetails";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setTheme } from "../../../Redux/features/MessageSlice";
const SummarizeComponent = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [sectionsData, setSectionsData] = useState([]);
  const [dynamicData, setDynamicData] = useState({});
  const [viewMode, setViewMode] = useState("Section List");
  const [showEmptyDataPopup, setShowEmptyDataPopup] = useState(false);
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(setTheme(false));
    };
  }, []);

  const handleFileUploadSuccess = (
    uploadedFileName,
    uploadedDataList,
    isFileUploaded
  ) => {
    setIsUploaded(isFileUploaded);
    setFileName(uploadedFileName);
    setSectionsData(uploadedDataList);
    setViewMode("Section List");

    if (uploadedDataList.length === 0) {
      setShowEmptyDataPopup(true);
    }
  };

  const handleSectionsData = (uploadedDataList, dynamicData) => {
    setSectionsData(uploadedDataList);
    setDynamicData(dynamicData);
  };

  const handleMenuItemClick = (viewMode) => {
    setViewMode(viewMode);
  };
  const handleBackClick = (event) => {
    event.preventDefault();
    setSectionsData([]);
    setDynamicData({});
    setIsUploaded(false);
  };
  const EmptyDataPopup = () => {
    const popupStyle = {
      backgroundColor: "red",
      color: "white",
      padding: "10px",
      borderRadius: "5px",
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
    };

    return (
      <div style={popupStyle}>
        <p>No data available. Please upload a file.</p>
      </div>
    );
  };

  return (
    <div>
      {showEmptyDataPopup && <EmptyDataPopup />}
      {isUploaded ? (
        <>
          <SplitButtonComponent
            onViewModeChange={handleMenuItemClick}
            selectedAction={viewMode}
          />
          {viewMode === "CCDA Viewer" ? (
            <CCDAViewer htmlContent={htmlContent} dynamicData={dynamicData} fileName={fileName} />
          ) : viewMode === "One Page Summary" ? (
            <MedicalDetails />
          ) : (
            <SectionListComponent
              sectionsData={sectionsData}
              onBackClick={handleBackClick}
              fileName={fileName}
            />
          )}
        </>
      ) : (
        <FileUploader
          onFileUploadSuccess={handleFileUploadSuccess}
          onDataFetched={handleSectionsData}
          setHtmlContent={setHtmlContent}
        />
      )}
    </div>
  );
};

export default SummarizeComponent;
