import React from 'react';
import "./index.css";
import Back from '../../../assets/Icons/Back.svg';
import { useNavigate } from 'react-router-dom';

function AllPatientDataRequest() {

    const navigate = useNavigate();

    return (

        <div className="patient_request_main_container">
            <div className='reports-ops'>
                <iframe title="HIP Report Client" className='dashboard-report-container' src={process.env.REACT_APP_CLINICAL_POWER_BI_URL} frameborder="0" allowFullScreen="true" />
            </div>
        </div>
    )
}

export default AllPatientDataRequest