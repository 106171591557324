import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Pagination, PaginationItem ,Tooltip} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GetCookie from "../../../../HIPCookies/getCookie";
import {
  getFilter, setCustomCollapse
} from "../../../../Redux/features/AlertsSlice";
import {
  setBillUrlList, setUpdateFields
} from "../../../../Redux/features/DocumentsSlice";
import BackImage from "../../../../assets/Icons/navigate-back.svg";
import Loading from "../../LoadingComponent/loading";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import MBWFileDisplay from "../../MBWFileDisplay/MBWFileDisplay";
import MBWPdfThumbnails from "../../MedicalBillWorksheetPage/PdfThumbnails/MBWPdfThumbnails";
import { MBWShowSections } from "../MBWShowSections/MBWShowSections";
import "./MBWSectionList.css";
import SetCookie from "../../../../HIPCookies/setCookie";


function MBWSectionList() {
  const [isClaimProcess, setisClaimProcess] = useState(false)
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const numberOfRows = 1;
  const [sectionData, setsectionData] = useState({});
  const [FileData, setFileData] = useState([]);
  const [File, setFile] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const filter = useSelector(getFilter);
  const pdfName = useSelector((state) => state.documents.billname);
  const [filename, setFilename] = useState(pdfName);
  const defaultImageUrls = useSelector((state) => state.documents.billUrlList);
  const FieldsUpdated = useSelector((state) => state.documents.FieldsUpdated);
  const [FileImages, setFileImages] = useState([]);
  const PageNumber = useSelector((state) => state.documents.pageNumber)
  const [pageNumber, setPageNumber] = useState(PageNumber);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let userId = (JSON.parse(GetCookie("userInfo")) || {}).userId;
  
  useEffect(() => {
    const storedPdfName = GetCookie("pdfName");
  
    if (storedPdfName) {
      // If "pdfName" is already in sessionStorage, use the stored value
      dispatch(setCustomCollapse(true));
      fetchSectionsFromAPI();
      setFilename(storedPdfName)
    } else {
      // If "pdfName" is not in sessionStorage, set it and then proceed
      SetCookie("pdfName", pdfName);
    }
    dispatch(setCustomCollapse(true))
    fetchSectionsFromAPI();
  }, []);
  
 

  useEffect(() => {
    setPageNumber(PageNumber)
  }, [PageNumber]);

  const [Con_Level, setCon_Level] = useState({
    High: 0,
    Medium: 0,
    Low: 0,
    Null: 0,
  });

  const [coArrow, setCoArrow] = useState({
    isActive: false,
  });
  const handleFarLeft = () => {
    setPage(0);
    setPageNumber(1);
  };

  const handleFarRight = () => {
    setPage(totalPages - 1);
    setPageNumber(totalPages);
  };
  const handleSave = async () => {
    if(!FieldsUpdated){
      toast.warning('No Changes', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    let resp;
    try {
      resp = await axios.post(`${process.env.REACT_APP_CONNECT}/med-bill-worksheet/save_verified_data`, {
        pdf_name: pdfName,
        pdf_info: sectionData
      },
        {
          headers: {
            "Content-Type": "application/json",
            userId: userId,
          },
        })
      dispatch(setUpdateFields(false))
      toast.success('Changes Updated Successfully', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (e) {

    }
  }
  const handleIndicators = (res) => {
    let high = 0;
    let medim = 0;
    let low = 0;
    let Null = 0;
    res.data_std.key_value_pairs.forEach((e, index) => {
      e.key_value_pairs.forEach((e1) => {
        if (e1.con_level === "high") {
          high += 1;
        } else if (e1.con_level === "low") {
          low += 1;
        } else if (e1.con_level === "mid") {
          medim += 1;
        } else {
          Null += 1;
        }
      });
    });

    setCon_Level({
      High: high,
      Medium: medim,
      Low: low,
      Null: Null,
    });
  };
  const handleNext = () => {
    if (pageNumber < totalPages) {
      setPage(page + 1);
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrev = () => {
    if (page !== 0) {
      setPage(page - 1);
      setPageNumber(pageNumber - 1);
    }
  };
  const changePage = (number) => {
    setPage(number - numberOfRows);
    setPageNumber(number);
  };

  const handlePageChange = (_, page) => {
    setPageNumber(page);
  };
  const changeSectiondata = (title, key, value) => {
    sectionData[title][key][0] = value
  }

  const fetchSectionsFromAPI = async () => {
    let userId = (JSON.parse(GetCookie("userInfo")) || {}).userId;
    let pdf_name = GetCookie("pdfName")
    setisClaimProcess(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/med-bill-worksheet/get_extracted_data`,
        {
          // Send the userId as a header
          pdf_name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            userId: userId,
          },
        }
      );
      const data = response.data;
      const urlList = data.images.map((item) => ({
        blob_url: item[1],
        page_number: item[0],
      }));

      const blobUrls = urlList.map((item) => item.blob_url);
      const transformedImages = blobUrls.map((blob_url) => ({
        img_url: blob_url,
        image: blob_url.split("/").slice(-1)[0],
        img_res: null,
      }));
      setFileImages(transformedImages);
      setTimeout(() => {
        dispatch(setBillUrlList(urlList));
      }, 1);
      const pdf_info = data.pdf_info;

      const keys = Object.keys(data.pdf_info);
      setSectionList(keys);
      setsectionData(pdf_info);
    } catch (e) {
      console.log("Error");
    }
    finally {
      setisClaimProcess(false)
    }
  };
  return (
    <div className="mbw-section_list_review_files_main_page">
      {isClaimProcess &&
        <LoadingScreen />
      }
      <div className="mbw-section_list_review_files_images_main_page">
        <div className="mbw-sectionlist_row">
          <div className="mbw-pdf-thumbnails-section">
            <MBWPdfThumbnails
              currentPage={pageNumber - 1}
              onThumbnailClick={(selectedPage) =>
                setPageNumber(selectedPage + 1)
              }
              filter={filter}
            />
          </div>
          <div className="mbw-section_list_filedisplay">
            <div className="mbw-section_list_verify_record_request_doc_display">
              <div className="mbw-section_list_verify_record_request_doc_display_header">
                <div className="mbw-section_list_verify_record_request_doc_display_filename">
                  <b>Filename</b>
                  <br />
                  <span>{filename}</span>
                </div>
                <div className="mbw-section_list_verify_record_request_doc_display_pagination">
                  <Pagination
                    count={FileImages.length}
                    variant="outlined"
                    siblingCount={0}
                    defaultPage={1}
                    boundaryCount={1}
                    page={pageNumber} // Subtract 1 because Pagination uses 0-based indexing
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          first: KeyboardDoubleArrowLeftIcon,
                          last: KeyboardDoubleArrowRightIcon,
                        }}
                        component="button"
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="mbw-section_list_verify_record_request_doc_display_content">
                <div className="mbw_section_list_verify_record_request_doc_display_content_images">
                  {FileImages.length > 0 && (
                    <MBWFileDisplay
                      pageNumber={pageNumber}
                      FileImages={FileImages}
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      page={page}
                      totalPages={totalPages}
                      isActive={isActive}
                      setCoArrow={setCoArrow}
                    // other props
                    />
                  )}
                  <img src={FileImages.img_url} height="200px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mbw-section_list_doc_display_request">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{marginLeft:0}}  className="mbw-filename_display">File Name</div>
          <div style={{marginLeft:0}}  className="mbw-filename">{filename}</div>
        </div>

        {false ? (
          <Loading />
        ) : (
          <MBWShowSections
            Con_Level={Con_Level}
            setCoArrow={setCoArrow}
            FileData={FileData}
            File={File}
            page={page}
            changePage={changePage}
            sectionList={sectionList}
            sectionData={sectionData}
            changeSectiondata={changeSectiondata}
          />
        )}

        <div className="mbw-section_list_doc_button">
          <button
            className="mbw-section_list_doc_submit"
          // onClick={() => handleSubmit()}
          >
            Submit
          </button>
          <Tooltip title="Save Changes" placement="top" arrow>
          <button
            className="section_list_doc_button_save"
            onClick={() => handleSave("save")}
          >
            Save Changes
          </button>
          </Tooltip>
          
        </div>
      </div>
    </div>
  );
}

export default MBWSectionList;
