import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import searchLight from "../../../assets/Send_light.svg";
import "./ChatBar.css";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import micDark from "../../../assets/Icons/Mic_Darkmode.svg";
import micLight from "../../../assets/Icons/Mic_lightmode.svg";
import micBlue from "../../../assets/Mic_blue.svg";
import searchDark from "../../../assets/Send_dark.svg";
import searchSend from "../../../assets/send_blue.svg";
import { Tooltip } from "@mui/material";

const ChatBar = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");
  const [micBlink, setMicBlink] = useState(false);
  const [isPlaceholderVisible, setPlaceholderVisible] = useState(true);
  const [isClicked, setIsClicked] = useState(false); // Track whether the chat bar is clicked
  const inputRef = useRef(null);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const isLoading = useSelector((state) => state.theme.isLoading);
  const darkMode = useSelector((state) => state.theme.darkMode);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
      setIsClicked(false);
    }
  };

  const handleSubmit = () => {
    if (isLoading) return;
    if (message.trim() !== "") {
      onSendMessage({
        id: "user",
        text: message,
      });
      setMessage("");
    }
    setIsClicked(false);
  };

  const handleMicClick = () => {
    if (!isListening) {
      setIsClicked(true);
      resetTranscript();
      setIsListening(true);
      SpeechRecognition.startListening();
      setMicBlink(true);
    } else {
      setIsListening(false);
      SpeechRecognition.stopListening();
      if (transcript.trim() !== "") {
        setMessage(transcript);
        handleSubmit(); // Automatically send the message when speaking stops
      }
      setIsClicked(false);
      setMicBlink(false);
    }
  };

  const handleInputFocus = () => {
    setPlaceholderVisible(false);
    setIsClicked(true); // Set chat bar as clicked
  };

  const handleInputBlur = () => {
    if (message === "") {
      setPlaceholderVisible(true);
      setIsClicked(false); // Set chat bar as not clicked
    }
  };

  useEffect(() => {
    if (transcript !== "") {
      setMessage(transcript);
    }
  }, [transcript]);

  return (
    <div className={`center-container ${darkMode ? "dark-mode" : ""}`}>
      <form
        id="chat-form"
        className={`chat-bar ${isClicked ? "clicked" : ""}`}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          type="text"
          placeholder={
            isPlaceholderVisible
              ? "Ask anything about Genzeon or Health Intelligence Platform One... eg. What is HIP One?"
              : ""
          }
          value={message}
          onChange={handleInputChange}
          ref={inputRef}
          onKeyPress={handleInputKeyPress}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <div className="chatbat-button-container">
          <div className="chatbar-voice-button-container">
            <Tooltip title="Search by Voice" placement="top">
              <button
                type="button"
                onClick={handleMicClick}
                className="voice-button"
              >
                <img
                  src={
                    !darkMode && isClicked
                      ? micBlue
                      : darkMode
                      ? micDark
                      : micLight
                  }
                  alt="Mic"
                  className={micBlink ? "mic-blink" : ""}
                />
              </button>
            </Tooltip>
          </div>
          <Tooltip title="Send Message" placement="top">
            <button
              type="button"
              onClick={handleSubmit}
              className="search-button"
            >
              <img
                src={
                  !darkMode && isClicked
                    ? searchSend
                    : darkMode
                    ? searchDark
                    : searchLight
                }
                alt="Search"
              />
            </button>
          </Tooltip>
        </div>
      </form>
    </div>
  );
};

export default ChatBar;
