import React, { useState } from 'react';
import './UserManagement.css';

function UserTabs({ tabs, content, setActiveTab, activeTab }) {

  return (
    <div className="tabs-container">
      <div className="tab-list">
        {tabs.map((tab) => (
          <button key={tab} className={`tab-button ${tab === activeTab ? 'active' : ''}`} onClick={() => setActiveTab(tab)} >
            {tab}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {content[activeTab]}
      </div>
    </div>
  );
}

export default UserTabs;