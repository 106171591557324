import React, { useState, useEffect } from "react";
import "./DataGenie.css";
import SendIcon from "../../../assets/Icons/send-icon.png";
import SearchIcon from "../../../assets/Icons/search-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { addMessage, clearMessages } from "../../../Redux/features/DataGenieSlice";
import axios from "axios";
import { setTheme } from "../../../Redux/features/MessageSlice";
import { setThemedb } from "../../../Redux/features/DbMessageSlice";
import DatagenieMesssages from "../../Components/DataGenieMessages/DatagenieMesssages";
import SendIcon1 from "../../../assets/Icons/send-icon1.png"

const Datagenie = () => {
  const [userMessage, setUserMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTheme(privacyModeEnabled));
    return async () => {
      dispatch(setTheme(false));
      dispatch(setThemedb(false));
      dispatch(clearMessages())
    };
  }, []);
  const isPrivacyModeOn = useSelector(
    (state) => state.dbMessages.isPrivacyModeOndb
  );

  const [privacyModeEnabled, setPrivacyModeEnabled] = useState(isPrivacyModeOn);

  const theme = useSelector((state) => state.messages.Theme) || "";

  const togglePrivacyMode = () => {
    setPrivacyModeEnabled(!privacyModeEnabled);
    dispatch(setTheme(!privacyModeEnabled));
  };


  const messageChangeHandler = (event) => {
    setUserMessage(event.target.value);
  };

  const handleKeyPress = (e) => {
    if ((e.key == "Enter") & (userMessage.trim() !== "")) {
      sendButtonHandler();
    }
  };
  const sendHandler = (e) => {
    if (userMessage.trim().length !== 0) {
      sendButtonHandler();
    }
  };

  const sendButtonHandler = async (event) => {
    let tempMessageobj = { id: "user", text: userMessage };
    dispatch(addMessage(tempMessageobj));
    setUserMessage("");
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/oper-intel/get-sql-query`,
        { question: userMessage }
      );

      setIsLoading(false);
      const id = response.data.id;
      if (id === "chart") {
        tempMessageobj = {
          id: "chart",
          data: response.data.data,
          options: { ...response.data.options, title: response.data.title },
          type: response.data.chart_type,
          text: response.data.summary,
        };
      } else {
        tempMessageobj = {
          id: "bot",
          text: response.data.summary,
        };
      }
      dispatch(addMessage(tempMessageobj));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={`${theme}db_main_container`}>
      <div className={`${theme}db_chatbox_container`}>
        <DatagenieMesssages isLoading={isLoading}></DatagenieMesssages>
      </div>
      <div className={`${theme}db_search_box_container`}>
        <input
          className={`${theme}db_text_box`}
          type="text"
          placeholder="Ask me.."
          value={userMessage}
          onChange={messageChangeHandler}
          onKeyDown={handleKeyPress}
          style={{
            backgroundImage: `url(${SearchIcon})`,
            backgroundSize: "2.5vh",
            backgroundPosition: "2vh center",
            backgroundRepeat: "no-repeat",
            paddingLeft: "6vh",
          }}
        />

        <img
          className={`${theme}db_send_button_icon`}
          src={userMessage.trim().length !== 0 ? SendIcon1 : SendIcon}
          onClick={sendHandler}
          style={{ backgroundColor: userMessage.trim().length !== 0 ? "#0044CC" : "white", }}
        />
      </div>
    </div>
  );
};

export default Datagenie;
