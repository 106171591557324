import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutWithBreadCrumb from "../Theme/LayoutWithBreadCrumb";
import ErrorPage from '../Components/ErrorPage/ErrorPage';
import UserManagement from './Screens/UserManagement/UserManagement';
import CustomizeOrganization from "./Screens/CustomizeOrganization/CustomizeOrganization";

function App() {

  return (
    <>
      <Routes>
        {/* Components with Header and Navigation  */}
        {/* With Menu Bar */}
        <Route element={<LayoutWithBreadCrumb type="settings" />}>
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/customize-organization" element={<CustomizeOrganization />} />
        </Route>

        {/* Error Route */}
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
