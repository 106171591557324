import React from 'react';
import "./index.css";
import Back from '../../../assets/Icons/Back.svg';
import { useNavigate } from 'react-router-dom';

function BulkDownloads() {

    const navigate = useNavigate();

    return (

        <div className="bulk_downloads_main_container">
            <div className="bulk_downloads_back_container" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <img src={Back} alt="" style={{ marginRight: '10px', width: '13%' }} />
                Back
            </div>
            <div className="bulk_downloads_sub_container">
                <h1 className='bulk_downloads_heading'> Bulk Downloads</h1>
            </div >
        </div>
    )
}

export default BulkDownloads