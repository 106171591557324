import React from "react";
import { useSelector } from "react-redux";

import UrlUserMessage from "../UrlUserMessage/UrlUserMessage";
import UrlBotMessage from "../UrlBotMessage/UrlBotMessage";
import msgLoading from "../../../assets/Gifs/loading.gif";
import "./UrlMessages.css";
import UrlBotMessageLoading from "../UrlMessgesLoding/UrlMessageLoading";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function UrlMessages({ chatbotName, isMeesageloading }) {
  const theme = useSelector((state) => state.urlMessages.Theme);
  const urlref = useRef()
  const [scrollAtBottom, setScrollAtBottom] = useState(true); 
  const chatbotMessages = useSelector(
    (state) => state.urlMessages.chatbotMessages
  );
  useEffect(() => {
    const container = urlref.current;
    const handleScroll = () => {
      setScrollAtBottom(container.scrollTop + container.clientHeight >= container.scrollHeight - 10);
    };
   container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [chatbotMessages]);
  const scrollToBottom = () => {
    const container = urlref.current;
    const scrollHeight = container.scrollHeight - container.clientHeight;
  
    container.scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    });
  
    setScrollAtBottom(true);
  };

  const messages = chatbotMessages[chatbotName] || [];
  return (
    <div className={`${theme}url_messages`} ref={urlref}>
      {messages.map((obj, index) =>
        obj.id === "user" ? (
          <UrlUserMessage key={index} message={obj.text} />
        ) : (
          <UrlBotMessage key={index} message={obj.text} />
        )
      )}
      {isMeesageloading && (
         <UrlBotMessageLoading></UrlBotMessageLoading>
      )}
   
      {!scrollAtBottom && (
        <button className="down-arrow-button" onClick={scrollToBottom}>
          ↓
        </button>
      )}
    </div>
  );
}
