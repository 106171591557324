import Skeleton from "@yisheng90/react-loading";
import React from "react";
import "./Loading.css";

const Loading = () => {
  const skeletonStyle = {
    height: "5px",
  };

  return (
    <div className="loading">
      <div className="loading-spinner">
        <div className="circle-container">
          <div className="half-circle"></div>
        </div>
        <p className="loading-text">Segmenting Fields</p>
      </div>
      <Skeleton width={350} style={skeletonStyle} />
      <Skeleton width={250} style={skeletonStyle} />
      <Skeleton width={150} style={skeletonStyle} />
      <Skeleton width={100} style={skeletonStyle} />
    </div>
  );
};

export default Loading;
