import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GetCookie from "../../../../HIPCookies/getCookie";
import {
  getUploadedFilename
} from "../../../../Redux/features/DocumentsSlice";
import MBWCollapsibleSection from "../../MBWCollpsibleSection/MBWCollapsibleSection";
import "./MBWShowSections.css";

export function MBWShowSections({
  Con_Level,
  setCoArrow,
  FileData,
  File,
  page,
  changePage,
  sectionList,
  sectionData,
  changeSectiondata
}) {
  const [loadingSection, setLoadingSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(-1);
  const dispatch = useDispatch();
  const userId = (JSON.parse(GetCookie("userInfo")) || {}).userId;
  
  return (
    <>
      {/* Indication of Confidence level */}
      <div className="mbw_section_list_doc_text_indication_container">
        <div style={{paddingLeft:0}} className="mbw_doc_text_indication_container_header">
          <span>Accuracy Level</span>
        </div>
        <div className="mbw_doc_text_field_indicators">
          <div className="mbw_doc_text_field_indicator_span">
            <Tooltip placement="top" title="80-100%">
              <div className="mbw_indicator_high"></div>
            </Tooltip>
            <p className="mbw_doc_acc_level"></p>
          </div>
          <div className="mbw_doc_text_field_indicator_span">
            <Tooltip placement="top" title="60-80%">
              <div className="mbw_indicator_medium"></div>
            </Tooltip>
            <p className="mbw_doc_acc_level"></p>
          </div>
          <div className="mbw_doc_text_field_indicator_span">
            <Tooltip placement="top" title="< 60%">
              <div className="mbw_indicator_low"></div>
            </Tooltip>
            <p className="mbw_doc_acc_level"></p>
          </div>
          <div className="mbw_doc_text_field_indicator_span">
            <Tooltip placement="top" title="No Data Extracted">
              <div className="mbw_indicator_null"></div>
            </Tooltip>
            <p className="mbw_doc_acc_level"></p>
          </div>
        </div>
      </div>

      {/*  Form for editing the data */}
      <div
        className="mbw_section_list_doc_text_field_container"
        onScroll={() =>
          setCoArrow({
            isActive: false,
          })
        }
      >
        <div className="mbw_section_list_doc_text_field_container">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            sectionList.map((sectionTitle, index) => {
              return (
                <MBWCollapsibleSection
                  key={index}
                  title={sectionTitle}
                  isActive={index === activeSection}
                  isLoading={loadingSection === sectionTitle}
                  sectionData={sectionData}
                  sectionTitle={sectionTitle}
                  changeSectiondata={changeSectiondata}
                  onToggle={() => {
                    if (index === activeSection) {
                      setActiveSection(-1); // Close the section if it's already active
                    } else {
                      setActiveSection(index); // Open the clicked section
                    }
                  }}
                >
                  <p>{sectionTitle}</p>
                </MBWCollapsibleSection>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}
