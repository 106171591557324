import React from "react";
import { Paper } from "@material-ui/core";
import { Box, Button, Rating, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { Select } from "antd";
import Grid from "@mui/material/Grid";
import Details from "./Details";

import LoadingScreen from "../../../../Billing/Pages/LoadingScreen/LoadingScreen";

const Card = (props) => {
  const map_overall_star_rating = props.data?.map_overall_star_rating || [];
  const national_avg = props.data?.national_avg || [];

  // console.log("props", props.data)

  const contractStarRatingData = (map_overall_star_rating ?? [])
    .map(item => ({ ...item })) // Copy each object
    .sort((a, b) => a.Year.localeCompare(b.Year));

    // console.log("contractStarRatingData", contractStarRatingData)

  const nationalAverageStarRatingData = (national_avg ?? [])
    .map(item => ({ ...item, national_average_star: parseFloat(item.national_average_star) })) // Convert the national_average_star property to a float and copy other properties
    .sort((a, b) => a.year.localeCompare(b.year)); // Sort based on the year property

    // console.log("nationalAverageStarRatingData", nationalAverageStarRatingData)

    const categories = (national_avg ?? []).map((item) => item.year).sort((a, b) => a.localeCompare(b)) ?? [];

const alignedNationalAverageStarRatingData = [];
const alignedContractStarRatingData = [];

for (const year of categories) {
  // console.log("year", year)
  const nationalDataForYear = nationalAverageStarRatingData.find(item => item.year === year);
  alignedNationalAverageStarRatingData.push(nationalDataForYear ? nationalDataForYear.national_average_star : 0);

  const contractDataForYear = contractStarRatingData.find(item => item.Year === year);
  alignedContractStarRatingData.push(contractDataForYear ? contractDataForYear.Overall_Star : 0);
}


// console.log("alignedNationalAverageStarRatingData", alignedNationalAverageStarRatingData);
// console.log("alignedContractStarRatingData", alignedContractStarRatingData);

  // console.log("categories", categories)

  categories.sort((a, b) => a - b);

  const obj = {
    series: [
      {
        name: "National Average Star Rating",
        data: alignedNationalAverageStarRatingData,
        // data: nationalAverageStarRatingData.map(item => ({ x: item.year, y: parseFloat(item.national_average_star) })),
        color: "#64E320",
      },
      {
        type: "area",
        name: "Contract Star Rating",
        data: alignedContractStarRatingData,
        // data: contractStarRatingData.map(item => ({ x: item.Year, y: item.Overall_Star })),
        color: "#9747FF",
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 2,
        colors: ["#64E320", "#9747FF"],
      },
      xaxis: {
        type: "category",
        categories: categories,
      },
      yaxis: {
        min: 0,
        max: 5,
        tickAmount: 5,
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "right",
        shape: "rect",
        markers: {
          width: 39,
          height: 5,
          radius: 0,
        },
      },
      markers: {
        size: 4,
        colors: ["#64E320", "#9747FF"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
    },
  };

  return props.isLoading ? (
    <LoadingScreen />
  ) : (
    <Box sx={{ marginLeft: "20px", marginRight: "20px" }}>
      <Typography sx={{ marginTop: "25px", marginBottom: "20px" }}>
        CAI (Categorical Adjustment Index)
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              backgroundColor: "#FFF",
              width: "100%",
              border: "1px solid #E0E3E8",
              height: "152px",
            }}
          >
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // background: "#FFEFF5",
                marginRight: "20px",
                backgroundColor: "#EBF1FF",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                }}
              >
                Puerto Rico Only
              </Typography>

              <Typography
                sx={{
                  // marginTop: "10px",
                  marginLeft: "16px",
                }}
              >
                {props.data?.cai_details?.puerto_rico_only
                  ? "Yes"
                  : "No" ?? "NA"}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // background: "#FFEFF5",
                marginRight: "20px",
                // backgroundColor: "#FFEFF5",
                backgroundColor: "#EBF1FF",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                }}
              >
                Part C FAC{" "}
              </Typography>

              <Typography
                sx={{
                  // marginTop: "10px",
                  marginLeft: "16px",
                }}
              >
                {props.data.cai_details?.partc_fac ?? "NA"}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // background: "#FFEFF5",
                marginRight: "20px",
                // backgroundColor: "#FFEFF5",
                backgroundColor: "#EBF1FF",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                }}
              >
                Part D MA-PD FAC
              </Typography>

              <Typography
                sx={{
                  // marginTop: "10px",
                  marginLeft: "16px",
                }}
              >
                {props.data.cai_details?.partd_mapdfac ?? "NA"}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // background: "#FFEFF5",
                marginRight: "20px",
                // backgroundColor: "#FFEFF5",
                backgroundColor: "#EBF1FF",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                }}
              >
                Part D PDP FAC
              </Typography>

              <Typography
                sx={{
                  // marginTop: "10px",
                  marginLeft: "16px",
                }}
              >
                {props.data.cai_details?.partd_pdpfac ?? "NA"}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // background: "#FFEFF5",
                marginRight: "20px",
                // backgroundColor: "#FFEFF5",
                backgroundColor: "#EBF1FF",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                }}
              >
                Overall FAC
              </Typography>

              <Typography
                sx={{
                  // marginTop: "10px",
                  marginLeft: "16px",
                }}
              >
                {props.data.cai_details?.overall_fac ?? "NA"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography sx={{ marginTop: "25px", marginBottom: "20px" }}>
        Disentrollment Reasons
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              backgroundColor: "#FFF",
              width: "100%",
              border: "1px solid #E0E3E8",
              height: "auto",
            }}
          >
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                // alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // background: "#FFEFF5",
                // justifyContent: "center",
                marginRight: "20px",
                backgroundColor: "#FFEFF5",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  // marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                  textAlign: "left",
                }}
              >
                Problems Getting the Plan to Provide and Pay for Needed Care
              </Typography>

              <Typography sx={{ textAlign: "left" }}>
                {/* {console.log(
                  "props.data.disenrollment_details[0].disenrollment_value",
                  props.data.disenrollment_details[0].disenrollment_value
                )} */}
                {/* {props.data?.disenrollment_details[0].disenrollment_value} */}
                {props.data.contract_data &&
                  ((
                    props.data.disenrollment_details?.[0]?.disenrollment_value *
                    100
                  ).toFixed(2) ??
                    "NA")}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // alignItems: "center",
                // background: "#FFEFF5",
                // justifyContent: "center",
                marginRight: "20px",
                backgroundColor: "#FFEFF5",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  // marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                  textAlign: "left",
                }}
              >
                Problems with Coverage of Doctors and Hospitals
              </Typography>

              <Typography
                sx={{
                  // marginTop: "10px",
                  // marginLeft: "16px",
                  // marginTop: "13px",
                  marginTop: "8px",
                  // width: "100%", // Set width to 100%
                  textAlign: "left",
                }}
              >
                {props.data.contract_data &&
                  ((
                    props.data.disenrollment_details?.[1]?.disenrollment_value *
                    100
                  ).toFixed(2) ??
                    "NA")}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // alignItems: "center",
                // background: "#FFEFF5",
                // justifyContent: "center",
                marginRight: "20px",
                backgroundColor: "#FFEFF5",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  // marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                  textAlign: "left",
                }}
              >
                Financial Reasons for Disenrollment
              </Typography>

              <Typography
                sx={{
                  marginTop: "10px",
                  // marginLeft: "16px",
                  textAlign: "left",
                }}
              >
                {props.data.contract_data &&
                  ((
                    props.data.disenrollment_details?.[2]?.disenrollment_value *
                    100
                  ).toFixed(2) ??
                    "NA")}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // alignItems: "center",
                // background: "#FFEFF5",
                // justifyContent: "center",
                marginRight: "20px",
                backgroundColor: "#FFEFF5",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  // marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                  textAlign: "left",
                }}
              >
                Problems with Prescription Drug Benefits and Coverage
              </Typography>

              <Typography
                sx={{
                  // marginTop: "10px",
                  // marginLeft: "16px",
                  textAlign: "left",
                }}
              >
                {props.data.contract_data &&
                  ((
                    props.data.disenrollment_details?.[3]?.disenrollment_value *
                    100
                  ).toFixed(2) ??
                    "NA")}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                height: "auto",
                width: "100%",
                padding: "24px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                borderRadius: "4px",
                // alignItems: "center",
                // background: "#FFEFF5",
                // justifyContent: "center",
                marginRight: "20px",
                backgroundColor: "#FFEFF5",
                marginBottom: "24px",
                marginLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  // marginLeft: "16px",
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14px",
                  textAlign: "left",
                }}
              >
                Problems Getting Information and Help from the Plan
              </Typography>

              <Typography
                sx={{
                  // marginTop: "10px",
                  // marginLeft: "16px",
                  textAlign: "left",
                }}
              >
                {props.data.contract_data &&
                  ((
                    props.data.disenrollment_details?.[4]?.disenrollment_value *
                    100
                  ).toFixed(2) ??
                    "NA")}
                %
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography sx={{ marginTop: "25px", marginBottom: "18px" }}>
        Historical Contract Rating
      </Typography>
      <Paper
        elevation={3}
        style={{ padding: "10px", maxWidth: "100%", margin: "0 auto" }}
      >
        <ReactApexChart
          options={obj.options}
          series={obj.series}
          type="line"
          height={250}
        />
      </Paper>
    </Box>
  );
};

export default Card;
