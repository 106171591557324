import React from "react";

import Details from "../Components/Details";
import Card from "../Components/Cards";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { Typography, Button, Rating, Box, Stack, Grid } from "@mui/material";
import { Select } from "antd";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";

import {
  setYear,
  setParentOrganization,
  setResponseData,
  setDetailsDashdata,
} from "../../../../Redux/features/StarSlice";

import LoadingScreen from "../../../../Billing/Pages/LoadingScreen/LoadingScreen";

import totalEntroll from "../../../assets/totalEntroll.svg";
import StateCovered from "../../../assets/StateCovered.svg";
import measureDetails from "../../../assets/measureDetails.svg";
import displayMeasure from "../../../assets/displayMeasure.svg";
import enrollment from "../../../assets/enrollment.svg";
import { lab } from "d3";

const DetailsDashboard = (props) => {
  const encryptionKey = "SecureKey";
  const decryptionKey = "SecureKey";

  const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value, encryptionKey).toString();
  };

  // Function to decrypt the value
  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, decryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const localYear = localStorage.getItem("year");
  const firstYear = decryptValue(localYear);

  const cont = encryptValue("C01");
  // Cookies.set("parent", firstParentt);
  localStorage.setItem("cont", cont);

  const [selectedParent, setSelectedparent] = useState();
  const [selectedYears, setselectedYears] = useState(firstYear);

  const localcontractId = localStorage.getItem("contract");
  const contractId = decryptValue(localcontractId);

  const [selectedContractId, setSelectedContractId] = useState(contractId);
  const contractIdRedux = useSelector(
    (state) => state.startDashBoard.contractId
  );
  // console.log("CID", selectedContractId,contractIdRedux)

  useEffect(() => {
    localStorage.setItem("selectedContractId", contractIdRedux);
  }, [contractIdRedux]);

  const [yearList, setYearList] = useState([]);
  // const [selecedContractId, setSelectedContractId] = useState(contractId);

  // const st = useSelector(
  //   (state) => state.startDashBoard.responseDashData.distinct_contract_info
  // );
  // console.log("state", st);
  const [contractNames, setContractNames] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [OverallStar, setOverallStar] = useState();

  const dispatch = useDispatch();

  // to get the selected contractId

  // const selectedContract =
  //   contractNames?.find(
  //     (contract) => contract.contract_id === selectedContractId
  //   ) ?? [];

  // contract name from previos page
  const [defaultContractName, setdefaultContractName] = useState();

  // localStorage.setItem("defaultContract", defaultContractName);
  // let defaultContract = localStorage.getItem("defaultContract") ?? "";

  // const defaultYear = localStorage.getItem("year");

  const topRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("selectedParent", selectedParent);
        // console.log(selectedYears);
        // const storedEncryptedValue = Cookies.get("parent");
        const storedParent = localStorage.getItem("parent");
        const decryptedValue = decryptValue(storedParent);

        const storedYear = localStorage.getItem("year");
        const decryptedYearValue = decryptValue(storedYear);

        // console.log(decryptedValue, "parent");
        // console.log(decryptedYearValue, "yeaaaaaaaaaa");
        const contractInfo = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/user_dashboard_details`,
          { year: decryptedYearValue, parent_organization: decryptedValue }
        );

        const st = contractInfo.data.distinct_contract_info;

        setContractNames(st);

        const selectedContract =
          st?.find((contract) => contract.contract_id === selectedContractId) ??
          [];

        setdefaultContractName(
          selectedContract
            ? capitalizeFirstLetter(selectedContract.contract_name)
            : ""
        );

        // setResData(contractInfo.data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView();
    }

    const fetchData = async () => {
      try {
        setIsLoading(true);
        // console.log(selectedParent);
        const storedParent = localStorage.getItem("parent");
        const decryptedValue = decryptValue(storedParent);

        const storedYear = localStorage.getItem("year");
        const decryptedYearValue = decryptValue(storedYear);

        const contDetails = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/view_contract_detail`,
          {
            year: decryptedYearValue,
            parent_organization: decryptedValue,
            contract_id: selectedContractId,
          }
        );
        // console.log("contract deatials Dashboard", contDetails.data);

        dispatch(setDetailsDashdata(contDetails.data));
        setData(contDetails.data);

        const dataForYear = contDetails.data?.map_overall_star_rating?.find(
          (item) => item.Year === selectedYears
        );

        const overallStarForYear = dataForYear ? dataForYear.Overall_Star : 0;

        setOverallStar(overallStarForYear);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    // console.log("selectedContractId", selectedContractId)

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const yearArray = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/get_all_years`
        );

        setYearList(yearArray.data.years);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const onYearHandleChange = async (value) => {
    const storedParent = localStorage.getItem("parent");
    const decryptedValue = decryptValue(storedParent);

    const storedYear = localStorage.getItem("year");
    const decryptedYearValue = decryptValue(storedYear);

    try {
      setIsLoading(true);
      setselectedYears(value);
      // dispatch(setYear(value));
      const firstYear = encryptValue(value);
      // Cookies.set("parent", firstParentt);
      localStorage.setItem("year", firstYear);

      const contDetails = await axios.post(
        `${process.env.REACT_APP_MEDICAL_ANALYATICS}/get_contract_id_name_wrt_year`,
        {
          year: value,
          parent_organization: decryptedValue,
        }
      );

      // console.log("yearChangeROute:", contDetails.data);

      setContractNames(contDetails.data);

      const dataForYear = contDetails.data?.map_overall_star_rating?.find(
        (item) => item.Year === value
      );

      const overallStarForYear = dataForYear ? dataForYear.Overall_Star : 0;

      setOverallStar(overallStarForYear);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }

    try {
      // console.log(selectedYears, selectedParent, selectedContractId);
      const contDetails = await axios.post(
        `${process.env.REACT_APP_MEDICAL_ANALYATICS}/view_contract_detail`,
        {
          year: value,
          parent_organization: decryptedValue,
          contract_id: selectedContractId,
        }
      );
      // console.log("onYearchange", contDetails.data);
      setData(contDetails.data);

      const dataForYear = contDetails.data?.map_overall_star_rating?.find(
        (item) => item.Year === selectedYears
      );

      const overallStarForYear = dataForYear ? dataForYear.Overall_Star : 0;

      setOverallStar(overallStarForYear);
      // console.log("overallStarForYearoverallStarForYear", overallStarForYear);
      // setYears(imagesResponse.data.years);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onHandleContractChange = async (value, option) => {
    const label = option ? option.label : "";

    setSelectedContractId(value);
    // console.log(label, "22222");

    setdefaultContractName(label);
    const contract = encryptValue(value);
    // Cookies.set("parent", firstParentt);
    localStorage.setItem("contract", contract);

    try {
      setIsLoading(true);
      // console.log(year);
      // console.log(
      //   "handle Dropdown change",
      //   selectedYears,
      //   selectedParent,
      //   value
      // );
      const storedParent = localStorage.getItem("parent");
      const decryptedValue = decryptValue(storedParent);

      const storedYear = localStorage.getItem("year");
      const decryptedYearValue = decryptValue(storedYear);

      const contDetails = await axios.post(
        `${process.env.REACT_APP_MEDICAL_ANALYATICS}/view_contract_detail`,
        {
          year: decryptedYearValue,
          parent_organization: decryptedValue,
          contract_id: value,
        }
      );
      // console.log("handle contract change return data", contDetails.data);

      // console.log("Response data:", contDetails.data);
      setData(contDetails.data);

      const dataForYear = contDetails.data?.map_overall_star_rating?.find(
        (item) => item.Year === selectedYears
      );

      const overallStarForYear = dataForYear ? dataForYear.Overall_Star : 0;

      setOverallStar(overallStarForYear);
      // console.log("overallStarForYearoverallStarForYear", overallStarForYear);
      // setYears(imagesResponse.data.years);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  function toCamelCase(str) {
    return str.toLowerCase().replace(/\b\w/g, function (word) {
      return word.toUpperCase();
    });
  }
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.replace(/\b\w/g, (char) => char.toUpperCase());
    }
    return "";
  }

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Grid container ref={topRef}>
      <Grid item xs={12}>
        <Stack direction="row" p={"16px"}>
          <Box>
            <Stack spacing={2} sx={{ marginBottom: "16px" }}>
              <Typography>Contract Details</Typography>
              <Stack direction="row">
                <Box
                  sx={{
                    // marginTop: "17px",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <Select
                    showSearch
                    style={{
                      width: "273px",
                      height: "48px",
                      marginLeft: "-16px",
                      padding: "8px 8px 8px 16px",
                    }}
                    placeholder="Contract Name"
                    onChange={onHandleContractChange}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={
                      contractNames?.map((contract) => ({
                        value: contract.contract_id,
                        label: toCamelCase(contract.contract_name),
                      })) ?? []
                    }
                    value={defaultContractName}
                    // defaultValue={defaultContractName}
                  />

                  <Select
                    showSearch
                    style={{
                      width: 222,
                      height: "48px",
                      padding: "8px 8px 8px 16px",
                      marginRight: "9px",
                    }}
                    value={selectedYears}
                    placeholder="Select Year"
                    onChange={onYearHandleChange}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionB?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionA?.label ?? "").toLowerCase())
                    }
                    options={
                      yearList?.map((year) => ({
                        value: year,
                        label: year,
                      })) ?? []
                    }
                    // defaultValue={defaultYear}
                  />
                </Box>
              </Stack>
            </Stack>

            <Grid container spacing={{ xs: 2, sm: 4, md: 6 }}>
              {/* Manually add your 6 boxes here */}
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    width: "100%",
                    height: "160px",
                    flexShrink: 0,
                    background: "#FFF",
                    boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
                    border: "1px solid #E0E3E8",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center", // Horizontally center the inner Box
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* Box content */}
                  <Box
                    sx={{
                      // marginLeft: "9px",
                      marginTop: "8px",
                      width: "90%",
                      height: "44px",
                      flexShrink: 0,
                      backgroundColor: "#FFFEE9",
                      borderRadius: "4px",
                      // alignContent: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Rating value={OverallStar ?? 0} precision={0.5} readOnly />
                  </Box>
                  <Box
                    sx={{
                      // marginLeft: "54px",
                      marginTop: "24px",
                      width: "31px",
                      height: "30px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#211A1D",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "48px",
                        textAlign: "center",
                      }}
                    >
                      {OverallStar}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "16px",
                      marginBottom: "24px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#211A1D",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      Overall Rating
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {/* Add more boxes in a similar manner */}
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    width: "100%",
                    height: "160px",
                    flexShrink: 0,
                    background: "#FFF",
                    boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
                    border: "1px solid #E0E3E8",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center", // Horizontally center the inner Box
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* Box content */}
                  <Box
                    sx={{
                      marginTop: "8px",
                      width: "90%",
                      height: "44px",
                      flexShrink: 0,
                      backgroundColor: "#E1F1FF",
                      borderRadius: "4px",
                      alignContent: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={totalEntroll} alt="totalEntroll" />
                  </Box>
                  <Box
                    sx={{
                      marginTop: "24px",
                      width: "31px",
                      height: "30px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#211A1D",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "48px",
                        textAlign: "center",
                      }}
                    >
                      {data.enrollment_count !== null
                        ? data.enrollment_count?.toLocaleString("en-US")
                        : "NA"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "16px",
                      marginBottom: "24px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#211A1D",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      Total Enrollments
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    width: "100%",
                    height: "160px",
                    flexShrink: 0,
                    background: "#FFF",
                    boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
                    border: "1px solid #E0E3E8",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center", // Horizontally center the inner Box
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* Box content */}
                  <Box
                    sx={{
                      marginTop: "8px",
                      width: "90%",
                      height: "44px",
                      flexShrink: 0,
                      backgroundColor: "rgba(225, 255, 228, 1)",
                      borderRadius: "4px",
                      alignContent: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={StateCovered} alt="StateCovered" />
                  </Box>
                  <Box
                    sx={{
                      marginTop: "24px",
                      width: "31px",
                      height: "30px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#211A1D",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "48px",
                        textAlign: "center",
                      }}
                    >
                      {data.distinct_state_code_count}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "16px",
                      marginBottom: "24px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#211A1D",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      States Covered
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    width: "100%",
                    height: "160px",
                    flexShrink: 0,
                    background: "#FFF",
                    boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
                    border: "1px solid #E0E3E8",
                    borderRadius: "8px",
                  }}
                >
                  {/* Box content */}
                  <Box
                    sx={{
                      marginLeft: "9px",
                      marginTop: "8px",
                      // width: "144px",
                      // height: "44px",
                      flexShrink: 0,
                      // backgroundColor: "rgba(225, 255, 228, 1)",
                      marginLeft: "",
                      borderRadius: "4px",
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={measureDetails} alt="measureDetails" />
                  </Box>

                  <Box
                    sx={{
                      marginTop: "18px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1A1B21",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        lineHeight: "14px",
                      }}
                    >
                      Measure Details
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "16px",
                      marginBottom: "20px",
                      // marginRight: "33px",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <Link
                      to={`/hip-operations/measure-details`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          display: "flex",
                          gap: "10px",
                          width: "93px",
                          height: "28px",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          textTransform: "none",
                        }}
                        variant="contained"
                      >
                        View More
                      </Button>
                    </Link>

                    {/* <Link
                        to={`/hip-operations/measure-details/${selectedContractId}`}
                        style={{ textDecoration: 'none' }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#5A03EE",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                              display: "flex",
                              gap: "10px",
                              width: "93px",
                              height: "28px",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              textTransform: "none",
                            }}
                            
                          >
                            View More
                          </Button>  */}
                    {/* </Link> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    width: "100%",
                    height: "160px",
                    flexShrink: 0,
                    background: "#FFF",
                    boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
                    border: "1px solid #E0E3E8",
                    borderRadius: "8px",
                  }}
                >
                  {/* Box content */}
                  <Box
                    sx={{
                      marginLeft: "9px",
                      marginTop: "8px",
                      // width: "144px",
                      // height: "44px",
                      flexShrink: 0,
                      // backgroundColor: "rgba(225, 255, 228, 1)",
                      marginLeft: "",
                      borderRadius: "4px",
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={displayMeasure} alt="displayMeasure" />
                  </Box>

                  <Box
                    sx={{
                      marginTop: "18px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1A1B21",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        lineHeight: "14px",
                      }}
                    >
                      Display Measures
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "16px",
                      marginBottom: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <Button
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        gap: "10px",
                        width: "93px",
                        height: "28px",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        textTransform: "none",
                      }}
                      variant="contained"
                    >
                      View More
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    width: "100%",
                    height: "160px",
                    flexShrink: 0,
                    background: "#FFF",
                    boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
                    border: "1px solid #E0E3E8",
                    borderRadius: "8px",
                  }}
                >
                  {/* Box content */}
                  <Box
                    sx={{
                      marginLeft: "9px",
                      marginTop: "8px",
                      // width: "144px",
                      // height: "44px",
                      flexShrink: 0,
                      // backgroundColor: "rgba(225, 255, 228, 1)",
                      marginLeft: "",
                      borderRadius: "4px",
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={enrollment} alt="enrollment" />
                  </Box>

                  <Box
                    sx={{
                      marginTop: "18px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1A1B21",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        lineHeight: "14px",
                      }}
                    >
                      Enrollment
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "16px",
                      marginBottom: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <Button
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        gap: "10px",
                        width: "93px",
                        height: "28px",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        textTransform: "none",
                      }}
                      variant="contained"
                    >
                      View More
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ flexGrow: 1, marginRight: "24px" }}>
            <Details
              contractId={selectedContractId}
              data={data}
              isLoading={isLoading}
            />
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12} sx={{ paddingBottom: "30px" }}>
        <Stack>
          <Box>
            <Card data={data} isLoading={isLoading} />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DetailsDashboard;
