import React, { useState, useEffect } from 'react';
import AlertIconError from "../../assets/Icons/Alert_Icon_Error.svg";
import AlertIconSuccess from "../../assets/Icons/Alert_Icon_Success.svg";
import AlertIconInformation from "../../assets/Icons/Alert_Icon_Information.svg";
import AlertIconWarning from "../../assets/Icons/Alert_Icon_Warning.svg";
import CloseIcon from "../../assets/Icons/Close.svg";
import "./Toast.css";
import { setToastAlert, toastAlert } from '../../Redux/features/AlertsSlice';
import { useDispatch, useSelector } from 'react-redux';

const toasts = [
    {
        type: 'success',
        title: 'Success',
        message: 'Welcome back!',
        icon: AlertIconSuccess,
        backgroundColor: "#F6FFF9",
        borderColor: "#48C1B5"
    },
    {
        type: 'warning',
        title: 'Warning',
        message: 'This action cannot be undone.',
        icon: AlertIconWarning,
        backgroundColor: "#FFF8EC",
        borderColor: "#FFBC00"
    },
    {
        type: 'information',
        title: 'Information',
        message: 'Please read the instructions carefully.',
        icon: AlertIconInformation,
        backgroundColor: "#F5F9FF",
        borderColor: "#0075FF"
    },
    {
        type: 'error',
        title: 'Error',
        message: 'Incorrect Login ID or Password',
        icon: AlertIconError,
        backgroundColor: "#FFF5F3",
        borderColor: "#FF4D00"
    },
];

export const Toast = () => {

    const [showToast, setShowToast] = useState(false);
    const [toastData, setToastdata] = useState({})
    const toast = useSelector(toastAlert)
    const dispatch = useDispatch()

    useEffect(() => {
        if (toast.isactive) {
            setShowToast(toast.isactive)
            if (toast.type !== undefined) {
                const values = toasts.find(item => item.type === toast.type);
                setToastdata(values)
            }
            setTimeout(() => {
                setShowToast(false);
            }, toast.exp === undefined ? 10000 : toast.exp);
        }

    }, [toast]);

    const handleClose = () => {
        setShowToast(false);
        dispatch(setToastAlert({
            isactive: false,
            message: "",
            type: "info",
            exp: 10000
        }))
    };

    if (!showToast || !toastData) return null;

    return (
        <div className='toast_container'>
            <div
                className={`toast ${showToast ? 'slide-in' : 'slide-out'}`}
                style={{
                    borderColor: toastData.borderColor,
                    backgroundColor: toastData.backgroundColor
                }}>
                <img src={toastData.icon} alt="Alert Icon" className="toast_alert_icon" />
                <div className='toast_text_container'>
                    <div className='toast_title_container'>
                        <div className='toast_title'>
                            {toastData.title}
                        </div>
                        <div className='toast_message'>
                            {toast.message === '' ? "No Message" : toast.message}
                        </div>
                    </div>
                </div>
                <img src={CloseIcon} alt="Close Icon" className="toast_close_button" onClick={handleClose} />
            </div>
        </div>
    )
}
