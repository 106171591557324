import React from "react";
import PropTypes from "prop-types";

const Message = ({ type, content }) => {
  return (
    <div className={`message ${type}-message`}>
      <p>{content}</p>
    </div>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(["user", "bot"]).isRequired,
  content: PropTypes.string.isRequired,
};

export default Message;
