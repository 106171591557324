import React from "react";
import "./Summary.css";
import { useSelector } from "react-redux";

const Summary = () => {
  const medical_data = JSON.parse(sessionStorage.getItem("medical_data"));
  const theme = useSelector((state) => state.messages.Theme) || "";

  return (
    <div className={`${theme}summary-container`}>
      <table className={`${theme}summary-table`}>
        <thead>
          <tr>
            <th colSpan={2}>
              <h2>Summary</h2>
              <hr />
            </th>
          </tr>
        </thead>
        <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
          <tbody >
            {medical_data[0] === "Not enough data to create one-page summary." ?
              <tr>
                <td style={{ textAlign: "center", textJustify: "inter-word" }}>
                  Not enough data to show summary.
                </td>
              </tr> :
              <div>
                {medical_data.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <strong>{data.title} :</strong>
                    </td>
                    <td style={{ textAlign: "justify", textJustify: "inter-word" }}>
                      {data.summary}
                    </td>
                  </tr>
                ))}
              </div>
            }
          </tbody>
        </div>
      </table>
    </div>
  );
};

export default Summary;
