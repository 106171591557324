import { useEffect } from 'react';
import { openDatabase } from './IndexedDBUtility';

const DatabaseProvider = ({ children }) => {
  useEffect(() => {
    const initializeDatabase = async () => {
      try {
        const db = await openDatabase();
        // You can do additional setup or initialization here if needed
      } catch (error) {
        console.error(`Error opening IndexedDB: ${error}`);
      }
    };

    initializeDatabase();
  }, []);

  return children;
};

export default DatabaseProvider;