
import { useEffect, useState } from 'react';
import { Paper, Box, Typography } from "@mui/material";
import { Select } from "antd";
import ReactApexChart from "react-apexcharts";
const { Option } = Select;

function TrendingGraph(props) {

  const cutPointPlotData = props.measureData?.Cut_Points_Plot_Data || [];
  const distinctYears = new Set(cutPointPlotData.map(item => item.star_year));

  const [clickedGraph, setClickedGraph] = useState("Contract Measure Score Trend");

  const allMeasures = props.measureData?.Measures || []
  const sortedMeasures = allMeasures.slice().sort((a, b) => {
    if (a.star_year < b.star_year) return -1;
    if (a.star_year > b.star_year) return 1;
    return 0;
});

// console.log("SM", props.measureData)

const [selectedOption, setSelectedOption] = useState(`Last ${sortedMeasures.length} Years`);
const [selectedOptionForCutPoint, setSelectedOptionForCutPoint] = useState(`Last ${distinctYears.size} Years`)

// console.log("selectedOptionForCutPoint", selectedOptionForCutPoint)
// console.log("sortedMeasures", sortedMeasures)

useEffect(()=>{
  setSelectedOption(`Last ${sortedMeasures.length} Years`)
  setSelectedOptionForCutPoint(`Last ${distinctYears.size} Years`)
}, [sortedMeasures.length, distinctYears.size])

function getYearData() {
  const regex = /Last (\d+) Years/;
  // console.log("selectedOption", selectedOption)
  const matches = selectedOption.match(regex);
  if (matches) {
    const yearCount = parseInt(matches[1]);
    return yearCount;
  }
  return 0;
}

function getYearDataForCutPointTrend() {
  const regex = /Last (\d+) Years/;
  const matches = selectedOptionForCutPoint.match(regex);
  if (matches) {
    const yearCount = parseInt(matches[1]);
    return yearCount;
  }
  return 0;
}

const ScoreData = [];
const ScoreCategories = [];
// const selectedOptionValueforScore = selectedOption === 'Last 4 Years' ? 4 : 2;
const selectedOptionValueforScore = getYearData();

ScoreData.length = 0;
ScoreCategories.length = 0;

for (let i = sortedMeasures.length - selectedOptionValueforScore; i < sortedMeasures.length; i++) {
  const measure = sortedMeasures[i];
  if (measure) {
      const score = (measure.measure_score * 100).toFixed(2);
      ScoreData.push(parseFloat(score));
      ScoreCategories.push(measure.star_year);
  }
}

const StarData = [];
const StarCategories = [];

for (let i = sortedMeasures.length - selectedOptionValueforScore; i < sortedMeasures.length; i++) {
  const measure = sortedMeasures[i];
  if (measure) {
    StarData.push(measure.measure_star_value);
    StarCategories.push(measure.star_year);
  }
}

  // console.log("Measure Data", props.measureData.Cut_Points_Plot_Data)
  // console.log("Sorted Measure Data", sortedMeasures)
  // console.log("ScoreData", ScoreData, ScoreCategories)
  // console.log("StarData", StarData, StarCategories)

  const [activeChart, setActiveChart] = useState('Contract Measure Score Trend');
  

  const handleChartChange = (chartName) => {
    setActiveChart(chartName);
  }

  const handleSelectChange = (value) => {
    setSelectedOption(value);
}

const handleSelectChangeforCutPoint = (value) => {
  setSelectedOptionForCutPoint(value);
}

  const measureScoreObj = {
    series: [{
      name:'Contract Measure Score',
      data: ScoreData
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false
        },
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '15%',
          distributed: true,
          borderRadius: 10,
        }
      },
      colors: ['#7A95FF'],
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: ScoreCategories,
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 10,
        labels: {
          formatter: function (value) {
            return value + '%';
          },
          style: {
            fontSize: '12px'
          }
        },
        ticks: [35, 65, 95]
      },
    }
  };

  const measureStarObj = {
    series: [
      {
        name: "Contract Measure Star Trend",
        data: StarData,
        color: "#9747FF",
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 2,
        colors: ["#9747FF"],
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#9747FF"],
          inverseColors: true,
          opacityFrom: 0.6,
          opacityTo: 0.3,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        type: "category",
        categories: StarCategories,
      },
      yaxis: {
        min: 0,
        max: 5,
        tickAmount: 5,
      },
      legend: {
        show: true,
        position: "top",
        shape: "rect",
        markers: {
          width: 20,
          height: 12,
          strokeColor: "#fff",
          fillColors: ["#9747FF"],
        },
      },
      markers: {
        size: 4,
        colors: ["#9747FF"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        }
      },

    },
  };

  // console.log("prop dtaa", props.measureData)

  const oneStar = [];
  const twoStar = [];
  const threeStar = [];
  const fourStar = [];
  const fiveStar = [];
  const years = [];

  const cutOffTypeOneStar = [];
  const cutOffTypeTwoStar = [];
  const cutOffTypeThreeStar = [];
  const cutOffTypeFourStar = [];
  const cutOffTypeFiveStar = [];
 

  const filteredDataForOneStar = cutPointPlotData.filter(item => item.star_value === 1);
  filteredDataForOneStar.sort((a, b) => a.star_year.localeCompare(b.star_year));
  // console.log("filteredDataForOneStar", filteredDataForOneStar)

  const filteredDataForTwoStar = cutPointPlotData.filter(item => item.star_value === 2);
  filteredDataForTwoStar.sort((a, b) => a.star_year.localeCompare(b.star_year));
  // console.log("filteredDataForTwoStar", filteredDataForTwoStar)

  const filteredDataForThreeStar = cutPointPlotData.filter(item => item.star_value === 3);
  filteredDataForThreeStar.sort((a, b) => a.star_year.localeCompare(b.star_year));
  // console.log("filteredDataForThreeStar", filteredDataForThreeStar)

  const filteredDataForFourStar = cutPointPlotData.filter(item => item.star_value === 4);
  filteredDataForFourStar.sort((a, b) => a.star_year.localeCompare(b.star_year));
  // console.log("filteredDataForFourStar", filteredDataForFourStar)

  const filteredDataForFiveStar = cutPointPlotData.filter(item => item.star_value === 5);
  filteredDataForFiveStar.sort((a, b) => a.star_year.localeCompare(b.star_year));
  // console.log("filteredDataForFiveStar", filteredDataForFiveStar)


const selectedOptionValue = getYearDataForCutPointTrend();
// console.log("selectedOptionValue", selectedOptionValue)
// console.log("distinctYears", distinctYears)

for (let i = distinctYears.size - selectedOptionValue; i < distinctYears.size; i++) {
    if (filteredDataForOneStar[i]?.star_value === 1) {
        oneStar.push(Number(filteredDataForOneStar[i]?.upper_cutoff).toFixed(2));
        cutOffTypeOneStar.push(filteredDataForOneStar[i]?.cut_off_type);
        years.push(filteredDataForOneStar[i]?.star_year);
    }
    if (filteredDataForTwoStar[i]?.star_value === 2) {
        twoStar.push(Number(filteredDataForTwoStar[i]?.upper_cutoff).toFixed(2));
        cutOffTypeTwoStar.push(filteredDataForTwoStar[i]?.cut_off_type);
    }
    if (filteredDataForThreeStar[i]?.star_value === 3) {
        threeStar.push(Number(filteredDataForThreeStar[i]?.upper_cutoff).toFixed(2));
        cutOffTypeThreeStar.push(filteredDataForThreeStar[i]?.cut_off_type);
    }
    if (filteredDataForFourStar[i]?.star_value === 4) {
        fourStar.push(Number(filteredDataForFourStar[i]?.upper_cutoff).toFixed(2));
        cutOffTypeFourStar.push(filteredDataForFourStar[i]?.cut_off_type);
    }
    if (filteredDataForFiveStar[i]?.star_value === 5) {
        fiveStar.push(Number(filteredDataForFiveStar[i]?.upper_cutoff).toFixed(2));
        cutOffTypeFiveStar.push(filteredDataForFiveStar[i]?.cut_off_type);
    }
}
// console.log("years", years)
// console.log("oneStar", oneStar)
// console.log("cutOffTypeOneStar", cutOffTypeOneStar)

function capitalizeFirstLetter(sentence) {
  return sentence.replace(/\b\w/g, function(char) {
      return char.toUpperCase();
  });
}

const cutPointTrendObj = {
    series: [
        {
            name: "1 star",
            data: oneStar,
            color: "#9747FF",
            marker: {
                colors: ["#9747FF"],
            }
        },
        {
            name: "2 star",
            data: twoStar,
            color: "#CE1010",
            marker: {
                colors: ["#CE1010"],
            }
        },
        {
            name: "3 star",
            data: threeStar,
            color: "#097F54",
            marker: {
                colors: ["#097F54"],
            }
        },
        {
            name: "4 star",
            data: fourStar,
            color: "#162A46",
            marker: {
                colors: ["#162A46"],
            }
        },
        {
            name: "5 star",
            data: fiveStar,
            color: "#FF7F37",
            marker: {
                colors: ["#FF7F37"],
            }
        },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 2,
        colors: ["#9747FF"],
      },
      fill: {
        type: "solid",
      },
      xaxis: {
        type: "category",
        categories: years,
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 10,
        labels: {
          formatter: function (value) {
            return value + '%';
          },
          style: {
            fontSize: '12px'
          }
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: 'right',
        shape: "rect",
        markers: {
          width: 20,
          height: 12,
          strokeColor: "#fff",
          fillColors: ["#9747FF"],
        },
      },
      markers: {
        size: 4,
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
            const upperCutoffOneStar = oneStar[dataPointIndex];
            const upperCutoffTwoStar = twoStar[dataPointIndex];
            const upperCutoffThreeStar = threeStar[dataPointIndex];
            const upperCutoffFourStar = fourStar[dataPointIndex];
            const upperCutoffFiveStar = fiveStar[dataPointIndex];
            const cutOffTypeOne = cutOffTypeOneStar[dataPointIndex];
            const cutOffTypeTwo = cutOffTypeTwoStar[dataPointIndex];
            const cutOffTypeThree = cutOffTypeThreeStar[dataPointIndex];
            const cutOffTypeFour = cutOffTypeFourStar[dataPointIndex];
            const cutOffTypeFive = cutOffTypeFiveStar[dataPointIndex];

            const year = years[dataPointIndex];

            return `<div class="tooltip">
                    <table>
                        <thead>
                            <tr style="background-color: #D3D3D3";>
                                <th colspan="3">Year: ${year}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="background-color: ${series[seriesIndex].color};"><td colspan="3"></td></tr>
                            <tr><td>1 Star:</td><td>${upperCutoffOneStar}%</td><td>${capitalizeFirstLetter(cutOffTypeOne)}</td></tr>
                            <tr><td>2 Star:</td><td>${upperCutoffTwoStar}%</td><td>${capitalizeFirstLetter(cutOffTypeTwo)}</td></tr>
                            <tr><td>3 Star:</td><td>${upperCutoffThreeStar}%</td><td>${capitalizeFirstLetter(cutOffTypeThree)}</td></tr>
                            <tr><td>4 Star:</td><td>${upperCutoffFourStar}%</td><td>${capitalizeFirstLetter(cutOffTypeFour)}</td></tr>
                            <tr><td>5 Star:</td><td>${upperCutoffFiveStar}%</td><td>${capitalizeFirstLetter(cutOffTypeFive)}</td></tr>
                        </tbody>
                    </table>
                </div>`;
        }
    }
    },
  };

  return (
    <Box>
        <Box sx={{maxWidth: "100%", display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Typography
                sx={{
                    color:'#000',
                    fontFamily:'Inter',
                    fontSize: '16px',
                    fontStyle:'normal',
                    fontWeight:'400',
                    lineHeight:'24px'
                }}
                
            >Historical Trend</Typography>
            {
              clickedGraph && clickedGraph === "Contract Measure Score Trend" && (
                <Select defaultValue="Historical Data" style={{ width: 120 }} onChange={handleSelectChange}>
                  {sortedMeasures.length > 10 && <Option value={`Last ${sortedMeasures.length} Years`}>{`Last ${sortedMeasures.length} Years`}</Option>}
                  {sortedMeasures.length > 5 && sortedMeasures.length <= 10 && <Option value={`Last ${sortedMeasures.length} Years`}>{`Last ${sortedMeasures.length} Years`}</Option>}
                  <Option value={`Last 5 Years`}>Last 5 Years</Option>
                </Select>
              )
            }
            {
              clickedGraph && clickedGraph === "Cut Point Trend" && (
                <Select defaultValue="Historical Data" style={{ width: 120 }} onChange={handleSelectChangeforCutPoint}>
                  {distinctYears.size > 10 && <Option value={`Last ${distinctYears.size} Years`}>{`Last ${distinctYears.size} Years`}</Option>}
                  {distinctYears.size > 5 && distinctYears.size <= 10 && <Option value={`Last ${distinctYears.size} Years`}>{`Last ${distinctYears.size} Years`}</Option>}
                  <Option value={`Last 5 Years`}>Last 5 Years</Option>
                </Select>
              )
            }
        </Box>
        <Paper
        elevation={3}
        style={{ padding: "10px", maxWidth: "100%", margin: "0 auto", marginTop:'15px', maxHeight:'474px', borderRadius:'4px', border: '1px solid #E0E3E8', background: '#FEFEFE', boxShadow: '0px 2px 8px 0px rgba(219, 219, 219, 0.40)' }}
        >
            <Box sx={{display:'flex', marginLeft:'24px', marginTop:'24px', gap:"24px",}}>
                <Typography
                    sx={{
                        color: activeChart === 'Contract Measure Score Trend' ? '#004BEE' : '#444',
                        fontFamily:'Inter',
                        fontSize: '14px',
                        fontStyle:'normal',
                        fontWeight:'400',
                        lineHeight:'14px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {handleChartChange('Contract Measure Score Trend')
                    setClickedGraph("Contract Measure Score Trend")
                  }
                  }
                >Contract Measure Score Trend</Typography>
                <Typography
                    sx={{
                        color: activeChart === 'Contract Measure Star Trend' ? '#004BEE' : '#444',
                        fontFamily:'Inter',
                        fontSize: '14px',
                        fontStyle:'normal',
                        fontWeight:'400',
                        lineHeight:'14px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {handleChartChange('Contract Measure Star Trend')
                    setClickedGraph("Contract Measure Score Trend")
                  }}
                >Contract Measure Star Trend</Typography>
                <Typography
                    sx={{
                        color: activeChart === 'Cut Point Trend' ? '#004BEE' : '#444',
                        fontFamily:'Inter',
                        fontSize: '14px',
                        fontStyle:'normal',
                        fontWeight:'400',
                        lineHeight:'14px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {handleChartChange('Cut Point Trend')
                    setClickedGraph("Cut Point Trend")
                  }}
                >Cut Point Trend</Typography>
            </Box>
            {/* {console.log("clicked state", clickedGraph)} */}
            {activeChart === 'Contract Measure Score Trend' && (
                <ReactApexChart
                    options={measureScoreObj.options}
                    series={measureScoreObj.series}
                    type="bar"
                    height={300}
                />
            )} 
            {activeChart === 'Contract Measure Star Trend' && (
                <ReactApexChart
                    options={measureStarObj.options}
                    series={measureStarObj.series}
                    type="area"
                    height={300}
                />
            )}
            {activeChart === 'Cut Point Trend' && (
                <ReactApexChart
                    options={cutPointTrendObj.options}
                    series={cutPointTrendObj.series}
                    type="line"
                    height={300}
                />
            )}
        </Paper>
    </Box>
  )
}

export default TrendingGraph;
