import React, { useState } from "react";
import "./Medications.css";
import { useSelector } from "react-redux";
const Medications = () => {
  const theme = useSelector((state) => state.messages.Theme) || ""
  const key_value_data = JSON.parse(sessionStorage.getItem("key_value_data"));

  // const medicationsList = key_value_data.Medications;

  const medicationsList = key_value_data?.Medications || key_value_data?.medications || [];

  const [selectedStatus, setSelectedStatus] = useState("All");

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const filteredMedications =
    selectedStatus === "All"
      ? medicationsList
      : medicationsList.filter(
        (medication) => medication[1][0] === selectedStatus
      );

  return (
    <div className={`${theme}medications-container`}>
      <table className={`${theme}medications-table`}>
        <thead>
          <tr>
            <th colSpan={2}>
              <h2>Medications</h2>
              <hr />
            </th>
          </tr>
        </thead>

        {filteredMedications.length === 0 ?
          <tr>
            <td style={{ textAlign: "center", textJustify: "inter-word" }}>
              Not enough data to show medications.
            </td>
          </tr> :
          <div className={`${theme}custom-scrollbar`} style={{ maxHeight: "400px", overflowY: "scroll" }}>
            <tbody>
              <tr>
                <th style={{ textAlign: "left" }}>Medication Name</th>
                <th style={{ textAlign: "left" }}>Status</th>
              </tr>
              <tr>
                <td></td>
                <td>
                  <select value={selectedStatus} onChange={handleStatusChange}>
                    <option value="All">All</option>
                    <option value="active">Active</option>
                    <option value="completed">Completed</option>
                  </select>
                </td>
              </tr>

              {filteredMedications.map((medication, index) => (
                <tr key={index}>
                  <td>{medication[0]}</td>

                  <td>
                    <span
                      style={{
                        display: "inline-block",
                        padding: "1px 5px",
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        backgroundColor:
                          medication[1][0] === "active" ? "#62c462" : "#ccc",
                        color: medication[1][0] === "active" ? "#fff" : "#333",
                      }}
                    >
                      {medication[1][0]}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </div>}
      </table>
    </div>
  );
};

export default Medications; 