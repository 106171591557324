import React from "react";
import "./CreateChatBot.css";
import { useState } from "react";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import searchIcon from "../../../../../assets/Icons/search-icon.png";
import fetchlinkIcon from "../../../../../assets/Icons/fetchlink.png";
import ReactLoading from "react-loading";
import { Link, useNavigate } from "react-router-dom";
import { setCreatedChatbot } from "../../../../../Redux/features/UrlMessageSlice";
import { useDispatch } from "react-redux";
import GetCookie from '../../../../../HIPCookies/getCookie';

export default function CreateChatBot() {
  const [linkList, setLinkList] = useState([]);
  const [url, setUrl] = useState("");
  const [ChatbotName, setChatbotName] = useState("");
  const [selectedLinks, setSelectedLinks] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [maxSelectedLinks] = useState(25);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user_Id = (JSON.parse(GetCookie('userInfo')) || {}).userId;

  const notifyTypeSomething = () => {
    toast.warning("Please Type Someting!", {
      position: toast.POSITION.TOP_RIGHT
    });
  };
  const notifyHttpsLink = () => {
    toast.error("Please enter a valid HTTPS URL!", {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      theme: "colored",
    });
  };
  const fetchLinks = async () => {
    if (!isValidHttpsUrl(url)) {
      notifyHttpsLink();
      return;
    }
    setIsLoading(true);

    try {
      setLinkList([]);
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/all_urls`,
        {
          url,
        }
      );
      if (response.data.all_urls) {
        setLinkList(response.data.all_urls);
        setIsLoading(false);
      } else {
        toast.error("Error in fetching links.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          theme: "colored",
        });
        return;
      }

    } catch (error) {
      setIsLoading(false);
      toast.error("Error fetching links! May be site is dynamic.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
    }
    finally {
      setIsLoading(false);
    }
  };

  const createChatBotHandler = async () => {
    if (selectedLinks.length === 0) {
      toast.error("Please Select links!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
      return;
    }
    if (ChatbotName.length === 0) {
      toast.error("Please type name of ChatBot!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
      return;
    }
    const all_urls = selectedLinks;
    const name = ChatbotName;

    setLoadingOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/selected_urls`,
        {
          url,
          all_urls: all_urls,
          name: name,
          userId: user_Id
        }
      );
      dispatch(setCreatedChatbot(ChatbotName));
      setChatbotName("");
      setSelectAllChecked(false);
      setUrl("");
      setLinkList([]);
      setLoadingOverlay(false);
      toast.success("ChatBot Created!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
      setLoadingOverlay(false);
      setTimeout(() => {
        navigationHandler();
      }, 1000);
    } catch (error) {
      setLoadingOverlay(false);
      console.error("Error fetching links:", error);
      toast.error("Error in creating chatbot!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
    }
  };

  const changeHandler = (e) => {
    setUrl(e.target.value);
  };
  const NamechangeHandler = (e) => {
    setChatbotName(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (url !== "") {
        if (isValidHttpsUrl(url)) {
          fetchLinks();
        } else {
          notifyHttpsLink();
        }
      } else {
        notifyTypeSomething();
      }
    }
  };

  const isValidHttpsUrl = (url) => {
    return url.startsWith("https://");
  };

  const handleCheckboxChange = (link) => {
    if (selectAllChecked) {
      setSelectedLinks([]);
      setSelectAllChecked(false);
    } else {
      if (selectedLinks.includes(link)) {
        setSelectedLinks(
          selectedLinks.filter((selectedLink) => selectedLink !== link)
        );
      } else {
        if (selectedLinks.length < maxSelectedLinks) {
          setSelectedLinks([...selectedLinks, link]);
        } else {
          // Display an error message when the limit is reached
          toast.error("You can select upto 25 URL’s for this Chatbot.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            theme: "colored",
          });
        }
      }
    }
  }

  const handleSelectAllChange = () => {
    if (!selectAllChecked) {
      if (linkList.length <= maxSelectedLinks) {
        setSelectedLinks([...linkList]);
        setSelectAllChecked(true);
      } else {
        // Display an error message when the limit is exceeded
        toast.error("You can select upto 25 URL’s for this Chatbot.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          theme: "colored",
        });
      }
    } else {
      setSelectedLinks([]);
      setSelectAllChecked(false);
    }
  }

  const navigationHandler = (event) => {
    navigate("../hip-connection-url");
  };

  return (
    <div className="chatbot_settings_outer_container">
      {loadingOverlay && (
        <div className="loading-overlay">
          <ReactLoading
            type={"spin"}
            color={"#fff"}
            height={"10%"}
            width={"10%"}
          />
        </div>
      )}
      <div style={{ marginLeft: "4vh" }}>
        <div className="chatbot_settings_title">
          <div>Chatbot Settings</div>
        </div>
        <div style={{ fontSize: "12px", fontWeight: "500", color: "#142952" }}>CHATBOT NAME</div>
        <input
          type="text"
          className="chatbot_name_input"
          value={ChatbotName}
          placeholder="Enter the name of chatbot"
          onChange={NamechangeHandler}
          style={{ paddingLeft: "2vh" }}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="text"
            className="create_chatbotbot_url_input"
            value={url}
            onChange={changeHandler}
            placeholder="Enter the URL to fetch required results"
            onKeyDown={handleKeyPress}
            style={{
              backgroundImage: `url(${searchIcon})`,
              backgroundPosition: "2vh 50%",
              backgroundSize: "2vh 2vh",
              backgroundRepeat: "no-repeat",
              paddingLeft: "6vh",
            }}
          />
          <button
            className="fetch_button"
            onClick={fetchLinks}
            style={{
              backgroundImage: `url(${fetchlinkIcon})`,
              backgroundPosition: "2vh 50%",
              backgroundSize: "3.5vh 3.5vh",
              backgroundRepeat: "no-repeat",
              paddingLeft: "5vh",
            }}
          >
            Fetch Links
          </button>
        </div>

        <div className="fetched_links_container">
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "2.5vh" }}
          >
            <Checkbox
              size="small"
              className="Url_input_checkbox"
              checked={selectAllChecked}
              onChange={handleSelectAllChange}
            />

            <div style={{ fontSize: "14px", color: "#142952", fontWeight: "500" }}>Select all links</div>
            <div className="count">Fetched links : {linkList.length}</div>
            <div className="count">Selected links : {selectedLinks.length}</div>
          </div>
          <div className="fetched_links_list">
            {isLoading ? (
              <ReactLoading
                type={"spokes"}
                color={"#0044CC"}
                height={"10%"}
                width={"10%"}
              />
            ) : (
              <div>
                {linkList.map((link, index) => (
                  <div key={index}>
                    <Checkbox
                      size="small"
                      className="select_url"
                      checked={selectAllChecked || selectedLinks.includes(link)}
                      onChange={() => handleCheckboxChange(link)}
                    />

                    {link}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "row",
              justifyContent: "flex-end",
              marginRight: "3vh",
            }}
          >
            <Link to={"../hip-connection-url"}>
              <button className="cancel_button">Cancel</button>
            </Link>
            <button
              className="create_new_chatbot_button"
              onClick={createChatBotHandler}
            >
              Create Chatbot
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
