import React from 'react';
import "./MenuItem.css";
import { NavLink, useLocation } from 'react-router-dom';
import MenuArrowRight from '../../../../../assets/Icons/MenuArrowRight.svg';
import MenuArrowDown from '../../../../../assets/Icons/MenuArrowDown.svg';

function MenuItem({ title, itemsList, navigateTo, isActive, handleActivateDropDown }) {
    const location = useLocation();

    const handleActive = (value) => {
        return location.pathname.includes(value) ? "active" : "";
    };
    return (
        <>
            <div className='menu_item_page'>
                <NavLink className={isActive ? 'menu_item_container active' : 'menu_item_container'}
                    onClick={handleActivateDropDown} to={navigateTo} >
                    <span>{title}</span>
                    <div style={{ width: "16px", height: "16px" }}>
                        {itemsList !== undefined ?
                            isActive ?
                                <img src={MenuArrowDown} alt='arrow-down' />
                                :
                                <img src={MenuArrowRight} alt='arrow-right' />
                            :
                            <></>
                        }
                    </div>
                </NavLink>
            </div>
            {isActive && (
                <div className='nav_menu_items'>
                    {itemsList !== undefined && (
                        <>
                            {itemsList.map((e, index) => {
                                return (
                                    <NavLink className={() => handleActive(e.ref)} to={`/profile${e.ref}`} key={index}>
                                        <span>{e.name}</span>
                                    </NavLink>
                                )
                            })}
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default MenuItem