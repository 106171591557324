import React, { useRef, useEffect } from "react";
import "../UrlBotMessage/UrlBotMessage.css";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Chart } from "react-google-charts";
import html2canvas from "html2canvas";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import HipOneChatbotIcon from "../../../assets/Icons/HpOneChatbotIcon.svg"

export default function ChartMessage(props) {
  const theme = useSelector((state) => state.messages.Theme);
  const [selectedId, setSelectedId] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    // Check if the document object is defined
    if (typeof document !== 'undefined') {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      // Check if the document object is defined before removing the event listener
      if (typeof document !== 'undefined') {
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);
  const handleChartClick = (itemId) => {
    setSelectedId(true);
    setTooltipVisible(false);
  };

  const handleClose = () => {
    setSelectedId(null);
    setTooltipVisible(true);
  };

  const chartref = useRef();
  const ref = useRef();

  // Function to capture and download the chart
  const captureAndDownloadChart = () => {
    // Hide the download button
    const downloadButton = document.querySelector("#downloadButton");
    downloadButton.style.display = "none";

    html2canvas(chartref.current, { useCORS: true }).then((canvas) => {
      const image = canvas.toDataURL();
      const link = document.createElement("a");
      link.href = image;
      link.download = "chart.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Show the download button again
      downloadButton.style.display = "block";
    });
  };

  return (
    <div className={`${theme}url_bot_message`} ref={ref}>
      <div className={`${theme}url_bot_messageInfo`}></div>

      <div className={`${theme}url_bot_messageContent`}>
        <img src={HipOneChatbotIcon} alt="" width={"24px"} height={"24px"}
          style={{ marginRight: "1.5vh" }} />

        <div
          style={{
            border: "1px black solid",
            width: "auto",
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              cursor: "pointer"
            }}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            onClick={() => handleChartClick(props.itemId)}
          >
            <div>

              <Chart
                chartType={props.type}
                data={props.data}
                options={{
                  ...props.options,
                  fontName: "Inter", // Replace with the font you want
                  fontSize: 14, // Replace with the desired font size
                }}
                style={{
                  padding: "2px",
                  height: "50vh",
                  width: "80vh",
                  fontSize: "6px",
                }}
              />

            </div>
          </div>
          <p
            style={{
              padding: "0vh 1vh 1vh 1vh",
              maxWidth: "70vh",
              whiteSpace: "pre-line"
            }}
          >
            {props.text}
          </p>
        </div>
      </div>

      <AnimatePresence>
        {selectedId && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2 }}
            style={{
              position: "absolute",
              top: "0%",
              left: "0",
              right: "0",
              bottom: "0",
              background: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <IconButton
              sx={{ backgroundColor: "white" }}
              aria-label="close"
              onClick={handleClose}
              className="close-button" // Add a CSS class for styling
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                marginRight: "2vh",
                marginTop: "3vh",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Button
              id="downloadButton"
              variant="contained"
              color="primary"
              size="small"
              style={{
                maxWidth: "37px",
                maxHeight: "32px",
                fontSize: "14px",
                marginRight: "1vh",
                marginTop: "3vh",
                position: "absolute",
                right: 0,
                top: 55,
              }}
              onClick={captureAndDownloadChart}
            >
              <DownloadIcon />
            </Button>
            <div ref={chartref}>
              <Chart
                chartType={props.type}
                data={props.data}
                options={props.options}
                style={{ padding: "2px", height: "80vh", width: "160vh" }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
