import React, { useState, useEffect } from "react";
import Demographics from "../Demographic/Demographics";
import CCDACard from "./CCDACard";
import PDFDownloader from "../../../HiPConnect/Components/PDFGenerator/PDFDownloader";
import "./CommonStyle.css";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const CCDAViewer = ({ dynamicData, htmlContent }) => {
  const [isOpenAll, setIsOpenAll] = useState(false);
  const [cardStates, setCardStates] = useState({});
  const [demographics, setDemographics] = useState({});
  const theme = useSelector((state) => state.messages.Theme) || ""
  useEffect(() => {
    const demographics_data = JSON.parse(
      sessionStorage.getItem("demographics")
    );
    setDemographics(demographics_data);
  }, []);

  const toggleAll = () => {
    const newIsOpenAll = !isOpenAll;
    setIsOpenAll(newIsOpenAll);
    const newCardStates = {};

    Object.keys(dynamicData).forEach((sectionKey) => {
      newCardStates[sectionKey] = newIsOpenAll;
    });

    setCardStates(newCardStates);
  };

  const toggleCard = (sectionKey) => {
    setCardStates((prevState) => ({
      ...prevState,
      [sectionKey]: !prevState[sectionKey],
    }));
  };

  return (
    <div>
      {/* <Demographics demographics={demographics} />
      <div className={`${theme}button-container`}>
        <Button onClick={toggleAll}>
          {isOpenAll ? "Collapse All" : "Expand All"}
        </Button>
        <PDFDownloader dynamicData={dynamicData} demographics={demographics} />{" "}
      </div>
      <div className={`${theme}ccda-viewer`}>
        {Object.keys(dynamicData).map((sectionKey, index) => (
          <CCDACard
            key={index}
            title={sectionKey}
            dataList={dynamicData[sectionKey][sectionKey] || []}
            isOpen={cardStates[sectionKey] || false}
            onToggle={() => toggleCard(sectionKey)}
          />
        ))}
      </div> */}
      <Demographics demographics={demographics} />
      <div className={`${theme}html-content`} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default CCDAViewer;
