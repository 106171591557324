import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancelUpload;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(config => {
  config.cancelToken = new CancelToken(function executor(c) {
    cancelUpload = c;
  });
  return config;
});

export { axiosInstance, cancelUpload };
