import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import chatIcon from "../../../../assets/Icons/Chat-icon.png";
import pdfIcon from "../../../../assets/Icons/Pdf-icon.png";
import SendIcon from "../../../../assets/Icons/send-icon.png";
import whiteChatIcon from "../../../../assets/Icons/white_chat_query_icon.svg"
import {
  addCurrentPdf,
  addMessageToGenzeonAi,
  addMessageToOpenAi,
  addMessageToPalmAi,
  addToAutogeneratedQuetions,
  changeChat,
  clearAutogeneratedQuetions,
  setTheme,
  setAutogeneratedQuetionsLoading,
  setIsPdfBeingUploadedState,
  setIsPdfUploadedState,
  setIsPdfUploadedOneTimeState,
  setPrivacyPopup,
  clearHistory,
  clearState,
  setResponseNumber,
  addMessageToGenzeonAiDark,
  clearHistoryDark
} from "../../../../Redux//features/MessageSlice";
import Messages from "../../../Components/Messages/Messages";
import PdfName from "../../../Components/PdfName/PdfName";
import SideBar from "../../../Components/SideBar";
import "./InteractWithPdf.css";

import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import searchIcon from "../../../../assets/Icons/search-icon.png";
import SendIcon1 from "../../../../assets/Icons/send-icon1.png";
import GetCookie from '../../../../HIPCookies/getCookie';
import { axiosInstance, cancelUpload } from "./AxiosInstance"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { ClockLoader } from "react-spinners";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#142952",
    },
  },
});
const Theme1 = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div" fontFamily="Inter">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const InteractWithPdf = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const privacyPopupState = useSelector((state) => state.messages.privacyPopup);
  const currentPdf = useSelector((state) => state.messages.currentpdf);
  const isPdfUploadedState = useSelector((state) => state.messages.isPdfUploadedState);
  const isPdfUploadedOneTimeState = useSelector((state) => state.messages.isPdfUploadedOneTimeState);
  const isPdfBeingUploadedState = useSelector((state) => state.messages.isPdfBeingUploadedState);
  const responseNumberState = useSelector((state) => state.messages.responseNumber);
  const chatHistoryState = useSelector((state) => state.messages.chatHistory);
  const GenzeonAimessagesDark = useSelector((state) => state.messages.GenzeonAimessagesDark);

  const [selectedFileName, setSelectedFileName] = useState(currentPdf);
  const [isPdfUploaded, setIsPdfUploaded] = useState(isPdfUploadedState);
  const [isPdfUploadedOneTime, setisPdfUploadedOneTime] = useState(isPdfUploadedOneTimeState);
  const fileInputRef = useRef(null);
  const [userMessage, setUserMessage] = useState("");
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [isPdfbeingUploaded, setIsPdfbeingUploaded] = useState(isPdfBeingUploadedState);
  const [massageLoading, setmassageLoading] = useState(false);
  const [privacyModeEnabled, setPrivacyModeEnabled] = useState(false);
  const userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;
  const theme = useSelector((state) => state.messages.Theme) || "";
  const genaibaseUrl = `${process.env.REACT_APP_CONNECT}/query-pdf-genzai`;
  const openaibaseUrl = `${process.env.REACT_APP_CONNECT}/query-pdf-openai`;
  const palmaibaseUrl = `${process.env.REACT_APP_CONNECT}/query-pdf-palmai`;

  useEffect(() => {
    if (Object.keys(chatHistoryState).length === 0) {
      dispatch(setIsPdfUploadedOneTimeState(false));
    } else {
      dispatch(setIsPdfUploadedOneTimeState(true));
    }

    return () => {
      dispatch(setTheme(false));
    };
  }, []);

  const handleChange = (event, newValue) => {
    if (privacyModeEnabled) {
      return;
    }

    if (newValue === 1 || newValue === 2) {
      dispatch(setTheme(false));
    } else if (newValue === 0 && privacyModeEnabled) {
      dispatch(setTheme(true));
    }
    setValue(newValue);
  };

  const togglePrivacyMode = () => {
    if (isPdfBeingUploadedState) {
      return;
    }

    if (isPdfUploadedState) {
      setIsDialogOpen(true);
      return;
    }

    if (!privacyModeEnabled) {
      dispatch(setIsPdfUploadedOneTimeState(false));
    } else if (privacyModeEnabled && Object.keys(chatHistoryState).length === 0) {
      dispatch(setIsPdfUploadedOneTimeState(false));
    } else {
      dispatch(setIsPdfUploadedOneTimeState(true));
    }

    if (value === 0) {
      setPrivacyModeEnabled(!privacyModeEnabled);
      dispatch(setTheme(!privacyModeEnabled));
      if (isPdfBeingUploadedState || isPdfUploadedState) {
        dispatch(setPrivacyPopup(!privacyPopupState));
      }
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmNavigation = () => {
    if (cancelUpload) {
      cancelUpload("Request canceled by the user.");
    }
    dispatch(setIsPdfBeingUploadedState(false));
    dispatch(setIsPdfUploadedState(false));
    if (!privacyModeEnabled) {
      dispatch(setIsPdfUploadedOneTimeState(false));
    } else {
      dispatch(setIsPdfUploadedOneTimeState(true));
    }
    dispatch(setPrivacyPopup(false));
    dispatch(clearState());

    if (value === 0) {
      setIsPdfUploaded(false);
      setPrivacyModeEnabled(!privacyModeEnabled);
      dispatch(setTheme(!privacyModeEnabled));
    }
    handleDialogClose();
  };

  let flag = false;

  const handleFileInputChange = async (event) => {
    dispatch(clearHistoryDark());
    let selectedFile = event.target.files[0];

    let success = true;
    let file = new FormData();

    file.append("file", selectedFile);
    if (selectedFile) {
      dispatch(setIsPdfBeingUploadedState(true))
      setIsPdfbeingUploaded(setIsPdfBeingUploadedState);

      if (theme === "dark_") {
        dispatch(setPrivacyPopup(true));
      }

      setIsPdfUploaded(true);
      dispatch(setIsPdfUploadedState(true))
      setSelectedFileName(selectedFile.name);

      if (theme !== "dark_") {
        dispatch(addCurrentPdf(selectedFile.name));
      }

      let resp;

      try {
        dispatch(setResponseNumber(1));
        resp = await axiosInstance.post(`${genaibaseUrl}/upload`,
          file,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              userId: userId,
            }
          },
        );
        if (resp) {
          flag = true;
        }

      } catch (e) {
        if (e.message === "Request canceled by the user.") {
          return;
        }

        if (e.response && e.response.status === 400) {
          toast.error("Bad Request: Your request is invalid.", {
            position: toast.POSITION.TOP_RIGHT
          });
        } if (e.message === "Error in file upload and text extraction.") {
          toast.error("Error in file upload and text extraction.", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        success = false;
        flag = false;
        dispatch(setResponseNumber(0));
      }

      try {
        dispatch(setResponseNumber(2));
        const [genaiProcessResponse, openaiProcessResponse, palmaiProcessResponse] = await Promise.all([
          axiosInstance.post(`${genaibaseUrl}/process_pdf`,
            {},
            {
              headers: {
                userId: userId,
              },
            }
          ),
          axiosInstance.post(`${openaibaseUrl}/process_pdf`,
            {},
            {
              headers: {
                userId: userId,
              },
            }
          ),
          axiosInstance.post(`${palmaibaseUrl}/process_pdf`,
            {},
            {
              headers: {
                userId: userId,
              },
            }
          ),
        ]);

        if (genaiProcessResponse) {
          flag = true;
        }
        if (openaiProcessResponse) {
          flag = true;
        }
        if (palmaiProcessResponse) {
          flag = true;
          toast.success("File has been processed successfully.", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }

      catch (e) {
        if (e.message === "Request canceled by the user.") {
          return;
        }

        if (e.message !== "Request canceled by the user.") {
          toast.error("File processing failed.", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        success = false;
        flag = false;
        dispatch(setResponseNumber(0));
      }

      if (flag && privacyPopupState) {
        setisPdfUploadedOneTime(true);
        dispatch(setIsPdfUploadedOneTimeState(true));
        dispatch(setIsPdfUploadedState(true))
      } else if (flag && !privacyPopupState) {
        setisPdfUploadedOneTime(true);
        dispatch(setIsPdfUploadedOneTimeState(true));
        dispatch(setIsPdfUploadedState(true))
      } else {
        setIsPdfUploaded(false);
        dispatch(setIsPdfUploadedState(false))
        setisPdfUploadedOneTime(false);
        dispatch(setIsPdfUploadedOneTimeState(false));
        dispatch(setIsPdfBeingUploadedState(false))
        setIsPdfbeingUploaded(setIsPdfBeingUploadedState);
        return;
      }

      dispatch(setIsPdfBeingUploadedState(false))
      setIsPdfbeingUploaded(setIsPdfBeingUploadedState);
      dispatch(setResponseNumber(0));

      if (success === false) {
        setIsPdfUploaded(false);
        dispatch(setIsPdfUploadedState(false))
        setisPdfUploadedOneTime(false);
        dispatch(setIsPdfUploadedOneTimeState(false));
      }

      event.target.value = "";

      let resp11;
      try {
        dispatch(setAutogeneratedQuetionsLoading(true))
        resp11 = await axiosInstance.post(
          `${process.env.REACT_APP_CONNECT}/query-pdf-openai/generate_questions`, {}, {
          headers: {
            userId: userId,
          },
        });
        dispatch(addToAutogeneratedQuetions(resp11.data.questions));
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setAutogeneratedQuetionsLoading(false))
      }
    }
  };

  const handleButtonClicked = () => {
    fileInputRef.current.click();
  };

  const messageChangeHandler = (event) => {
    setUserMessage(event.target.value);
  };

  const handleKeyPress = (e) => {
    if ((e.key == "Enter") & (userMessage.trim() !== "") & !massageLoading) {
      sendHandler(e);
    } else if (e.key == "Enter" && massageLoading) {
      toast.warning("Please wait for response!", {
        position: toast.POSITION.TOP_CENTER
      });
    } else if ((e.key == "Enter") & (userMessage.trim() === "")) {
      notifyTypeSomething();
    }
  };
  const sendHandler = (e) => {
    if ((userMessage.trim() === "")) {
      notifyTypeSomething();
    }
    else if (!isPdfUploaded & !massageLoading) {
      notifyFileUplaod("Please upload file ");
    } else if (massageLoading) {
      toast.warning("Please wait for response!", {
        position: toast.POSITION.TOP_CENTER
      });
    } else {
      sendButtonHandler(e);
    }
  };
  const notifyFileUplaod = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      theme: "colored",
    });
  };
  const notifyTypeSomething = () => {
    toast.warning("Please Type Someting!", {
      position: toast.POSITION.TOP_CENTER
    });
  };
  const getCurrentTime = () => {
    const currentTime = new Date();
    let hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  };

  const sendButtonHandler = async (event) => {
    if (theme === "dark_") {
      sendButtonHandlerDark();
    } else {
      sendButtonHandlerNormal();
    }
  };

  const sendButtonHandlerDark = async (event) => {
    let tempMessageobj;

    tempMessageobj = { id: "user", text: userMessage, time: getCurrentTime() };
    dispatch(addMessageToGenzeonAiDark(tempMessageobj));
    setUserMessage("");
    setmassageLoading(true);

    try {
      const genaiResponse = await axios.post(`${genaibaseUrl}/ask`, {
        question: userMessage,
      }, {
        headers: {
          userId: userId,
        },
      });

      if (genaiResponse) {
        dispatch(addMessageToGenzeonAiDark({ id: "bot", text: genaiResponse.data.answer, time: getCurrentTime() }));
      }

    } catch (e) {
      tempMessageobj = {
        id: "bot",
        text: "Sorry, I am not able to answer this question.",
        time: getCurrentTime()
      };
      dispatch(addMessageToGenzeonAiDark(tempMessageobj));
    }
    setmassageLoading(false);
  };

  const sendButtonHandlerNormal = async (event) => {
    let tempMessageobj;

    dispatch(changeChat(currentPdf));
    tempMessageobj = { id: "user", text: userMessage, time: getCurrentTime() };
    dispatch(addMessageToGenzeonAi(tempMessageobj));
    setUserMessage("");
    setmassageLoading(true);
    tempMessageobj = { id: "user", text: userMessage, time: getCurrentTime() };
    dispatch(addMessageToOpenAi(tempMessageobj));
    setUserMessage("");
    tempMessageobj = { id: "user", text: userMessage, time: getCurrentTime() };
    dispatch(addMessageToPalmAi(tempMessageobj));
    setUserMessage("");

    try {
      const [genaiResponse, openaiResponse, palmResponse] = await Promise.all([
        axios.post(`${genaibaseUrl}/ask`, {
          question: userMessage,
        }, {
          headers: {
            userId: userId,
          },
        }),
        axios.post(`${openaibaseUrl}/ask`, {
          question: userMessage,
        }, {
          headers: {
            userId: userId,
          },
        }),
        axios.post(`${palmaibaseUrl}/ask`, {
          question: userMessage,
        }, {
          headers: {
            userId: userId,
          },
        }),
      ]);

      if (genaiResponse) {
        dispatch(addMessageToGenzeonAi({ id: "bot", text: genaiResponse.data.answer, time: getCurrentTime() }));
      }

      if (openaiResponse) {
        dispatch(addMessageToOpenAi({ id: "bot", text: openaiResponse.data.answer, time: getCurrentTime() }));
      }

      if (palmResponse) {
        dispatch(addMessageToPalmAi({ id: "bot", text: palmResponse.data.answer, time: getCurrentTime() }));
      }

    } catch (e) {
      tempMessageobj = {
        id: "bot",
        text: "Sorry, I am not able to answer this question.",
        time: getCurrentTime()
      };
      dispatch(addMessageToGenzeonAi(tempMessageobj));
    }
    setmassageLoading(false);
  };

  const fileRemoveHandler = () => {
    setIsPdfUploaded(false);
    setSelectedFileName("No file selected");
    dispatch(clearAutogeneratedQuetions());
  };
  const GenzeonAimessages = useSelector(
    (state) => state.messages.GenzeonAimessages
  );
  const OpenAimessages = useSelector((state) => state.messages.OpenAimessages);
  const PalmAImessages = useSelector((state) => state.messages.PalmAimessages);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        backgroundColor: theme === "dark_" ? "#0F0F0F" : "#F4F3F8",
        height: "92%",
        marginLeft: 0,
      }}
    >
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <DialogContentText>
            Leaving this screen will result in the loss of your interactive data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmNavigation} color="primary">
            OK
          </Button>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ width: "100%" }}>
        <div className={`${theme}chatbox_container`}>
          <ThemeProvider theme={theme === "dark_" ? Theme1 : Theme}>
            {" "}
            <Box sx={{ padding: "0px 20px 10px 20px" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: theme === "dark_" ? "#49454F" : "#E6E0E9",
                  display: "flex",
                }}
                className={`${theme}tabs_container`}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"

                >
                  <Tab
                    sx={{
                      textTransform: "capitalize",
                      color: theme === "dark_" ? "#787878" : "#787878",
                      cursor: theme === "dark_" ? "revert" : "pointer",
                      fontFamily: 'Inter'
                    }}
                    label="GenzeonAI"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      textTransform: "capitalize",
                      color: theme === "dark_" ? "#787878" : "#787878",
                      cursor: theme === "dark_" ? "revert" : "pointer",
                      fontFamily: 'Inter'
                    }}
                    label="OpenAI"
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{
                      textTransform: "capitalize",
                      color: theme === "dark_" ? "#787878" : "#787878",
                      cursor: theme === "dark_" ? "revert" : "pointer",
                      fontFamily: 'Inter'
                    }}
                    label="PalmAI"
                    {...a11yProps(2)}
                  />
                </Tabs>
                <div className="switch-container">
                  <span className={`${theme}switch-label`}>Privacy Mode</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      className={`${value === 1 || value === 2 ? "disabled_" : ""
                        }switch-input`}
                      checked={privacyModeEnabled}
                      onChange={value === 0 ? togglePrivacyMode : null}
                    />
                    <span className="switch-slider" style={{ cursor: isPdfBeingUploadedState || value === 1 || value === 2 ? "revert" : "pointer", }}></span>
                  </label>
                </div>
              </Box>
              <CustomTabPanel value={value} index={0} >
                {isPdfBeingUploadedState ? (
                  <div className="loading-container">
                    <ClockLoader color="#0044cc" />
                    {
                      responseNumberState === 1 && <div className="loading-progress-text">File is uploading and text extracting...</div>
                    }
                    {
                      responseNumberState === 2 && <div className="loading-progress-text">File is processing...</div>
                    }
                  </div>
                ) : (
                  <div>
                    {isPdfUploadedOneTimeState ? (
                      <Messages
                        messages={theme === "dark_" ? GenzeonAimessagesDark : GenzeonAimessages}
                        isMeesageloading={massageLoading}
                      />
                    ) : (
                      <div className="chat_icon_container">
                        {theme === "dark_" ?
                          <img className="chat_icon_container_image" src={whiteChatIcon} alt="" /> :
                          <img className="chat_icon_container_image" src={chatIcon} alt="" />
                        }
                        <div className={`${theme}chat_icon_container_lable`} >Get Started with your queries</div>
                      </div>
                    )}
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {isPdfBeingUploadedState ? (
                  <div className="loading-container">
                    <ClockLoader color="#0044cc" />
                    {
                      responseNumberState === 1 && <div className="loading-progress-text">File is uploading and text extracting...</div>
                    }
                    {
                      responseNumberState === 2 && <div className="loading-progress-text">File is processing...</div>
                    }
                  </div>
                ) : (
                  <div>
                    {isPdfUploadedOneTimeState ? (
                      <Messages
                        messages={OpenAimessages}
                        isMeesageloading={massageLoading}
                      />
                    ) : (
                      <div className="chat_icon_container">
                        {theme === "dark_" ?
                          <img className="chat_icon_container_image" src={whiteChatIcon} alt="" /> :
                          <img className="chat_icon_container_image" src={chatIcon} alt="" />
                        }
                        <div className={`${theme}chat_icon_container_lable`} >Get Started with your queries</div>
                      </div>
                    )}
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {isPdfBeingUploadedState ? (
                  <div className="loading-container">
                    <ClockLoader color="#0044cc" />
                    {
                      responseNumberState === 1 && <div className="loading-progress-text">File is uploading and text extracting...</div>
                    }
                    {
                      responseNumberState === 2 && <div className="loading-progress-text">File is processing...</div>
                    }
                  </div>
                ) : (
                  <div>
                    {isPdfUploadedOneTimeState ? (
                      <Messages
                        messages={PalmAImessages}
                        isMeesageloading={massageLoading}
                      />
                    ) : (
                      <div className="chat_icon_container">
                        {theme === "dark_" ?
                          <img className="chat_icon_container_image" src={whiteChatIcon} alt="" /> :
                          <img className="chat_icon_container_image" src={chatIcon} alt="" />
                        }
                        <div className={`${theme}chat_icon_container_lable`} >Get Started with your queries</div>
                      </div>
                    )}
                  </div>
                )}
              </CustomTabPanel>
            </Box>
          </ThemeProvider>
        </div>

        <div className={`${theme}pdf_search_box_container`}>
          <div className={`${theme}pdf_search_box`}>
            <input
              className={`${theme}pdf_text_box`}
              type="text"
              placeholder="Upload PDF and ask or search anything"
              value={userMessage}
              onChange={messageChangeHandler}
              onKeyDown={handleKeyPress}
              style={{
                backgroundImage: `url(${searchIcon})`,
                backgroundSize: "2.5vh",
                backgroundPosition: "2vh center",
                backgroundRepeat: "no-repeat",
                paddingLeft: "6vh",
                fontFamily: 'Inter',
              }}
            />
          </div>

          <div className={`${theme}pdf_send_button_container`}>
            <img
              className={`${theme}pdf_send_button_icon`}
              src={userMessage.trim().length !== 0 ? SendIcon1 : SendIcon}
              onClick={sendHandler}
              style={{
                backgroundColor:
                  userMessage.trim().length !== 0 ? "#0044CC" :
                    (theme === "dark_" ? "#252525" : "white"),
                borderRadius: "5px",
                padding: "2px"
              }}
            />
          </div>
          <input
            type="file"
            accept=".pdf"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
          <button
            variant="contained"
            onClick={handleButtonClicked}
            className={`${theme}new_pdf_button`}
            style={{
              backgroundImage: `url(${pdfIcon})`,
              backgroundSize: "20px",
              backgroundPosition: "2.5vh center",
              backgroundRepeat: "no-repeat",
              paddingLeft: "4.5vh",
              fontFamily: 'Inter',
            }}
          >
            PDF
          </button>
        </div>
        <div>
          {isPdfUploadedState && (
            <PdfName
              name_of_pdf={selectedFileName}
              removeFile={fileRemoveHandler}
            ></PdfName>
          )}
        </div>
      </div >

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SideBar closeFile={fileRemoveHandler} />
      </div>
    </div >
  );
};

export default InteractWithPdf;
