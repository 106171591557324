import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import Paper from "@mui/material/Paper";
// import data from "../Components/BulletChart/data.json";
import mdata from "../Components/data.json";
import { Select } from "antd";
import { scaleOrdinal } from "d3-scale";
import { Box, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./animations.css";
import {
  setYear,
  setParentOrganization,
  setResponseData,
  setDetailsDashdata,
  setMeasureDetails,
  setContractId,
} from "../../../../Redux/features/StarSlice";
 
import LoadingScreen from "../../../../Billing//Pages/LoadingScreen/LoadingScreen";
import MeasureDetailTable from "../Components/MeasureDetailTable";
import TrendingGraph from "../Components/TrendingGraph";
import CryptoJS from "crypto-js";
const SIZE = 450;
const RADIUS = SIZE / 2;
 
const Dashboard = () => {
  // const root = 0;
  let fontSizee = "15px";
  const encryptionKey = "SecureKey";
  const decryptionKey = "SecureKey";
 
  const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value, encryptionKey).toString();
  };
 
  // Function to decrypt the value
  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, decryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  const dispatch = useDispatch();
  const localYear = localStorage.getItem("year");
  const firstYear = decryptValue(localYear);
 
  const [selectedParent, setSelectedparent] = useState();
  const [selectedYears, setselectedYears] = useState(firstYear);
 
  const [data, setData] = useState(mdata);
  const [show, setShow] = useState(false);
 
  const localcontractId = localStorage.getItem("contract");
  const contractId = decryptValue(localcontractId);
 
  const [selectedContractId, setSelectedContractId] = useState(contractId);
 
  const [yearList, setYearList] = useState([]);
  // const [selecedContractId, setSelectedContractId] = useState(contractId);
 
  const [contractNames, setContractNames] = useState();
  const [defaultContractName, setdefaultContractName] = useState();
  const svgRef = useRef(null);
  const [viewBox, setViewBox] = useState("0,0,0,0");
  const [isLoading, setIsLoading] = useState(false);
 
  const cont = localStorage.getItem("cont");
  const decryptedValue = decryptValue(cont);
 
  const [selectedCode, setSelectedCode] = useState(decryptedValue);
 
  const MD = useSelector((state) => state.startDashBoard.measureDetails);
 
  const [measureData, setMeasureData] = useState(MD);
 
  const partition = (data) =>
    d3.partition().size([2 * Math.PI, RADIUS])(
      d3
        .hierarchy(data)
        .sum((d) => d.value || 0)
        .sort((a, b) => b.value - a.value)
    );
 
  const color = d3.scaleOrdinal(["#4B27DE", "#1A17BC"]);
 
  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(RADIUS / 2)
    .innerRadius((d) => d.y0)
    .outerRadius((d) => d.y1 - 1);
 
  const getAutoBox = () => {
    if (!svgRef.current) {
      return "";
    }
    const { x, y, width, height } = svgRef.current.getBBox();
    return [x, y, width, height].toString();
  };
 
  useEffect(() => {
    setViewBox(getAutoBox());
  }, []);
 
  const getColor = (d) => {
    if (
      selectedCode === d.data.measure_codes ||
      selectedCode === d.data.extracted_domain_star_id ||
      selectedCode === d.data.domain_part
    ) {
      return "#B026FF "; // Change to the color you want for the selected element
    }
 
    // Color for domain_part "PART C"
    if (d.data.domain_part === "Part C") {
      return "#4B27DE";
    }
    // Color for domain_part "PART D"
    else if (d.data.domain_part === "Part D") {
      return "#1A17BC";
    }
    // Color for extracted_domain_star_id
    else if (d.data.extracted_domain_star_id) {
      return "#2F315C";
    }
    // Color for measure_codes
    else if (d.data.measure_codes) {
      return "#8BA0BE";
    }
 
    while (d.depth > 1) d = d.parent;
    return color(d.data.domain_part);
  };
 
  const getTransform = (d) => {
    // Increase size of selected element slightly
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = (d.y0 + d.y1) / 2;
 
    if (selectedCode === d.data.measure_codes) {
      fontSizee = "18px";
    } else if (selectedCode === d.data.extracted_domain_star_id) {
      fontSizee = "17px";
    } else {
      fontSizee = "11px";
    }
 
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  };
 
  const handleArcClick = async (d) => {
    const startsWithD = /^D/;
    if (d.depth === 1) {
      // console.log(`Domain Part: ${d.data.domain_part}`);
      setSelectedCode(d.data.domain_part);
      const storedParent = localStorage.getItem("parent");
      const decryptedValue = decryptValue(storedParent);
 
      const storedYear = localStorage.getItem("year");
      const decryptedYearValue = decryptValue(storedYear);
 
      const cont = encryptValue(d.data.domain_part);
      // Cookies.set("parent", firstParentt);
      localStorage.setItem("cont", cont);
 
      try {
        setIsLoading(true);
        // console.log(
        //   "handle arc change",
        //   selectedYears,
        //   selectedParent,
        //   selectedContractId,
        //   d.data.extracted_domain_star_id
        // );
        const measureDetails = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/view_measure_details`,
          {
            year: decryptedYearValue,
            parent_organization: decryptedValue,
            contract_id: selectedContractId,
            measure_id: d.data.domain_part,
          }
        );
        // console.log(
        //   "handle arc click data doman part C or D",
        //   measureDetails.data
        // );
        dispatch(setMeasureDetails(measureDetails.data));
        setMeasureData(measureDetails.data);
        // console.log("Response data:", contDetails.data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    } else if (d.depth === 2) {
      // console.log(
      //   `Domain Part: ${d.parent.data.domain_part}, Extracted Domain Star ID: ${d.data.extracted_domain_star_id}`
      // );
      if (startsWithD.test(d.data.extracted_domain_star_id)) {
        setShow(true);
      } else {
        setShow(false);
      }
 
      setSelectedCode(d.data.extracted_domain_star_id);
      const storedParent = localStorage.getItem("parent");
      const decryptedValue = decryptValue(storedParent);
 
      const storedYear = localStorage.getItem("year");
      const decryptedYearValue = decryptValue(storedYear);
 
      const cont = encryptValue(d.data.extracted_domain_star_id);
      // Cookies.set("parent", firstParentt);
      localStorage.setItem("cont", cont);
 
      try {
        setIsLoading(true);
        // console.log(
        //   "handle arc change",
        //   selectedYears,
        //   selectedParent,
        //   selectedContractId,
        //   d.data.extracted_domain_star_id
        // );
        const measureDetails = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/view_measure_details`,
          {
            year: decryptedYearValue,
            parent_organization: decryptedValue,
            contract_id: selectedContractId,
            measure_id: d.data.extracted_domain_star_id,
          }
        );
        // console.log("handle arc click data", measureDetails.data);
        dispatch(setMeasureDetails(measureDetails.data));
        setMeasureData(measureDetails.data);
        // console.log("Response data:", contDetails.data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      // console.log(
      //   `Domain Part: ${d.parent.parent.data.domain_part}, Extracted Domain Star ID: ${d.parent.data.extracted_domain_star_id}, Measure Codes: ${d.data.measure_codes}`
      // );
      if (startsWithD.test(d.data.measure_codes)) {
        setShow(true);
      } else {
        setShow(false);
      }
      setSelectedCode(d.data.measure_codes);
      const storedParent = localStorage.getItem("parent");
      const decryptedValue = decryptValue(storedParent);
 
      const storedYear = localStorage.getItem("year");
      const decryptedYearValue = decryptValue(storedYear);
      const cont = encryptValue(d.data.measure_codes);
      // Cookies.set("parent", firstParentt);
      localStorage.setItem("cont", cont);
 
      try {
        setIsLoading(true);
        // console.log(
        //   "handle Dropdown change",
        //   selectedYears,
        //   selectedParent,
        //   selectedContractId,
        //   d.data.measure_codes
        // );
        const measureDetails = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/view_measure_details`,
          {
            year: decryptedYearValue,
            parent_organization: decryptedValue,
            contract_id: selectedContractId,
            measure_id: d.data.measure_codes,
          }
        );
        // console.log("handle arc return data", measureDetails.data);
        dispatch(setMeasureDetails(measureDetails.data));
        setMeasureData(measureDetails.data);
        // console.log("Response data:", contDetails.data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
 
  const onYearHandleChange = async (value) => {
    const storedParent = localStorage.getItem("parent");
    const decryptedValue = decryptValue(storedParent);
    const firstYear = encryptValue(value);
    // Cookies.set("parent", firstParentt);
    localStorage.setItem("year", firstYear);
 
    try {
      setIsLoading(true);
      setselectedYears(value);
      dispatch(setYear(value));
 
      // console.log("contractNamesRoute", selectedParent, value);
      const contDetails = await axios.post(
        `${process.env.REACT_APP_MEDICAL_ANALYATICS}/get_contract_id_name_wrt_year`,
        {
          year: value,
          parent_organization: decryptedValue,
        }
      );
 
      // console.log("yearChangeROute:", contDetails.data);
      setContractNames(contDetails.data);
    } catch (error) {
      console.error("Error:", error);
    }
 
    try {
      // console.log(year);
      // console.log(
      //   "handle year Dropdown change",
      //   value,
      //   selectedParent,
      //   selectedContractId,
      //   selectedCode
      // );
      const measureDetails = await axios.post(
        `${process.env.REACT_APP_MEDICAL_ANALYATICS}/view_measure_details`,
        {
          year: value,
          parent_organization: decryptedValue,
          contract_id: selectedContractId,
          measure_id: selectedCode,
        }
      );
      // console.log("handle year change return data", measureDetails.data);
      dispatch(setMeasureDetails(measureDetails.data));
      setMeasureData(measureDetails.data);
      // console.log("Response data:", contDetails.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
 
  function toCamelCase(str) {
    return str.toLowerCase().replace(/\b\w/g, function (word) {
      return word.toUpperCase();
    });
  }
 
  const onHandleContractChange = async (value, option) => {
    const label = option ? option.label : "";
    dispatch(setContractId(value));
    setdefaultContractName(label);
    setSelectedContractId(value);
    const contract = encryptValue(value);
    // Cookies.set("parent", firstParentt);
    localStorage.setItem("contract", contract);
 
    const storedParent = localStorage.getItem("parent");
    const decryptedValue = decryptValue(storedParent);
 
    const storedYear = localStorage.getItem("year");
    const decryptedYearValue = decryptValue(storedYear);
 
    try {
      setIsLoading(true);
      // console.log(year);
      // console.log(
      //   "handle Dropdown change",
      //   selectedYears,
      //   selectedParent,
      //   value,
      //   selectedCode
      // );
      const measureDetails = await axios.post(
        `${process.env.REACT_APP_MEDICAL_ANALYATICS}/view_measure_details`,
        {
          year: decryptedYearValue,
          parent_organization: decryptedValue,
          contract_id: value,
          measure_id: selectedCode,
        }
      );
      // console.log("handle contract change return data", measureDetails.data);
      dispatch(setMeasureDetails(measureDetails.data));
      setMeasureData(measureDetails.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.replace(/\b\w/g, (char) => char.toUpperCase());
    }
    return "";
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("selectedParent", selectedParent);
        // console.log(selectedYears);
        // const storedEncryptedValue = Cookies.get("parent");
        const storedParent = localStorage.getItem("parent");
        const decryptedValue = decryptValue(storedParent);
 
        const storedYear = localStorage.getItem("year");
        const decryptedYearValue = decryptValue(storedYear);
 
        // console.log(decryptedValue, "parent");
        // console.log(decryptedYearValue, "yeaaaaaaaaaa");
        const contractInfo = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/user_dashboard_details`,
          { year: decryptedYearValue, parent_organization: decryptedValue }
        );
 
        const st = contractInfo.data.distinct_contract_info;
 
        setContractNames(st);
 
        const selectedContract =
          st?.find((contract) => contract.contract_id === selectedContractId) ??
          [];
 
        setdefaultContractName(
          selectedContract
            ? capitalizeFirstLetter(selectedContract.contract_name)
            : ""
        );
 
        // setResData(contractInfo.data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const yearArray = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/get_all_years`
        );
 
        setYearList(yearArray.data.years);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
 
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const storedParent = localStorage.getItem("parent");
      const decryptedValue = decryptValue(storedParent);
 
      const storedYear = localStorage.getItem("year");
      const decryptedYearValue = decryptValue(storedYear);
 
      // console.log(
      //   decryptedYearValue,
      //   decryptedValue,
      //   selectedContractId,
      //   selectedCode
      // );
      try {
        const measureDetails = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/view_measure_details`,
          {
            year: decryptedYearValue,
            parent_organization: decryptedValue,
            contract_id: selectedContractId,
            measure_id: selectedCode,
          }
        );
        if (data === mdata) {
          setData(measureDetails.data.Combined_Data[0]);
        }
        // console.log(
        //   "dataaaaaaaaaaaaaaaaaaa",
        //   measureDetails.data.Combined_Data[0]
        // );
        // console.log(measureDetails.data);
        dispatch(setMeasureDetails(measureDetails.data));
        setMeasureData(measureDetails.data);
 
        // console.log("measureData", measureDetails.data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
 
    fetchData();
  }, [data]);
 
  // console.log(data);
  const root = partition(data.data[0]);
  return (
    <Box sx={{ marginLeft: "24px", marginRight: "24px", marginBottom: "68px" }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={{ marginTop: "20px" }}>
            <Typography>Measure Details</Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ height: "100%" }}>
            <Stack height="100%">
              {" "}
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    height: "48px",
                    marginRight: "16px",
                  }}
                  placeholder="Contract Name"
                  onChange={onHandleContractChange}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    contractNames?.map((contract) => ({
                      value: contract.contract_id,
                      label: toCamelCase(contract.contract_name),
                    })) ?? []
                  }
                  value={defaultContractName}
                />
 
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    height: "48px",
                  }}
                  value={selectedYears}
                  placeholder="Select Year"
                  onChange={onYearHandleChange}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionB?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionA?.label ?? "").toLowerCase())
                  }
                  options={
                    yearList?.map((year) => ({
                      value: year,
                      label: year,
                    })) ?? []
                  }
                  // defaultValue={defaultYear}
                />
              </Box>
              <Paper
                sx={{
                  width: "calc(100% - 32px)",
                  height: "calc(100% - 80px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "16px",
                  padding: "16px",
                  borderRadius: "4px",
                  border: "1px solid #E0E3E8",
                  background: "#FFF",
                  boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
                }}
              >
                <svg
                  width={SIZE}
                  height={SIZE}
                  viewBox={viewBox}
                  ref={svgRef}
                  style={{ cursor: "pointer" }}
                  className="svg-container"
                >
                  <g fillOpacity={1}>
                    {root
                      .descendants()
                      .filter((d) => d.depth)
                      .map((d, i) => (
                        <g
                          key={`${d.data.measure_codes}-${i}`}
                          onClick={() => handleArcClick(d)}
                        >
                          <path fill={getColor(d)} d={arc(d)} />
 
                          <text
                            fill="#FFF"
                            textAnchor="middle"
                            transform={getTransform(d)}
                            dy="0.35em"
                            style={{
                              fontSize: fontSizee,
                            }}
                            className={
                              selectedCode === d.data.measure_codes
                                ? "selected lift-up"
                                : ""
                            }
                          >
                            {d.depth === 1
                              ? d.data.domain_part
                              : d.depth === 2
                              ? d.data.extracted_domain_star_id
                              : d.data.measure_codes}
                          </text>
                        </g>
                      ))}
                  </g>
                </svg>
              </Paper>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Stack>
            {" "}
            <MeasureDetailTable
              measureData={measureData}
              code={selectedCode}
              show={show}
            />
          </Stack>
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <Stack>
            <TrendingGraph measureData={measureData} code={selectedCode} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
 
export default Dashboard;