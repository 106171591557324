import { createSlice } from "@reduxjs/toolkit";

// const currentYear = new Date().getFullYear().toString();
const initialState = {
  loading: {
    isActive: false,
  },
  year: new Date().getFullYear().toString(),
  parentOrganization: "",
  responseDashData: {},
  detailsDashdata: {},
  measureDetails: {},
  contractId: ''
};

export const StarSlice = createSlice({
  name: "StarDashboardData",
  initialState,
  reducers: {
    setLoadingScreen: (state, action) => {
      state.loading = action.payload;
    },
    setContractId:(state, action) =>{
      state.contractId = action.payload
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setParentOrganization: (state, action) => {
      state.parentOrganization = action.payload;
    },
    setResponseData: (state, action) => {
      state.responseDashData = action.payload;
    },
    setDetailsDashdata: (state, action) => {
      state.detailsDashdata = action.payload;
    },
    setMeasureDetails: (state, action) => {
      state.measureDetails = action.payload;
    },
  },
});

export const {
  setLoadingScreen,
  setYear,
  setParentOrganization,
  setResponseData,
  setDetailsDashdata,
  setMeasureDetails,
  setContractId
} = StarSlice.actions;

export const getLoading = (state) => state.StarDashboardData.loading;
export const getYear = (state) => state.StarDashboardData.year;
export const getParentOrganization = (state) =>
  state.StarDashboardData.parentOrganization;
export const getResponseData = (state) =>
  state.StarDashboardData.responseDashData; // Selector for responseDashData
export const getDetailsDashdata = (state) =>
  state.StarDashboardData.responseDashData;
export const getMeasureDetails = (state) =>
  state.StarDashboardData.measureDetails;
export default StarSlice.reducer;
