// messageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Messages: [],
  LogshiftersMessages: []
};

const DataGMessageSlice = createSlice({
  name: "DataGMessage",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.Messages.push(action.payload);
    },
    addMessage1: (state, action) => {
      state.LogshiftersMessages.push(action.payload);
    },
    clearMessages:(state, action)=>{
      state.Messages = []
    },
    clearLogsifterMessages:(state, action)=>{
      state.LogshiftersMessages = []
    }
  },
});

export const { addMessage , addMessage1 ,clearMessages,clearLogsifterMessages} = DataGMessageSlice.actions;
export default DataGMessageSlice.reducer;
