import React, { useRef } from 'react';
import {
    Box,
    Button,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material';

import leftArrow from '../../../assets/Arrow_alt_left_alt.svg';
import rightArrow from '../../../assets/Arrow_alt_lright_alt.svg';
import leftIcon from '../../../assets/Prev.svg';
import rightIcon from '../../../assets/Next.svg';


const commonStyles = {
    borderRadius: '0.5rem',
    border: '1px solid #E0E3E8',
    background: '#FFF',
};

const buttonStyles = {
    borderRadius: '.25rem',
    padding: '.37rem 1rem',
    gap: '.5rem',
    fontFamily: 'Inter',
    fontSize: '.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.125rem',
};

const CustomPagination = ({ currentPage, rowsPerPage, onChangePage, onChangeRowsPerPage, claims }) => {
    const totalPages = Math.ceil(claims.length / rowsPerPage);
    // console.log("claims", claims)


    const handlePreviousPage = () => {
        if (currentPage > 1) {
            onChangePage(currentPage - 1);

        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onChangePage(currentPage + 1);

        }
    };

    const handleChange = (event) => {
        onChangeRowsPerPage(event.target.value);
        // Reset the current page when rowsPerPage changes
        onChangePage(1);
        // Add any other logic you want to perform on value change
    };
    return (
        <Box textAlign="center">
            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            color: '#444',
                            fontFamily: 'Inter',
                            fontSize: '.875rem',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '1.125rem',
                        }}
                    >
                        Rows per page
                    </Typography>
                    <FormControl sx={{ ml: '.62rem' }}>
                        <InputLabel id="rows-per-page-label"></InputLabel>
                        <Select
                            labelId="rows-per-page-label"
                            id="rows-per-page-select"
                            value={rowsPerPage}
                            onChange={handleChange}
                            sx={{
                                ...commonStyles,
                                color: '#444',
                                width: '4.375rem',
                                height: '2.625rem',
                            }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        sx={{ ...buttonStyles, color: '#B9B9B9', textTransform: 'none' }}
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        <img src={leftArrow} alt="Left Arrow" /> Previous Page
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ ...buttonStyles, textTransform: 'none', ml: '.87rem', bgcolor: '#5A03EE', color: '#FFFFFF' }}
                        onClick={handleNextPage}
                        disabled={totalPages === 1 || currentPage == totalPages}
                    >
                        Next Page <img src={rightArrow} alt="Right Arrow" />
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Stack direction={'row'} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>
                            Page{' '}
                            <input
                                type="text"
                                value={currentPage}
                                onChange={(e) => onChangePage(e.target.value)}
                                style={{
                                    ...commonStyles,
                                    width: '3rem',
                                    height: '2.25rem',
                                    outline: 'none',
                                    paddingLeft: "1rem"
                                }}
                            />
                            &nbsp; of {totalPages}
                        </Typography>
                        <Box sx={{ ...commonStyles, display: 'flex', width: '2rem', height: '2rem', padding: '0.625rem', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '0.625rem', ml: '1rem', flexShrink: 0, borderRadius: '2rem', border: '1px solid #E0E3E8', background: '#FFF' }}>
                            <img src={leftIcon} alt="Left Icon" onClick={handlePreviousPage} />
                        </Box>
                        <Box sx={{ ...commonStyles, display: 'flex', width: '2rem', height: '2rem', padding: '0.625rem', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '0.625rem', ml: '1rem', flexShrink: 0, borderRadius: '2rem', border: '1px solid #E0E3E8', background: '#FFF' }}>
                            <img src={rightIcon} alt="Right Icon" onClick={handleNextPage} />
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
};

export default CustomPagination;