import React from 'react'
import "./ErrorPage.css"
import { useNavigate } from 'react-router-dom'
function ErrorPage() {
    const navigate = useNavigate()
    return (
        <div className='error_page_container'>
            <div className='error_page_card'>
                {/* Page Not Found */}
                <div className='error_page_card_error'>
                    <span>Page</span>
                    <span>Not</span>
                    <span>Found</span>
                </div>
                {/* Message and go back */}
                <div className='error_page_message'>
                    <span>This location is not exists</span><br />
                    <button onClick={() => navigate('/')}>Go Back</button>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage