import { useState } from "react";
import TimelineChart from "../TimeLineChart/TimelineChart"
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "../HealthWatch/HealthWatch.css"

const TimeLineSection = ({ sectionData }) => {

  const theme = useSelector((state) => state.messages.Theme)

  const yearsSet = new Set();
  Object.values(sectionData).forEach((sectionInfo) => {
    sectionInfo.forEach(([date]) => {
      if (date) {
        const year = new Date(date).getFullYear().toString();
        yearsSet.add(year);
      }
    });
  });

  const years = Array.from(yearsSet);
  const [filterYear, setFilterYear] = useState(years[0]);

  const [filteredSection, setFilteredSection] = useState([]);

  useEffect(() => {
    const filteredYearData = Object.keys(sectionData).reduce((result, sectionName) => {
      let yearwiseData = sectionData[sectionName].filter(([date]) => {
        const year = new Date(date).getFullYear().toString();
        return year === filterYear;
      });
      return yearwiseData;
    }, {});
    setFilteredSection(filteredYearData);
  }, [filterYear, sectionData]);

  const filteredChangeHandler = (selectedYear) => {
    setFilterYear(selectedYear);
  };

  return (
    <div className={`${theme}healthwatch-table`}>
      <tbody>
        {Object.keys(sectionData).map((sectionName) => (
          <div key={sectionName}>
            <tr >
              <td>

                <div className={`${theme}filter-container"`}>
                  <strong>{sectionName} : </strong>

                  <label style={{ marginLeft: "200px" }} className={`${theme}filter-label`}>Filter by Year : </label>
                  <select
                    className="filter-dropdown"
                    value={filterYear}
                    onChange={(e) => filteredChangeHandler(e.target.value)}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ width: "150vw" }}>
                <TimelineChart
                  section={filteredSection}
                  Title={sectionName}
                />
              </td>
            </tr>
          </div>
        ))}
      </tbody>
    </div >
  )
}

export default TimeLineSection