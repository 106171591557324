import React from 'react';
import { Outlet } from 'react-router-dom';
import HiPOperationsBreadCrumb from '../HiPOperational/Pages/HiPOperationsBreadCrumb/HiPOperationsBreadCrumb';
import HiPClinicalBreadCrumb from '../HiPClinical/Pages/HiPClinicalBreadCrumb/HiPClinicalBreadCrumb';
import HiPConnectBreadCrumb from '../HiPConnect/Pages/HiPConnectBreadCrumb/HiPConnectBreadCrumb';
import HiPSettingsBreadCrumb from '../Settings/Pages/HiPSettingBreadCrumb/HiPSettingBreadCrumb';
import HiPBillingBreadCrumb from '../Billing/Pages/HiPBillingBreadCrumb/HiPBillingBreadCrumb';
import './LayoutWithBreadCrumb.css';
import { useSelector } from 'react-redux';

function LayoutWithBreadCrumb({ type }) {
    const theme = useSelector((state) => state.messages.Theme) || "";
    const renderBreadcrumb = () => {
        switch (type) {
            case 'operations':
                return <HiPOperationsBreadCrumb />;
            case 'clinical':
                return <HiPClinicalBreadCrumb />;
            case 'connect':
                return <HiPConnectBreadCrumb />;
            case 'settings':
                return <HiPSettingsBreadCrumb />;
            case 'billing':
                return <HiPBillingBreadCrumb />;
            default:
                return null;
        }
    };

    return (
        <div className="route_with_navigation_screen_hip">
            <div className={`${theme}route_with_navigation_screen_breadcrumb`}>
                {renderBreadcrumb()}
                <Outlet />
            </div>
        </div>
    );
}

export default LayoutWithBreadCrumb;
