import React, { useEffect, useState } from "react";
import "./DocumentTypes.css";
import Axios from "axios";
import Extracted from '../../../assets/Icons/Extracted.svg';
import WIP from '../../../assets/Icons/WIP.svg';
import Completed from '../../../assets/Icons/Completed.svg';
import Back from '../../../assets/Icons/Back.svg';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "@mui/material";


function DocumentTypes() {
  const [configurationData, setConfigurationData] = useState([]);
  const navigate = useNavigate();

  const baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;

  useEffect(() => {
    Axios.get(`${baseURL}/use_case_configs`).then((result) => {
      setConfigurationData(result.data);
    });
  }, [baseURL]);

  return (
    <div className="document_types_main_page">
      <div className="document_types_container">
        {configurationData.length === 0 ? (
          <div className="no_data_doc_types">No Data</div>
        ) : (
          <>
            {configurationData.map((e, index) => {
              return (
                <div className="document_types_card" key={index}>
                  <div className="document_types_header">
                    <div className="document_header_name">
                      {e.use_case === "Medical Record"
                        ? "Medical Record Request"
                        : e.use_case === "Medical Billing"
                          ? "Medical Bill Review"
                          : e.use_case === "Patient History"
                            ? "Clinical Data Extraction"
                            : "No Data"}
                    </div>
                  </div>
                  <div className="document_types_content">
                    <div className="row">
                      <div className="column">
                        <div className="document_card">
                          <Tooltip placement='top' title='Extracted'>
                            <div className="image">
                              <img src={Extracted} className="imgStyle" alt=""></img>
                            </div>
                          </Tooltip>
                          <div className="document_value">{e.uploaded}</div>
                          <div className="document_type">Extracted</div>
                        </div>
                      </div>
                      <div class="column">
                        <div class="document_card">
                          <Tooltip placement='top' title='Work In Progress (WIP)'>
                            <div className="image_WIP">
                              <img src={WIP} className="imgStyle" alt=""></img>
                            </div>
                          </Tooltip>
                          <div className="document_value">{e.review_pending}</div>
                          <div className="document_type">Work In Progress (WIP)</div>
                        </div>
                      </div>
                      <div class="column">
                        <div class="document_card">
                          <Tooltip placement='top' title='Verify Completed'>
                            <div className="image_Completed">
                              <img src={Completed} className="imgStyle" alt=""></img>

                            </div>
                          </Tooltip>

                          <div className="document_value">{e.approved}</div>
                          <div className="document_type">Verify Completed</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default DocumentTypes;
