import React, { useState, useEffect } from "react";
import "./CreateDatabaseChatbot.css";
import axios from "axios";
import { setTheme } from "../../../../../Redux/features/MessageSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setThemedb } from "../../../../../Redux/features/DbMessageSlice";

const CreateDatabaseChatBot = () => {
  const navigate = useNavigate();
  const [privacyModeEnabled, setPrivacyModeEnabled] = useState(false);
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.messages.Theme) || "";

  useEffect(() => {
    return () => {
      if (!flag) {
        dispatch(setThemedb(false));
        dispatch(setTheme(false));
      }
    };
  }, []);
  const togglePrivacyMode = () => {
    setPrivacyModeEnabled(!privacyModeEnabled);
    dispatch(setTheme(!privacyModeEnabled));
    dispatch(setThemedb(!privacyModeEnabled));
  };

  const navigationHandler = () => {
    setFlag(true);
    navigate("/hip-connect/hip-connection-database-create-chatbot");
  };

  return (
    <div className={`${theme}create_db_main_container`}>
      <div className={`${theme}info_main_container`}>
        <div className={`${theme}info`}>
          <p style={{ marginBottom: "40px" }}>
            Conversation with Database leverages the power of an LLM to
            seamlessly retrieve and present data from the database. This
            intelligent feature streamlines data access and enhances user
            experiences, enabling efficient and natural interactions with your
            data.
          </p>
          <p style={{ marginBottom: "40px", fontStyle: "italic" }}>
            "Ask your data questions in plain English and get answers in
            seconds, no coding required."
          </p>
        </div>

        <div className={`${theme}note_container`}>
          <p className={`${theme}note`}>
            Note ! The data you see here is generated for demonstration purpose
            using our fabricated database tables. This demo is currently
            connected to our showcase database.
          </p>
        </div>
        <button
          type="submit"
          className={`${theme}connect_button`}
          onClick={navigationHandler}
        >
          Connect to the Database
        </button>
      </div>
    </div>
  );
};

export default CreateDatabaseChatBot;
