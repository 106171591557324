import React, { useEffect, useState } from 'react'

function Textarea({ e, handleUpdate, val }) {

    const [data, setData] = useState('')

    useEffect(() => {

        // setData(e[index] === null || e[index] === '' ? "NA" : e[index])
        setData(val)

    }, [val])

    const handleChange = (e) => {
        setData(e.target.value)
        handleUpdate(e.target.name, e.target.value)
    }

    return (
        <textarea name={e} id={e} value={data} onChange={handleChange} />
    )
}

export default Textarea