import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlankPages,
  getImageUrlList,
  getOriginalUrlList,
  getUploadedFilename,
  getisBlankApiCall,
} from "../../../../Redux/features/DocumentsSlice";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import "./MBWPdfThumbnails.css";

function MBWPdfThumbnails({ currentPage, onThumbnailClick, filter }) {
  const [isLoading, setIsLoading] = useState(false);
  const thumbnails = useSelector((state) => state.documents.billUrlList)
  var filename = useSelector(getUploadedFilename);
  filename = filename.replace(/\.pdf$/, "");
  // let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId
  const thumbnailRefs = thumbnails.map(() => React.createRef());

  useEffect(() => {
    thumbnailRefs[currentPage]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }, [currentPage, thumbnails]);

  return (
    <div
      className="mbw-pdf-thumbnails-container"
      style={{ overflow: "scroll", maxHeight: "100vh" }}
    >
      {isLoading && <LoadingScreen />}
      <div
        style={{
          overflow: "scroll",
          Height: "120vh",
          width: "160px",
        }}
      >
        {thumbnails.map((thumbnailObj, index) => (
          <div className="mbw-thumbnail-paper" ref={thumbnailRefs[index]}>
            <img
              src={thumbnailObj.blob_url}
              alt={`Thumbnail ${index}`}
              className={index === currentPage ? "mbw-active-thumbnail" : ""}
              onClick={() => onThumbnailClick(index)}
            />
            <span className="mbw-page-number">{thumbnailObj.page_number}</span>{" "}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MBWPdfThumbnails;
