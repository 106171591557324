import Demographics from "../Demographic/Demographics";
import Summary from "../Summary/Summary";
import Medications from "../Medications/Medications";
import HealthWatch from "../HealthWatch/HealthWatch";
import "./MedicalDetails.css";
import { useSelector } from "react-redux";
function MedicalDetails() {
  const theme = useSelector((state) => state.messages.Theme) || "";

  return (
    <div>
      <Demographics />
      <div
        style={{
          display: "flex",
          width: "100%",
          background: theme === "dark_" ? "#252525" : "#f0f0f0",
        }}
      >
        <div style={{ width: "70%" }}>
          <Summary />
        </div>
        <div style={{ width: "30%" }}>
          <Medications />
        </div>
      </div>
      <HealthWatch />
    </div>
  );
}

export default MedicalDetails;
