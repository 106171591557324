import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./assets/global.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import GetCookie from "./HIPCookies/getCookie";
import TokenExpiration from "./HIPTokenManager/TokenManager";
import { ThemeProvider } from "@mui/material";
import theme from "./Theme/Theme";

const Root = () => {
  useEffect(() => {
    // Retrieve token expiration from the stored data
    const storedData = GetCookie("userInfo");
    const storedTokenExpiration = storedData
      ? JSON.parse(storedData).tokenExpiration
      : null;
    // Check for token expiration when the component mounts
    TokenExpiration(storedTokenExpiration);
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
          <ToastContainer className="error_message_content" />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <Root />,
  document.getElementById("root")
);
