// messageSlice.js

import { createSlice } from "@reduxjs/toolkit";

const convMessage = createSlice({
  name: "convMessage",
  initialState: [],
  reducers: {
    addMessage: (state, action) => {
      // Add a new message to the state
      state.push(action.payload);
    },
    clearMessages: (state) => {
      // Clear all messages from the state
      state.length = 0;
    },
  },
});

export const { addMessage, clearMessages, setFeedback } = convMessage.actions;
export default convMessage.reducer;
