import React from 'react'
import { Route, Routes } from 'react-router-dom'

function App() {
    return (
        <Routes>
            <Route path='/' element={<h2>HIP Finance</h2>} />
        </Routes>
    )
}

export default App