import React from 'react';
import "../../../Theme/HIPBreadCrumb.css";
import { useLocation, useNavigate, NavLink as Link } from 'react-router-dom';
import BackImage from '../../../assets/Back.svg';
import HomeImage from '../../../assets/home.svg';
import RightArrowImage from '../../../assets/RightArrow.svg';

function BillingHiPBreadCrumb() {

    const location = useLocation();
    const navigate = useNavigate();

    return (

        <div className='bread_crumb_navigations'>
            <div className="bread_crumb_back_container" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <img src={BackImage} alt="" style={{ width: '18px' }} />
            </div>
            <div className='bread_crumb_navigations_content' >
                <a href='/dashboard'>
                    <img src={HomeImage} alt='home' />
                </a>
                <div className='bread_crumb_arrow_container'>
                    <img src={RightArrowImage} alt='arrow right' className='bread_crumb_arrow' />
                </div>
            </div>
            {
                Routes.filter(item => item.currentroute === location.pathname || item.currentroute.split('/')[1] === location.pathname.split('/')[2]).map((e, index) => {
                    return (
                        <div className="bread_crumb_navigation_sub_container" key={index}>
                            {
                                e.currentroute === '/billing/invoice' ?
                                    <>
                                        <Link to='/billing/invoice' >Billing</Link>
                                        <div className="bread_crumb_navigation_sub_content" >
                                            <img src={RightArrowImage} alt='arrow right' className='bread_crumb_nav_img' />
                                            <Link className="bread_crumb_nav_item_bold" to='/billing/invoice'>Invoice</Link>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <Link to='/billing/invoice'>Billing</Link>
                                        {
                                            e.title.map((e2, index1) => {
                                                return (
                                                    <div className="bread_crumb_navigation_sub_content" key={index1}>
                                                        <img src={RightArrowImage} alt='arrow right' className='bread_crumb_nav_img' />
                                                        <Link
                                                            className={e.title.length - 1 === index1 && "bread_crumb_nav_item_bold"}
                                                            to={e.routes[index1]}
                                                            key={index1}
                                                        >{e.title[index1]}</Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                            }
                        </div>
                    )
                })
            }
        </div >
    )
}

export default BillingHiPBreadCrumb


const Routes = [
    {
        title: ["Transactions", "Patient Data"],
        currentroute: "/billing/patient-data-request-summary",
        routes: ["/billing/patient-data-request-summary", '/billing/patient-data-request-summary']
    },
    {
        title: ["Invoice"],
        currentroute: "/billing/invoice",
        routes: ["/billing/invoice", '/billing/invoice']
    }
]