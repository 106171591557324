import React, { useEffect, useState } from "react";
import "./PdfReader.css";

function PdfReader({ zoom, imageURI, name, setImageWidth, width, setOffSet }) {
  const [Image, setImage] = useState("");
  
  useEffect(() => {
    setImage(imageURI);
    setImageWidth(width);
    setOffSet(Number.parseFloat((zoom / width).toFixed(3)));
  }, [imageURI, setImageWidth, width, zoom, setOffSet]);

  return (
    <div
      style={{
        width: zoom,
      }}
      className="pdf_viewer_image"
    >
      <img src={Image} alt={name} />
    </div>
  );
}

export default PdfReader;
