import React, { useRef, useEffect, useState } from "react";
import "./GenMessage.css";
import copyIcon from "../../../assets/Icons/copy-icon.png";
import copyIconWhite from "../../../assets/Icons/copy-icon-white.png";
import copiedIcon from "../../../assets/Icons/copied-icon.png";
import copiedIconWhite from "../../../assets/Icons/copied-icon-white.png";
import { useSelector } from "react-redux";
import HipOneChatbotIcon from "../../../assets/Icons/HpOneChatbotIcon.svg"

export default function GenMessage(props) {
  const ref = useRef();
  const [showCopiedIcon, setShowCopiedIcon] = useState(false);
  const theme = useSelector((state) => state.messages.Theme);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [props.message, props.typing]);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.message);
    setShowCopiedIcon(true);

    setTimeout(() => {
      setShowCopiedIcon(false);
    }, 2000);
  };

  return (
    <div className={`${theme}pdf_message`} ref={ref}>
      <div className={`${theme}messageContent`}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={HipOneChatbotIcon} alt="" width={"24px"} height={"24px"} />
          <p className={`${theme}message-time`}>{props.time}</p>
        </div>
        <p className={`${theme}gen_message-p`}>
          {props.message}
          <img
            src={
              showCopiedIcon
                ? theme === "dark_"
                  ? copiedIconWhite
                  : copiedIcon
                : theme === "dark_"
                  ? copyIconWhite
                  : copyIcon
            }
            alt="Copy Icon"
            className="copy_icon"
            onClick={handleCopy}
          />
        </p>
      </div>
    </div>
  );
}
