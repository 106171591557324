import { Box, Typography, Stack, Grid } from "@mui/material";
import StarDashboard from "../Components/StarRatingDashboard";
import USAMap from "../Components/USAMap";
import ContractDataTable from "../Components/ContractDataTable";
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../../../Billing/Pages/LoadingScreen/LoadingScreen";
import {
  setYear,
  setParentOrganization,
  setResponseData,
} from "../../../../Redux/features/StarSlice";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import customIcon from "../assets/selectBoxDownArrowIcon.svg"

const MedicareAnalyticsDashboard = (props) => {
  const encryptionKey = "SecureKey";
  const decryptionKey = "SecureKey";

  const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value, encryptionKey).toString();
  };

  // Function to decrypt the value
  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, decryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const [selectedParent, setSelectedparent] = useState();
  const [selectedYears, setselectedYears] = useState();

  const [yearList, setYearList] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState();

  const [resdata, setResData] = useState(
    useSelector((state) => state.startDashBoard.responseDashData)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("parent")) {
        const storedParent = localStorage.getItem("parent");
        const decryptedValue = decryptValue(storedParent);
        setSelectedparent(decryptedValue);
      }
      let year = new Date().getFullYear().toString();
      if (!localStorage.getItem("year")) {
        setselectedYears(year);
        const firstYear = encryptValue(year);
        localStorage.setItem("year", firstYear);
      } else {
        const localYear = localStorage.getItem("year");
        const firstYear = decryptValue(localYear);

        setselectedYears(firstYear);
      }

      try {
        const yearResponse = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/get_all_years`
        );

        setYearList(yearResponse.data.years);
      } catch (error) {
        console.error("Error:", error);
      }
      try {
        const storedYear = localStorage.getItem("year");
        const decryptedYearValue = decryptValue(storedYear);

        const parentData = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/get_all_parent_organizations`,
          { year: decryptedYearValue }
        );

        const firstParent = parentData.data?.parent_organizations?.sort();

        setParentList(firstParent);

        if (!localStorage.getItem("parent")) {
          setSelectedparent(firstParent[0]);
          const firstParentt = encryptValue(firstParent[0]);
          localStorage.setItem("parent", firstParentt);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedParent = localStorage.getItem("parent");
        const decryptedValue = decryptValue(storedParent);

        const storedYear = localStorage.getItem("year");
        const decryptedYearValue = decryptValue(storedYear);

        const contractInfo = await axios.post(
          `${process.env.REACT_APP_MEDICAL_ANALYATICS}/user_dashboard_details`,
          { year: decryptedYearValue, parent_organization: decryptedValue }
        );
        // console.log("setting data from Dashboard")
        // localStorage.setItem("contractData",JSON.stringify(contractInfo.data.distinct_contract_info));
        // console.log("contractInfo.data", contractInfo.data)
        // console.log("Distinct Contract Info:", contractInfo.data.distinct_contract_info);
        setTableData(contractInfo.data.distinct_contract_info)
        dispatch(setResponseData(contractInfo.data));

        const contInfo = encryptValue(contractInfo.data);
        localStorage.setItem("contract_info", contInfo);


      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedParent, selectedYears]);

  const handleYearChange = (value) => {
    setselectedYears(value);
    dispatch(setYear(value));
    const firstYear = encryptValue(value);
    localStorage.setItem("year", firstYear);

    handleChangeParent(selectedParent);
  };

  const handleChangeParent = async (value) => {
    dispatch(setParentOrganization(value));
    setSelectedparent(value);

    const firstParentt = encryptValue(value);
    // Cookies.set("parent", firstParentt);
    localStorage.setItem("parent", firstParentt);
    const storedYear = localStorage.getItem("year");
    const decryptedYearValue = decryptValue(storedYear);

    try {
      setIsLoading(true);
      const contractInfo = await axios.post(
        `${process.env.REACT_APP_MEDICAL_ANALYATICS}/user_dashboard_details`,
        { year: decryptedYearValue, parent_organization: value }
      );

      dispatch(setResponseData(contractInfo.data));
      const contInfo = encryptValue(contractInfo.data);
      // Cookies.set("parent", firstParentt);
      localStorage.setItem("contract_info", contInfo);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function toCamelCase(str) {
    return str.toLowerCase().replace(/\b\w/g, function (word) {
      return word.toUpperCase();
    });
  }

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack sx={{ marginTop: "16px" }}>
          <Box>
            <div>
              <Box sx={{ marginLeft: "24px" }}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0px",
                    textAlign: "left",
                  }}
                >
                  Overall Star Rating
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    marginTop: "17px",
                    marginLeft: "24px",
                    flexDirection: "column",
                  }}
                >
                  <Select
                    showSearch
                    style={{
                      width: 236,
                      height: "30px",
                      display: "flex",
                    }}
                    placeholder="Parent Organization"
                    value={selectedParent}
                    onChange={handleChangeParent}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={
                      parentList?.map((parentOrg) => ({
                        value: parentOrg,
                        label: toCamelCase(parentOrg),
                      })) ?? []
                    }
                    suffixIcon={<img src={customIcon} alt="Custom Icon"  />}
                  >
                    {parentList?.map((parentOrg) => (
                      <Select.Option
                        key={parentOrg}
                        value={parentOrg}
                        style={{ color: "black" }}
                      >
                        {toCamelCase(parentOrg)}
                      </Select.Option>
                    )) ?? []}
                  </Select>
                </Box>

                <Box sx={{ marginTop: "17px", marginRight: "24px" }}>
                  <Select
                    showSearch
                    style={{
                      width: "93px",
                      height: "30px",
                      display: "flex",
                      cursor: "pointer"
                    }}
                    value={selectedYears}
                    onChange={handleYearChange}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionB?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionA?.label ?? "").toLowerCase())
                    }
                    options={
                      yearList?.map((year) => ({
                        value: year,
                        label: year,
                      })) ?? []
                    }
                    suffixIcon={<img src={customIcon} alt="Custom Icon"  />}
                  ></Select>
                </Box>
              </Box>
            </div>
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" gap={3} sx={{ padding: "20px 20px 0 20px" }}>
          <Box>
            <StarDashboard isLoading={isLoading} />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <USAMap isLoading={isLoading} />
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Stack p={5}>
          <Box sx={{ marginBottom: "16px" }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
              }}
            >
              Contracts
            </Typography>
          </Box>
          <Box>
            {" "}
            {/* {console.log("tbalaa data", tableData)} */}
            <ContractDataTable isLoading={isLoading} tableData={tableData}/>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MedicareAnalyticsDashboard;
