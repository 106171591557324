import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addMessage1, clearLogsifterMessages } from "../../../../Redux/features/DataGenieSlice";
import SearchIcon from "../../../../assets/Icons/search-icon.png";
import LogshiftersMessages from "../../../Components/LogshiftersMessages/LogshiftersMessages";
import "./Logsifters.css";
import SendIcon1 from "../../../../assets/Icons/send-icon1.png"
import SendIcon from "../../../../assets/Icons/send-icon.png";
const Logshifters = () => {
    const [userMessage, setUserMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const theme = useSelector((state) => state.messages.Theme) || "";

    const messageChangeHandler = (event) => {
        setUserMessage(event.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && userMessage.trim() !== "") {
            sendButtonHandler();
        }
    };

    const sendHandler = (e) => {
        if (userMessage.trim().length !== 0) {
            sendButtonHandler();
        }
    };


    const handleButtonClicked = () => {
        fileInputRef.current.click();
    };

    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files);
        let names = ""
        const formData = new FormData();
        files.forEach((file, index) => {
            names = names + `${index + 1}) ${file.name} \n `
            formData.append("files", file);
        });



        const id = toast.loading("File is being uploaded...");

        axios
            .post(`${process.env.REACT_APP_CONNECT}/logsifters/upload_files`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                toast.update(id, {
                    render: "File uploaded successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                });
                // dispatch(addMessage1(tempMessageobj));
            })
            .catch((error) => {
                toast.update(id, {
                    render: "Error while uploading file",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                });
                console.error("Error uploading files:", error);
            });
    };

    const sendButtonHandler = async () => {
        let tempMessageobj = { id: "user", text: userMessage };
        dispatch(addMessage1(tempMessageobj));
        setUserMessage("");
        setIsLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_CONNECT}/logsifters/generate-final-answer`,
                { question: userMessage }
            );

            setIsLoading(false);

            const id = response.data.id;
            if (id === "chart") {
                tempMessageobj = {
                    id: "chart",
                    data: response.data.data,
                    options: { ...response.data.options, title: response.data.title },
                    type: response.data.chart_type,
                    text: response.data.summary,

                };
            } else if (id == "chat") {
                tempMessageobj = {
                    id: "bot",
                    text: response.data.chat_result,
                };
            }
            else {
                tempMessageobj = {
                    id: "bot",
                    text: response.data.summary
                    ,
                };
            }
            dispatch(addMessage1(tempMessageobj));
        } catch (error) {
            setIsLoading(false);
            tempMessageobj = { id: "bot", text: "Oops! Something went wrong. Please try again later." };
            dispatch(addMessage1(tempMessageobj));
            console.log(error);
        }
    };

    return (
        <div className={`${theme}db_main_container`}>
            <div className={`${theme}db_chatbox_container`}>
                <LogshiftersMessages isLoading={isLoading}></LogshiftersMessages>
            </div>
            <div className={`${theme}db_search_box_container_log`}>
                <input
                    className={`${theme}db_text_box`}
                    type="text"
                    placeholder="Ask me.."
                    value={userMessage}
                    onChange={messageChangeHandler}
                    onKeyDown={handleKeyPress}
                    style={{
                        backgroundImage: `url(${SearchIcon})`,
                        backgroundSize: "2.5vh",
                        backgroundPosition: "1vh center",
                        backgroundRepeat: "no-repeat",
                        paddingLeft: "6vh",
                    }}
                />

                <img
                    className={`${theme}db_send_button_icon`}
                    src={userMessage.trim().length !== 0 ? SendIcon1 : SendIcon}
                    onClick={sendHandler}
                    style={{ backgroundColor: userMessage.trim().length !== 0 ? "#0044CC" : "white" }}
                />
                <input
                    type="file"
                    multiple
                    accept=".json"
                    ref={fileInputRef}
                    className="file-input"
                    onChange={handleFileUpload}
                    hidden={true}
                />
                <button
                    variant="contained"
                    onClick={handleButtonClicked}
                    className="log_create_new_chatbot_button_"
                    style={{
                        backgroundSize: "2.5vh",
                        backgroundPosition: "2vh center",
                        backgroundRepeat: "no-repeat",
                        paddingLeft: "1vh",
                    }}
                >
                    Upload JSON
                </button>
            </div>
        </div>
    );
};

export default Logshifters;
