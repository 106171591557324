import React, { useEffect, useState } from 'react'
import DocumentsTable from '../../Pages/DocumentsTable/DocumentsTable'
import { getDocuments } from '../../../Redux/features/DocumentsSlice'
import { useSelector, useDispatch } from 'react-redux'
import "./ClinicalDataExtraction.css"
import { setDocumentConfig, setUploadAlert } from '../../../Redux/features/AlertsSlice'
import { useNavigate } from 'react-router-dom'

import UploadImage from '../../../assets/Icons/Upload.svg'
import ConfigImage from '../../../assets/Icons/Configuration.svg'
import SearchImage from '../../../assets/Icons/Search.svg'
import BackImage from '../../../assets/Icons/navigate-back.svg'
import { openDatabase } from "../../../IndexedDBUtility/IndexedDBUtility";

function ClinicalDataExtraction() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const documents = useSelector(getDocuments);
    const componentHeading = "Clinical Data Extraction";
    const [ClinicalDocument, setClinicalDocuments] = useState([])
    let permissionData;
    const [readAccess, setReadAccess] = useState(false);
    const [writeAccess, setWriteAccess] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = await openDatabase();
                const transaction = db.transaction(['permissionData'], 'readonly');
                const objectStore = transaction.objectStore('permissionData');
                const getRequest = objectStore.getAll();

                getRequest.onsuccess = (event) => {
                    const storedData = event.target.result;
                    permissionData = storedData[0].value
                    const operationsModule = permissionData.find((module) => module.moduleName === 'Operations');
                    const screenDisplayName = componentHeading;
                    const screenPermissions = operationsModule.permissionsDataArray.find(
                        (item) => item.displayName === screenDisplayName
                    );

                    if (screenPermissions) {
                        const readPermission = screenPermissions.allowToReadData;
                        const writePermission = screenPermissions.allowToWriteData;
                        setReadAccess(readPermission);
                        setWriteAccess(writePermission);
                    }
                };
                getRequest.onerror = (error) => {
                    console.error('Error getting data from IndexedDB:', error.target.error);
                };
            } catch (error) {
                console.error(`Error opening IndexedDB: ${error}`);
            }

        };
        fetchData();
    }, []);

    useEffect(() => {
        if (documents !== undefined) {
            setClinicalDocuments(documents.filter(item => item.Type === 'ph'))
        }
    }, [documents])

    return (
        <>
            <div className='clinical_data_ext_main_container'>
                <div className='hip_configuration_btns'>
                    <div className='hip_configuration_document_title'>
                        <span>{componentHeading}</span>
                    </div>
                    <div className='hip_config_span'>
                        <div className='hip_config_doc_search'>
                            <input type={'text'} placeholder='Search by record' />
                            <img src={SearchImage} alt='search' />
                        </div>
                        <div className='hip_config_btns'>
                            <div className={`hip_config_btn hip_configuration_btn ${writeAccess ? "" : "disabled"}`}
                                onClick={() => {
                                    if (writeAccess) {
                                        dispatch(setDocumentConfig({
                                            isActive: true,
                                            useCase: "Patient History",
                                            name: "Clinical Data Extraction"
                                        }))
                                    }
                                }}
                            >
                                <img src={ConfigImage} alt='config' />
                                <span>Config</span>
                            </div>
                            <div className={`hip_config_btn hip_upload_btn ${writeAccess ? "" : "disabled"}`}
                                onClick={() => {
                                    if (writeAccess) {
                                        dispatch(setUploadAlert({
                                            isActive: true,
                                            configuration: "ph",
                                            goto: "hip-operations/clinical-data-extraction"
                                        }))
                                    }
                                }}
                            >
                                <img src={UploadImage} alt='upload' />
                                <span>Upload</span>
                            </div>
                        </div>
                    </div>
                </div>
                <DocumentsTable
                    columns={columns}
                    documents={ClinicalDocument}
                    navigateTo={{ goto: "/hip-operations/ph", goBack: "/clinical-data-extraction" }}
                />
            </div >
        </>
    )
}

export default ClinicalDataExtraction

const columns = [
    {
        field: "id",
        headerName: "Document Name",
        width: "43%",
        alignment: "left",
    },
    {
        field: "status",
        headerName: "Status",
        width: "15%",
        alignment: "left",
    },
    {
        field: "datemodified",
        headerName: "Date Modified",
        width: "15%",
        alignment: "left",
    },
    {
        field: "dateadded",
        headerName: "Date Added",
        width: "15%",
        alignment: "left",
    },
    {
        field: "optinos",
        headerName: "Actions",
        width: "12%",
        alignment: "left",
    },
];