import React from 'react';
import HipOneLogo from '../../../assets/HIPOneLogo.svg';
import { Typography, Divider } from '@mui/material';
import './InvoiceUI.css'

const InvoiceUI = ({ invoiceData, isLastPage, totalAmount, footerContent }) => {

  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  function getCurrentDate() {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month because it is 0-based.
    const day = String(currentDate.getDate()).padStart(2, '0');
    const year = currentDate.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  const currentDateInMMDDYYYY = getCurrentDate();
  return (
    <div className='invoice_pdf_container'>
      <div className='invoice_pdf_heading'>
        <img src={HipOneLogo} alt="HIPOneLogo" />
        <Divider orientation='vertical' flexItem sx={{ backgroundColor: "#004080" }} />
        <Typography variant='h7' className='invoice_info_contents'>{invoiceData[0].addressLine1}<br />{invoiceData[0].addressLine2}<br />{invoiceData[0].city}<br />Postal Code: {invoiceData[0].postalCode}<br />Main: {invoiceData[0].phone1}</Typography>
      </div>
      <div className='invoice_pdf_sub_heading_contents'>
        <div className='invoice_pdf_sub_heading_contents_bill_info'>
          <Typography variant='h7' className='invoice_pdf_info_headings'>INVOICE</Typography>
          <Typography variant='h7' className='invoice_info_contents'>Bill to: {invoiceData[0].clientName}<br />{invoiceData[0].country}</Typography>
          <Typography variant='h7' className='invoice_info_contents'>Duration: {formatDate(invoiceData[0].billStartDate)} - {formatDate(invoiceData[0].billEndDate)}</Typography>
        </div>
        <Typography variant='h7' className='invoice_info_contents'>Invoice No: {invoiceData[0].invoiceId}<br />Date: {currentDateInMMDDYYYY}</Typography>
      </div>
      <div className='invoice_pdf_table_container'>
        <table className='invoice_pdf_table'>
          <thead>
            <tr>
              {/* <th className='invoice_table_header'>Date</th> */}
              <th className='invoice_table_header'>Description</th>
              <th className='invoice_table_header'>QTY</th>
              <th className='invoice_table_header'>Rate</th>
              <th className='invoice_table_header'>Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.map((item, index) => (
              <tr key={index}>
                <td className='invoice_table_data'>{item.description}</td>
                <td className='invoice_table_data'>{item.successCount}</td>
                <td className='invoice_table_data'>{item.currency}{item.rate}</td>
                <td className='invoice_table_data'>{item.currency}{item.successCount * item.rate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isLastPage && (
        <div className='invoice_pdf_information'>
          <Typography variant='h7' className='invoice_table_amount_total' >Total Amount: {invoiceData[0].currency}{totalAmount}<br /> Due Date: {formatDate(invoiceData[0].dueDate)}</Typography>
          <Typography variant='h7' className='invoice_pdf_info_headings'>Payment Information: </Typography>
          <Divider orientation='horizontal' flexItem sx={{ backgroundColor: "#004080" }} />
          <div className='invoice_pdf_notifications_contents'>
            <Typography variant='h7' className='invoice_pdf_info_headings'>Notifications: </Typography>
            <Typography variant='h7' className='invoice_info_contents'>A total payment of ${totalAmount} is due by {formatDate(invoiceData[0].dueDate)}</Typography>
          </div>
        </div>
      )}
      <div className='invoice_pdf_footer'>
        {footerContent}
      </div>
    </div>
  );
};

export default InvoiceUI;
