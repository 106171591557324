import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutWithBreadCrumb from "../Theme/LayoutWithBreadCrumb";
import ErrorPage from '../Components/ErrorPage/ErrorPage';
import Invoice from './Screens/Invoice/Invoice';
import PatientDataRequestSummary from "./Screens/PatientDataRequestSummary/PatientDataRequestSummary";

function App() {

  return (
    <>
    
      <Routes>
        {/* Components with Header and Navigation  */}

        {/* With Menu Bar */}
        <Route element={<LayoutWithBreadCrumb type="billing" />}>

          <Route path="/invoice" element={<Invoice />} />

          <Route path="/patient-data-request-summary" element={<PatientDataRequestSummary />} />

        </Route>

        {/* Error Route */}
        <Route path="/*" element={<ErrorPage />} />

      </Routes>
      
    </>
  );
}

export default App;
