import React from "react";
import { Route, Routes } from "react-router-dom"; // Import from react-router-dom
import "./App.css";
import ChatboxInterface from "./Components/ChatBoxInterface/ChatboxInterface";
import ContactUs from "./Components/ContactUs/ContactUs";
import { ThemeProvider } from "./Contexts/ThemeContext";
import Master from "./MasterComponent/Master";
import ReactGA from "react-ga";

const App = () => {
  const TRACKING_ID = "G-3SB5MDZ2JX";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname);
  return (
    <div className="main_route">
      <ThemeProvider>
        <Routes>
          <Route element={<Master />}>
            <Route path="/" element={<ChatboxInterface />} />
            <Route path="/contact" element={<ContactUs />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
};
export default App;
