import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  documents_list: [],
  encodedImages: [],
  sections: [],
  uploadedFilename: "",
  extractedInfo: {},
  imageUrl_list: [],
  originalUrlList: [],
  duplicatePages: [],
  blankPages: [],
  boundingBoxes: [],
  isBlankApiCall: false,
  selectedFieldBoundingBoxes: null,
  billname: "",
  billsList: [],
  billUrlList: [],
  mbwBoundingbox: [],
  updatebillsList: "start",
  pageNumber: 1,
  claimProcess_Document_list: [],
  claimProcessMessage: "",
  selectedSection: "",
  extractedFieldChanges: [],
  FieldsUpdated: false,
  vitalData: "",
  initialVitaldata: "",
  selectedSectionImages: [],
  confidenceScoreForExtractedFields: []
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocumentsList: (state, action) => {
      state.documents_list = action.payload;
    },
    setConfidenceScoreForExtractedFields: (state, action) => {
      state.confidenceScoreForExtractedFields = action.payload
    },
    setSelectedSectionImages: (state, action) => {
      state.selectedSectionImages = action.payload;
    },
    setVitalData: (state, action) => {
      state.vitalData = action.payload;
    },
    setInitialVitalData: (state, action) => {
      state.initialVitaldata = action.payload
    },
    setExtractedFieldChanges: (state, action) => {
      state.extractedFieldChanges.push(action.payload)
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload
    },
    setClaimProcessDocumentList: (state, action) => {
      state.claimProcess_Document_list = action.payload;
    },
    setBlankApiCall: (state, action) => {
      state.isBlankApiCall = action.payload;
    },

    setDuplicatePages: (state, action) => {
      state.duplicatePages = action.payload;
    },
    setOriginalUrlList: (state, action) => {
      state.originalUrlList = action.payload;
    },
    setEncodedImages: (state, action) => {
      state.encodedImages = action.payload;
    },
    setUploadedFilename: (state, action) => {
      state.uploadedFilename = action.payload;
    },
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setExtractedInfo: (state, action) => {
      state.extractedInfo = action.payload;
    },
    setImageUrlList: (state, action) => {
      state.imageUrl_list = action.payload;
    },
    deleteDuplicatePage: (state, action) => {
      state.duplicatePages = state.duplicatePages.filter(
        (url) => url !== action.payload
      );
      state.originalUrlList = state.originalUrlList.filter(
        (url) => url !== action.payload
      );
    },
    setBlankPages: (state, action) => {
      state.blankPages = action.payload;
    },
    deleteBlankPage: (state, action) => {
      state.blankPages = state.blankPages.filter(
        (url) => url !== action.payload
      );
      state.originalUrlList = state.originalUrlList.filter(
        (url) => url !== action.payload
      );
      state.imageUrl_list = state.imageUrl_list.filter(
        (url) => url !== action.payload
      );
    },
    setCurrentPageNumber: (state, action) => {
      state.currentPageNumber = action.payload;
    },
    setBoundingBoxes: (state, action) => {
      state.boundingBoxes = action.payload;
    },
    clearBoundingBoxes: (state) => {
      state.boundingBoxes = [];
    },
    setSelectedFieldBoundingBoxes: (state, action) => {
      state.selectedFieldBoundingBoxes = action.payload;
    },
    removeDupliacte: (state, action) => {
      const pagesToRemove = action.payload.page_index;

      state.imageUrl_list = state.imageUrl_list.filter(item => !pagesToRemove.includes(item.page_number));
      console.log('Updated imageUrl_list:', state.imageUrl_list);

      // Assuming originalUrlList also has the same structure as imageUrl_list
      state.originalUrlList = state.originalUrlList.filter(item => item.page_number !== pagesToRemove[0]);
    },


    removeBlankPage: (state, action) => {
      state.imageUrl_list.splice(action.payload.page_index, 1)

      state.originalUrlList = state.originalUrlList.filter((item) => item.page_number != action.payload.page_number)

    },
    setClaimProcessMessage: (state, action) => {
      state.claimProcessMessage = action.payload;
    },
    setExtractedInfoValue: (state, action) => {
      const { name, initialValueRef, newValue } = action.payload;
      // Ensure the field exists in extractedInfo before attempting to update
      if (state.extractedInfo[name] !== undefined) {
        state.extractedInfo[name] = state.extractedInfo[name].replace(
          initialValueRef,
          newValue
        );
      }
      const selectedSection = state.selectedSection
      const changePayload = {
        [selectedSection]: { ...state.extractedInfo },
      };
      state.extractedFieldChanges.push(changePayload);

    },
    clearExtractedFieldChanges: (state) => {
      state.extractedFieldChanges = [];
    },
    changeBillName: (state, action) => {
      state.billname = action.payload
    },
    setBillUrlList: (state, action) => {
      state.billUrlList = action.payload
    },
    setBillList: (state, action) => {
      state.billsList = action.payload;
    },
    updateBillStatus: (state, action) => {
      const name = action.payload;

      // Find the bill with the specified name and update its status
      const updatedBillsList = state.billsList.map((bill) =>
        bill.Name === name ? { ...bill, Status: "Extracted" } : bill
      );

      // Update the state with the new billsList
      state.billsList = updatedBillsList;
    },
    setmbwBoundingBox: (state, action) => {
      state.mbwBoundingbox = action.payload
    }
    ,
    setUpdateBillList: (state, action) => {
      if (state.updatebillsList === "start") { state.updatebillsList = "end" }
      else {
        state.updatebillsList = "start"
      }
    },
    addBillInList: (state, action) => {
      state.billsList.push(action.payload)
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload
    },
    setUpdateFields: (state, action) => {
      state.FieldsUpdated = action.payload
    },
    updateVitalData: (state, action) => {
      const { key, value } = action.payload;
      if (state.vitalData[key]) {
        state.vitalData[key].value = value;
      }
    },

  },
});
export const {
  setDocumentsList,
  setEncodedImages,
  setUploadedFilename,
  setSections,
  setExtractedInfo,
  setImageUrlList,
  setOriginalUrlList,
  setDuplicatePages,
  deleteDuplicatePage,
  setBlankPages,
  setBlankApiCall,
  deleteBlankPage,
  setBoundingBoxes,
  clearBoundingBoxes,
  setSelectedFieldBoundingBoxes,
  removeDupliacte,
  setClaimProcessDocumentList,
  setClaimProcessMessage,
  setExtractedInfoValue,
  setSelectedSection,
  clearExtractedFieldChanges,
  removeBlankPage,
  changeBillName,
  setBillUrlList,
  setBillList,
  updateBillStatus,
  setmbwBoundingBox,
  setUpdateBillList,
  addBillInList,
  setPageNumber,
  setUpdateFields,
  setVitalData,
  updateVitalData,
  setInitialVitalData,
  setSelectedSectionImages,
  setConfidenceScoreForExtractedFields
} = documentsSlice.actions;
export const getClaimProcessMessage = (state) => state.documents.claimProcessMessage;
export const getSelectedSectionImage = (state) => state.documents.selectedSectionImages;
export const getConfidenceScoreOfExtractedFields = (state) => state.documents.confidenceScoreForExtractedFields;
export const getDocuments = (state) => state.documents.documents_list;
export const getEncodeImages = (state) => state.documents.encodedImages;
export const getUploadedFilename = (state) => state.documents.uploadedFilename;
export const getSections = (state) => state.documents.sections;
export const getExtractedInfo = (state) => state.documents.extractedInfo;
export const getImageUrlList = (state) => state.documents.imageUrl_list;
export const getOriginalUrlList = (state) => state.documents.originalUrlList;
export const getDuplicatePagesList = (state) => state.documents.duplicatePages;
export const getBlankPages = (state) => state.documents.blankPages;
export const getisBlankApiCall = (state) => state.documents.isBlankApiCall;
export const getBoundingBoxes = (state) => state.documents.boundingBoxes;
export const getSelectedFieldBoundingBoxes = (state) =>
  state.documents.selectedFieldBoundingBoxes;
export const getClaimProcessDocumentList = (state) => state.documents.claimProcess_Document_list;
export const getSelectedSection = (state) => state.documents.selectedSection;
export const getExtractedFileChanges = (state) => state.documents.extractedFieldChanges;
export const getVitalsData = (state) => state.documents.vitalData;
export const getInitialVitalsData = (state) => state.documents.initialVitaldata
export default documentsSlice.reducer;
