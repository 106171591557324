import React, { useEffect, useState } from "react";
import "./BoundingBox.css";

function MedicalReviewBoundingBox({ coordinates }) {
  const [con_level, setConLevel] = useState("0,128,0"); // Green color
  const imageHeight = 59; // Assuming imageHeight is in pixels
  const imageWidth = 70; // Assuming imageWidth is in pixels
  if (coordinates) {
    // Extract the bottom-left and top-right coordinates
    console.log(coordinates)
    const { bottom, left, right, top } = coordinates;
    console.log(bottom, left, right, top)
    const wLeft = left[0];
    const wTop = left[1];
    console.log(wLeft, wTop)
    // Calculate width and height
    const width = bottom[0] - left[0];
    const height = top[1] - left[1];
    // Convert to pixel values
    const leftInPixels = wLeft * imageWidth;
    const topInPixels = wTop * imageHeight;
    const widthInPixels = width * imageWidth
    const heightInPixels = height * imageHeight + 30;
    console.log(leftInPixels, topInPixels, widthInPixels, heightInPixels)
    return (
      <div
        style={{
          position: "absolute",
          left: `${leftInPixels}px`,
          top: `${topInPixels}px`,
          width: `${widthInPixels}px`,
          height: `${heightInPixels}px`,
          backgroundColor: `rgba(${con_level}, 0.4)`,
        }}
        className="bounding_box"
      ></div>
    );
  } else {
    return null;
  }
}

export default MedicalReviewBoundingBox;