import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Select, Typography, MenuItem, Button, FormControl, FormLabel, Box, FormGroup, FormControlLabel, Checkbox, FormHelperText, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxNull from "../../../../assets/Icons/Checkboxnull.svg";
import CheckBoxTick from "../../../../assets/Icons/Checkbox_tick.svg";
import CloseIcon from "@mui/icons-material/Close";
import GetCookie from '../../../../HIPCookies/getCookie';

function PermissionToSecurity() {

  const [active, setActive] = useState(false);
  const [securityGroups, setSecurityGroups] = useState([]);
  const [selectedSecurityGroup, setSelectedSecurityGroup] = useState("");
  const [selectedSecurityGroupId, setSelectedSecurityGroupId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissionModules, setPermissionModules] = useState([]);
  const [selectAllEnable, setSelectAllEnable] = useState(true);
  const [clearAllEnable, setClearAllEnable] = useState(false);
  const [newlySelectedPermissions, setNewlySelectedPermissions] = useState([])
  const [selectedRead, setSelectedRead] = useState(false);
  const [selectedWrite, setSelectedWrite] = useState(false);
  const [selectedPHI, setSelectedPHI] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;
  const userInfo = (JSON.parse(GetCookie('userInfo')) || {}).userId + ":" + (JSON.parse(GetCookie('userInfo')) || {}).userNameDisplay;

  useEffect(() => {
    handleSecurityGroupChange()
  }, [selectedSecurityGroup])

  useEffect(() => {
    fetchPermissionsModules();
    fetchSecurityGroups();
    fetchPermissions();
  }, []);


  useEffect(() => {
    // Check if all user groups are selected
    const allPermissionIds = permissions.map((item) => item.id);
    const isAllSelected = selectedPermissions.length === allPermissionIds.length;
    // Enable/disable buttons accordingly
    setSelectAllEnable(!isAllSelected);
    setClearAllEnable(isAllSelected);
  }, [selectedPermissions, permissions]);

  useEffect(() => {
    if (selectedSecurityGroup) {
      const selectedSecurityGroupArr = securityGroups.filter(
        (security) => security.displayName === selectedSecurityGroup
      );

      if (selectedSecurityGroupArr.length > 0) {
        const selectedId = parseInt(selectedSecurityGroupArr[0].id);

        setSelectedSecurityGroupId(selectedId);
      }
    }
  }, [selectedSecurityGroup, securityGroups]);

  const functionOpenPopup = () => {

    if (selectedPermissions.length !== 0 && selectedSecurityGroup !== "") {
      if (selectedRead || selectedWrite) {
        setActive(true);
      } else {
        toast.error('Please select at least one option among "Read & Write"', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    else if (selectedSecurityGroup === "Select") {
      setSelectedSecurityGroup('');
      return;
    }
    else if (selectedPermissions.length === 0 && selectedSecurityGroup !== "") {
      toast.error('Please select at least one permission to Save "Security Group"', {
        position: toast.POSITION.TOP_CENTER,
      })
    }
    else {
      setActive(false);
    }
  }

  const closeDialogPopup = () => {
    setActive(false);
  };

  const fetchSecurityGroups = () => {
    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/SecurityGroup`,
      {
        headers: {
          'Authorization': `Bearer ${jWTToken}`
        },
      })
      .then((response) => {
        setSecurityGroups(response.data);
      })
      .catch((error) => {
        console.error(error);
      })

  }

  const fetchPermissions = () => {
    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Permission`,
      {
        headers: {
          'Authorization': `Bearer ${jWTToken}`
        },
      })
      .then((response) => {
        setPermissions(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
  }

  const fetchPermissionsModules = () => {
    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/PermissionModule`,
      {
        headers: {
          'Authorization': `Bearer ${jWTToken}`
        },
      })
      .then((response) => {
        setPermissionModules(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
  }

  // Function to chunk the securityGroups into rows of 4
  const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const handleCheckboxChange = (id) => {
    setSelectedPermissions((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });

    if (!selectedPermissions.includes(id) && !newlySelectedPermissions.includes(id)) {
      setNewlySelectedPermissions((prevSelected) => [...prevSelected, id]);
    }
  };

  const handleReadWriteChange = (checkboxName) => {
    switch (checkboxName) {
      case "read":
        setSelectedRead(!selectedRead);
        break;
      case "write":
        setSelectedWrite(!selectedWrite);
        break;
      case "phi":
        setSelectedPHI(!selectedPHI);
        break;
      default:
        break;
    }
  };

  const handleSecurityGroupChange = () => {
    setFormSubmitted(false)
    setSelectedPermissions([]);
    setSelectedRead(false);
    setSelectedWrite(false);
    setSelectedPHI(false);
    if (selectedSecurityGroup) {
      const selectSecurityGroupObject = securityGroups.find(securityGroup => securityGroup.displayName === selectedSecurityGroup);
      if (selectSecurityGroupObject) {
        const selectedSecurityGroupId = selectSecurityGroupObject.id;

        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Permission/SecurityGroupIds`,
          {
            headers: {
              'Authorization': `Bearer ${jWTToken}`,
              SecurityGroupIds: selectedSecurityGroupId
            }
          }
        ).then((response) => {
          if (response.status === 200) {
            const idArrayPermissions = response.data.map((item) => item.id);
            setSelectedPermissions(idArrayPermissions);

            const selectedPermissionData = response.data.find((item) => item.id === selectedSecurityGroupId);
            const allowToReadData = response.data.some((item) => item.allowToReadData);
            setSelectedRead(allowToReadData);

            // Check if "allowToWriteData" and set selectedWrite accordingly
            const allowToWriteData = response.data.some((item) => item.allowToWriteData);
            setSelectedWrite(allowToWriteData);

            // Check if "viewPHIData" and set selectedPHI accordingly
            const viewPHIData = response.data.some((item) => item.viewPHIData);
            setSelectedPHI(viewPHIData);
          }
        })
      }
    }
  };

  const handleSelectAll = () => {
    const allPermissionIds = permissions.map((item) => item.id);
    setSelectedPermissions(allPermissionIds);
  };

  const handleClearAll = () => {
    setSelectedPermissions([]);
    setSelectedRead(false);
    setSelectedWrite(false);
    setSelectedPHI(false);
  };

  const handleSave = () => {
    axios.put(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1//SecurityGroup/${selectedSecurityGroupId}`,
      {
        securityGroupName: selectedSecurityGroup,
        displayName: selectedSecurityGroup,
        LastUpdatedBy: userInfo,
      },
      {
        headers:
        {
          'Authorization': `Bearer ${jWTToken}`,
          PermissionId: selectedPermissions,
          relation: true,
          AllowToReadData: selectedRead,
          AllowToWriteData: selectedWrite,
          ViewPHIData: selectedPHI,
        }
      }
    ).then(resp => {
      if (resp.status === 200 && resp.data !== "Data already exist") {
        toast.success('Permissions assigned successfully.', {
          position: toast.POSITION.TOP_CENTER,
        })
        closeDialogPopup();
        setSelectedPermissions([]);
        setSelectedSecurityGroup("")
        setSelectedRead(false);
        setSelectedWrite(false);
        setSelectedPHI(false);
      }
    })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
  };

  return (
    <form onSubmit={handleSubmit} className='permission_security_container'>
      <Typography variant='h7' className='header_container'> Assign Permissions to Security Group </Typography>
      <div className='security_role_form_container'>
        <Typography variant="h7" className="security_role_form_heading" > SECURITY GROUP </Typography>
        <Select
          name="securityGroup"
          IconComponent={ExpandMoreIcon}
          className='security_role_select'
          fullWidth
          displayEmpty
          value={selectedSecurityGroup}
          onChange={(e) => {
            const selectedDisplayName = e.target.value;
            setSelectedSecurityGroup(selectedDisplayName)
          }}
          renderValue={(value) => (value.length !== 0 ? value : "Select")}
          error={formSubmitted && selectedSecurityGroup.length === 0}
          defaultValue="Select"
        >
          <MenuItem className="select_content" value="Select" > Select </MenuItem>
          {securityGroups.map((item) => (
            <MenuItem key={item.id} className="select_content" value={item.displayName}  >
              {item.displayName}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: "#d32f2f" }}>{formSubmitted && !selectedSecurityGroup.length && "Please select Security Group"}</FormHelperText>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl className='roles_permissions_form_container' component="fieldset" variant="standard">
          {permissionModules.map((module) => (
            <React.Fragment key={module.id}>
              <Typography className="permission_module_heading">{module.displayName}</Typography>
              <FormControl>
                {chunkArray(permissions.filter((perm) => perm.displayName.startsWith(module.displayName)), 4).map((row, rowIndex) => (
                  <div key={rowIndex} className="checkbox_row">
                    {row.map((item) => (
                      <label className="roles_permission_checkbox" key={item.id}>
                        <Checkbox
                          checked={selectedPermissions.includes(item.id)}
                          onChange={() => handleCheckboxChange(item.id)}
                          checkedIcon={<img src={CheckBoxTick} alt='checkbox' />}
                          icon={<img src={CheckBoxNull} alt='checkbox' />}
                          color="primary"
                          inputProps={{ "aria-label": "checkbox" }}
                        />
                        {item.displayName.substring(item.displayName.indexOf(':') + 2)}
                      </label>
                    ))}
                  </div>
                ))}
              </FormControl>
            </React.Fragment>
          ))}
          <Typography className="permission_module_heading" > Read & Write </Typography>
          <FormControl>
            <div className="checkbox_row">
              <label className="roles_permission_checkbox">
                <Checkbox
                  checked={selectedRead}
                  checkedIcon={<img src={CheckBoxTick} alt='checkbox' />}
                  onChange={() => handleReadWriteChange("read")}
                  icon={<img src={CheckBoxNull} alt='checkbox' />}
                  color="primary"
                  inputProps={{ "aria-label": "checkbox" }}
                />
                Allow to Read Data
              </label>
              <label className="roles_permission_checkbox">
                <Checkbox
                  checked={selectedWrite}
                  checkedIcon={<img src={CheckBoxTick} alt='checkbox' />}
                  onChange={() => handleReadWriteChange("write")}
                  icon={<img src={CheckBoxNull} alt='checkbox' />}
                  color="primary"
                  inputProps={{ "aria-label": "checkbox" }}
                />
                Allow to Edit Data
              </label>
              <label className="roles_permission_checkbox">
                <Checkbox
                  checked={selectedPHI}
                  checkedIcon={<img src={CheckBoxTick} alt='checkbox' />}
                  onChange={() => handleReadWriteChange("phi")}
                  icon={<img src={CheckBoxNull} alt='checkbox' />}
                  color="primary"
                  inputProps={{ "aria-label": "checkbox" }}
                />
                Allow to View PHI
              </label>
            </div>
          </FormControl>
        </FormControl>
      </Box>

      <Dialog className="roles_permission_dialog_box" open={active} onClose={closeDialogPopup} fullWidth >
        <CloseIcon onClick={closeDialogPopup} className="popup_close_icon" />
        <DialogTitle> {"Update Permissions to Security Group"} </DialogTitle>
        <DialogContent>
          <DialogContentText> {`Do you want to update Security Group : ${selectedSecurityGroup}?`} </DialogContentText>
        </DialogContent>
        <DialogActions className="roles_permission_dialog_box_buttons">
          <Button onClick={handleSave} className="roles_permission_sub_button" > Yes </Button>
          <Button onClick={closeDialogPopup} className="roles_permission_cancel_btn">No </Button>
        </DialogActions>
      </Dialog>
      <FormControl className='buttons_form_container' >
        <Button type="button" className={`all_button ${!selectAllEnable || selectedSecurityGroup === "Select" || selectedSecurityGroup === "" ? 'disabled-button' : ''}`}
          onClick={handleSelectAll} disabled={!selectAllEnable || selectedSecurityGroup === "Select" || selectedSecurityGroup === ""} // Disable when not all are selected 
        >
          Select All
        </Button>
        <Button type="button" className={`all_button ${!clearAllEnable ? 'disabled-button' : ''}`} onClick={handleClearAll}
          disabled={!clearAllEnable} // Disable when not all are selected 
        >
          Clear All
        </Button>
        <Button type="submit" className='Save_changes_button' onClick={functionOpenPopup} >
          Save Changes
        </Button>
      </FormControl>
    </form >
  )
}

export default PermissionToSecurity;