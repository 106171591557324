import React from "react";
import "./ButtonList.css"; // Importing the CSS

const ButtonList = ({ onItemSelect }) => {
  const items = [
    { link: "https://www.cms.gov", label: "CMS Official Site" },
    {
      link: "https://www.cms.gov/medicare/regulations-guidance/manuals",
      label: "CMS Manuals",
    },
  ];

  return (
    <div className="ask-button-container">
      {items.map((item, index) => (
        <button key={index} className="ask-button">
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.label} {/* Using label for display */}
          </a>
        </button>
      ))}
    </div>
  );
};

export default ButtonList;
