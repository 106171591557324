// messageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dbMessages: [],
  Theme: "",
  isPrivacyModeOndb: false,
};

const DbMessageSlice = createSlice({
  name: "dbMessage",
  initialState,
  reducers: {
    addDbMessage: (state, action) => {
      state.dbMessages.push(action.payload);
    },
    setThemedb: (state, action) => {
      if (action.payload === true) {
        state.Theme = "dark_";
        state.isPrivacyModeOndb = true;
      } else if (action.payload === false) {
        state.Theme = "";
        state.isPrivacyModeOndb = false;
      }
    },
  },
});

export const { addDbMessage, setThemedb } = DbMessageSlice.actions;
export default DbMessageSlice.reducer;
