import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import HIPClinical from '../../../assets/HIP Clinical.svg';
import SendOutlineIcon from '../../../assets/sendOuline.svg';
import { openDatabase } from '../../../IndexedDBUtility/IndexedDBUtility';
import "../../../Theme/Dashboard.css";
import { useNavigate } from "react-router-dom";

function Dashboard() {
    const [permissionData, setPermissionData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = await openDatabase();

                const transaction = db.transaction(['permissionData'], 'readonly');
                const objectStore = transaction.objectStore('permissionData');

                const getRequest = objectStore.getAll();

                getRequest.onsuccess = (event) => {
                    const storedData = event.target.result;

                    const data = storedData[0].value;
                    const clinicalModule = data.find((module) => module.moduleName === 'Clinical')
                    const displayNamesList = clinicalModule.permissionsDataArray.map((permission) => permission.displayName);
                    setPermissionData(displayNamesList)
                };

                getRequest.onerror = (error) => {
                    console.error('Error getting data from IndexedDB:', error.target.error);
                };


            } catch (error) {
                console.error(`Error opening IndexedDB: ${error}`);
            }

        };

        fetchData();
    }, []);
    return (
        <>
            <Grid container className="landing_screen_menu_container" >
                <Grid item xs={12}>
                    <Stack>
                        <Box className="landing_screen_scale">
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <img className="landing_screen_scale_icon" src={HIPClinical} alt="hipClinical" />
                            </Box>
                            <Box className="landing_screen_heading" >
                                <Typography variant="h3" >Hip One Clinical</Typography>
                            </Box>
                            <Box className="landing_screen_heading" sx={{ margin: 4 }} >
                                <Typography variant="h4">Clinical Operation Optimized</Typography>
                            </Box>

                            <Box className="landing_screen_heading" sx={{ gap: 5, p: 2 }}>
                                {permissionData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {item === "Patient Search" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" onClick={() => navigate("/hip-clinical/single-patient-search")} >
                                                <p className="landing_screen_button_text"> Patient Search </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "Medical Review" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" onClick={() => navigate("/hip-clinical/medical-review")}>
                                                <p className="landing_screen_button_text"> Medical Review </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "Clinical Summarization" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" onClick={() => navigate("/hip-clinical/ccda-summarization")}>
                                                <p className="landing_screen_button_text"> Clinical summarization </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                        {item === "Operational Intelligence" && (
                                            <Button className="landing_screen_button" variant="contained" color="primary" onClick={() => navigate("/hip-clinical/operational-intelligence")}>
                                                <p className="landing_screen_button_text"> Operational Intelligence </p>
                                                <img src={SendOutlineIcon} alt='arrow' />
                                            </Button>
                                        )}
                                    </React.Fragment>

                                ))
                                }

                            </Box>

                            <Box className="landing_screen_menu_sub_content" >
                                <Typography variant="h4" className="landing_screen_content_text" >
                                    HIP Clinical optimizes clinical operations with a focus on improving productivity of clinical teams by leveraging <br />
                                    leading technology solutions and frameworks including healthcare centered LLM models, GEM governance <br />
                                    framework, FHIR interoperability for data ingestion and ML algorithms for clinical decision support.
                                </Typography>
                            </Box>
                            <Box className="landing_screen_menu_sub_content" >
                                <Typography variant="h4" className="landing_screen_content_text" >
                                    We have optimized platform capabilities for utilization and care management, claim review and risk adjustment.
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default Dashboard
