import React from 'react'
import "./HiPBreadCrumb.css"
import { useLocation, NavLink as Link } from 'react-router-dom'
import RightArrowImage from '../../../assets/Icons/CaretBreadcrumb.svg'
import HomeImage from '../../../assets/Icons/Home-Icon.svg'

function HiPBreadCrumb() {
    const location = useLocation()
    return (
        <div className='profile_bread_crumb_navigations'>
            <div className='profile_bread_crumb_navigations_content' >
                <a href='/dashboard'>
                    <img src={HomeImage} alt='home' style={{ width: "14px" }} />
                </a>
                <img src={RightArrowImage} alt='bread-crumb-arrow-right' style={{ width: "12px", margin: "-2px 5px" }} />
            </div>
            {
                Routes.filter(item => item.currentroute.includes(location.pathname)).map((e, index) => {
                    return (
                        <div key={index}>
                            {
                                e.currentroute === '/profile/edit-profile' ?
                                    <>
                                        <Link to='/profile/edit-profile'> Profile</Link>
                                        <span>
                                            <img src={RightArrowImage} alt='bread-crumb-arrow-right' className='nav_img' />
                                            <Link className="nav_item_bold" to='/profile/edit-profile'>Edit Profile</Link>
                                        </span>
                                    </>

                                    :
                                    <>
                                        <Link to='/profile/edit-profile'>Profile</Link>
                                        {
                                            //   e.title.map((e2, index1) => {
                                            e.title.map((title, index1) => {
                                                return (
                                                    <span key={index1}>
                                                        <img src={RightArrowImage} alt='bread-crumb-arrow-right' className='profile_nav_img' />
                                                        <Link
                                                            className={e.title.length - 1 === index1 && "nav_item_bold"}
                                                            //  to={e.routes[index1]}
                                                            to={index1 === 0 ? e.routes[1] : e.routes[index1]}
                                                            key={index1}
                                                        // >{e.title[index1]}</Link>
                                                        >{title}</Link>
                                                    </span>
                                                )
                                            })
                                        }
                                    </>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default HiPBreadCrumb


const Routes = [
    {
        title: ["Edit Profile"],
        currentroute: "/profile/edit-profile",
        routes: ["profile/edit-profile", "/profile/edit-profile"]
    },

]
