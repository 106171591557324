import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import { Box, Button, Stack, TextField, Typography, Tooltip, IconButton, } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './SetUpPassword.css';
import HIPLogo from '../assets/HIPOneLogo.svg';
import passwordPolicyIcon from '../assets/Icons/passwordPolicyIcon.svg';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Eye from "../assets/NewIcons/View_alt_light.svg";
import EyeShow from "../assets/NewIcons/View_alt_fill.svg";
import HIPBackground from "../assets/NewIcons/Logo_BG.svg";
import { useDispatch } from "react-redux";
import { setToastAlert } from "../Redux/features/AlertsSlice";

function SetUpPassword() {

    const dispatch = useDispatch()
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordPolicyVisible, setPasswordPolicyVisible] = useState(false);
    const [hasToastError, setHasToastError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        if (hasToastError) {
            setPasswordPolicyVisible(true);
            setIsButtonDisabled(false);
        }
    }, [hasToastError]);

    const togglePasswordPolicy = () => {
        setPasswordPolicyVisible(!passwordPolicyVisible);
    };

    const passwordPolicyContent = (
        <div>
            <ul>
                <li>Your password must be at least 12 characters long.</li>
                <li>It should include at least one uppercase and lowercase letters.</li>
                <li>It should include at least one number and special characters to enhance complexity.</li>
                <li>Avoid common names or guessable sequences (eg. Don't set password Containing '1234' but can have at least 3 sequence numbers.)</li>
            </ul>
        </div>
    );

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleTogglePasswordVisibilityConfirm = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    // const isSequenceValid = (newPassword) => {
    //     // Check if the password contains a sequence of more than three consecutive digits
    //     return !/\d{4,}/.test(newPassword);
    // };



    const isSequenceValid = (newPassword) => {
        const consecutiveRegex = /(?:0123|1234|2345|3456|4567|5678|6789)/;
        return !consecutiveRegex.test(newPassword);
    };

    const handleChangePassword = (event) => {
        const newPassword = event.target.value.replace(/\s/g, '');
        if (isSequenceValid(newPassword) || newPassword === '') {
            setPassword(newPassword);
        } else {
            dispatch(setToastAlert({
                isactive: true,
                type: 'error',
                exp: 10000,
                message: "Password should not contain a sequence of more than 3 consecutive digits."
            }))
            setHasToastError(true);
            setPasswordPolicyVisible(true);
        }
        if (hasToastError) {
            setHasToastError(false);
            setPasswordPolicyVisible(false);
        }
    };

    const handleChangeConfirmPassword = (event) => {
        const newConfirmPassword = event.target.value.replace(/\s/g, '');
        if (isSequenceValid(newConfirmPassword) || newConfirmPassword === '') {
            setConfirmPassword(newConfirmPassword);
        } else {
            dispatch(setToastAlert({
                isactive: true,
                type: 'error',
                exp: 10000,
                message: "Password should not contain a sequence of more than 3 consecutive digits."
            }))
            setHasToastError(true);
            setPasswordPolicyVisible(true);
        }
        if (hasToastError) {
            setHasToastError(false);
            setPasswordPolicyVisible(false);
        }
    };

    const handleSavePassword = async () => {
        setPassword('');
        setConfirmPassword('');
        setIsButtonDisabled(true);
        if (!isPasswordValid(password)) {
            dispatch(setToastAlert({
                isactive: true,
                type: 'error',
                exp: 10000,
                message: "Password doesn\'t match with password policy."
            }))
            setHasToastError(true);
            setIsButtonDisabled(false);
        } else if (password !== confirmPassword) {
            dispatch(setToastAlert({
                isactive: true,
                type: 'error',
                exp: 10000,
                message: "New Password and Confirm Password must be the same."
            }))
            setHasToastError(true);
            setIsButtonDisabled(false);
        } else {
            try {
                const response = await axios.put(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Users`, {
                    Id: queryParams.get('id'),
                    Password: password
                });
                if (response.status === 200) {
                    dispatch(setToastAlert({
                        isactive: true,
                        type: 'success',
                        exp: 10000,
                        message: "Password updated successfully."
                    }))
                    setTimeout(() => {
                        window.open("/Login", "_self");
                    }, 1000);
                } else {
                    dispatch(setToastAlert({
                        isactive: true,
                        type: 'error',
                        exp: 10000,
                        message: "Failed to update password. Please try again."
                    }))
                    setIsButtonDisabled(false);
                }
            } catch (error) {
                dispatch(setToastAlert({
                    isactive: true,
                    type: 'error',
                    exp: 10000,
                    message: "An error occurred while updating the password. Please try again."
                }))
                setIsButtonDisabled(false);
            }
        }
    };

    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&]).{12,}$/;
        if (passwordRegex.test(password)) {
            return true;
        }
    };

    const handleSignup = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <div className="app_container">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} className="setup_password_grid" sx={{ backgroundImage: `url(${HIPBackground})` }} >
                            <Stack direction="column" className="setup_password_grid_container" >
                                <Box>
                                    <Typography variant="jumbotron" className="setup_password_grid_content" >
                                        What is Health <br /> Intelligence <br />Platform?
                                    </Typography>
                                    <Typography variant="h1" className="setup_password_grid_content_text" >
                                        Efficiency through intelligence.
                                    </Typography>
                                    <Typography variant="jumbotron" className="setup_password_grid_content" >
                                        Why Choose Health Intelligence Platform?
                                    </Typography>
                                    <Typography variant="h1" className="setup_password_grid_content_text" >
                                        Health Intelligence Platform helps <br /> business and
                                        clinical operations be <br /> more efficient and creates value
                                        <br /> through intelligence.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ height: "100vh", position: 'relative' }}>
                            <Box className="setup_password_grid_box" elevation={0} >
                                <div className="setup_password_content">
                                    <form onSubmit={handleSignup} className="setup_password_form">
                                        <div className="setup_password_gen_logo">
                                            <img src={HIPLogo} alt="HIP One" />
                                        </div>
                                        <div className='setup_password_heading_container'>
                                            <Typography variant="h3" className="setup_password_heading"> Setup Password </Typography>
                                            <Tooltip
                                                title={passwordPolicyVisible ? passwordPolicyContent : ''}
                                                open={passwordPolicyVisible}
                                                placement="bottom-start"
                                                interactive
                                                PopperProps={{
                                                    strategy: 'fixed',
                                                    modifiers: [
                                                        {
                                                            name: 'flip',
                                                            enabled: false,
                                                        },
                                                        {
                                                            name: 'preventOverflow',
                                                            options: {
                                                                rootBoundary: 'viewport',
                                                                opacity: '0',
                                                            },
                                                        },
                                                    ],
                                                }}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            display: 'flex',
                                                            width: '22vw !important',
                                                            padding: '8px 8px 5px 2px !important',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            bgcolor: '#F1F1F1',
                                                            borderRadius: '8px',
                                                            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.16)',
                                                            color: '#252525',
                                                            fontFamily: 'Inter',
                                                            fontSize: '12px',
                                                            fontStyle: 'normal',
                                                            fontWeight: 500,
                                                            lineHeight: '16px',
                                                            marginLeft: '-2px !important',
                                                            marginRight: '0px !important',
                                                            marginTop: '2px !important',
                                                            transition: 'opacity 0s ease-in-out !important',
                                                        },
                                                    },
                                                }}
                                            >
                                                <IconButton className="setup_password_icon_button_container" onMouseEnter={togglePasswordPolicy} onMouseLeave={togglePasswordPolicy}>
                                                    <img src={passwordPolicyIcon} alt="policyIcon" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <Typography variant="h5" className="setup_password_heading_subtext" >
                                            Enter your New and Confirm Password
                                        </Typography>
                                        <Typography variant="h5" className="setup_password_text_heading" >
                                            New Password
                                        </Typography>
                                        <div className="setup_password_container_image">
                                            <TextField
                                                sx={{ marginBottom: "0.625rem" }}
                                                className="setup_password_text_field"
                                                inputProps={{ maxLength: 100 }}
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="Enter New Password"
                                                value={password}
                                                onChange={handleChangePassword}
                                                onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                                            >
                                            </TextField>
                                            {showPassword ?
                                                (<img src={EyeShow} alt="Eyeshow" onClick={handleTogglePasswordVisibility} />)
                                                : <img src={Eye} alt="Eyehide" onClick={handleTogglePasswordVisibility} />}
                                        </div>
                                        <Typography variant="h5" className="setup_password_text_heading" >
                                            Confirm New Password
                                        </Typography>
                                        <div className="setup_password_container_image">
                                            <TextField
                                                sx={{ marginBottom: "0.625rem" }}
                                                className="setup_password_text_field"
                                                inputProps={{ maxLength: 100 }}
                                                name="password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                placeholder="Re-enter New Password"
                                                value={confirmPassword}
                                                onChange={handleChangeConfirmPassword}
                                            >
                                            </TextField>
                                            {showConfirmPassword ?
                                                (<img src={EyeShow} alt="Eyeshow" onClick={handleTogglePasswordVisibilityConfirm} />)
                                                : <img src={Eye} alt="Eyehide" onClick={handleTogglePasswordVisibilityConfirm} />}
                                        </div>
                                        <div>
                                            <Button className="setup_password_button" type="submit" variant="contained" color="primary" disabled={isButtonDisabled} onClick={handleSavePassword} >
                                                Send
                                            </Button>
                                        </div>
                                        <div className="setup_password_login_content" >
                                            <Typography variant="h5" style={{ paddingTop: '2px', paddingRight: '5px' }}>
                                                Do you want login?
                                            </Typography>
                                            <a className="setup_password_redirect" href="/login" >
                                                Login
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </Box>
                            <div className="setup_password_privacy_containers">
                                <div className="setup_password_privacy_details">
                                    <a
                                        href="https://www.genzeon.com/privacy-policy/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    <label>Copyright © Genzeon | All Rights Reserved</label>
                                </div>
                            </div>
                        </Grid>
                    </Grid >
                </Box>
            </div >
        </>
    );
};

export default SetUpPassword