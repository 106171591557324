import React from "react";
import "../../../Theme/HIPBreadCrumb.css";
import { NavigateNext } from "@mui/icons-material";
import { useLocation, NavLink as Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RightArrowWhiteImage from "../../../assets/Icons/right-arrow-white.png"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useState } from "react";
import {
  clearState, setIsPdfBeingUploadedState, setIsPdfUploadedOneTimeState,
  setIsPdfUploadedState, setPrivacyPopup
} from "../../../Redux/features/MessageSlice";
import { cancelUpload } from "../../Screens/HipConnectScreen/InteractWithPdf/AxiosInstance";
import BackImage from '../../../assets/Back.svg';
import HomeImage from '../../../assets/home.svg';
import RightArrowImage from '../../../assets/RightArrow.svg';


function HiPBreadCrumb() {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(window.location.pathname)
  const theme = useSelector((state) => state.messages.Theme) || "";
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const privacyPopupState = useSelector((state) => state.messages.privacyPopup);
  const chatHistoryState = useSelector((state) => state.messages.chatHistory);

  function handleMenuClick(menu) {
    setSelectedMenu(menu);
    if (privacyPopupState) {
      setIsDialogOpen(true);
      return;
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmNavigation = () => {
    if (cancelUpload) {
      cancelUpload("Request canceled by the user.");
    }
    dispatch(setIsPdfBeingUploadedState(false));
    dispatch(setIsPdfUploadedState(false));
    if (Object.keys(chatHistoryState).length === 0) {
      dispatch(setIsPdfUploadedOneTimeState(false));
    } else {
      dispatch(setIsPdfUploadedOneTimeState(true));
    }
    dispatch(clearState());
    dispatch(setPrivacyPopup(false));
    navigateTo(selectedMenu);
    handleDialogClose();
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="bread_crumb_navigations">
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <DialogContentText>
            Leaving this screen will result in the loss of your interactive data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmNavigation} color="primary">
            OK
          </Button>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className="bread_crumb_back_container" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
        <img src={BackImage} alt="" style={{ width: '18px' }} />
      </div>
      <div className='bread_crumb_navigations_content' >
        <a href='/dashboard'>
          <img src={HomeImage} alt='home' />
        </a>
        <div className='bread_crumb_arrow_container'>
          {theme === "dark_" ? <img src={RightArrowWhiteImage} alt='arrow right' className='bread_crumb_arrow' /> :
            <img src={RightArrowImage} alt='arrow right' className='bread_crumb_arrow' />
          }
        </div>
      </div>
      {Routes.filter(
        (item) => item.currentroute === location.pathname || item.currentroute.split("/")[1] === location.pathname.split("/")[2]
      ).map((e, index) => {
        return (
          <div className="bread_crumb_navigation_sub_container" key={index} onClick={() => handleMenuClick("/hip-connect")}>
            {e.currentroute === "/hip-connect" ? (
              <Link to={privacyPopupState ? null : "/hip-connect"} style={{ fontWeight: "500" }}>
                HIP One Connect
              </Link>
            ) : (
              <>
                <Link to={privacyPopupState ? null : "/hip-connect"}>HIP One Connect</Link>
                {e.title.map((e2, index1) => {
                  return (
                    <div className="bread_crumb_navigation_sub_content" key={index1}>
                      {theme === "dark_" ? <img src={RightArrowWhiteImage} alt='arrow right' className='bread_crumb_nav_img' /> :
                        <img src={RightArrowImage} alt='arrow right' className='bread_crumb_nav_img' />
                      }
                      {e.title.length - 1 === index1 ? (
                        <Link className={`bread_crumb_nav_item_bold ${theme}last_breadcrumb`} to={privacyPopupState ? null : e.routes[index1]}
                          key={index1}
                        >
                          {e.title[index1]}
                        </Link>
                      ) : (
                        <Link to={privacyPopupState ? null : e.routes[index1]} key={index1} >
                          {e.title[index1]}
                        </Link>)
                      }
                    </div>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default HiPBreadCrumb;

const Routes = [
  {
    title: ["Conversational Document", "Interactive Document"],
    currentroute: "/hip-connect/interactive-document",
    routes: ["/hip-connect", "/hip-connect/interactive-document"],
  },

  {
    title: ["Summary", "Summarize"],
    currentroute: "/hip-connect/summarize-document",
    routes: ["/hip-connect", "/hip-connect/summarize-document"],
  },

  {
    title: ["Real Time Data Conversation", "URL"],
    currentroute: "/hip-connect/hip-connection-url",
    routes: ["/hip-connect", "/hip-connect/hip-connection-url"],
  },

  {
    title: ["Real Time Data Conversation", "URL"],
    currentroute: "/hip-create-chatbot",
    routes: ["/hip-connect", "/hip-create-chatbot"],
  },

  {
    title: ["Real Time Data Conversation", "URL"],
    currentroute: "/hip-modify-chatbot",
    routes: ["/hip-connect", "/hip-modify-chatbot"],
  },

  {
    title: ["Real Time Data Conversation", "Business Intelligence"],
    currentroute: "/hip-connect/hip-connection-database",
    routes: ["/hip-connect", "/hip-connect/hip-connection-database"],
  },

  {
    title: ["Real Time Data Conversation", "Business Intelligence"],
    currentroute: "/hip-connection-database-create-chatbot",
    routes: ["/hip-connect", "/hip-connection-database-create-chatbot"],
  },

  {
    title: ["HIP Connect"],
    currentroute: "/hip-connect",
  },

  {
    title: ["Real Time Data Conversation", "Logshifters"],
    currentroute: "/hip-connect/hip-logsifters",
    routes: ["/hip-connect", "/hip-connect/hip-logsifters"],
  },

];
