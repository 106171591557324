import React, { useState } from "react";
import "./UserMessage.css";
import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import GetCookie from "../../../HIPCookies/getCookie";
import UserAvatar from "./UserAvatar";
import { Typography } from "@mui/material";

export default function UserMessage(props) {
  let userName = (JSON.parse(GetCookie('userInfo')) || {}).userNameDisplay;
  const ref = useRef();
  const theme = useSelector((state) => state.messages.Theme) || ""
  const profileImage = GetCookie('userProfileImage');

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [props.message]);

  return (
    <div className={`${theme}pdf_message`} ref={ref}>
      <div className={`${theme}messageContent`}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            {(profileImage && profileImage.length > 2) ?
              <img className="pdf_user_image"
                src={profileImage}
                alt="User"
              />
              : (profileImage && profileImage.length === 2) ?
                <div className='pdf_initial_circle_content'>
                  <Typography className='pdf_intial_text'> {profileImage} </Typography>
                </div>
                : null
            }
          </div>
          <p className={`${theme}message-time`}>{props.time}</p>
        </div>
        <p className={`${theme}message-p`}>{props.message}</p>
      </div>
    </div>
  );
}
