const HorizontalBarGraph = ({ objective, income }) => {
  const svgWidth = '100%';
  const svgHeight = '100%';
  const barWidth = 476;
  const barHeight = 50;
  const objectiveWidth = (objective / 100) * barWidth;
  const incomeWidth = (income / 100) * barWidth;

  const textOffset = 10;
  const incomeTextX = Math.min(incomeWidth + textOffset, barWidth - textOffset);
  const objectiveTextX = Math.min(objectiveWidth - textOffset, barWidth - textOffset);

  return (
    <svg viewBox={`0 0 ${barWidth} ${barHeight + 40}`} width={svgWidth} height={svgHeight}>
      <rect x="0" y="20" width={barWidth} height={barHeight} fill="#E0E3E8" />

      {[0, 20, 40, 60, 80, 100].map((value) => {
        // const xPos = (value / 100) * barWidth;
        let xPos;
        if (value === 0) {
          xPos = 5;
        } else if (value === 100) {
          xPos = barWidth - 10;
        } else {
          xPos = (value / 100) * barWidth;
        }
        return (
          <g key={value}>
            <text x={xPos} y="85.5" textAnchor="middle" fontSize="10" fill="#3B3B3B">{value}</text>
          </g>
        );
      })}

      <rect x={objectiveWidth + 2} y="30" width={(incomeWidth - objectiveWidth) - 3} height="30" fill="#25B771" />

      <line x1={objectiveWidth + 2} y1="20" x2={objectiveWidth + 2} y2={barHeight + 20} stroke="#3B3B3B" strokeWidth="2" />
      <line x1={incomeWidth - 2} y1="20" x2={incomeWidth - 2} y2={barHeight + 20} stroke="#3B3B3B" strokeWidth="2" />

      {/* Draw the text */}
      <text x={incomeTextX-2} y="15" textAnchor="middle" fontSize="10" fill="#3B3B3B">{income}%</text>
      <text x={objectiveTextX} y="15" textAnchor="middle" fontSize="10" fill="#3B3B3B">{objective}%</text>
    </svg>
  );
};

export default HorizontalBarGraph;

