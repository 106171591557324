import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Pagination, PaginationItem, Tooltip } from "@mui/material";
import axios from "axios";
import Cryptojs from "crypto-js";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import GetCookie from "../../../HIPCookies/getCookie";
import {
  getClaimProcess,
  getFilter,
  setClaimProcess,
  setCustomCollapse,
  setToastAlert,
} from "../../../Redux/features/AlertsSlice";
import {
  clearExtractedFieldChanges,
  getClaimProcessMessage,
  getExtractedFileChanges,
  getImageUrlList,
  getInitialVitalsData,
  getSections,
  getSelectedSectionImage,
  getUploadedFilename,
  getVitalsData,
  removeBlankPage,
  removeDupliacte,
  setImageUrlList,
  setInitialVitalData,
  setOriginalUrlList,
  setSections,
  setUploadedFilename
} from "../../../Redux/features/DocumentsSlice";
import DragIcon from "../../../assets/Icons/Move_alt_alt.png";
import greenTickButton from "../../../assets/Icons/greentickbutton.png";
import BackImage from "../../../assets/Icons/navigate-back.svg";
import redXButton from "../../../assets/Icons/redxbutton.png";
import tickButton from "../../../assets/Icons/tickbutton.png";
import xButton from "../../../assets/Icons/xbutton.png";
import chatBotImage from "../../../assets/askchatbot.svg";
import "./SectionList.css";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import PdfThumbnails from "../PdfThumbnails/PdfThumbnails";
import FileDisplayNoridian from "../FileDisplay/FileDisplayNoridian";
import { ShowSections } from "../ShowSections/ShowSections";
import ClaimsSectionSummary from "../SectionSummary/SectionSummary";
import Loading from "../LoadingComponent/loading";
import Chatbot from "../Chatbot/Chatbot";

function SectionList() {
  const isClaimProcess = useSelector(getClaimProcess);
  const defaultImageUrls = useSelector(getImageUrlList);
  const [page, setPage] = useState(0);
  const initialPageNumber = defaultImageUrls.length > 0 && defaultImageUrls[0].page_number;

  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  console.log('pageNumber', pageNumber)
  const [totalPages, setTotalPages] = useState(0);
  const numberOfRows = 1;
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [FileData, setFileData] = useState([]);
  const [FileImages, setFileImages] = useState([]);
  const [File, setFile] = useState([]);
  const [updatedFile, setUpdatedFile] = useState([]);
  const sectionList = useSelector(getSections);
  const [filename, setFilename] = useState(useSelector(getUploadedFilename));
  const [isActive, setIsActive] = useState(false);
  const [duplicatepageNumbers, setduplicatepageNumbers] = useState([])
  const [isLoad, setIsLoad] = useState(false);
  const duplicatePages = useSelector((state) => state.documents.duplicatePages)

  const [isSectionSummaryOpen, setIsSectionSummaryOpen] = useState(false);
  const [isDuplicate, setsDuplicate] = useState([false, false])
  const filter = useSelector(getFilter);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;
  const selectedSectionName = useSelector(getClaimProcessMessage)
  const [isApiCalled, setIsApiCalled] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const sectionListchanges = useSelector(getExtractedFileChanges)
  const updatedVitalsInfo = useSelector(getVitalsData)
  const initialVitalData = useSelector(getInitialVitalsData)
  const selectedSectionImage = useSelector(getSelectedSectionImage)

  useEffect(() => {
    if (selectedSectionImage && selectedSectionImage.length > 0) {

      const pageNumberFromSelectedImage = selectedSectionImage[0].page_number;
      setPageNumber(pageNumberFromSelectedImage);
    }


  }, [selectedSectionImage]);


  useEffect(() => {
    setImages();

    if (!isApiCalled) {
      fetchSectionsFromAPI();
    }
  }, [filter, filename, dispatch, selectedSectionName, defaultImageUrls]);

  const setImages = () => {
    const blobUrls = defaultImageUrls.map((item) => item.blob_url);

    const transformedImages = defaultImageUrls.map((item) => {
      if (item.blob_url) {
        return {
          img_url: item.blob_url,
          image: item.blob_url.split("/").slice(-1)[0],
          img_res: null,
          page_number: item.page_number
        };
      } else {

        return null;
      }
    }).filter(image => image !== null);

    setTotalPages(transformedImages.length);
    setPageNumber(initialPageNumber)
    setFileImages(transformedImages);
  }

  useEffect(() => {

    let allPageNumbers = [];


    duplicatePages.forEach((entry) => {

      const originalPageNumber = entry.original.page_number;

      allPageNumbers.push([originalPageNumber]);


      entry.duplicates.forEach(duplicate => {
        allPageNumbers[allPageNumbers.length - 1].push(duplicate.page_number);
      });
    });
    console.log(allPageNumbers)

    setduplicatepageNumbers(allPageNumbers);
    console.log(duplicatepageNumbers)
    console.log(defaultImageUrls)

    setsDuplicate([false, false]);

  }, [duplicatePages]);


  const [Con_Level, setCon_Level] = useState({
    High: 0,
    Medium: 0,
    Low: 0,
    Null: 0,
  });

  const [coArrow, setCoArrow] = useState({
    isActive: false,
  });
  const handleFarLeft = () => {
    setPage(0);
    setPageNumber(1);
  };

  const handleFarRight = () => {
    setPage(totalPages - 1);
    setPageNumber(totalPages);
  };

  const handleIndicators = (res) => {
    let high = 0;
    let medim = 0;
    let low = 0;
    let Null = 0;
    res.data_std.key_value_pairs.forEach((e, index) => {
      e.key_value_pairs.forEach((e1) => {
        if (e1.con_level === "high") {
          high += 1;
        } else if (e1.con_level === "low") {
          low += 1;
        } else if (e1.con_level === "mid") {
          medim += 1;
        } else {
          Null += 1;
        }
      });
    });

    setCon_Level({
      High: high,
      Medium: medim,
      Low: low,
      Null: Null,
    });
  };
  const handleNext = () => {
    if (pageNumber < totalPages) {
      setPage(page + 1);
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrev = () => {
    if (page !== 0) {
      setPage(page - 1);
      setPageNumber(pageNumber - 1);
    }
  };
  const changePage = (number) => {
    setPage(number - numberOfRows);
    setPageNumber(number);

  };


  const handlePageChange = (_, page) => {
    setPageNumber(page);
  };

  const handelRemoveBlank = async () => {
    let file_name = filename.split(".")[0]
    const willRemove = await swal({
      title: 'Are you sure?',
      text: 'You want to remove this blank page!',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Yes, remove it!'
      },
      dangerMode: true,
    });

    if (willRemove) {

      try {

        const pageToRemove = defaultImageUrls.find(
          (item) => item.page_number === pageNumber
        );


        if (!pageToRemove) {
          // Handle case where page with pageNumber is not found
          throw new Error('Page not found in defaultImageUrls');
        }

        const resp = await axios.post(
          `${process.env.REACT_APP_CONNECT}/med-review-process/delete-specific-blank`,
          {
            blanks: [
              {
                blob_url: pageToRemove.blob_url,
                page_number: pageToRemove.page_number
              }
            ]
          },
          {
            headers: {
              "Content-Type": "application/json",
              userId
            }
          }
        );

        setsDuplicate([false, false]);


        dispatch(
          removeBlankPage({
            page_index: defaultImageUrls.findIndex(item => item.page_number === pageToRemove.page_number),
            page_number: pageToRemove.page_number
          })
        );


        swal('Removed!', 'Your blank page has been removed.', 'success');
      } catch (error) {
        console.error(error);
        // Handle error if needed
        setsDuplicate([false, false]);
        swal('Error', 'An error occurred while removing the blank page.', 'error');
      }
    } else {
      // Handle cancellation here
      setsDuplicate([false, false]);
    }
  };


  const handleRemovePage = () => {
    if (duplicatePages.length !== 0) {
      // Filter out the page number from duplicatepageNumbers
      const filteredDuplicatePageNumbers = duplicatepageNumbers.filter(numbers => numbers[0] !== pageNumber && numbers[1] !== pageNumber);
      console.log('after deletion', filteredDuplicatePageNumbers)
      setduplicatepageNumbers(filteredDuplicatePageNumbers);

    }
    setsDuplicate([false, false]);

    function findPageNumber() {
      const pageIndex = duplicatepageNumbers.findIndex(numbers => numbers[0] === pageNumber || numbers[1] === pageNumber)
      const pageToRemove = duplicatepageNumbers[pageIndex][0] || duplicatepageNumbers[pageIndex][1];
      console.log('pagetoremove', pageToRemove)
      return pageToRemove;
    }
    function getPageIndex() {
      const pageIndex = duplicatepageNumbers.findIndex(numbers => numbers[0] === pageNumber || numbers[1] === pageNumber)
      console.log('page index, and array', pageIndex, duplicatepageNumbers[pageIndex])
      return duplicatepageNumbers[pageIndex];
    }

    dispatch(removeDupliacte({ page_index: getPageIndex(), page_number: defaultImageUrls[defaultImageUrls.findIndex(item => item.page_number === findPageNumber())].page_number }))
    console.log(defaultImageUrls)
  };

  const handleSubmitChanges = async () => {

    const vitalsResponse = await axios.post(
      `${process.env.REACT_APP_CONNECT}/med-review-process/onsubmit`,
      {
        filename: filename
      },
      {
        headers: { userId: userId }
      }
    );
    dispatch(setToastAlert({
      isactive: true,
      type: 'success',
      exp: 10000,
      message: "Data Submitted Successfully"
    }))
    setIsSaved(false)
  }


  const handleSaveChanges = async () => {

    const isVitalsUpdated = Object.keys(initialVitalData).some(key => {
      return initialVitalData[key].value !== updatedVitalsInfo[key]?.value;
    });
    let vitalsUpdatedSuccessfully = false;
    if (isVitalsUpdated) {
      dispatch(setInitialVitalData(updatedVitalsInfo))
      vitalsUpdatedSuccessfully = true;
    }

    const latestIndexMap = new Map();

    sectionListchanges.forEach((item, index) => {
      const sectionName = Object.keys(item)[0];
      latestIndexMap.set(sectionName, index);
    });

    if (sectionListchanges.length === 0 && !vitalsUpdatedSuccessfully) {
      // toast.warning("No Changes", {
      //   position: toast.POSITION.TOP_CENTER
      // });
      dispatch(setToastAlert({
        isactive: true,
        type: 'warning',
        exp: 10000,
        message: "No Changes"
      }))
    }
    else {
      if (isVitalsUpdated) {
        try {
          const formattedVitals = Object.keys(updatedVitalsInfo).reduce((acc, key) => {
            acc[key] = updatedVitalsInfo[key].value;
            return acc;
          }, {});
          const vitalsResponse = await axios.post(
            `${process.env.REACT_APP_CONNECT}/med-review-process/update-vitals/${filename}`,
            {
              section_name: "HISTORY_AND_PHYSICAL",
              vitals: formattedVitals
            },
            {
              headers: { userId: userId }
            }
          );
          if (vitalsResponse.data.message === 'Vitals updated successfully') {
            vitalsUpdatedSuccessfully = true;
            setIsSaved(true)
          } else {
            // toast.error("Failed to update vitals", {
            //   position: toast.POSITION.TOP_CENTER
            // });
            dispatch(setToastAlert({
              isactive: true,
              type: 'error',
              exp: 10000,
              message: "Failed to update vitals"
            }))
            return;
          }
        } catch (error) {
          // toast.error("Error updating vitals", {
          //   position: toast.POSITION.TOP_CENTER
          // });
          dispatch(setToastAlert({
            isactive: true,
            type: 'error',
            exp: 10000,
            message: "Error updating vitals"
          }))
          return;
        }
      }

      for (const [sectionName, latestIndex] of latestIndexMap.entries()) {
        try {
          const sectionData = sectionListchanges[latestIndex][sectionName];
          const formattedSectionData = {
            extracted_info: sectionData,
          };


          const response = await axios.post(
            `${process.env.REACT_APP_CONNECT}/med-review-process/update_section_detail`,
            {
              pdfname: filename,
              sectionname: sectionName,
              data: formattedSectionData,
            },
            {
              headers: {
                userId: userId,
              },
            }
          );


          if (latestIndex === (sectionListchanges.length - 1)) {
            // toast.success("Data Updated Successfully", {
            //   position: toast.POSITION.TOP_CENTER
            // });
            dispatch(setToastAlert({
              isactive: true,
              type: 'success',
              exp: 10000,
              message: "Data Updated Successfully"
            }))
            setIsSaved(true)
            dispatch(clearExtractedFieldChanges())
          }

        } catch (error) {

          // toast.error(error.data.message, {
          //   position: toast.POSITION.TOP_CENTER
          // });
          dispatch(setToastAlert({
            isactive: true,
            type: 'error',
            exp: 10000,
            message: error.data.message
          }))
        }
      }
      if (vitalsUpdatedSuccessfully) {
        // toast.success("Data Updated Successfully", {
        //   position: toast.POSITION.TOP_CENTER
        // });
        dispatch(setToastAlert({
          isactive: true,
          type: 'error',
          exp: 10000,
          message: "Data Updated Successfully"
        }))
        vitalsUpdatedSuccessfully = false;
      }
    }
  };



  const handleTickButtonClick = async () => {
    const willRemove = await swal({
      title: 'Are you sure?',
      text: 'You want to remove this page!',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Yes, remove it!'
      },
      dangerMode: true,
    });

    if (willRemove) {

      var flag = false;
      try {
        const pageIndex = duplicatepageNumbers.findIndex(numbers => numbers[0] === pageNumber || numbers[1] === pageNumber);
        const pageToRemove = duplicatepageNumbers[pageIndex][0] || duplicatepageNumbers[pageIndex][1];
        console.log(pageToRemove)
        console.log(defaultImageUrls.find(image => image.page_number === pageToRemove)?.blob_url)
        const resp = await axios.post(
          `${process.env.REACT_APP_CONNECT}/med-review-process/delete-specific-duplicates`,
          {
            duplicates: [{
              page_number: pageToRemove,
              blob_url: defaultImageUrls.find(image => image.page_number === pageToRemove)?.blob_url
            }]

          },
          {
            headers: {
              "Content-Type": "application/json",
              userId
            }
          }
        );

        if (resp) {
          flag = true
        }
        if (flag) {
          handleRemovePage();
        }

        swal('Removed!', 'Your page has been removed.', 'success');
      } catch (error) {
        console.error(error);

        swal('Error', 'An error occurred while removing the page.', 'error');
      }
    } else {

      setsDuplicate([false, false]);
    }
  };

  function decryptFilename(encryptedFilename) {
    const SECRET_KEY_PARAMS = "JEOFH-JFOEH-EOFDH-KHIEO-OADOE-PIADD";
    const bytes = Cryptojs.AES.decrypt(encryptedFilename, SECRET_KEY_PARAMS);
    const decryptedFilename = bytes.toString(Cryptojs.enc.Utf8);
    dispatch(setUploadedFilename(decryptedFilename))
    setFilename(decryptedFilename)
  }

  const fetchSectionsFromAPI = async () => {

    var userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;
    const storedEncryptedFilename = GetCookie('filename');
    decryptFilename(storedEncryptedFilename)

    if (filename !== "") {
      axios.post(
        `${process.env.REACT_APP_CONNECT}/med-review-process/get_pdf_images`,
        {
          pdf_filename: filename,
        },
        {
          headers: {
            userId: userId,
          },
        }
      )
        .then((response) => {

          if (response.status === 200) {
            setIsApiCalled(true)
            const images = response.data.data;
            const sortedImages = images.sort((a, b) => a.page_number - b.page_number);

            dispatch(setOriginalUrlList(sortedImages));
            dispatch(setImageUrlList(sortedImages));
          } else {
            console.error("Error fetching PDF images:", response.data.error);
          }
        })
    }
    if (filename !== "") {
      let url = `${process.env.REACT_APP_CONNECT}/med-review-process/segmented-pdf/${filename}`;

      try {
        const response = await axios.post(url, {}, {
          headers: {
            accept: "application/json",
            userId: userId,
          },
        });
        const data = response.data;
        if (data.title && data.title.length > 0) {
          const uniqueTitlesSet = new Set(data.title);
          const uniqueTitles = [...uniqueTitlesSet];
          dispatch(setSections(uniqueTitles));
        } else {
          console.error("No section titles found in the API response");
        }
      } catch (error) {
        console.error("Error fetching segmented PDF:", error);

      } finally {
        setIsLoad(true);
        dispatch(setClaimProcess(false));
      }

    }

  };
  const getIndexForPageNumber = (pageNumber) => {

    const index = FileImages.findIndex((item) => item.page_number === pageNumber);
    return index !== -1 ? index + 1 : 0; // Return 0 if page number not found
  };
  return isClaimProcess ? (
    <LoadingScreen />
  ) : (
    <div className="section_list_review_files_main_page">
      <div className="section_list_review_files_images_main_page">
        <div className="sectionlist_row">
          <div className="pdf-thumbnails-section">
            <PdfThumbnails
              currentPage={pageNumber}
              onThumbnailClick={(selectedPage) => {
                console.log('pagination selected page', selectedPage);
                setPageNumber(selectedPage);
              }}
              filter={filter}
            />

          </div>
          <div className="section_list_filedisplay">
            <div className="section_list_verify_record_request_doc_display">
              <div className="section_list_verify_record_request_doc_display_header">
                <div className="section_list_verify_record_request_doc_display_filename">
                  <b>File Name</b>
                  <br />
                  <span>{filename}</span>
                </div>
                <div className="section_list_verify_record_request_doc_display_pagination">
                  {FileImages.length > 0 &&
                    <Pagination
                      count={totalPages}
                      variant="outlined"
                      siblingCount={0}
                      boundaryCount={1}
                      page={getIndexForPageNumber(pageNumber)}
                      onChange={handlePageChange}
                      showFirstButton
                      showLastButton
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            first: KeyboardDoubleArrowLeftIcon,
                            last: KeyboardDoubleArrowRightIcon,
                          }}
                          component="button"
                          {...item}
                        >

                        </PaginationItem>
                      )}
                    />
                  }

                </div>
              </div>
              <div style={{ marginTop: "30px" }}>
                {filter === "Duplicate Pages" && duplicatePages.length !== 0 && (
                  <div style={{ backgroundColor: "#F2F2F2", height: "90vh", display: "flex", flexDirection: "column" }}>
                    {defaultImageUrls.length !== 0 && (
                      <div style={{ height: "30px", width: "145px", backgroundColor: "#FFFFFF", border: "0.5px solid #787878", marginBottom: "10px", marginLeft: "290px", marginTop: "30px", borderRadius: "4px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ fontSize: "12px", marginRight: "10px", color: "#142952" }}>Duplicate Pages</div>
                        <img src={isDuplicate[1] ? redXButton : xButton} style={{ height: "20px", width: "20px" }} onClick={() => setsDuplicate([false, true])} />
                        <img
                          src={isDuplicate[0] ? greenTickButton : tickButton}
                          style={{ height: "20px", width: "20px" }}
                          onClick={() => { setsDuplicate([true, false]); handleTickButtonClick(); }}
                        />
                      </div>
                    )}
                    {duplicatePages.length !== 0 && duplicatepageNumbers[0] && (
                      <div style={{ display: "flex" }}>
                        <img
                          src={defaultImageUrls.find(image => image.page_number === duplicatepageNumbers[0][0])?.blob_url}
                          height="300px"
                          width="230px"
                          style={{ marginLeft: "30px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.19)" }}
                        />
                        <img
                          src={defaultImageUrls.find(image => image.page_number === duplicatepageNumbers[0][1])?.blob_url}
                          height="300px"
                          width="230px"
                          style={{ marginLeft: "30px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.19)" }}
                        />
                      </div>
                    )}

                  </div>
                )}

              </div>
              {filter === "Blank Pages" &&
                <div style={{ height: "30px", width: "145px", backgroundColor: "#FFFFFF", border: "0.5px solid #787878", marginBottom: "10px", marginLeft: "10px", marginTop: "30px", borderRadius: "4px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ fontSize: "12px", marginRight: "10px", color: "#142952" }}>Blank Page</div>
                  <img src={isDuplicate[1] ? redXButton : xButton} style={{ height: "20px", width: "20px" }} onClick={() => setsDuplicate([false, true])} />
                  <img
                    src={isDuplicate[0] ? greenTickButton : tickButton}
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => { setsDuplicate([true, false]); handelRemoveBlank() }}
                  />
                </div>
              }
              {filter !== "Duplicate Pages" &&
                <div className="section_list_verify_record_request_doc_display_content">

                  <div className="section_list_verify_record_request_doc_display_content_images">
                    {FileImages.length > 0 && (
                      <FileDisplayNoridian
                        pageNumber={pageNumber}
                        FileImages={FileImages}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        page={page}
                        totalPages={totalPages}
                        isActive={isActive}
                        setCoArrow={setCoArrow}
                      // other props
                      />
                    )}
                    <img src={FileImages.img_url} height="200px" />

                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="section_list_doc_display_request">
        <Tooltip title="HIP Copilot" placement="right" arrow>
          <button
            className="chatbot-button"
            onClick={() => setIsChatbotOpen(true)}
          >
            <img src={chatBotImage} alt="Chatbot Logo" className="chatbot-logo" />
            HIP Copilot
          </button>
        </Tooltip>
        <Tooltip title="Section Summary" placement="right" arrow>
          <button
            className="chatbot-button"
            onClick={() => setIsSectionSummaryOpen(true)}
          >
            Section Summary
          </button>
        </Tooltip>
        {isChatbotOpen && (
          <Draggable handle=".chatbot-modal-header">
            <div className="chatbot-modal">
              <div className="chatbot-modal-header">
                HIP Copilot
                <div>
                  <img src={DragIcon} alt="Drag Icon" />
                </div>
                <div
                  className="chatbot-modal-close"
                  onClick={() => setIsChatbotOpen(false)}
                >
                  ×
                </div>
              </div>
              <Chatbot />
            </div>
          </Draggable>
        )}
        {isSectionSummaryOpen && (
          <Draggable handle=".sectionsummary-modal-header">
            <div className="sectionsummary-modal">
              <div className="sectionsummary-modal-header">
                Summary
                <div>
                  <img src={DragIcon} alt="Drag Icon" />
                </div>
                <div
                  className="chatbot-modal-close"
                  onClick={() => setIsSectionSummaryOpen(false)}
                >
                  ×
                </div>
              </div>
              <ClaimsSectionSummary sectionsData={sectionList} />
            </div>
          </Draggable>
        )}

        <div className="filename_display">File Name</div>
        <div className="filename"> {filename}</div>

        {!isLoad ? (
          <Loading />
        ) : (
          <ShowSections
            Con_Level={Con_Level}
            setCoArrow={setCoArrow}
            FileData={FileData}
            File={File}
            page={page}
            setUpdatedFile={setUpdatedFile}
            changePage={changePage}
            sectionList={sectionList}
          />
        )}

        <div className="section_list_doc_button">
          <Tooltip title="Submit" placement="top" arrow>

            <button
              className="section_list_doc_submit"
              onClick={handleSubmitChanges}
            >
              Submit
            </button>
          </Tooltip>
          <Tooltip title="Save Changes" placement="top" arrow>
            <button
              className="section_list_doc_button_save"
              onClick={handleSaveChanges}
            >
              Save Changes
            </button>
          </Tooltip>
        </div>
      </div>
    </div >
  );
}

export default SectionList;
