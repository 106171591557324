import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./DocumentTableDisplay.css";
import NavNext from '../../../../assets/Icons/VectorArrowRight.svg';
import NavPrev from '../../../../assets/Icons/Vectorarrowleft_active.svg';
import EditTable from '../../../../assets/Icons/Edit-table-date.svg';
import { MdClose } from 'react-icons/md';
import Textarea from '../features/TeaxtArea/Textarea';
import TextField from '../features/TextField/TextField';
import GetCookie from '../../../../HIPCookies/getCookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoadingScreen, setNormalAlert } from '../../../../Redux/features/AlertsSlice';
import Axios from 'axios';
import { setDocumentsList } from '../../../../Redux/features/DocumentsSlice';

function DocumentTableDisplay({ File, page, pageNumber, setUpdatedFile, status }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userName = (JSON.parse(GetCookie('userInfo')) || {}).userNameDisplay;
    const params = useParams();
    const [navigateTo, setNavigateTO] = useState('');
    // const [File, setFile] = useState([])
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableColumns(File.data_std.tables.tb_col_names);
        setTableData(File.data_std.tables.tb_std_array.slice(page, pageNumber)[0].tables[0].data);
    }, [File, page, pageNumber])

    const [numberOfRows, setNumberOfRows] = useState(10);
    const [Page, setPage] = useState(0);
    const [rows, setRows] = useState(0);
    const [PageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [currentRow, setCurrentRow] = useState(-1);
    const [editFields, setEditFields] = useState(false);
    const [test, setTest] = useState(0);

    useEffect(() => {
        if (tableData.length !== 0) {
            setRows(Number.parseInt(numberOfRows))
            setTotalRows(tableData.length)
            setTotalPages(Math.ceil(tableData.length / Number.parseInt(numberOfRows)))
        }
    }, [tableData, numberOfRows])

    const handleNext = () => {
        if (PageNumber < totalPages) {
            setPage(Page + Number.parseInt(numberOfRows))
            setRows(rows + Number.parseInt(numberOfRows))
            setPageNumber(PageNumber + 1)
        }
    }

    const handlePrev = () => {
        if (Page !== 0) {
            setPage(Page - Number.parseInt(numberOfRows))
            setRows(rows - Number.parseInt(numberOfRows))
            setPageNumber(PageNumber - 1)
        }
    }

    const handleCurrentRow = (index) => {
        setCurrentRow(index)
        setEditFields(true)
    }

    const handleEditClose = () => {
        setCurrentRow(-1)
        setEditFields(false)
    }
    const changeName = (e) => {
        let Name;
        switch (e) {
            case 'Svc Dt':
                Name = 'Service Date'
                break;
            case 'Qty':
                Name = 'Quantity'
                break;
            case 'Rev Cd':
                Name = 'Revenue Code'
                break;
            case 'Chg Amt':
                Name = 'Change Amount'
                break;
            default:
                Name = e;
                break;
        }
        return Name;
    }
    const UpdateValue = (e2, name, value) => {
        for (let i = 0; i < tableColumns.length; i++) {
            if (changeName(tableColumns[i]) === name) {
                return e2.splice(i, 1, value);
            }
        }
    }
    const handleUpdate = (name, value) => {
        File.data_std.tables.tb_std_array.map((e) => {
            return {
                ...e, "tables": e.page_number === pageNumber ? e.tables.map((e1) => {
                    return {
                        ...e1, "data": e1.data.map((e2, index2) => {
                            return {
                                ...e2, "DATA": index2 === currentRow ? UpdateValue(e2, name, value)
                                    : e2
                            }
                        })
                    }
                }) : e.tables
            }
        })
        setUpdatedFile(File)
    }
    const handleReviewed = async (option) => {

        dispatch(setLoadingScreen({
            isActive: true
        }))


        if (File.length === 0) {
            File.status = option === 'save' ? 'save' : 'complete'
            File.user = userName
            await Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/update_Documents`, File)

            // update the list of documents......
            let result = await Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/list_of_Documents`)
            dispatch(setDocumentsList(result.data))

            navigate(`hip-operations/${navigateTo}`)

            dispatch(setNormalAlert({
                isActive: true,
                message: option === 'save' ? "Changes are Saved" : "Successfully Updated...",
                variant: "success"
            }))

            setTimeout(() => {
                dispatch(setNormalAlert({
                    isActive: false,
                }))
            }, 3000)
            handleEditClose()
        }
        else {
            File.status = option === 'save' ? 'save' : 'complete'
            File.user = userName
            await Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/update_Documents`, File)
            // update the list of documents......
            let result = await Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/list_of_Documents`)

            dispatch(setDocumentsList(result.data))

            navigate(`${navigateTo}`)

            dispatch(setNormalAlert({
                isActive: true,
                message: option === 'save' ? "Changes are Saved" : "Successfully Updated...",
                variant: "success"
            }))

            setTimeout(() => {
                dispatch(setNormalAlert({
                    isActive: false,
                }))
            }, 3000)
        }

        dispatch(setLoadingScreen({
            isActive: false
        }))
        handleEditClose()

    }
    return (
        <>
            <div className='document_table_display_container'>
                <div className='document_table_display_table'>
                    <table>
                        <thead>
                            <tr>
                                {
                                    tableColumns.map((e, index) => {
                                        return <td
                                            key={index}
                                            style={{ width: e === 'Description' ? "300px" : "100px" }}
                                        >{e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}</td>
                                    })
                                }
                                {
                                    status !== 'Completed' && (
                                        <td>Option</td>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.length === 0 ?
                                    <></>
                                    :
                                    tableData.slice(Page, rows).map((e, index1) => {
                                        return (
                                            <tr
                                                key={index1}
                                                className={index1 % 2 === 0 && "row_even"}
                                            >
                                                {
                                                    tableColumns.map((col, index) => {
                                                        return (

                                                            <td key={index}>{e[index] === null || e[index] === '' ? "NA" : e[index]}</td>
                                                        )
                                                    })
                                                }
                                                {
                                                    status !== 'Completed' && (
                                                        <td><img src={EditTable} alt='edit-data' onClick={() => handleCurrentRow(index1)} /></td>
                                                    )
                                                }
                                            </tr>
                                        )
                                    })
                            }
                        </tbody>
                    </table>
                </div>
                <div className='document_table_pagination_container'>
                    <div className='document_table_pagination_span'>
                        <div className='document_table_pagination_select_box'>
                            <b>Row per pages: </b>
                            <select
                                value={numberOfRows}
                                onChange={(e) => {
                                    setPageNumber(1)
                                    setPage(0)
                                    setNumberOfRows(e.target.value)
                                }}
                            >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className='document_table_pagination_pages'>
                            <b>{PageNumber === totalPages ? `${Page + 1} - ${totalRows}` : `${Page + 1} - ${rows}`} of {totalRows}</b>
                            {/* <b>{PageNumber !== totalPages ? `${rows + 1} - ${totalRows}` : `${page + 1} - ${totalPages}`} of {totalRows}</b> */}
                        </div>
                        <div className='document_table_pagination_navigation'>
                            <Tooltip placement='top' title='Previous'>
                                <div className='table_nav_btn' onClick={handlePrev}>
                                    <img src={NavPrev} alt='prev' />
                                </div>
                            </Tooltip>
                            <Tooltip placement='top' title='Next'>
                                <div className='table_nav_btn' onClick={handleNext}>
                                    <img src={NavNext} alt='next' />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div className='document_table_data_edit_page' style={{ right: editFields ? 0 : -550 }}>
                <div className='document_table_data_edit_page_header'>
                    <span>Edit Bill Data</span>
                    <MdClose onClick={handleEditClose} style={{ cursor: "pointer" }} />
                </div>
                <div className='document_table_data_edit_page_content'>
                    {currentRow === -1 ? <></> :
                        <>
                            {
                                tableColumns.map((e, index) => {
                                    if (tableData[currentRow][index] !== null) {
                                        if (tableData[currentRow][index].length > 20) {
                                            return (
                                                <div className='document_table_data_edit_page_teaxtarea' key={index}>
                                                    <label htmlFor={e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}>{e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}</label>
                                                    <Textarea
                                                        e={e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}
                                                        handleUpdate={handleUpdate}
                                                        val={tableData[currentRow][index] === null || e[index] === '' ? "NA" : tableData[currentRow][index]}

                                                    />
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <>
                                                    {
                                                        <div className='document_table_data_edit_page_inputs' key={index}>
                                                            <label htmlFor={e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}>{e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}</label>
                                                            <TextField
                                                                e={e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}
                                                                handleUpdate={handleUpdate}
                                                                val={tableData[currentRow][index] === null || e[index] === '' ? "NA" : tableData[currentRow][index]}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            )
                                        }
                                    }
                                    else {
                                        return (
                                            <>
                                                {

                                                    <div className='document_table_data_edit_page_inputs' key={index}>
                                                        <label htmlFor={e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}>{e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}</label>
                                                        <TextField
                                                            e={e === 'Svc Dt' ? 'Service Date' : e === 'Qty' ? 'Quantity' : e === 'Rev Cd' ? 'Revenue Code' : e === 'Chg Amt' ? 'Change Amount' : e}
                                                            handleUpdate={handleUpdate}
                                                            val={tableData[currentRow][index] === null || e[index] === '' ? "NA" : tableData[currentRow][index]}
                                                        />
                                                    </div>
                                                }
                                            </>
                                        )

                                    }

                                })
                            }
                        </>
                    }
                    <span style={{ position: 'relative', top: '10%' }}>
                        <button className='doc_Edit_Save' onClick={() => handleReviewed('save')}>Save Changes</button>

                    </span>

                </div>


            </div>
        </>
    )
}

export default DocumentTableDisplay