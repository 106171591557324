import { Info, CheckCircle, Warning, Dangerous } from '@mui/icons-material'
import React from 'react'

function NormalAlert({ color, bg, variant, message }) {
    return (
        <div style={{
            position: "fixed",
            bottom: "30px",
            right: "30px",
            background: bg,
            color: color,
            width: "400px",
            height: "55px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "5px 10px",
            boxShadow: "0 0 5px #000",
            zIndex: 999999
        }}>
            {
                variant === 'success' ?
                    <CheckCircle />
                    :
                    <>
                        {
                            variant === 'error' ?
                                <Dangerous />
                                :
                                <>
                                    {
                                        variant === 'warning' ?
                                            <Warning />
                                            :
                                            <Info />
                                    }
                                </>
                        }</>
            }
            <span style={{ marginLeft: "10px" }}>{message.lenght > 20 ? message.substring(0, 20) : message}</span>
        </div>
    )
}

export default NormalAlert