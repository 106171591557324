import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ChartMessage from "../ChartMessage/Chartmessage";
import "../DbMessages/DbMessages.css";
import UrlBotMessage from "../UrlBotMessage/UrlBotMessage";
import UrlBotMessageLoading from "../UrlMessgesLoding/UrlMessageLoading";
import UrlUserMessage from "../UrlUserMessage/UrlUserMessage";

export default function DatagenieMesssages({ isLoading }) {
  const DatagenieMessages = useSelector(
    (state) => state.datagmessages.Messages
  );
  const [scrollAtBottom, setScrollAtBottom] = useState(true);

  const myref = useRef();
  useEffect(() => {
    const container = myref.current;

    const handleScroll = () => {
    
      setScrollAtBottom(container.scrollTop + container.clientHeight >= container.scrollHeight - 10);
    };

    container.addEventListener("scroll", handleScroll);

   
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [DatagenieMessages]);
  const scrollToBottom = () => {
    const container = myref.current;
    const scrollHeight = container.scrollHeight - container.clientHeight;
  
    container.scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    });
  
    // Update scrollAtBottom after the scroll has finished
    setTimeout(() => {
      setScrollAtBottom(true);
    }, 300); // Adjust the timeout duration if needed
  };


  return (
    <div className={`db_messages`} ref={myref}>
      {DatagenieMessages.map((obj, index) => {
        if (obj.id === "user") {
          return <UrlUserMessage key={index} message={obj.text} />;
        } else if (obj.id === "chart") {
          return (
            <ChartMessage
              key={index}
              data={obj.data}
              options={obj.options}
              type={obj.type}
              text={obj.text}
            />
          );
        } else if (obj.id === "bot") {
          return <UrlBotMessage key={index} message={obj.text} />;
        }
        return null; // Handle other cases or unknown IDs as needed
      })}
      {isLoading && <UrlBotMessageLoading />}
      {!scrollAtBottom && (
        <button className="down-arrow-button" onClick={scrollToBottom}>
          ↓
        </button>
      )}
    </div>
  );
}
