import React, { useEffect, useState } from 'react';
import "./ManualReview.css";
import { useNavigate, useParams } from 'react-router-dom';
import Cryptojs from 'crypto-js';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLoadingScreen, setNormalAlert } from '../../../Redux/features/AlertsSlice';
import { setDocumentsList } from '../../../Redux/features/DocumentsSlice';
import { DocumentTextFields } from '../../Pages/DocumentsTextFields/DocumentTextFields';
import { DocumentTables } from '../../Pages/DocumentTables/DocumentTables';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BackImage from '../../../assets/Icons/navigate-back.svg';
import Xarrow from "react-xarrows";
import FileDisplay from '../../Pages/FileDisplay/FileDisplay';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import GetCookie from '../../../HIPCookies/getCookie';

function ManualReview() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [fileType, setFileType] = useState('');
    const [status, setStatus] = useState('');
    // For Pagination of Images
    const [page, setPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const numberOfRows = 1;
    const [FileData, setFileData] = useState([]);
    const [FileImages, setFileImages] = useState([]);
    const [File, setFile] = useState([]);
    const [updatedFile, setUpdatedFile] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [download, setDownload] = useState('');
    const SECRET_KEY_PARAMS = 'JEOFH-JFOEH-EOFDH-KHIEO-OADOE-PIADD';
    const userName = (JSON.parse(GetCookie('userInfo')) || {}).userNameDisplay;
    const [filename, setFilename] = useState('');
    const [navigateTo, setNavigateTO] = useState('');
    const [Con_Level, setCon_Level] = useState({
        High: 0,
        Medium: 0,
        Low: 0,
        Null: 0
    })
    const [coArrow, setCoArrow] = useState({
        isActive: false
    })

    const handleFarLeft = () => {
        setPage(0)
        setPageNumber(1)
    }

    const handleFarRight = () => {
        setPage(totalPages - 1)
        setPageNumber(totalPages)
    }

    const handleIndicators = (res) => {
        let high = 0;
        let medim = 0;
        let low = 0;
        let Null = 0
        res.data_std.key_value_pairs.forEach((e, index) => {
            e.key_value_pairs.forEach((e1) => {
                if (e1.con_level === 'high') {
                    high += 1
                }
                else if (e1.con_level === 'low') {
                    low += 1
                }
                else if (e1.con_level === 'mid') {
                    medim += 1
                }
                else {
                    Null += 1
                }
            })
        })

        setCon_Level({
            High: high,
            Medium: medim,
            Low: low,
            Null: Null
        })

    }

    useEffect(() => {
        dispatch(setLoadingScreen({
            isActive: true
        }))

        let file_data = JSON.parse(Cryptojs.AES.decrypt(params.file_id.split('|').join('/'), SECRET_KEY_PARAMS).toString(Cryptojs.enc.Utf8))
        setFilename(file_data.filename)
        setFileType(file_data.file_type)
        setStatus(file_data.Status)
        setNavigateTO(file_data.navigateTo)

        if (file_data.Status === 'Completed') {
            Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/download_document?file_id=${file_data.Id}`).then((result) => {
                setDownload(result.data.response)
            })
        }

        let values = [];
        let images = [];

        Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/ocr/man_rev_sc?file_id=${file_data.Id}`).then((res) => {
            if (res.data.status) {
                setFile(res.data.response)
                if (file_data.file_type === 'mr' || file_data.file_type === 'ph') {
                    handleIndicators(res.data.response)
                    res.data.response.data_std.key_value_pairs.forEach((e, index) => {
                        images.push(e)
                        values.push(e.key_value_pairs)
                    })
                    setFileData(values)
                    setFileImages(images)
                    setTotalPages(values.length)
                    dispatch(setLoadingScreen({
                        isActive: false
                    }))

                }
                else {
                    setTimeout(() => {
                        dispatch(setLoadingScreen({
                            isActive: false
                        }))
                        setIsLoad(true)
                    }, 1000)

                    res.data.response.data_std.tables.tb_std_array.forEach((e) => {
                        images.push({ img_url: e.img_url, img_res: e.img_res })
                    })

                    setFileImages(images)
                    setTotalPages(images.length)
                }
            }
        })
    }, [params.file_id, dispatch])

    const changePage = (number) => {
        setPage(number - numberOfRows)
        setPageNumber(number)

    }
    const [zoom, setZoom] = useState(600)

    const handleZoom = () => {
        if (zoom < 1300) {
            setZoom(zoom + 100)
        }
    }
    const [tabSpan, setTabSpan] = useState(0)
    const handleSpan = (num) => {
        setPage(num)
        setPageNumber(num + 1)
        setTabSpan(num * 100)
    }

    const handleZoomOut = () => {
        if (zoom > 400) {
            setZoom(zoom - 100)
        }
    }
    const handleNext = () => {
        if (pageNumber < totalPages) {
            setPage(page + numberOfRows)
            setPageNumber(pageNumber + 1)
        }
    }

    const handlePrev = () => {
        if (page !== 0) {
            setPage(page - numberOfRows)
            setPageNumber(pageNumber - 1)
        }
    }

    const handleTablePages = (page_number) => {
        setPage(page_number - 1)
        setPageNumber(page_number)
    }

    const handleReviewed = async (option) => {

        dispatch(setLoadingScreen({
            isActive: true
        }))

        if (updatedFile.length === 0) {
            File.status = option === 'save' ? 'save' : 'complete';
            File.user = userName;
            await Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/update_Documents`, File);

            // update the list of documents......
            let result = await Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/list_of_Documents`)
            dispatch(setDocumentsList(result.data))
            navigate(`/hip-operations${navigateTo}`)
            dispatch(setNormalAlert({
                isActive: true,
                message: option === 'save' ? "Changes are Saved" : "Successfully Updated...",
                variant: "success"
            }))

            setTimeout(() => {
                dispatch(setNormalAlert({
                    isActive: false,
                }))
            }, 3000)
        }
        else {
            updatedFile.status = option === 'save' ? 'save' : 'complete';
            updatedFile.user = userName;
            await Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/update_Documents`, updatedFile);
            // update the list of documents......
            let result = await Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/list_of_Documents`);

            dispatch(setDocumentsList(result.data))
            navigate(`/hip-operations${navigateTo}`)
            dispatch(setNormalAlert({
                isActive: true,
                message: option === 'save' ? "Changes are Saved" : "Successfully Updated...",
                variant: "success"
            }))

            setTimeout(() => {
                dispatch(setNormalAlert({
                    isActive: false,
                }))
            }, 3000)
        }

        dispatch(setLoadingScreen({
            isActive: false
        }))

    }

    return (
        <>
            <div className='review_files_main_page'>
                {/* Details of the Document */}
                <div className='review_files_images_main_page'>
                    {/* Document Display */}
                    <div className='row' onScroll={() => setCoArrow({ isActive: false })}>

                        <div className='column' >
                            <div className='verify_record_request_doc_display'>
                                <div className='verify_record_request_doc_display_header'>
                                    <div className='verify_record_request_doc_display_filename'>
                                        <b>Filename</b><br />
                                        <span>{filename}</span>
                                    </div>
                                    <div className='verify_record_request_doc_display_pagination'>
                                        <div className='navigate_icons' onClick={handleFarLeft}>
                                            <MdKeyboardDoubleArrowLeft />
                                        </div>
                                        <div className='navigate_icons' onClick={handlePrev}>
                                            <MdNavigateBefore />
                                        </div>
                                        {/* Numbers */}
                                        {
                                            FileImages.map((item, index) => {
                                                return (
                                                    <div className={pageNumber === (index + 1) ? 'navigate_icons navigate_icons_active' : 'navigate_icons'}
                                                        onClick={() => changePage(index + 1)}
                                                    >{index + 1}</div>
                                                )
                                            })
                                        }
                                        <div className='navigate_icons' onClick={handleNext}>
                                            <MdNavigateNext />
                                        </div>
                                        <div className='navigate_icons' onClick={handleFarRight}>
                                            <MdKeyboardDoubleArrowRight />
                                        </div>
                                    </div>

                                    <div className='verify_record_request_doc_display_download_btn'>
                                        <button>
                                            <a href={download} target="blank">
                                                <FileDownloadOutlinedIcon style={{ color: "#fff", fontSize: "24px" }} />
                                            </a>
                                        </button>
                                    </div>

                                </div>
                                <div className='verify_record_request_doc_display_content'>
                                    <div className='verify_record_request_doc_display_content_images' >
                                        <FileDisplay
                                            FileImages={FileImages}
                                            handleNext={handleNext}
                                            handlePrev={handlePrev}
                                            page={page}
                                            pageNumber={pageNumber}
                                            totalPages={totalPages}
                                            isActive={isActive}
                                            setCoArrow={setCoArrow}
                                            fileType={fileType}
                                            // downloadLink={download}
                                            status={status}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='column1 doc_display_request'>
                    <div className='filename_display'>
                        File Name
                    </div>
                    <div className='filename'> {filename}</div>

                    {
                        fileType === 'mr' || fileType === 'ph'
                            ?
                            <DocumentTextFields
                                Con_Level={Con_Level}
                                setCoArrow={setCoArrow}
                                FileData={FileData}
                                File={File}
                                page={page}
                                setUpdatedFile={setUpdatedFile}
                                changePage={changePage}
                            />
                            :
                            <DocumentTables
                                File={File}
                                handleTablePages={handleTablePages}
                                setIsActive={setIsActive}
                                isActive={isActive}
                            />
                    }
                    <div className="document_buttons">
                        <button className='doc_button' onClick={() => handleReviewed('complete')}>Submit</button>
                        <button className='doc_button_save' onClick={() => handleReviewed('save')}>Save Changes</button>
                    </div>
                </div>
            </div>
            {
                coArrow.isActive && (
                    <Xarrow
                        start={coArrow.Start}
                        end={coArrow.end}
                        lineColor={coArrow.color}
                        color={coArrow.color}
                        strokeWidth={2}
                        curveness={0}
                        className='x-arrow-container'
                    ></Xarrow>
                )
            }
        </>
    )
}

export default ManualReview