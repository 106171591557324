// Footer.js
import React from "react";
import { useSelector } from "react-redux"; // Import the useSelector hook
import "./Footer.css";

function Footer() {
  const darkMode = useSelector((state) => state.theme.darkMode); // Fetch the darkMode state from Redux

  return (
    <div className={darkMode ? "hip-one-footer hip-one-dark-mode" : "hip-one-footer"}>
      <div className="hip-one-privacy-text">
        <a
          href="https://www.genzeon.com/privacy-policy/"
          className="hip-one-privacy-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>

      <p className="hip-one-copyright">Copyrights © Genzeon | All Rights Reserved</p>
    </div>
  );
}

export default Footer;
