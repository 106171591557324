import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from '../Components/ErrorPage/ErrorPage';
// import HomeScreen from './Screens/HomeScreen/HomeScreen';
import SinglePatientSearch from './Screens/SinglePatientSearch/index';
import BulkPatientSearch from './Screens/BulkPatientSearch/index';
import BulkDownloads from './Screens/BulkDownloads/index';
import PatientResults from "./Screens/PatientResults/index";
import AllPatientDataRequest from './Screens/AllPatientDataRequest/index';
import BulkPatientDataRequest from './Screens/BulkPatientDataRequest/index';
import BulkDownloadsSummary from './Screens/BulkDownloadsSummary/index';
import Datagenie from "./Screens/DataGenie/DataGenie";
import ClinicalDashboard from "./Screens/Dashboard";
import LayoutWithBreadCrumb from "../Theme/LayoutWithBreadCrumb";
import ClaimProcess from "./Screens/ClaimProcess/ClaimProcess";
import SectionList from "./Pages/SectionList/SectionList";
import POPUP from "./Pages/Popups/POPUP";
import SummarizeComponent from "./Components/SummarizeComponent/SummarizeComponent";

function App() {

  return (
    <>

      <Routes>
        {/* Components with Header and Navigation  */}

        {/* With Menu Bar */}
        <Route element={<LayoutWithBreadCrumb type="clinical" />}>

          <Route path="/" element={<ClinicalDashboard />} />

          <Route path="/single-patient-search" element={<SinglePatientSearch />} />

          <Route path="/bulk-patient-search" element={<BulkPatientSearch />} />

          <Route path="/bulk-downloads" element={<BulkDownloads />} />

          <Route path="/all-patient-data-request" element={<AllPatientDataRequest />} />

          <Route path="/bulk-patient-data-request" element={<BulkPatientDataRequest />} />

          <Route path="/bulk-downloads-summary" element={<BulkDownloadsSummary />} />

          <Route path="/operational-intelligence" element={<Datagenie />} />

          <Route path="/single-patient-search/results" element={<PatientResults />} />
          <Route path="/medical-review" element={<ClaimProcess />} />
          <Route path="/ccda-summarization" element={<SummarizeComponent />} />
          <Route path="/cp/review-file/:file_id" element={<SectionList />} />

        </Route>

        {/* Error Route */}
        <Route path="/*" element={<ErrorPage />} />

      </Routes>
      <POPUP />
    </>
  );
}

export default App;
