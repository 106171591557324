import React from "react";
import "../../../Theme/HIPBreadCrumb.css";
import { NavigateNext } from "@mui/icons-material";
import { useLocation, useNavigate, NavLink as Link } from "react-router-dom";
import BackImage from "../../../assets/Back.svg";
import HomeImage from "../../../assets/home.svg";
import RightArrowImage from "../../../assets/RightArrow.svg";

function HiPOperationsBreadCrumb() {
  const location = useLocation();
  const navigate = useNavigate();

  // Routes.forEach((item) => {
  //   console.log(item.currentroute.split('/')[1])
  //   console.log(location.pathname.split('/')[2])
  //   console.log(item.currentroute.split('/')[1] === location.pathname.split('/')[2])
  // })

  return (
    <div className="bread_crumb_navigations">
      <div
        className="bread_crumb_back_container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <img src={BackImage} alt="" style={{ width: "18px" }} />
      </div>
      <div className="bread_crumb_navigations_content">
        <a href="/dashboard">
          <img src={HomeImage} alt="home" />
        </a>
        <div className="bread_crumb_arrow_container">
          <img
            src={RightArrowImage}
            alt="arrow right"
            className="bread_crumb_arrow"
          />
        </div>
      </div>
      {Routes.filter(
        (item) =>
          item.currentroute === location.pathname ||
          item.currentroute.split("/")[1] === location.pathname.split("/")[2]
      ).map((e, index) => {
        // console.log(e)
        return (
          <div className="bread_crumb_navigation_sub_container" key={index}>
            {e.currentroute === "/hip-operations" ? (
              <Link to="/hip-operations" style={{ fontWeight: "500" }}>
                HIP One Operations
              </Link>
            ) : (
              <>
                <Link to="/hip-operations">HIP One Operations</Link>
                {e.title.map((e2, index1) => {
                  return (
                    <div
                      className="bread_crumb_navigation_sub_content"
                      key={index1}
                    >
                      <img
                        src={RightArrowImage}
                        alt="arrow right"
                        className="bread_crumb_nav_img"
                      />
                      <Link
                        className={
                          e.title.length - 1 === index1 &&
                          "bread_crumb_nav_item_bold"
                        }
                        to={e.routes[index1]}
                        key={index1}
                      >
                        {e.title[index1]}
                      </Link>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default HiPOperationsBreadCrumb;

const Routes = [
  {
    title: ["Business Processes", "Medical Record Request"],
    currentroute: "/hip-operations/medical-records",
    routes: ["/hip-operations", "/hip-operations/medical-records"],
  },
  {
    title: ["Business Processes", "Medical Bill Review"],
    currentroute: "/hip-operations/medical-bills",
    routes: ["/hip-operations", "/hip-operations/medical-bills"],
  },
  {
    title: ["Business Processes", "Clinical Data Extraction"],
    currentroute: "/hip-operations/clinical-data-extraction",
    routes: ["/hip-operations", "/hip-operations/clinical-data-extraction"],
  },

  {
    title: ["Business Processes", "Medical Record Request", "Document Verify"],
    currentroute: "/mr",
    routes: ["/hip-operations", "/hip-operations/medical-records"],
  },
  {
    title: ["Business Processes", "Medical Bill Worksheet", "Document Verify"],
    currentroute: "/mbr",
    routes: ["/hip-operations", "/hip-operations/medical-bills"],
  },
  {
    title: [
      "Business Processes",
      "Clinical Data Extraction",
      "Document Verify",
    ],
    currentroute: "/ph",
    routes: ["/hip-operations", "/hip-operations/clinical-data-extraction"],
  },
  {
    title: ["Business Process", "Medical Review"],
    currentroute: "/hip-operations/claim-process",
    routes: [
      "/hip-operations",
      "/hip-operations/claim-process",
      "/hip-operations/review-file",
    ],
  },
  {
    title: ["Business Process", "Medical Bill Worksheet"],
    currentroute: "/hip-operations/medical-bill-worksheet",
    routes: ["/hip-operations", "/hip-operations/medical-bill-worksheet"],
  },
  {
    title: ["Business Process", "Medical Review", "Document Verify"],
    currentroute: "/cp",
    routes: ["/hip-operations", "/hip-operations/claim-process"],
  },
  {
    title: ["Business Processes", "Medical Bill Worksheet", "Document Verify"],
    currentroute: "/mbw",
    routes: ["/hip-operations", "/hip-operations/medical-bill-worksheet"],
  },
  {
    title: ["Dashboard", "Document History"],
    currentroute: "/hip-operations/document-history",
    routes: [
      "/hip-operations/document-history",
      "/hip-operations/document-history",
    ],
  },
  {
    title: ["Dashboard", "Workflow Analysis"],
    currentroute: "/hip-operations/reports",
    routes: ["/hip-operations/document-history", "/hip-operations/reports"],
  },

  {
    title: ["HIP Operations"],
    currentroute: "/hip-operations",
  },
  {
    title: ["Medicare Analytics"],
    currentroute: "/hip-operations/medicare-dashboard",
    routes: ["/hip-operations/medicare-dashboard"],
  },
  // {
  //   title: ["Medicare Analytics", "Contract Details"],
  //   currentroute: "/contract-details",
  //   routes: [ "/hip-operations/medicare-dashboard", "/hip-operations/medicare-dashboard/contract-details"],
  // },
  {
    title: ["Medicare Analytics", "Contract Details"],
    currentroute: "/contract-details",
    routes: [
      "/hip-operations/medicare-dashboard",
      "/hip-operations/contract-details",
    ],
  },
  {
    title: ["Medicare Analytics", "Contract Details", "Measure Details"],
    currentroute: "/measure-details",
    routes: [
      "/hip-operations/medicare-dashboard",
      "/hip-operations/contract-details",
      "/hip-operations/measure-details",
    ],
  },
];
