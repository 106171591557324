import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import chaticon from "../../../assets/Icons/Chat-icon.png";
import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

const QuestionList = ({ questions }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  function pxToRem(pxValue) {
    const baseFontSize = 35;
    const remValue = pxValue / baseFontSize;
    return remValue;
  }
  const handleAccordionChange = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "10px",
    marginBottom: "16px",
    width: "92%"
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  return (
    <div className="question-list" sx={{ overflow: "scroll" }}>
      <List>
        {questions.map((question, index) => (
          <Accordion
            key={index}
            expanded={expandedIndex === index}
            onChange={() => handleAccordionChange(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  display: "flex",
                }}
              >
                <div>{question.question}</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ fontSize: "14px" }}>
                <strong>A:</strong> {question.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </div>
  );
};

export default QuestionList;
