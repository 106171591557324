import React, { useState } from 'react';
import { TextField, Grid, Typography, Select, MenuItem, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import "./index.css";
import Back from '../../../assets/Icons/Back.svg';
import { useNavigate } from 'react-router-dom';
import PatientResult from '../../SinglePatient.json';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SinglePatientSearch() {

    const navigate = useNavigate();
    const [fields, setFields] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        startDate: '',
        endDate: '',
        gender: '',
    });

    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        dob: false,
        startDate: false,
        endDate: false,
    });

    const [option, setOption] = useState('healthinformation');
    const [healthinformation, setHealthInformation] = useState('');
    const [electronicmedical, setElectronicMedical] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleOptionChange = (event) => {
        setOption(event.target.value);
        setHealthInformation('');
        setElectronicMedical('');
    };

    const handleHealthInformationChange = (event) => {
        setHealthInformation(event.target.value);
    };

    const handleElectronicMedicalChange = (event) => {
        setElectronicMedical(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        let formValid = true;
        const updatedErrors = {};

        Object.keys(fields).forEach((field) => {
            if (!fields[field] && field !== "gender") {
                updatedErrors[field] = true;
                formValid = false;
            }
        });

        setErrors(updatedErrors);

        if (formValid) {
            if (PatientResult.find((value) => fields.firstName?.toLowerCase() === value.firstName?.toLowerCase() && fields.lastName?.toLowerCase() === value.lastName?.toLowerCase() && fields.dob === value.birthDate && fields.startDate === value.startDate && fields.endDate === value.endDate)) {
                navigate(`/hip-clinical/single-patient-search/results?firstName=${fields.firstName}&lastName=${fields.lastName}&dob=${fields.dob}`)
            }
            else {
                toast.error('Patient Not Found', {
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
        }
    };

    return (
        <div className="patient_search_main_container">
            <div className="patient_search_sub_container">
                <form onSubmit={handleSearch}>
                    <Typography variant="h1" className="patient_search_heading" >Enter Details</Typography>
                    <Grid container spacing={2} className="patient_search_grid">
                        <Grid item xs={12} md={4} sm={12} lg={4} className="patient_search_sub_grid">
                            <Typography variant="subtitle1" className="patient_search_sub_heading">FIRST NAME * </Typography>
                            <TextField
                                className="patient_search_sub_field"
                                type="text"
                                name="firstName"
                                placeholder="Enter First Name"
                                hinttext="Enter Name"
                                value={fields.firstName}
                                onChange={handleChange}
                                helperText={errors.firstName && "Please enter First Name"}
                                error={errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={12} lg={4}>
                            <Typography variant="subtitle1" className="patient_search_sub_heading">LAST NAME *</Typography>
                            <TextField
                                className="patient_search_sub_field"
                                type="text"
                                name="lastName"
                                placeholder='Enter Last Name'
                                value={fields.lastName}
                                onChange={handleChange}
                                error={errors.lastName}
                                helperText={errors.lastName && "Please enter Last Name"}

                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="patient_search_grid">
                        <Grid item xs={12} md={4} sm={12} lg={4}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle1" className="patient_search_sub_heading">GENDER</Typography>
                                <Select name="gender"
                                    value={fields.gender}
                                    onChange={handleChange}
                                    className="patient_search_sub_select"
                                    fullWidth
                                    displayEmpty
                                    renderValue={(value) => (value !== '' ? value : 'Select')}
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} sm={12} lg={4}>
                            <Typography variant="subtitle1" className="patient_search_sub_heading">DOB *</Typography>
                            <TextField
                                className="patient_search_sub_field"
                                style={{textTransform: "uppercase ! important"}}
                                type="date"
                                name="dob"
                                value={fields.dob}
                                onChange={handleChange}
                                helperText={errors.dob && "Please select Date of Birth"}
                                error={errors.dob}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} sm={12} lg={12} >
                            <FormControl component="fieldset" sx={{ width: '100%' }}>
                                <RadioGroup row aria-label="option" sx={{ display: 'flex', flexDirection: 'row !important' }} name="option" value={option} onChange={handleOptionChange}>

                                    <Grid item xs={12} md={4} sm={12} lg={4} >
                                        <FormControlLabel className="patient_search_sub_heading" value="healthinformation" control={<Radio />} label="Health Information Exchange" />
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={12} lg={4} >
                                        <FormControlLabel className="patient_search_sub_heading" value="electronicmedical" control={<Radio />} label="Electronic Medical Records" />
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginLeft: '30px', marginBottom: '30px' }}>
                            <Grid item xs={12} md={4} sm={12} lg={4}>
                                <Select value={healthinformation} className="patient_search_sub_radio_select" 
                                    onChange={handleHealthInformationChange} 
                                    fullWidth 
                                    disabled={option !== 'healthinformation'}
                                    displayEmpty
                                    renderValue={(value) => (value !== '' ? value : 'Select')}
                                >
                                    <MenuItem value="HSX">HSX</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={4} sm={12} lg={4} sx={{ marginLeft: '8px' }}>
                                <Select value={electronicmedical} className="patient_search_sub_radio_select" 
                                    onChange={handleElectronicMedicalChange} 
                                    fullWidth 
                                    disabled={option !== 'electronicmedical'}
                                    displayEmpty
                                    renderValue={(value) => (value !== '' ? value : 'Select')}
                                >
                                    <MenuItem value="Veradigm">Veradigm</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className="patient_search_grid">
                        <Grid item xs={12} md={4} sm={12} lg={4}>
                            <Typography variant="subtitle1" className="patient_search_sub_heading">START DATE *</Typography>
                            <TextField
                                className="patient_search_sub_field"
                                type="date"
                                name="startDate"
                                value={fields.startDate}
                                onChange={handleChange}
                                helperText={errors.startDate && "Please select Start Date"}
                                error={errors.startDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={12} lg={4}>
                            <Typography variant="subtitle1" className="patient_search_sub_heading">END DATE *</Typography>
                            <TextField
                                className="patient_search_sub_field"
                                type="date"
                                name="endDate"
                                value={fields.endDate}
                                onChange={handleChange}
                                helperText={errors.endDate && "Please select End Date"}
                                error={errors.endDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12} >
                        <Button type="submit" variant="contained" color="primary" className='patient_search_sub_button'>
                            Search
                        </Button>
                    </Grid>
                </form>
            </div >
        </div>
    )
}

export default SinglePatientSearch