import React, { useState } from "react";
import closeBtn from "../../../assets/Icons/closebtn.png";
import "./PdfName.css";
import { useSelector } from "react-redux";

const PdfName = (props) => {
  const theme = useSelector((state) => state.messages.Theme)

  const handleCloseClick = () => {
    props.removeFile()
  };

  return (

    <div className={`${theme}outer_box`}>
      <div className={`${theme}pdf_name`} >{props.name_of_pdf}</div>
      <img
        className={`${theme}close-icon`}
        src={closeBtn}
        alt=""
        onClick={handleCloseClick}
      />
    </div>

  );
};

export default PdfName;