import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import HIPLogo from "../assets/NewIcons/HIP Logo Horizontal.svg";
import Eye from "../assets/NewIcons/View_alt_light.svg";
import EyeShow from "../assets/NewIcons/View_alt_fill.svg";
import "./Login.css";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import SetCookie from '../HIPCookies/setCookie';
import { jwtDecode } from "jwt-decode";
import HIPBackground from "../assets/NewIcons/Logo_BG.svg";
import { useDispatch } from "react-redux";
import { setToastAlert } from "../Redux/features/AlertsSlice";

const Login = () => {

    const dispatch = useDispatch();
    const [fields, setFields] = useState({
        emailId: "",
        password: "",
    });
    const [errors, setErrors] = useState({
        emailId: false,
        password: false,
    });
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        // Cleanup button disabled status when component unmounts
        return () => {
            setIsButtonDisabled(false);
        };
    }, []);

    const clearIndexedDB = () => {
        const dbDeleteRequest = window.indexedDB.deleteDatabase("Database1");

        dbDeleteRequest.onsuccess = () => {
            console.log("IndexedDB cleared successfully");
        };

        dbDeleteRequest.onerror = (error) => {
            console.error("Error clearing IndexedDB:", error.target.error);
        };
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleTogglePasswordVisibility = () => {
        setShowLoginPassword(!showLoginPassword);
    };

    // Function to extract a claim by searching for a specific keyword
    function extractClaim(claims, keyword) {
        for (const claim in claims) {
            if (claim.includes(keyword)) {
                return claims[claim];
            }
        }
        return null;
    }

    const handleLogin = (event) => {
        event.preventDefault();
        setIsButtonDisabled(true);
        let formValid = true;
        const updatedErrors = {};

        Object.keys(fields).forEach((field) => {
            if (!fields[field]) {
                updatedErrors[field] = true;
                formValid = false;
            }
            setIsButtonDisabled(false);
        });

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(fields.emailId);

        if (!isValidEmail) {
            formValid = false;
            dispatch(setToastAlert({
                isactive: true,
                type: 'error',
                exp: 10000,
                message: "Please enter a valid email address."
            }))
            setIsButtonDisabled(false);
        }
        setErrors(updatedErrors);

        if (formValid) {
            axios.post(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Auth/Inbound/HIP/Token`,
                {
                    email: fields.emailId,
                    password: fields.password
                }).then(response => {
                    if (response.status === 200 && response.data !== 'User is inactive') {
                        if (response.data.isActive === true) {
                            const loginJWTToken = response.data.token;
                            const user = jwtDecode(loginJWTToken);
                            if (user) {
                                const givenName = extractClaim(user, 'givenname');
                                const emailAddress = extractClaim(user, 'emailaddress');
                                const surName = extractClaim(user, 'surname');
                                const expiryTime = parseInt(response.data.tokenExpiration, 10) + 1;
                                const tokenExpirationDate = new Date(Date.now() + (expiryTime * 60 * 1000));

                                const userData = {
                                    userId: response.data.userId,
                                    emailId: emailAddress,
                                    userName: surName,
                                    clientId: response.data.clientId,
                                    userNameDisplay: givenName,
                                    isAuth: true,
                                    tokenExpiration: response.data.tokenExpiration,
                                }
                                const jwtToken = { token: loginJWTToken }
                                const roleData = (response.data.userRoles || []).map(role => ({
                                    roleName: role.roleName
                                }));
                                if (response.data.modules !== "Data not available") {
                                    const modules = JSON.parse(response.data.modules);

                                    const moduleData = modules.map((module) => ({
                                        id: module.ModuleId,
                                        displayName: module.ModuleName
                                    }))
                                    const permissionDataReadWrite = modules.flatMap((module) => ({
                                        moduleName: module.ModuleName,
                                        permissionsDataArray: module.Permission.map((permission) => ({
                                            displayName: permission.displayName,
                                            allowToReadData: permission.PermissionSecurityGroup[0].AllowToReadData,
                                            allowToWriteData: permission.PermissionSecurityGroup[0].AllowToWriteData,
                                            viewPHIData: permission.PermissionSecurityGroup[0].ViewPHIData
                                        }))
                                    }))

                                    clearIndexedDB();
                                    const request = window.indexedDB.open('Database1', 1);
                                    request.onupgradeneeded = (event) => {
                                        const database = event.target.result;

                                        if (!database.objectStoreNames.contains('permissionData')) {
                                            database.createObjectStore('permissionData', { autoIncrement: true, keyPath: 'id' });
                                        }
                                    };
                                    request.onsuccess = (event) => {
                                        const database = event.target.result;
                                        const transaction = database.transaction('permissionData', 'readwrite');
                                        const objectStore = transaction.objectStore('permissionData');

                                        const addRequest = objectStore.add({ key: 'perData', value: permissionDataReadWrite });

                                        addRequest.onerror = (error) => {
                                            console.error('Error storing API response in IndexedDB: ', error.target.error)
                                        }
                                    }
                                    SetCookie('moduleInfo', JSON.stringify(moduleData), tokenExpirationDate);
                                } else {
                                    const moduleData = null;
                                    SetCookie('moduleInfo', JSON.stringify(moduleData), tokenExpirationDate);
                                }

                                // Save user data to cookie
                                SetCookie('token', JSON.stringify(jwtToken), tokenExpirationDate);
                                SetCookie('roleInfo', JSON.stringify(roleData), tokenExpirationDate);
                                SetCookie('userInfo', JSON.stringify(userData), tokenExpirationDate);
                            }

                            dispatch(setToastAlert({
                                isactive: true,
                                type: 'success',
                                exp: 10000,
                                message: "Login successfully."
                            }))

                            setTimeout(() => {
                                window.open("/dashboard", "_self");
                            }, 1000);

                        } else if (response.status === 200 && response.data === "Wrong Password") {
                            dispatch(setToastAlert({
                                isactive: true,
                                type: 'error',
                                exp: 10000,
                                message: "Incorrect Password."
                            }))
                            setIsButtonDisabled(false);
                        }
                    } else if (response.status === 204 || response.data === "") {

                        dispatch(setToastAlert({
                            isactive: true,
                            type: 'error',
                            exp: 10000,
                            message: "Incorrect Email Address."
                        }))
                        setIsButtonDisabled(false);
                    } else {
                        dispatch(setToastAlert({
                            isactive: true,
                            type: 'error',
                            exp: 10000,
                            message: "User is Inactive."
                        }))
                        setIsButtonDisabled(false);
                    }
                }).catch((error) => {
                    console.error("An error occured while fetching the JWT Token: ", error);
                    setIsButtonDisabled(false);
                })
        }
    };

    return (
        <>
            <div className="app_container">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} className="hip_one_login_grid" sx={{ backgroundImage: `url(${HIPBackground})` }} >
                            <Stack direction="column" className="hip_one_login_grid_container" >
                                <Box>
                                    <Typography variant="jumbotron" className="hip_one_login_grid_content" >
                                        What is Health <br /> Intelligence <br />Platform?
                                    </Typography>
                                    <Typography variant="h1" className="hip_one_login_grid_content_text" >
                                        Efficiency through intelligence.
                                    </Typography>
                                    <Typography variant="jumbotron" className="hip_one_login_grid_content" >
                                        Why Choose Health Intelligence Platform?
                                    </Typography>
                                    <Typography variant="h1" className="hip_one_login_grid_content_text" >
                                        Health Intelligence Platform helps <br /> business and
                                        clinical operations be <br /> more efficient and creates value
                                        <br /> through intelligence.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ height: "100vh", position: 'relative' }}>
                            <Box className="hip_one_login_grid_box" elevation={0} >
                                <div className="hip_one_login_content">
                                    <form onSubmit={handleLogin} className="hip_one_login_form">
                                        <div className="hip_one_login_gen_logo">
                                            <img src={HIPLogo} alt="Genzeon" />
                                        </div>
                                        <Typography variant="h3" className="hip_one_login_heading"> Login</Typography>
                                        <Typography variant="h5" className="hip_one_login_heading_subtext" >
                                            Enter your Email Address and Password
                                        </Typography>
                                        <Typography variant="h5" className="hip_one_login_text_heading" >
                                            Email Address
                                        </Typography>
                                        <TextField
                                            sx={{ marginBottom: "1.25rem" }}
                                            className="hip_one_login_text_field"
                                            inputProps={{ maxLength: 100 }}
                                            name="emailId"
                                            value={fields.emailId}
                                            onChange={handleChange}
                                            error={errors.emailId}
                                            helperText={errors.emailId && "Please enter a valid Email Address"}
                                            type="text"
                                            placeholder="Enter Email Address"
                                        />
                                        <Typography variant="h5" className="hip_one_login_text_heading" > Password</Typography>
                                        <div className="hip_one_login_password_container_image">
                                            <TextField
                                                sx={{ marginBottom: "0.625rem" }}
                                                className="hip_one_login_text_field"
                                                inputProps={{ maxLength: 100 }}
                                                name="password"
                                                type={showLoginPassword ? 'text' : 'password'}
                                                placeholder="Enter Password"
                                                value={fields.password}
                                                onChange={handleChange}
                                                error={errors.password}
                                                helperText={errors.password && "Please enter Password"}
                                            >
                                            </TextField>
                                            {showLoginPassword ?
                                                (<img src={EyeShow} alt="Eyeshow" onClick={handleTogglePasswordVisibility} />)
                                                : <img src={Eye} alt="Eyehide" onClick={handleTogglePasswordVisibility} />}
                                        </div>
                                        <div>
                                            <a className="hip_one_login_forgot_password" href="/forgot-password" >
                                                Forgot Password?
                                            </a>
                                        </div>
                                        <Button className="hip_one_login_button" type="submit" variant="contained" color="primary" disabled={isButtonDisabled} >
                                            Login
                                        </Button>
                                        <div style={{ width: '17.313rem' }}>
                                            <a className="hip_one_login_redirect" href="/" >
                                                Go back to home page
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </Box>
                            <div className="hip_one_login_privacy_containers">
                                <div className="hip_one_login_privacy_details">
                                    <a
                                        href="https://www.genzeon.com/privacy-policy/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    <label>Copyright © Genzeon | All Rights Reserved</label>
                                </div>
                            </div>
                        </Grid>
                    </Grid >
                </Box>
            </div >
        </>
    );
};

export default Login;
