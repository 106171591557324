import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutWithMenuBar from "./Layout/LayoutWithMenuBar";
import ErrorPage from '../Components/ErrorPage/ErrorPage';
import EditUserProfile from "./Screens/EditProfile/EditProfile";

function App() {
  return (
    <>
      <Routes>
        {/* Components with Header and Navigation  */}
        {/* With Menu Bar */}
        <Route element={<LayoutWithMenuBar />}>
          <Route path="/edit-profile" element={<EditUserProfile />} />
        </Route>

        {/* Error Route */}
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
