import React, { useState } from "react";
import "./IntractWithUrl.css";
import SendIcon from "../../../../assets/Icons/send-icon.png";
import SearchIcon from "../../../../assets/Icons/search-icon.png";
import CreateChatbotIcon from "../../../../assets/Icons/add-chatbot.png";
import UrlMessages from "../../../Components/UrlMessages/UrlMessages.js";
import { useDispatch, useSelector } from "react-redux";
import {
  addUrlMessage,
  setCreatedChatbot,
} from "../../../../Redux//features/UrlMessageSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import SendIcon1 from "../../../../assets/Icons/send-icon1.png";
import { Divider, MenuItem, Select } from "@mui/material";
import GetCookie from "../../../../HIPCookies/getCookie.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const InteractWithUrl = () => {

  let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;

  const navigate = useNavigate();
  const createdChatbot = useSelector(
    (state) => state.urlMessages.createdChatbot
  );
  const [userMessage, setUserMessage] = useState("");
  const dispatch = useDispatch();
  const [chatbotNames, setChatbotNames] = useState([]);
  const [selectedChatbot, setSelectedChatbot] = useState("");
  const [ismessageLoading, setIsmessageLoading] = useState(false);
  const [disableChat, setDisableChat] = useState(false);
  const [chatbotUserId, setChatbotUserId] = useState({});

  useEffect(() => {
    const fetchChatbotNames = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CONNECT}/interact-url/openai/all_chatbots`
        );
        const chatbotNamesData = response.data.chatbot_names;
        setChatbotUserId(response.data.chatbot_dict);

        if (chatbotNamesData.length === 0) {
          setChatbotNames(["No Chatbots"]);
          setDisableChat(true);
        } else {
          setChatbotNames([...chatbotNamesData]);
          if (createdChatbot !== "") {
            setSelectedChatbot(createdChatbot);
          } else {
            setSelectedChatbot(chatbotNamesData[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching chatbot names:", error);
      }
    };

    fetchChatbotNames();
  }, []);

  const messageChangeHandler = (event) => {
    setUserMessage(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key == "Enter" && userMessage.trim() !== "") {
      sendButtonHandler();
    }
  };
  const sendHandler = (e) => {
    if (userMessage.trim() !== "") {
      sendButtonHandler();
    }
  };

  const sendButtonHandler = async (event) => {
    if (userMessage.trim() === "") {
    }
    let tempMessageobj = { id: "user", text: userMessage };
    dispatch(
      addUrlMessage({ chatbotName: selectedChatbot, message: tempMessageobj })
    );
    setIsmessageLoading(true);
    setUserMessage("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/get_answer`,
        {
          query: userMessage,
          chatbot_name: selectedChatbot,
        }
      );
      tempMessageobj = { id: "bot", text: response.data.answer };
      dispatch(
        addUrlMessage({ chatbotName: selectedChatbot, message: tempMessageobj })
      );
      setIsmessageLoading(false);
    } catch (error) {
      console.error(error);
      setIsmessageLoading(false);
      dispatch(
        addUrlMessage({
          chatbotName: selectedChatbot,
          message:
            "I am not able to answer this question. I don't have enough information to answer this question.",
        })
      );
    }
  };
  const handleNavigate = (e) => {
    navigate("/hip-connect/hip-create-chatbot");
  };

  const handleModifyNavigate = () => {
    if (chatbotNames[0] === "No Chatbots") {
      toast.error("No chatbots please first create chatbot");
      return;
    }
    dispatch(setCreatedChatbot(selectedChatbot));
    navigate("/hip-connect/hip-modify-chatbot");
  };

  const customMenuProps = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <div className="url_main_container">
      <div className="url_switch-container">
        <span className="url_switch-label">Privacy Mode</span>
        <label className="url_switch">
          <input type="checkbox" className="url_switch-input" disabled />
          <span className="url_switch-slider"></span>
        </label>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Divider
          sx={{
            width: "96%",
            borderColor: "#E6E0E9",
            borderWidth: "1px",
          }}
        />
      </div>
      <div className="url_chatbox_container">
        <UrlMessages
          chatbotName={selectedChatbot}
          isMeesageloading={ismessageLoading}
        />
      </div>
      <div className="url_search_box_container">
        <Tooltip title={selectedChatbot} placement="bottom-start">
          <div className="url_select_box">
            <Select
              IconComponent={ExpandMoreIcon}
              displayEmpty
              fullWidth
              className="url_select_box_select"
              value={selectedChatbot}
              onChange={(e) => {
                setSelectedChatbot(e.target.value);
              }}
              MenuProps={customMenuProps}
            >
              {chatbotNames.map((chatbotName, index) => (
                <MenuItem className="url_select_box_content"
                  style={{ fontWeight: chatbotName === selectedChatbot ? "500" : "400" }}
                  key={index}
                  value={chatbotName}
                >
                  {chatbotName}
                </MenuItem>
              ))}
            </Select>
          </div>

        </Tooltip>

        <input
          className="url_text_box"
          type="text"
          placeholder="Ask me.."
          disabled={disableChat}
          value={userMessage}
          onChange={messageChangeHandler}
          onKeyDown={handleKeyPress}
          style={{
            backgroundImage: `url(${SearchIcon})`,
            backgroundSize: "16px",
            backgroundPosition: "2vh center",
            backgroundRepeat: "no-repeat",
            paddingLeft: "6vh",
          }}
        />

        <img
          className="url_send_button_icon"
          src={userMessage.trim().length !== 0 ? SendIcon1 : SendIcon}
          onClick={sendHandler}
          style={{
            backgroundColor:
              userMessage.trim().length !== 0 ? "#0044CC" : "white",
            borderRadius: "5px"
          }}
        />

        {chatbotUserId[selectedChatbot] === userId ? <button
          variant="contained"
          onClick={handleModifyNavigate}
          className="modify_chatbot_button_"
        >
          Modify
        </button> :
          <button
            variant="contained"
            onClick={handleModifyNavigate}
            className="modify_chatbot_button_disable"
            disabled
          >
            Modify
          </button>}
        <button
          variant="contained"
          onClick={handleNavigate}
          className="create_new_chatbot_button_"
          style={{
            backgroundImage: `url(${CreateChatbotIcon})`,
            backgroundSize: "20px",
            backgroundPosition: "15px center",
            backgroundRepeat: "no-repeat",
            paddingLeft: "37px",
          }}
        >
          New Chatbot
        </button>
      </div>
    </div>
  );
};

export default InteractWithUrl;
