import React, { useState } from "react";
import SendButtonIcon from "../../../assets/Icons/Send_fill.png";
import SearchIcon from "../../../assets/Icons/search-icon.png";

const Chatbar = ({ onSendMessage }) => {
  const [inputText, setInputText] = useState("");

  const handleSend = () => {
    if (inputText.trim()) {
      onSendMessage(inputText);
      setInputText("");
    }
  };

  return (
    <div className="chatbar">
      <div>
        <img
          src={SearchIcon}
          alt="Search Icon"
          className="chatbar-search-icon"
        />
      </div>
      <div>
        <input
          type="text"
          placeholder="Ask for.."
          className="chat-input"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
        />
      </div>
      <button className="send-button" onClick={handleSend}>
        <img
          src={SendButtonIcon}
          alt="Send Icon"
          className="chatbar-send-icon"
        />
      </button>
    </div>
  );
};

export default Chatbar;
