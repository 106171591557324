import React, { useState } from "react";
import { useSelector } from "react-redux"; // Import useSelector from react-redux to access the Redux state
import magicIcon from "../../../assets/magic 1.svg";
import magicDarkIcon from "../../../assets/magicdarkMode.svg";
import tryAskingIcon from "../../../assets/tryaskingicon.svg";
import "./TryAsking.css";

const TryAsking = ({ suggestedQuestions, onQuestionClick }) => {
  // State variable to track whether a question is being asked
  const [isQuestionBeingAsked, setIsQuestionBeingAsked] = useState(false);
  // State variables to keep track of the clicked questions
  const [clickedQuestions, setClickedQuestions] = useState([]);

  // Use useSelector to access the darkMode from Redux state
  const darkMode = useSelector((state) => state.theme.darkMode);

  // Function to handle question click
  const handleQuestionClick = (question) => {
    // Toggle the state to indicate whether a question is being asked
    setIsQuestionBeingAsked(!isQuestionBeingAsked);

    // Store the clicked question
    setClickedQuestions([...clickedQuestions, question]);

    // Call the provided callback function
    onQuestionClick(question);
  };

  // Check if both questions have been clicked
  const bothQuestionsAsked = clickedQuestions.length === 2;

  return (
    <div className={`suggested-questions ${darkMode ? "dark-mode" : ""}`}>
      {!bothQuestionsAsked && (
        <React.Fragment>
          <p className="suggested-questions-header">
            <img
              src={tryAskingIcon}
              alt="Try Asking Icon"
              className="try-asking-icon"
            />
            Try asking
          </p>
          {isQuestionBeingAsked
            ? // Render only the clicked questions when a question is being asked
              clickedQuestions.map((question, index) => (
                <button
                  key={index}
                  onClick={() => handleQuestionClick(question)}
                  className="suggested-question-button"
                >
                  {question}
                </button>
              ))
            : // Render all questions if no question is being asked
              suggestedQuestions.map((question, index) => (
                <button
                  key={index}
                  onClick={() => handleQuestionClick(question)}
                  className="suggested-question-button"
                >
                  {question}{" "}
                  <img
                    src={darkMode ? magicDarkIcon : magicIcon}
                    alt="Magic Icon"
                    className="suggested-question-icon"
                  />
                </button>
              ))}
        </React.Fragment>
      )}
    </div>
  );
};

export default TryAsking;
