import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilter } from "../../../Redux/features/AlertsSlice";
import {
  deleteBlankPage,
  deleteDuplicatePage,
  getSelectedFieldBoundingBoxes
} from "../../../Redux/features/DocumentsSlice";
import MedicalBillBoundingBox from "../MedicalBillBoundingBox/MedicalBillBoundingBox";
import PdfReader from "../PdfReader/PdfReader";
import "./MBWFileDisplay.css";

function MBWFileDisplay(props) {
  const initialValue = 460;
  const selectedFieldBoundingBox = useSelector(getSelectedFieldBoundingBoxes);
  const dispatch = useDispatch();
  useEffect(() => {
    setZoom(initialValue);
  }, []);
  const filter = useSelector(getFilter);
  const [zoom, setZoom] = useState(460);
  const [imageWidth, setImageWidth] = useState(0);
  const [offSet, setOffSet] = useState(0.300);

  const handleZoom = () => {
    if (zoom < 1300) {
      setZoom(zoom + 100);
      setOffSet(Number.parseFloat(((zoom + 100) / imageWidth).toFixed(3)));
    }
  };

  const handleZoomOut = () => {
    if (zoom > 400) {
      setZoom(zoom - 100);
      setOffSet(Number.parseFloat(((zoom - 100) / imageWidth).toFixed(3)));
    }
  };

  const handleDelete = (currentUrl) => {
    dispatch(deleteDuplicatePage(currentUrl));
  };
  const handleBlankDelete = (url) => {
    dispatch(deleteBlankPage(url));
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="file_display_btns" >
          <Tooltip placement="bottom" title="Zoom In">
            <ZoomIn fontSize="large" onClick={handleZoom} />
          </Tooltip>
          <Tooltip placement="bottom" title="Zoom out">
            <ZoomOut fontSize="large" onClick={handleZoomOut} />
          </Tooltip>
        </div>
      </div>
      <div
        className="file_display_image_display"
        style={{
          height: props.isActive ? "450px" : "780px",

        }}
        onScroll={() => props.setCoArrow({ isActive: false })}
      >
        {[props.FileImages && props.FileImages[props.pageNumber - 1]].map(
          (e, index) => (
            <div style={{ display: "flex" }}>

              <div key={index}>
                <PdfReader
                  zoom={zoom}
                  imageURI={e.img_url === null ? "" : e.img_url}
                  name={e.image}
                  setImageWidth={setImageWidth}
                  width={e.img_res === null ? 2000 : e.img_res[0]}
                  setOffSet={setOffSet}
                />

                <MedicalBillBoundingBox
                  coordinates={selectedFieldBoundingBox}
                  color="high" // You can set the appropriate color here
                  offSet={offSet}
                />

              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default MBWFileDisplay;
