import React, { useEffect, useState } from 'react'
import "./POPUP.css"
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import { useSelector } from 'react-redux'
import { getLoading, getNormalAlert, getViewFile } from '../../../Redux/features/AlertsSlice'
import NormalAlert from './features/NormalAlert'
import UploadFile from './UploadFile/UploadFile'
import Audit from './Audit/Audit'
import DocConfig from './DocConfig/DocConfig'
import ViewFile from '../ViewFile/ViewFile'

function POPUP() {

    const loading = useSelector(getLoading)
    const normalAlert = useSelector(getNormalAlert)

    const viewFileAlert = useSelector(getViewFile)

    const [colors, setColors] = useState('')
    const [bgColors, setBgColors] = useState('')

    useEffect(() => {

        if (normalAlert.variant === undefined || normalAlert.variant === '' || normalAlert.variant === 'info') {
            // Info
            setColors('#014361')
            setBgColors("#E5F7ED")
        }
        if (normalAlert.variant === 'success') {
            // Success
            setColors('#1E4620')
            setBgColors("#EDF7ED")
        }
        if (normalAlert.variant === 'error') {
            // Error
            setColors('#5F2120')
            setBgColors("#FDEDED")
        }
        if (normalAlert.variant === 'warning') {
            // Warning
            setColors('#663C00')
            setBgColors("#FFF6FD")
        }

    }, [normalAlert.variant])

    return (
        <div className='pop_up_screen'>
            {
                loading.isActive && (
                    <LoadingScreen />
                )
            }
            {
                normalAlert.isActive && (
                    <NormalAlert
                        color={colors}
                        bg={bgColors}
                        variant={normalAlert.variant}
                        message={normalAlert.message}
                    />
                )
            }
            {
                viewFileAlert.isActive && (
                    <ViewFile
                        currentId={viewFileAlert.currentId}
                        currentFileType={viewFileAlert.currentFileType}
                        fileName={viewFileAlert.fileName}
                    />
                )
            }

            <Audit />
            <DocConfig />
            <UploadFile />
        </div>
    )
}

export default POPUP