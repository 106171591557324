import CircularProgress from "@mui/material/CircularProgress"; // Import Material-UI spinner
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SectionSummary.css";
import { getUploadedFilename } from "../../../Redux/features/DocumentsSlice";
import GetCookie from "../../../HIPCookies/getCookie";
import { Chip } from "@mui/material";

const ClaimsSectionSummary = ({ sectionsData, onBackClick }) => {
  const [selectedSection, setSelectedSection] = useState("");
  const [showSummary, setShowSummary] = useState(false);
  const [sectionSummaries, setSectionSummaries] = useState("");
  const [loading, setLoading] = useState(false);
  var filename = useSelector(getUploadedFilename);
  let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;

  const dispatch = useDispatch();
  // const theme = useSelector((state) => state.messages.Theme) || "";

  const handleSectionSelection = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleRemoveSection = () => {
    setSelectedSection("");
  };

  const handleSummarizeClick = async () => {
    if (!selectedSection) {
      // Handle the case where no section is selected
      return;
    }

    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        userId: userId, // Include the userId in the headers
      };

      const imagesResponse = await axios.post(
        `${process.env.REACT_APP_CONNECT}/med-review-process/get-section-images`,
        {
          section_name: selectedSection,
          pdf_filename: filename, // Provide the PDF filename
        },
        { headers: headers } // Pass the headers object in the request
      );
    } catch (error) {
      console.error("API call for images failed", error);
    }
    try {
      setSectionSummaries();

      console.log(filename, selectedSection, userId);
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/med-review-process/generate_summary`,
        { section_name: selectedSection, pdf_filename: filename },
        { headers: { userId: userId } }
      );
      // Extract summary data from the response
      const Summary = response.data.summary;
      console.log(Summary)
      setSectionSummaries(Summary);
      setShowSummary(true);
    } catch (error) {
      setSectionSummaries("");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="claims-section-list-container">
      <div className="claims-section-box-container">
        <p className="claim-section-list-title">Sections List</p>
        <div className="claims-sections-container">
          <select value={selectedSection} onChange={handleSectionSelection}>
            <option value="">Select a Section</option>
            {sectionsData && sectionsData.length > 0 ? (
              sectionsData.map((section, index) => (
                <option key={index} value={section}>
                  {section}
                </option>
              ))
            ) : (
              <option disabled>No Sections Available</option>
            )}
          </select>
        </div>
        {selectedSection && <h3>Selected Section:</h3>}
        <div>
          {selectedSection ? (
            <div className="claims-selected-sections-box">
              <Chip
                label={selectedSection}
                onDelete={handleRemoveSection}
              ></Chip>
            </div>
          ) : (
            <h5>No Section Selected</h5>
          )}
          <button
            className="claims-summarize-button"
            onClick={handleSummarizeClick}
            disabled={loading} // Disable button when loading
          >
            {loading ? (
              <CircularProgress size={24} thickness={4} color="inherit" /> // Material-UI spinner
            ) : (
              "Summarize Section"
            )}
          </button>
        </div>
      </div>

      {showSummary && (
        <div className="claims-section-summaries">
          <h3>Section Summaries</h3>
          <p className="summary-paragraph">
            {JSON.stringify(sectionSummaries)}
          </p>
        </div>
      )}
    </div>
  );
};

export default ClaimsSectionSummary;
