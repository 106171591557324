
import React, { useEffect, useRef, useState } from "react";
import { TextField, Typography, Button, FormControl, Select, FormHelperText, MenuItem, Box } from '@mui/material';
import HomeImage from '../assets/Icons/Home-Icon.svg';
import { NavLink as Link } from 'react-router-dom';
import RightArrowImage from '../assets/Icons/CaretBreadcrumb.svg';
import AttachImage from '../assets/Img_box_light.svg';
import TrashImage from '../assets/Icons/Trash.svg';
import './Feedback.css';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import GetCookie from "../HIPCookies/getCookie";
import { toast } from "react-toastify";

const Feedback = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [category, setCategory] = useState([]);
  const [title, setTitle] = useState('');
  const [feedback, setFeedback] = useState('');
  const userName = (JSON.parse(GetCookie('userInfo')) || {}).userName;
  const emailId = (JSON.parse(GetCookie('userInfo')) || {}).emailId;
  const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;
  const userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;

  useEffect(() => {
    getCategory()
  }, [])

  function getCategory() {
    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/PermissionModule`, {
      headers: {
        "Authorization": `Bearer ${jWTToken}`
      }
    })
      .then((response) => {
        setCategory(response.data)
      })
      .catch((error) => {
        console.error("An error occured while fetching the category:", error)
      })
  }

  const [errors, setErrors] = useState({
    category: false,
    title: false,
    feedback: false,
  });

  const handleCancel = () => {
    setSelectedCategory('');
    setSelectedFile(null);
    setTitle('');
    setFeedback('');
    // Clear file input field
    if (inputRef.current) {
      inputRef.current.value = null;
    }
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'category':
        setSelectedCategory(value);
        setErrors((prevErrors) => ({
          ...prevErrors,
          category: false,
        }));
        break;

      case 'title':
        if (value.length <= 100) {
          setTitle(value);
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          title: false,
        }));
        break;

      case 'feedback':
        if (value.length <= 250) {
          setFeedback(value);
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          feedback: false,
        }));
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value)
    // setCategoryError(false);
    setErrors((prevErrors) => ({
      ...prevErrors,
      category: false,
    }));
  };

  // const handleImageChange = (event) => {
  //   if (event.target.files.length === 0) {
  //     return;
  //   }
  //   // setButtonDisabled(false);
  //   const file = event.target.files[0];
  //   if (!selectedFile || file === selectedFile) {
  //     setSelectedFile(file);
  //   }
  //   else {
  //     if (!file) {
  //       setSelectedFile(null);
  //       toast.error('Please upload a valid image file', {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       return;
  //     }
  //     const allowedExtensions = ['.jpg', '.jpeg', '.png', '.tiff', '.bmp'];
  //     const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

  //     if (!allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
  //       setSelectedFile(null);
  //       toast.error('Invalid file type. Please select a .jpg, .jpeg, .png, tiff or bmp file.', {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       return;
  //     }

  //     if (file) {
  //       const minSizeInBytes = 4 * 1024;
  //       const maxSizeInBytes = 2 * 1024 * 1024;

  //       if (file.size >= minSizeInBytes && file.size <= maxSizeInBytes) {
  //         setSelectedFile(file);
  //       } else {
  //         setSelectedFile(null);
  //         toast.error('File size should be between 4KB to 2MB.', {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       }
  //     }
  //     } else {
  //       if (!file) {
  //         toast.error('Please upload a valid image file', {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //         return;
  //       }
  //     }
  //   }
  // };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const allowedExtensions = ['.jpg', '.jpeg', '.png', '.tif', '.tiff', '.bmp'];
    const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

    if (!allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
      toast.error('Invalid file type. Please select a .jpg, .jpeg, .png, tiff or bmp file.', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const minSizeInBytes = 4 * 1024;
    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file.size < minSizeInBytes || file.size > maxSizeInBytes) {
      toast.error('File size should be between 4KB to 2MB.', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    setSelectedFile(file);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCategory || !feedback) {
      toast.error('Please fill in the required fields.', {
        position: toast.POSITION.TOP_CENTER,
      });
      setErrors({
        category: !selectedCategory,
        feedback: !feedback
      });
      return;

    }
    const formData = new FormData();
    // formData.append("FeedbackFile", selectedFile);
    if (title) {
      formData.append("Title", title);
    }
    if (selectedFile) {
      formData.append("FeedbackFile", selectedFile);
    }

    formData.append("SenderEmailID", emailId);
    formData.append("Username", userName);
    formData.append("Category", selectedCategory);
    formData.append("Feedback", feedback);
    formData.append("FeedbackFileName", userId);

    const apiEndpoint = `${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Feedback`;
    try {
      let response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Authorization": `Bearer ${jWTToken}`,
          "Content-Type": 'multipart/form-data'
        },
      });

      if (response.status === 200) {
        toast.success('Feedback submitted successfully.',
          {
            position: toast.POSITION.TOP_CENTER,
          });
        handlePageReload();
      }
      else {
        toast.error('Invalid file, please upload a valid file', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    catch (error) {
      toast.error('Image upload failed.', {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error submitting feedback:", error);
    }
    console.log("formData:", formData)
  }

  const handlePageReload = () => {
    setTitle('');
    setFeedback('');
    setSelectedCategory('');
    setSelectedFile(null);
    // window.location.reload();
  }
  return (
    <div className="feedback_main_container">
      <div className="feedback_breadcrumb">
        <Box className='feedback_bread_crumb_navigations_content' >
          <Link to='/dashboard'>
            <img src={HomeImage} alt='home' style={{ width: "14px" }} />
          </Link>
          <img src={RightArrowImage} alt='bread-crumb-arrow-right' style={{ width: "12px", margin: "0.5px 5px" }} />
          <span style={{ margin: "1px 2px" }}>
            <Link to='/feedback' className="nav_item_bold">Feedback</Link>
          </span>
        </Box>
      </div>
      <div className="feedback_body_main_container">
        <div className="feedback_body_heading">
          Feedback
        </div>
        <div className="feedback_body_desc_form_container">
          <div className="feedback_body_desc_container">
            We value your Feedback
            <div className="feedback_body_desc_para">
              Please complete the form and help us to improve our
              customer experience
            </div>
          </div>
          <div className="feedback_body_form_container">
            <div>
              <Typography className="feedback_body_form_heading"> Let's hear from you</Typography>
            </div>
            <FormControl className='category_select_option_container' error={errors.category}>
              <Typography className="category_select_option_heading" > CATEGORY* </Typography>
              <Select
                className="category_select_option_dropdown"
                name="category"
                placeholder="Select category"
                IconComponent={ExpandMoreIcon}
                fullWidth
                displayEmpty
                value={selectedCategory}
                onChange={handleCategoryChange}
                helperText={errors.category && 'please select a category'}
                error={errors.category}
                renderValue={(selected) => (category.find((option) => option.displayName === selected)?.displayName || 'Select Category')}
              >
                {category.map((option) => (
                  <MenuItem key={option.id} value={option.displayName}>
                    {option.displayName}
                  </MenuItem>
                ))}
                <MenuItem key="others" value="Others">
                  Others
                </MenuItem>
              </Select>
              {errors.category && <FormHelperText id="category-error">Please select a category.</FormHelperText>}
            </FormControl>

            <div className="feedback_form_title">
              <Typography variant="subtitle1" className="category_select_option_heading">TITLE</Typography>
              <TextField
                className="title_sub_field"
                type="text"
                name="title"
                placeholder="Enter title"
                value={title}
                onChange={handleChange}
                helperText={errors.title && "please enter the title"}
                error={errors.title}
              />
            </div>
            <div className="feedback_form_feedback_description">
              <Typography variant="subtitle1" className="category_select_option_heading" >FEEDBACK*</Typography>
              <TextField
                minRows={2}
                maxRows={4}
                className="feedback_form_feedback_textbox"
                type="text"
                name="feedback"
                multiline
                variant="outlined"
                placeholder="Write here"
                value={feedback}
                onChange={handleChange}
                helperText={errors.feedback && "please write your feedback here"}
                error={errors.feedback}
              />
            </div>
            <div className="feedback_form_attach">
              <div className="feedback_form_attach_image">
                <img src={AttachImage} alt="attachImage"
                />
              </div>
              <div className="feedback_form_attach_image_heading_button">
                <input
                  ref={inputRef} // Add this line to attach the ref
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: 'none' }}
                />
                <label htmlFor="fileInput" className="image-preview-label">
                  {selectedFile ? (
                    <>
                      <Typography>{selectedFile.name}</Typography>
                      <div
                        className="cancel-button"
                        onClick={() => setSelectedFile(null)}
                        role="button"
                        tabIndex={0}
                        style={{ color: 'red', fontSize: '2em', cursor: 'pointer' }}
                      >
                        <img src={TrashImage} alt="trashImage" style={{ width: '20px', height: '20px' }} />
                      </div>
                    </>
                  ) : (
                    <Typography>Attach image</Typography>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="feedback_body_button_container">
          <div className="feedback_form_button_submit">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="feedback_submit_form_button"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
          <div className="feedback_form_button_cancel">
            <Button
              type="submit"
              variant="outlined"
              className="feedback_cancel_button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;