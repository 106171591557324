import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import HeaderHIPLogo from '../assets/HIPOneLogo.svg';
import GetCookie from '../HIPCookies/getCookie';
import SetCookie from '../HIPCookies/setCookie';

function LayoutWithOnlyHeader() {
    const userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;
    const userName = String((JSON.parse(GetCookie('userInfo')) || {}).userNameDisplay);
    const profileUserName = userName.split(' ').map(word => word[0]).join('');
    const [isLoading, setIsLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [headerLogo, setHeaderLogo] = useState();
    const [profileImage, setProfileImage] = useState();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get_logo_sas?user_id=${userId}`
        ).then((response) => {
            setHeaderLogo(response.data.logo_sas);
            setImageLoaded(true);
        })
            .catch((error) => {
                console.error('Error fetching image URL:', error);
                setHeaderLogo(HeaderHIPLogo);
                setImageLoaded(true);
                setIsLoading(false);
            });
        const apiUrl = `${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserImage/${userId}`;
        const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;
        axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${jWTToken}`,
            },
        })
            .then((response) => {
                const imageData = response.data[0].imageFilepath;
                if (imageData.length > 2) {
                    const decodedimageFilepath = atob(imageData);
                    setProfileImage(decodedimageFilepath);
                    SetCookie("userProfileImage", decodedimageFilepath);
                } else if (imageData.length === 2) {
                    setProfileImage(imageData);
                    SetCookie("userProfileImage", imageData);
                } else {
                    setProfileImage(profileUserName);
                    SetCookie("userProfileImage", profileUserName);
                }
                setImageLoaded(true);
            })
            .catch((error) => {
                console.error(`Error fetching image URL for user ${userId}:`, error);
            });
    }, [userId]);

    useEffect(() => {
        if (imageLoaded) {
            setIsLoading(false);
        }
    }, [imageLoaded]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <div style={{ width: "100%" }}>
            <Header headerLogoImage={headerLogo} profileImage={profileImage} />
            <div style={{ display: "flex", width: "100%" }}>
                <Outlet />
            </div>
        </div>
    )
}

export default LayoutWithOnlyHeader