import React, { useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinearProgress from "@mui/material/LinearProgress";
import "./FileUploader.css";
import { useSelector, useDispatch } from "react-redux";
import { setIsXmlUploaded, setTheme } from "../../../Redux/features/MessageSlice";
import GetCookie from '../../../HIPCookies/getCookie';
import ClipLoader from "react-spinners/ClipLoader";

const FileUploader = ({ onFileUploadSuccess, setHtmlContent }) => {

  const [selectedFileName, setSelectedFileName] = useState("No file selected");
  const [isFileUploaded, setFileUploaded] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const theme = useSelector((state) => state.messages.Theme) || "";
  const privacyModeEnabled = useSelector(
    (state) => state.messages.privacyModeEnabled
  );
  const dispatch = useDispatch();
  const handleFileChange = async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFileName(file.name);
      await uploadFileToBackend(file);
    } else {
      setSelectedFileName("No file selected");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileChange,
  });
  const togglePrivacyMode = () => {

    dispatch(setTheme(!privacyModeEnabled));
  };

  const uploadFileToBackend = async (file) => {
    setUploading(true);

    const baseUrl = `${process.env.REACT_APP_CONNECT}/ccda-summ`;
    let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;

    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("xml_file", file);

    const formData1 = new FormData();
    formData1.append("user_id", userId);

    try {
      dispatch(setIsXmlUploaded(false));
      const responseUpload = await axios.post(`${baseUrl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (responseUpload !== null) {
        toast.success(responseUpload.data);
      }
      setFileUploaded(true);
      dispatch(setIsXmlUploaded(true));

      try {
        const sectionsResponse = await axios.post(
          `${baseUrl}/get_non_empty_titles`, formData1, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        try {
          let response = await axios.post(
            `${baseUrl}/extract_personal_details`, formData1, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          sessionStorage.setItem(
            "demographics",
            JSON.stringify(response.data.personal_details)
          );

          // response = await axios.post(`${baseUrl}/one_page_summary`, formData1, {
          //   headers: {
          //     "Content-Type": "multipart/form-data",
          //   },
          // });

          // if (response.data.summaries === "Not enough data to create one-page summary.") {
          //   sessionStorage.setItem(
          //     "medical_data",
          //     JSON.stringify(["Not enough data to create one-page summary."])
          //   );
          // } else {
          //   sessionStorage.setItem(
          //     "medical_data",
          //     JSON.stringify(response.data.summaries)
          //   );
          // }

          response = await axios.post(`${baseUrl}/ccda_viewer`, formData1, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          setHtmlContent(response.data);

          // response = await axios.post(`${baseUrl}/extract_medications`, formData1, {
          //   headers: {
          //     "Content-Type": "multipart/form-data",
          //   },
          // });

          // sessionStorage.setItem(
          //   "key_value_data",
          //   JSON.stringify(response.data)
          // );

          // response = await axios.post(`${baseUrl}/extract_timeline`, formData1, {
          //   headers: {
          //     "Content-Type": "multipart/form-data",
          //   },
          // })

          // sessionStorage.setItem(
          //   "timeline_data",
          //   JSON.stringify(response.data)
          // );

        } catch (error) {
          console.error("Error fetching sections data:", error);
        }
        const sections = sectionsResponse.data.non_empty_titles


        onFileUploadSuccess(file.name, sections, true);
      } catch (error) {
        console.error("Error fetching sections data:", error);
      }

    } catch (error) {
      toast.error("Please Upload XML File Only");
      setFileUploaded(false);
      setUploading(false);
      console.error("Error uploading file:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className={`${theme}file-uploader`}>
      <div className={`${theme}section-paper`}>
        <div style={{ display: "flex" }}>
          <h3>Upload an XML File or C-CDA document to view.</h3>
          <div className="switch-container">
            <span className={`${theme}switch-label`}>Privacy Mode</span>
            <label className="switch">
              <input
                type="checkbox"
                className="switch-input"
                checked={privacyModeEnabled}
                onChange={togglePrivacyMode}
              />
              <span className="switch-slider"></span>
            </label>
          </div>
        </div>

        <p>
          <b>One-Click C-CDA Viewing:</b> This feature allows for quick viewing
          and printing of standard C-CDA documents. Genzeon's HIP renders .xml
          data generated by EHRs in C-CDA format and presents the information as
          a human-readable document. The user may set all C-CDA documents to be
          rendered in a consistent way or apply customized style sheets for
          different viewing purposes.
          <b>
            The C-CDA document will be temporarily rendered for this session
            only. The Genzeon's HIP server will not retain any content related
            to this C-CDA once the session is closed.
          </b>
        </p>
      </div>
      <div className={`${theme}component`}>
        <div className={`${theme}content`}>
          <h3 className={`${theme}title`}>CCDA Summarization</h3>
          <div className={`${theme}box`}>
            {isUploading ? (
              <LinearProgress />
            ) : !isFileUploaded ? (
              <div {...getRootProps()} className={`${theme}dropzone`}>
                <input {...getInputProps()} />
                <FaCloudUploadAlt size={35} />
                <p>Drag and drop your XML file here, or click to browse</p>
                <p className={`${theme}selected-file-name`}>
                  {selectedFileName}
                </p>
              </div>
            ) : (
              <div>
                <p>File uploaded successfully: {selectedFileName}</p>
              </div>
            )}
          </div>
        </div>
      </div>


    </div>
  );
};
export default FileUploader;
