import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilter, setFilter } from "../../../Redux/features/AlertsSlice";
import {
  getBlankPages,
  getImageUrlList,
  getOriginalUrlList,
  getUploadedFilename,
  getisBlankApiCall,
  setBlankApiCall,
  setBlankPages,
  setDuplicatePages,
  setImageUrlList,
} from "../../../Redux/features/DocumentsSlice";
import filterImage from "../../../assets/Filter_alt_light.svg";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import "./PdfThumbnails.css";
import GetCookie from "../../../HIPCookies/getCookie";
import { Button, Popover, Typography, useTheme } from "@mui/material";

function PdfThumbnails({ currentPage, onThumbnailClick, filter }) {
  const [isLoading, setIsLoading] = useState(false);
  const thumbnails = useSelector(getImageUrlList);
  const selectedFilter = useSelector(getFilter);
  var filename = useSelector(getUploadedFilename);
  filename = filename.replace(/\.pdf$/, "");
  let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId
  const thumbnailRefs = thumbnails.map(() => React.createRef());
  const dispatch = useDispatch();
  const originalurl = useSelector(getOriginalUrlList);

  useEffect(() => {
    thumbnailRefs[currentPage]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });

  }, [currentPage, filter, dispatch,]);
  useEffect(() => {
    handleFilterChange(selectedFilter)
  }, [selectedFilter])
  const handleFilterChange = async (name) => {
    // setSelectedFilter(selectedFilter);

    dispatch(setFilter(name));

    if (name === "Blank Pages") {
      setIsLoading(true);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_CONNECT}/med-review-process/find-blank`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              userId: userId
            },
            body: JSON.stringify({ filename }),
          }
        );

        const data = await response.json();

        if (data.error) {
          // Handle error
        } else if (data.blank_pages) {
          dispatch(setBlankApiCall(true));
          dispatch(setImageUrlList(data.blank_pages));
          dispatch(setBlankPages(data.blank_pages));
        } else {
          // Handle other cases
        }
      } catch (error) {
        // Handle error
      } finally {
        setIsLoading(false);
      }
    } else if (name === "All") {
      dispatch(setImageUrlList(originalurl));
    } else if (name === "Duplicate Pages") {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_CONNECT}/med-review-process/find-duplicates`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              userId: userId
            },
            body: JSON.stringify({ filename }),
          }
        );

        const data = await response.json();

        if (data.error) {
          // Handle error
        } else if (data.data) {
          const duplicateUrls = data.data.flatMap((item, index) => [
            { blob_url: item.original.url, page_number: item.original.page_number },
            ...item.duplicates.map((duplicate, duplicateIndex) => ({
              blob_url: duplicate.url,
              page_number: duplicate.page_number,
            })),
          ]);
          console.log(duplicateUrls)
          console.log(data.data)
          dispatch(setDuplicatePages(data.data));
          dispatch(setImageUrlList(duplicateUrls));

        } else {
          // Handle other cases
        }
      } catch (error) {
        // Handle error
      } finally {
        setIsLoading(false);
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);



  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null); // Close popover if already open
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div
      className="pdf-thumbnails-container"
      style={{ overflow: "scroll", maxHeight: "100vh" }}
    >
      {isLoading && <LoadingScreen />}
      <div className="claim-filter-container">
        <Button
          onClick={handleClick}
          variant="outlined"
          aria-describedby={id}
          sx={{
            width: "80%",
            margin: 'auto',
            border: '.5px solid #9BF',
            background: '#F4F3F8',
            borderRadius: '.25rem',
            color: '#142952',
            fontSize: '12px',
            fontWeight: '500',
            padding: '0.37rem 0.63rem',
            textTransform: 'capitalize',
            display: 'flex',
            alignItems: 'center',
            justifyContent: "space-between",
          }}
        >
          {selectedFilter}
          <img src={filterImage} alt="filter" className="filterimage" style={{ marginLeft: 'auto' }} />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{ maxHeight: "85vh", marginTop: "5px", width: "400px" }}
          >
            {[
              "All",
              "Duplicate Pages",
              "Blank Pages",
            ].map((name) => (
              <Typography
                key={name}
                value={name}
                className="filter-option"
                onClick={() => handleFilterChange(name)}
                sx={{ color: "#142952", fontSize: "13px", p: "5px 10px", '&:hover': { display: 'flex', alignItems: 'center', gap: '10px', flexShrink: 0, background: '#EBEFF8' } }}
              >
                {name}
              </Typography>
            ))}
          </Popover>
        </Button>
      </div>

      <div
        style={{
          overflow: "scroll",
          Height: "120vh",
          width: "160px",
        }}
      >
        {thumbnails.map((thumbnailObj) => (
          <div className="thumbnail-paper" ref={thumbnailRefs[thumbnailObj.page_number]}>
            <img
              src={thumbnailObj.blob_url}
              alt={`Thumbnail ${thumbnailObj.page_number}`}
              className={thumbnailObj.page_number === currentPage ? "active-thumbnail" : ""}
              onClick={() => onThumbnailClick(thumbnailObj.page_number)}
            />
            <span className="page-number">{thumbnailObj.page_number}</span>{" "}
          </div>
        ))}

      </div>
    </div >
  );
}

export default PdfThumbnails;
