import React, { useEffect, useState } from "react";
import DocumentsTable from "../../Pages/DocumentsTable/DocumentsTable";
import { getDocuments } from "../../../Redux/features/DocumentsSlice";
import { useSelector, useDispatch } from "react-redux";
import "./MedicalRecords.css";
import {
  setDocumentConfig,
  setUploadAlert,
} from "../../../Redux/features/AlertsSlice";
import { useNavigate } from "react-router-dom";
import UploadImage from "../../../assets/Icons/Upload.svg";
import ConfigImage from "../../../assets/Icons/Configuration.svg";
import SearchImage from "../../../assets/Icons/Search.svg";
import BackImage from "../../../assets/Icons/navigate-back.svg";
import { openDatabase } from "../../../IndexedDBUtility/IndexedDBUtility";
import { Typography } from "@mui/material";

function MedicalRecords() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documents = useSelector(getDocuments);
  const [MedicalRecords, setMedicalRecords] = useState([]);
  const componentHeading = "Medical Record Request";
  let permissionData;
  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);

  const [search, setSearch] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = await openDatabase();
        const transaction = db.transaction(['permissionData'], 'readonly');
        const objectStore = transaction.objectStore('permissionData');
        const getRequest = objectStore.getAll();

        getRequest.onsuccess = (event) => {
          const storedData = event.target.result;
          permissionData = storedData[0].value
          const operationsModule = permissionData.find((module) => module.moduleName === 'Operations');
          const screenDisplayName = componentHeading;
          const screenPermissions = operationsModule.permissionsDataArray.find(
            (item) => item.displayName === screenDisplayName
          );

          if (screenPermissions) {
            const readPermission = screenPermissions.allowToReadData;
            const writePermission = screenPermissions.allowToWriteData;
            setReadAccess(readPermission);
            setWriteAccess(writePermission);
          }
        };
        getRequest.onerror = (error) => {
          console.error('Error getting data from IndexedDB:', error.target.error);
        };
      } catch (error) {
        console.error(`Error opening IndexedDB: ${error}`);
      }

    };
    fetchData();
  }, []);

  useEffect(() => {
    if (documents !== undefined) {
      setMedicalRecords(documents.filter((item) => item.Type === "mr"));
    }
  }, [documents]);

  const handleSearch = (e) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      setMedicalRecords(documents.filter((item) => item.Type === "mr"));
      return false
    }
    setMedicalRecords(documents.filter((item) => item.Type === "mr").filter(item => item.Name.toLowerCase().includes(e.target.value.toLowerCase())));
  }

  return (
    <>
      <div className="medical_records_main_container">
        <div className="hip_configuration_btns">
          <div className="hip_configuration_document_title">
            <Typography variant="h3">{componentHeading}</Typography>
          </div>
          <div className="hip_config_span_medical_records">
            <div className="hip_config_doc_search_medical_records">
              <input type={"text"} placeholder="Search by record" value={search} onChange={handleSearch} />
              <img src={SearchImage} alt="search" />
            </div>
            <div className="hip_config_btns">
              <div
                className={`hip_config_btn hip_configuration_btn ${writeAccess ? "" : "disabled"}`}
                onClick={() => {
                  if (writeAccess) {
                    dispatch(
                      setDocumentConfig({
                        isActive: true,
                        useCase: "Medical Billing",
                        name: "Medical Bill Review",
                      })
                    );
                  }
                }}
              >
                <img src={ConfigImage} alt="config" />
                <span>Config</span>
              </div>
              <div
                className={`hip_config_btn hip_upload_btn ${writeAccess ? "" : "disabled"}`}
                onClick={() => {
                  if (writeAccess) {
                    dispatch(
                      setUploadAlert({
                        isActive: true,
                        configuration: "mr",
                        goto: "hip-operations/medical-records",
                      })
                    );
                  }
                }}
              >
                <img src={UploadImage} alt="upload" />
                <span>Upload</span>
              </div>
            </div>
          </div>
        </div>
        <DocumentsTable
          columns={columns}
          documents={MedicalRecords}
          setDocuments={setMedicalRecords}
          navigateTo={{
            goto: "/hip-operations/mr",
            goBack: "/medical-records",
          }}
        />
      </div>
    </>
  );
}

export default MedicalRecords;

const columns = [
  {
    field: "id",
    key: "Name",
    headerName: "Document Name",
    width: "43%",
    alignment: "left",
  },
  {
    field: "status",
    key: "Status",
    headerName: "Status",
    width: "15%",
    alignment: "left",
  },
  {
    field: "datemodified",
    key: "DateModified",
    headerName: "Date Modified",
    width: "15%",
    alignment: "left",
  },
  {
    field: "dateadded",
    key: "Onboarded_time",
    headerName: "Date Added",
    width: "15%",
    alignment: "left",
  },
  {
    field: "optinos",
    key: "Actions",
    headerName: "Actions",
    width: "12%",
    alignment: "left",
  },
];
