import { Outlet } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import axios from "axios";
import Header from '../../Components/Header/index';
import NavigationPage from '../../Components/NavigationBar/index';
import HeaderHIPLogo from '../../assets/HIPOneLogo.svg';
import GetCookie from '../../HIPCookies/getCookie';

function RouteWithOutMenuItems() {

    const userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;
    const [isLoading, setIsLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [headerLogo, setHeaderLogo] = useState();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get_logo_sas?user_id=${userId}`
        ).then((response) => {
            setHeaderLogo(response.data.logo_sas);
            setImageLoaded(true);
        })
            .catch((error) => {
                console.error('Error fetching image URL:', error);
                setHeaderLogo(HeaderHIPLogo);
                setImageLoaded(true);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (imageLoaded) {
            setIsLoading(false);
        }
    }, [imageLoaded]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <Header headerLogoImage={headerLogo} />
            <div style={{
                display: "flex",
                background: "#f9fcff",
                border: "1px solid gainsboro"
            }}>
                <NavigationPage />
                <Outlet />
            </div>
        </div>
    )
}

export default RouteWithOutMenuItems