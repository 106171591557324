import { createSlice } from "@reduxjs/toolkit";

const sessionsSlice = createSlice({
  name: "sessions",
  initialState: [],
  reducers: {
    // Set method to add a session
    setSession: (state, action) => {
      state.push(action.payload);
    },
    // Get method to retrieve a session by sessionid
    getSession: (state, action) => {
      return state.find(
        (session) => session.sessionid === action.payload.sessionid
      );
    },
    setDataInfo: (state, action) => {
      const { user, backend, IPaddress, sessionId, isLike } = action.payload;
      state.push({ user, backend, IPaddress, sessionId, isLike });
    },
  },
});

export const { setSession, getSession, setDataInfo } = sessionsSlice.actions;

export default sessionsSlice.reducer;
