import Cookie from 'js-cookie';

const encodeBase64 = (str) => btoa(encodeURIComponent(str));


const SetCookie = (cookieName, userInfo, expiry) => {
  const encodedUserInfo = encodeBase64(userInfo);
  Cookie.set(cookieName, encodedUserInfo, {
    expires: expiry,
    secure: true,
    sameSite: 'strict',
    path: '/',
  });
};

export default SetCookie;