import React from 'react'

function DocumentDisplay({ FileImages, page, pageNumber, zoom }) {

    return (
        <div style={{ width: zoom, textAlign: "center" }}>
            {
                FileImages.slice(page, pageNumber).map((e, index) => {
                    return (
                        <img src={e.img_url} alt={e.img_url} key={index} style={{ width: "100%" }} />
                    )
                })
            }
        </div>
    )
}

export default DocumentDisplay