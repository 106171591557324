import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { Transition } from "react-transition-group";

// Define your custom transition styles
const transitionStyles = {
  entering: { opacity: 3 },
  entered: { opacity: 1 },
  exiting: { opacity: 2 },
  exited: { opacity: 5 },
};

const NotificationDialog = ({
  isNotificationOpen,
  closeNotification,
  notification,
}) => {
  return (
    <Transition in={isNotificationOpen} timeout={300} unmountOnExit>
      {(state) => (
        <Dialog
          open={isNotificationOpen}
          onClose={closeNotification}
          maxWidth="sm"
          PaperProps={{
            sx: { borderRadius: "20px" }, // Adjust the border radius as needed
          }}
        >
          <DialogContent
            sx={{
              //   borderRadius: "20px", // Adjust the border radius
              //   padding: "20px", // Adjust the padding
              textAlign: "center",
              backgroundColor: "#E5E7E9", // Use your preferred background color
              transition: "opacity 0.3s ease", // Add a smooth opacity transition
              ...transitionStyles[state],
              // Apply the custom transition style
            }}
          >
            <Typography variant="p" sx={{ color: "#000000" }}>
              <h1 style={{ color: "#6495ED" }}>Thank You!</h1>
              {notification}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </Transition>
  );
};

export default NotificationDialog;
