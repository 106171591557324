// import Typography from "@mui/material/Typography";

import { Typography, Stack, Grid, Box,} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getYear } from "../../../../Redux/features/StarSlice";
import overallContracts from "../../../assets/OverAllContracts.svg";
import totalEntrollment from "../../../assets/totalEntrollment.svg";
import TotalPart_D from "../../../assets/TotalPart_D.svg";
import totalSNP from "../../../assets/totalSNP.svg";
import LoadingScreen from "../../../../Billing/Pages/LoadingScreen/LoadingScreen";
import highPerformingContract from "../assets/highPerformingContract.svg";
import lowPerformingContract from "../assets/lowPerformingContract.svg";
import upTrendIcon from "../assets/UpTrendIcon.svg";
import downTrendIcon from "../assets/downTrendIcon.svg";

const StarDashboard = (props) => {
  const storedObject = useSelector(
    (state) => state.startDashBoard.responseDashData
  );

  return props.isLoading ? (
    <LoadingScreen />
  ) : (
    <Box>
      <Stack direction={"row"} gap={"12px"} sx={{ marginBottom: "12px" }}>
        <Box>
          <Box
            sx={{
              width: "148px",
              height: "178px",
              flexShrink: 0,
              background: "#FFF",
              boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
              border: "1px solid #E0E3E8",
              borderRadius:'8px',
            }}
          >
            <Box sx={{ marginLeft: "16px", marginTop: "27px", width:'30px', height:'30px', background:'#FFECE1', borderRadius:'4px'}}>
              <img src={overallContracts} alt="overallContracts" style={{padding:'3px'}}/>
            </Box>
            <Box
              sx={{
                // marginBottom: "60px",
                marginTop: "19px",
                width: "61px",
                height: "48px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "48px",
                  textAlign: "center",
                  marginLeft: "20px",
                }}
              >
                {/* {storedObject.overallData?.overallContracts} */}
                {storedObject.overallData?.overallContracts
                  ? storedObject.overallData.overallContracts
                  : 0}
              </Typography>
            </Box>
            <Box
              sx={{
                marginRight: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Overall Contracts
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              width: "148px",
              height: "178px",
              flexShrink: 0,
              background: "#FFF",
              boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
              border: "1px solid #E0E3E8",
              borderRadius:'8px',
            }}
          >
            <Box sx={{ marginLeft: "16px", marginTop: "27px",  width:'30px', height:'30px', background:'#E1F1FF', borderRadius:'4px'}}>
              <img src={totalEntrollment} alt="totalEntrollment" style={{padding:'3px'}}/>
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
                // marginBottom: "60px",
                marginTop: "21px",
                width: "61px",
                height: "48px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "48px",
                  textAlign: "center",
                  marginLeft: "8px",
                }}
              >
                {storedObject.overallData?.totalEnrollmentCount
                  ? storedObject.overallData.totalEnrollmentCount?.toLocaleString(
                      "en-US"
                    )
                  : "NA"}
              </Typography>
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  wordWrap: "break-word",
                }}
              >
                Total Enrollment
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              width: "148px",
              height: "178px",
              flexShrink: 0,
              background: "#FFF",
              boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
              border: "1px solid #E0E3E8",
              borderRadius:'8px',
            }}
          >
            <Box sx={{ marginLeft: "16px", marginTop: "27px", width:'30px', height:'30px', background:'#E1FFF4', borderRadius:'4px' }}>
              <img src={TotalPart_D} alt="TotalPart_D" style={{padding:'3px'}}/>
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
                // marginBottom: "60px",
                marginTop: "19px",
                width: "61px",
                height: "48px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "48px",
                  textAlign: "center",
                  marginLeft: "8px",
                }}
              >
                {storedObject.overallData?.totalPlansOfferingD
                  ? storedObject.overallData.totalPlansOfferingD
                  : 0}
              </Typography>
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  wordWrap: "break-word",
                }}
              >
                Total Plans Offering Part D
              </Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
      <Stack direction={"row"} gap={"12px"}>
        <Box>
          <Box
            sx={{
              width: "148px",
              height: "178px",
              flexShrink: 0,
              background: "#FFF",
              boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
              border: "1px solid #E0E3E8",
              borderRadius:'8px',
            }}
          >
            <Box sx={{ marginLeft: "16px", marginTop: "27px", width:'30px', height:'30px', background:'#E7FEE5', borderRadius:'4px' }}>
              <img src={highPerformingContract} alt="highPerformance" style={{padding:'3px'}}/>
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
                // marginBottom: "60px",
                marginTop: "19px",
                width: "61px",
                height: "48px",
                display:'flex',
                alignItems:'center'
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "48px",
                  textAlign: "center",
                  marginLeft: "8px",
                  marginRight:'8px'
                }}
              >
                {storedObject.high_total_count
                  ? storedObject.high_total_count
                  : 0}
              </Typography>
              {storedObject.high_total_count != null &&
                storedObject.previous_high_total_count != null && 
                  storedObject.high_total_count != storedObject.previous_high_total_count &&(
                  <>
                    {storedObject.high_total_count > storedObject.previous_high_total_count && (
                      <img src={upTrendIcon} alt="upIcon" style={{ width: '24px', height: '24px' }}/>
                    )}
                    {storedObject.high_total_count < storedObject.previous_high_total_count && (
                      <img src={downTrendIcon} alt="downIcon" style={{ width: '24px', height: '24px' }}/>
                    )}
                  </>
                )}
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  wordWrap: "break-word",
                }}
              >
                High Performing Contracts
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              width: "148px",
              height: "178px",
              flexShrink: 0,
              background: "#FFF",
              boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
              border: "1px solid #E0E3E8",
              borderRadius:'8px',
            }}
          >
            <Box sx={{ marginLeft: "16px", marginTop: "27px", width:'30px', height:'30px', background:'#FFE7E7', borderRadius:'4px' }}>
              <img src={lowPerformingContract} alt="lowPerformance" style={{padding:'3px'}} />
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
                // marginBottom: "60px",
                marginTop: "19px",
                width: "61px",
                height: "48px",
                display:'flex',
                alignItems:'center'
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "48px",
                  textAlign: "center",
                  marginLeft: "8px",
                  marginRight:'8px'
                }}
              >
                {storedObject.low_total_count
                  ? storedObject.low_total_count
                  : 0}
              </Typography>
              {storedObject.low_total_count != null &&
                storedObject.previous_low_total_count != null && 
                  storedObject.low_total_count != storedObject.previous_low_total_count &&(
                  <>
                    {storedObject.low_total_count > storedObject.previous_low_total_count && (
                      <img src={upTrendIcon} alt="upIcon" style={{ width: '24px', height: '24px' }}/>
                    )}
                    {storedObject.low_total_count < storedObject.previous_low_total_count && (
                      <img src={downTrendIcon} alt="downIcon" style={{ width: '24px', height: '24px' }}/>
                    )}
                  </>
                )}
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  wordWrap: "break-word",
                }}
              >
                Low Performing Contracts
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              width: "148px",
              height: "178px",
              flexShrink: 0,
              background: "#FFF",
              boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
              border: "1px solid #E0E3E8",
              borderRadius:'8px',
            }}
          >
            <Box sx={{ marginLeft: "16px", marginTop: "27px", width:'30px', height:'30px', background:'#E1E6FF', borderRadius:'4px' }}>
              <img src={totalSNP} alt="totalSNP" style={{padding:'3px'}}/>
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
                // marginBottom: "60px",
                marginTop: "19px",
                width: "61px",
                height: "48px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "48px",
                  textAlign: "center",
                  marginLeft: "20px",
                }}
              >
                {storedObject.overallData?.totalSNP
                  ? storedObject.overallData.totalSNP
                  : 0}
              </Typography>
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "#211A1D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  wordWrap: "break-word",
                }}
              >
                Total SNP (Special Needs Plan)
              </Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default StarDashboard;
