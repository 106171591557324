import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import HIPOperations from "../assets/HIP Operations Icon.svg";
import HIPClinical from "../assets/HIP Clinical Icon.svg";
import HIPFinance from "../assets/HiP Financial Old.png";
import HIPConnect from "../assets/HIP Connect Icon.svg";
import GetCookie from "../HIPCookies/getCookie";
import sendFillIcon from "../assets/NewIcons/Send_fill_launch.svg";
import HIPLogo from "../assets/NewIcons/HIP Logo Horizontal.svg";

function HIPDashboardScreen() {
    const navigate = useNavigate();

    const storedData = GetCookie("userInfo");
    const storedToken = GetCookie("token");

    const handleClickOperational = () => {
        navigate("/hip-operations");
    };

    const handleClickClinical = () => {
        navigate("/hip-clinical");
    };

    const handleClickFinance = () => {
        navigate("/hip-finance");
    };

    const handleClickConnect = () => {
        navigate("/hip-connect");
    };

    const moduleData = JSON.parse(GetCookie("moduleInfo"));

    return (
        <Paper className="hip_container">
            <Box className="main_container">
                <Box className="hip_container_logo">
                    <img src={HIPLogo} alt="Genzeon" />
                </Box>
                <Typography variant="body1" className="hip_container_sub_heading_span" >
                    Your Data. Your Innovation. Secure.<br /> Enabled by HIP One
                </Typography>
                <Typography variant="body1" className="hip_container_sub_heading" >
                    Health Intelligence Platform (HIP One) is the leading intelligence platform powered by Gen AI, tailored <br /> specifically for healthcare operation optimization, with built-in privacy and security controls.
                </Typography>
                <Grid container spacing={2} className='hip_grid_content'>
                    {moduleData && moduleData.map(item => (
                        <React.Fragment key={item.displayName}>
                            {item.displayName === "Operations" && (
                                <Grid item xs={12} md={6} sm={12} lg={4}>
                                    <Box className="hip_link_content" >
                                        <img src={HIPOperations} alt="HIP Operations" />
                                        <Typography variant="h3"> HIP One Operations </Typography>
                                        <Typography variant="h2" className="hip_link_content_sub_heading">Business Operations Innovated</Typography>
                                        <Typography variant="h4" >
                                            Driving business optimization through data extraction, standardization, and insights through ML and AI algorithms.
                                        </Typography>
                                        <Box className="hip_link_button">
                                            <Button className="hip_link_content_button" onClick={handleClickOperational} color="primary" >
                                                <img src={sendFillIcon} alt="arrow" /> Launch
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </React.Fragment>
                    ))}
                    {moduleData && moduleData.map(item => (
                        <React.Fragment key={item.displayName}>
                            {item.displayName === "Clinical" && (
                                <Grid item xs={12} md={6} sm={12} lg={4}>
                                    <Box className="hip_link_content">
                                        <img src={HIPClinical} alt="HIP Connect" />
                                        <Typography variant="h3" > HIP One Clinical </Typography>
                                        <Typography variant="h2" className="hip_link_content_sub_heading">Clinical Operation Optimized</Typography>
                                        <Typography variant="h4" sx={{ width: '190px !important' }} >
                                            Offering clinical data and insights in real-time to drive clinical operations.
                                        </Typography>
                                        <Box className="hip_link_button" >
                                            <Button className="hip_link_content_button" onClick={handleClickClinical} color="primary" >
                                                <img src={sendFillIcon} alt="arrow" /> Launch
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </React.Fragment>
                    ))}
                    {moduleData && moduleData.map(item => (
                        <React.Fragment key={item.displayName}>
                            {item.displayName === "Connect" && (
                                <Grid item xs={12} md={6} sm={12} lg={4}>
                                    <Box className="hip_link_content">
                                        <img src={HIPConnect} alt="HIP Connect" />
                                        <Typography variant="h3"> HIP One Connect </Typography>
                                        <Typography variant="h2" className="hip_link_content_sub_heading">Intelligent Conversation Enabled</Typography>
                                        <Typography variant="h4">
                                            Removing Payor provider friction points through digital transformation and driving value intelligence.
                                        </Typography>
                                        <Box className="hip_link_button" >
                                            <Button className="hip_link_content_button" onClick={handleClickConnect} color="primary" >
                                                <img src={sendFillIcon} alt="arrow" /> Launch
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </React.Fragment>
                    ))}
                    {false && (
                        <Grid item xs={12} md={6} sm={12} lg={3}>
                            <div className="hip_link_content">
                                <img src={HIPFinance} alt="HIP Finance" />
                                <div>HIP Finance</div>
                                <Typography >
                                    Enabling financial optimization through intelligent
                                    automation.
                                </Typography >
                                <div className="hip_link_button">
                                    <Button className="hip_link_content_button" onClick={handleClickFinance} color="primary" >
                                        <img src={sendFillIcon} alt="arrow" /> Launch
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Paper >
    );
}

export default HIPDashboardScreen;
