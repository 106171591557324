import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    darkMode: false, // Initial state with darkMode set to false
    isLoading: false, // Initial state with isLoading set to false
    messageList: [],
  },
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { toggleDarkMode, startLoading, stopLoading } = themeSlice.actions;
export default themeSlice.reducer;
