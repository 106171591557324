import { TypeSpecimen } from "@mui/icons-material";
import { Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import star from "../assets/star.svg";
import HorizontalBarGraph from "./HorizontalBarGraph";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef, useLayoutEffect } from "react";

const StyledTableHeaderTypography = styled(Typography)`
  color: #444;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const HeaderDataTypography = styled(Typography)`
  color: #1a1b21;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const SelectedMeasureDataTypography = styled(Typography)`
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

// function toCamelCase(str) {
//   return str.toLowerCase().replace(/\b\w/g, function (word) {
//     return word.toUpperCase();
//   });
// }

function toCamelCase(str) {
  if (!str) return "NA"; // Return "NA" if str is undefined, null, or falsy

  return str.toLowerCase().replace(/\b\w/g, function (word) {
    return word.toUpperCase();
  });
}

function MeasureDetailTable(props) {
  // console.log("MeasureDetailsTable Props", props.measureData);

  // const objective = parseInt(props.measureData?.Rating_Rule_Data[0]?.lower_cutoff ?? '0');
  // const income = parseInt(props.measureData?.Rating_Rule_Data[0]?.upper_cutoff ?? '0');
  const ratingRuleData = props.measureData?.Rating_Rule_Data;
  // console.log("Rating rule data", ratingRuleData)
  const objective = parseInt(ratingRuleData?.[0]?.lower_cutoff ?? "0");
  const income = parseInt(ratingRuleData?.[0]?.upper_cutoff ?? "0");

  const parent = useSelector(
    (state) => state.startDashBoard.parentOrganization
  );
  const year = useSelector((state) => state.startDashBoard.year);
  const [selectedParent, setSelectedparent] = useState(parent);
  const [selectedYears, setselectedYears] = useState(year);

  // console.log("selected year in measuredetail table", year);

  const measureStar = props.measureData.Measures?.find(
    (item) => item.star_year === year
  );

  const ms = measureStar ? measureStar?.measure_star_value : "NA";

  const date = props.measureData.Measures?.find(
    (item) => item.star_year === year
  );

  const d = measureStar ? measureStar.date_span : null;

  const [startDate, endDate] = d?.split(" - ") ?? ["NA", "NA"];

  // console.log("Start Date:", startDate); // Output: Start Date: 01/01/2021
  // console.log("End Date:", endDate); // Output: End Date: 12/31/2021

  const nationalStar = props.measureData.National_Averages?.find(
    (item) => item.star_year === year
  );

  const na = measureStar ? nationalStar.National_Average_Star : "NA";

  const measure_score = props.measureData.Measures?.find(
    (item) => item.star_year === year
  );
  const DomainName = props.measureData?.Domain_Star_Details?.find(
    (item) => item.star_year === year
  );

  const measureName = measureStar ? measureStar.measure_name : "NA";

  const measureScore = measureStar ? measureStar.measure_score : "NA";

  const nationalAverageScore = props.measureData.National_Averages?.find(
    (item) => item.star_year === year
  );

  const national_Average_Score = measureStar
    ? nationalAverageScore.National_Average_Score
    : "NA";

  return (
    <Box
      sx={{
        width: "100%",
        // maxWidth: "797px",
        height: "550px",
        borderRadius: "4px",
        border: "1px solid #E0E3E8",
        boxShadow: "0px 2px 8px 0px rgba(219, 219, 219, 0.40)",
        padding: "35px", // Add padding for better spacing
        boxSizing: "border-box", // Include padding in the width and height
        background: "#FFF",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: "24px" }}>
        <Box>
          <StyledTableHeaderTypography>Contract ID</StyledTableHeaderTypography>
          <HeaderDataTypography sx={{ marginTop: "8px" }}>
            {props.measureData?.Contract?.[0]?.contract_id ?? "NA"}
          </HeaderDataTypography>
        </Box>
        <Box>
          <StyledTableHeaderTypography>
            Organization Type
          </StyledTableHeaderTypography>
          <HeaderDataTypography sx={{ marginTop: "8px" }}>
            {" "}
            {toCamelCase(props.measureData?.Contract?.[0]?.organization_type) ??
              "NA"}
          </HeaderDataTypography>
        </Box>
        <Box>
          <StyledTableHeaderTypography>
            Contract Name
          </StyledTableHeaderTypography>
          <HeaderDataTypography sx={{ marginTop: "8px" }}>
            {toCamelCase(props.measureData?.Contract?.[0]?.contract_name) ??
              "NA"}
          </HeaderDataTypography>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "24px",
          minWidth: "332px",
          width: "fit-content",
          height: "36px",
          borderRadius: "4px",
          background: "#4026A7",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <SelectedMeasureDataTypography sx={{ marginLeft: "10px" }}>
            {props.code}
          </SelectedMeasureDataTypography>
          <SelectedMeasureDataTypography
            sx={{ marginLeft: "16px", marginRight: "10px" }}
          >
               {props.code === "PART C" || props.code === "PART D"? props.code === "PART C" 
                ? "Medicare Advantage" 
                : "Prescription  Drug"
              : toCamelCase(
                  props.measureData.Measures
                    ? measureName
                    : props.measureData?.Domain_Star_Details?.find(
                        (item) => item.star_year === year
                      )?.domain_name
                ) ?? "NA"}
          </SelectedMeasureDataTypography>
        </Box>
      </Box>
      <Box sx={{ marginTop: "16px" }}>
        <StyledTableHeaderTypography>Star Rating</StyledTableHeaderTypography>
        <Box
          sx={{
            marginTop: "21px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box>
            <StyledTableHeaderTypography
              sx={{ color: "#211A1D", lineHeight: "14px", whiteSpace:'nowrap' }}
            >
             {(() => {
                if (props.code === "PART C") {
                  return "Part C Star";
                } else if (props.code === "PART D") {
                  return "Part D Star";
                } else if (props.measureData?.Measures) {
                  return "Measure Star";
                } else {
                  return "Domain Star";
                }
              })()}
            </StyledTableHeaderTypography>
          </Box>
          <Box
            sx={{
              height: "30px",
              width: "52px",
              flexDirection: "row",
              display: "flex",
              marginLeft: "8px",
              alignItems: "center",
              border: "0.5px solid #FFEAB0",
              borderRadius: "4px",
              background: "#FFFAF2",
              justifyContent: "center",
            }}
          >
            <img src={star} alt="star" />
            <HeaderDataTypography sx={{ marginLeft: "5px", color: "#211A1D" }}>
              {/* {props.measureData.Measures
                ? typeof ms === "number"
                  ? ms.toFixed(1)
                  : "NA"
                : props.measureData?.Domain_Star_Details?.find(
                    (item) => item.star_year === year
                  )?.domain_star_value ?? "NA"} */}

              {(() => {
                if (props.code === "PART C") {
                  return (
                    props.measureData.Contract?.[0]?.["Type C Star"] ?? "NA"
                  );
                } else if (props.code === "PART D") {
                  return (
                    props.measureData.Contract?.[0]?.["Type D Star"] ?? "NA"
                  );
                } else if (props.measureData.Measures) {
                  // console.log("11111");
                  return typeof ms === "number" ? ms.toFixed(1) : "NA";
                } else if (props.measureData?.Domain_Star_Details) {
                  return (
                    props.measureData?.Domain_Star_Details?.find(
                      (item) => item.star_year === year
                    )?.domain_star_value ?? "NA"
                  );
                }
              })()}
            </HeaderDataTypography>
          </Box>
          <Box sx={{ marginLeft: "24px" }}>
            <StyledTableHeaderTypography
              sx={{ color: "#211A1D", lineHeight: "14px" }}
            >
              National Avg Star
            </StyledTableHeaderTypography>
          </Box>
          <Box
            sx={{
              height: "30px",
              width: "52px",
              flexDirection: "row",
              display: "flex",
              marginLeft: "8px",
              alignItems: "center",
              border: "0.5px solid #FFEAB0",
              borderRadius: "4px",
              background: "#FFFAF2",
              justifyContent: "center",
            }}
          >
            <img src={star} alt="star" />
            <HeaderDataTypography sx={{ marginLeft: "5px", color: "#211A1D" }}>
              {/* {typeof na === "number" ? na.toFixed(1): "NA"} */}

              {props.measureData.Measures
                ? typeof na === "number"
                  ? na.toFixed(1)
                  : "NA"
                : props.measureData?.National_Averages?.find(
                    (item) => item.star_year === year
                  )?.National_Average_Star.toFixed(1) ?? "NA"}
            </HeaderDataTypography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ width: "100%", marginTop: "16px", display: "flex", gap: "20px" }}
      >
        <Box
          sx={{
            width: "172px",
            height: "108px",
            borderRadius: "4px",
            border: "1px solid #E0E3E8",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <StyledTableHeaderTypography
            sx={{
              marginLeft: "16px",
              marginTop: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: "14px",
            }}
          >
            Measure Score %
          </StyledTableHeaderTypography>
          <HeaderDataTypography
            sx={{
              marginLeft: "16px",
              marginTop: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#211A1D",
            }}
          >
            {typeof measureScore === "number"
              ? (measureScore * 100).toFixed(2) + "%"
              : "NA"}
          </HeaderDataTypography>
        </Box>
        <Box
          sx={{
            width: "172px",
            height: "108px",
            borderRadius: "4px",
            border: "1px solid #E0E3E8",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <StyledTableHeaderTypography
            sx={{
              marginLeft: "16px",
              marginTop: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "12px",
              lineHeight: "14px",
            }}
          >
            National Avg Score %
          </StyledTableHeaderTypography>
          <HeaderDataTypography
            sx={{
              marginLeft: "16px",
              marginTop: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#211A1D",
            }}
          >
            {typeof national_Average_Score === "number"
              ? (national_Average_Score * 100).toFixed(1) + "%"
              : "NA"}
          </HeaderDataTypography>
        </Box>
        <Box
          sx={{
            width: "172px",
            height: "108px",
            borderRadius: "4px",
            border: "1px solid #E0E3E8",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <StyledTableHeaderTypography
            sx={{
              marginLeft: "16px",
              marginTop: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: "14px",
            }}
          >
            Data Considered From
          </StyledTableHeaderTypography>
          <HeaderDataTypography
            sx={{
              marginLeft: "16px",
              marginTop: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#211A1D",
            }}
          >
            {startDate ?? "NA"}
          </HeaderDataTypography>
        </Box>
        <Box
          sx={{
            width: "172px",
            height: "108px",
            borderRadius: "4px",
            border: "1px solid #E0E3E8",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <StyledTableHeaderTypography
            sx={{
              marginLeft: "16px",
              marginTop: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: "14px",
            }}
          >
            Data Considered Till
          </StyledTableHeaderTypography>
          <HeaderDataTypography
            sx={{
              marginLeft: "16px",
              marginTop: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#211A1D",
            }}
          >
            {endDate ?? "NA"}
          </HeaderDataTypography>
        </Box>
      </Box>
      <Box sx={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
        {props.show ? (
          <StyledTableHeaderTypography>
            Part D Cut off
          </StyledTableHeaderTypography>
        ) : (
          <StyledTableHeaderTypography>
            Part C Cut off
          </StyledTableHeaderTypography>
        )}
        <Box width={"60%"}>
          <HorizontalBarGraph objective={objective} income={income} />
        </Box>
      </Box>
    </Box>
  );
}

export default MeasureDetailTable;
