import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GetCookie from "../../../HIPCookies/getCookie";
import { openDatabase } from "../../../IndexedDBUtility/IndexedDBUtility";
import {
  setDocumentConfig,
  setUploadAlert,
} from "../../../Redux/features/AlertsSlice";
import { getClaimProcessDocumentList, getUploadedFilename, setClaimProcessDocumentList } from "../../../Redux/features/DocumentsSlice";
import ConfigImage from "../../../assets/Icons/Configuration.svg";
import SearchImage from "../../../assets/Icons/Search.svg";
import UploadImage from "../../../assets/Icons/Upload.svg";
import BackImage from "../../../assets/Icons/navigate-back.svg";
import MedicalReviewDocumentTable from "../../Pages/DocumentsTable/MedicalReviewDocumentTable";
import "./ClaimProcess.css";
import { Typography } from "@mui/material";


function ClaimProcess() {
  const uploadedFileName = useSelector(getUploadedFilename); // Fetch the uploaded file name from Redux
  const ClaimDocuments = useSelector(getClaimProcessDocumentList)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseURL = `${process.env.REACT_APP_CONNECT}/med-review-process`;
  let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;
  let permissionData;
  const componentHeading = "Medical Review";
  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);

  const [ClaimProcessDocuments, setClaimProcessDocuments] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = await openDatabase();
        const transaction = db.transaction(['permissionData'], 'readonly');
        const objectStore = transaction.objectStore('permissionData');
        const getRequest = objectStore.getAll();

        getRequest.onsuccess = (event) => {
          const storedData = event.target.result;
          permissionData = storedData[0].value;
          const operationsModule = permissionData.find((module) => module.moduleName === 'Clinical');
          const screenDisplayName = componentHeading;
          const screenPermissions = operationsModule.permissionsDataArray.find(
            (item) => item.displayName === screenDisplayName
          );

          if (screenPermissions) {
            const readPermission = screenPermissions.allowToReadData;
            const writePermission = screenPermissions.allowToWriteData;
            setReadAccess(readPermission);
            setWriteAccess(writePermission);
          }
        };
        getRequest.onerror = (error) => {
          console.error('Error getting data from IndexedDB:', error.target.error);
        };
      } catch (error) {
        console.error(`Error opening IndexedDB: ${error}`);
      }

    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.post(`${baseURL}/list_files`, {}, {
          headers: { userId: userId },
        });
        dispatch(setClaimProcessDocumentList(result.data.pdf_details));
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [uploadedFileName]);

  useEffect(() => {
    if (ClaimDocuments !== undefined) {
      setClaimProcessDocuments(ClaimDocuments)
    }
  }, [ClaimDocuments])

  const handleSearch = (e) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      setClaimProcessDocuments(ClaimDocuments);
      return false
    }
    setClaimProcessDocuments(ClaimDocuments.filter(item => item.pdfname.toLowerCase().includes(e.target.value.toLowerCase())));
  }
  return (
    <>
      <div className="medical_bills_main_container">
        <div className="hip_configuration_btns">
          <div className="hip_configuration_document_title">
            <Typography variant="h3">{componentHeading}</Typography>
          </div>
          <div className="hip_config_span_claim_process">
            <div className="hip_claim_process_config_doc_search_claim_process">
              <input type={"text"} placeholder="Search by record" value={search} onChange={handleSearch} />
              <img src={SearchImage} alt="search" />
            </div>
            <div className="hip_config_btns">
              {/* <div config button div dont remove anyone
                className={`hip_config_btn hip_configuration_btn ${writeAccess ? "" : "disabled"}`}
                onClick={() => {
                  if (writeAccess) {
                    dispatch(
                      setDocumentConfig({
                        isActive: true,
                        useCase: "Medical Review Process",
                        name: "Medical Review Process",
                      })
                    )
                  }
                }}
              >
                <img src={ConfigImage} alt="config" />
                <span>Config</span>
              </div> */}
              <div className={`hip_config_btn hip_upload_btn ${writeAccess ? "" : "disabled"}`}
                onClick={() => {
                  if (writeAccess) {
                    dispatch(
                      setUploadAlert({
                        isActive: true,
                        isClaimActive: true,
                        configuration: "cp",
                        goto: "hip-clinical/medical-review",
                      })
                    )
                  }
                }}
              >
                <img src={UploadImage} alt="upload" />
                <span>Upload</span>
              </div>
            </div>
          </div>
        </div>
        {
          <MedicalReviewDocumentTable
            columns={columns}
            documents={ClaimProcessDocuments}
            navigateTo={{
              goto: "/hip-clinical/cp",
              goBack: "/medical-review",
            }}
          />
        }

      </div>
    </>
  );
}

export default ClaimProcess;

const columns = [
  {
    field: "id",
    key: "Name",
    headerName: "Document Name",
    width: "43%",
    alignment: "left",
  },
  {
    field: "status",
    key: "Status",
    headerName: "Status",
    width: "15%",
    alignment: "left",
  },
  {
    field: "datemodified",
    key: "DateModified",
    headerName: "Date Modified",
    width: "15%",
    alignment: "left",
  },
  {
    field: "dateadded",
    key: "Onboarded_time",
    headerName: "Date Added",
    width: "15%",
    alignment: "left",
  },
  {
    field: "optinos",
    key: "Actions",
    headerName: "Actions",
    width: "12%",
    alignment: "left",
  },
];