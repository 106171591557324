import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./SplitButtonComponent.css";
import { useSelector, useDispatch } from "react-redux";
import { setIsXmlUploaded, setTheme } from "../../../Redux/features/MessageSlice";
import axios from "axios";
import GetCookie from "../../../HIPCookies/getCookie";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";

const SplitButtonComponent = ({ onViewModeChange, selectedAction }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const reduxprivacyModeEnabled = useSelector((state) => state.messages.privacyModeEnabled);
  const [privacyModeEnabled, setPrivacyModeEnabled] = useState(reduxprivacyModeEnabled);
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.messages.Theme);
  const dispatch = useDispatch();
  const handleButtonClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const baseUrl = `${process.env.REACT_APP_CONNECT}/ccda-summ`;
  let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;

  const formData1 = new FormData();
  formData1.append("user_id", userId);

  const isXmlUploadedState = useSelector(
    (state) => state.messages.isXmlUploaded
  );

  const handleMenuItemClick = async (viewMode) => {
    if (viewMode === "One Page Summary" && isXmlUploadedState === true) {
      dispatch(setIsXmlUploaded(false));
      setMenuAnchor(null);
      setLoading(true);
      try {
        const [summaryResponse, medicationsResponse, timelineResponse] = await Promise.all([
          axios.post(`${baseUrl}/one_page_summary`, formData1, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }),
          axios.post(`${baseUrl}/extract_medications`, formData1, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }),
          axios.post(`${baseUrl}/extract_timeline`, formData1, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }),
        ]);

        if (summaryResponse.data.summaries === "Not enough data to create one-page summary.") {
          sessionStorage.setItem(
            "medical_data",
            JSON.stringify(["Not enough data to create one-page summary."])
          );
        } else {
          sessionStorage.setItem(
            "medical_data",
            JSON.stringify(summaryResponse.data.summaries)
          );
        }

        sessionStorage.setItem(
          "key_value_data",
          JSON.stringify(medicationsResponse.data)
        );

        sessionStorage.setItem(
          "timeline_data",
          JSON.stringify(timelineResponse.data)
        );
      } catch {
        toast.error("Error in loading One Page Summary.");
      } finally {
        setLoading(false);
      }

    }
    onViewModeChange(viewMode);
    setMenuAnchor(null);
  };
  const togglePrivacyMode = () => {
    setPrivacyModeEnabled(!privacyModeEnabled);
    dispatch(setTheme(!privacyModeEnabled));
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="split-button-container">
        {loading && (
          <div className='loading_screen'>
            <ClipLoader
              color={"#fff"}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}

        <div className="split-button-label">Select CCDA Format</div>
        <Button
          variant="contained"
          color="primary"
          aria-controls="split-button-menu"
          aria-haspopup="true"
          endIcon={<ArrowDropDownIcon />}
          onClick={handleButtonClick}
          className="split-button"
        >
          {selectedAction}
        </Button>
      </div>
      <Menu
        id="split-button-menu"
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem onClick={() => handleMenuItemClick("Section List")}>
          Section List
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("CCDA Viewer")}>
          CCDA Viewer
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("One Page Summary")}>
          One Page Summary
        </MenuItem>
      </Menu>
      <div className="switch-container" style={{ marginRight: "15vh" }}>
        <span
          className="switch-label"
          style={{ color: theme === "dark_" ? "#fff" : "black" }}
        >
          Privacy Mode
        </span>
        <label className="switch">
          <input
            type="checkbox"
            className="switch-input"
            checked={privacyModeEnabled}
            onChange={togglePrivacyMode}
          />
          <span className="switch-slider"></span>
        </label>
      </div>
    </div>
  );
};

export default SplitButtonComponent;
