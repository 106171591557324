import React from "react";
import "./DeleteUrlChatBot.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import addChatbotIcon from "../../../../../assets/Icons/add-chatbot.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import refreshIcon from "../../../../../assets/Icons/refresh-icon.png";
import { setCreatedChatbot } from "../../../../../Redux/features/UrlMessageSlice";
import GetCookie from '../../../../../HIPCookies/getCookie';

export default function DeleteUrlChatbot() {
  let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;

  const [selectedLinks, setSelectedLinks] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedChatbot, setSelectedChatbot] = useState("");
  const [chatbotNames, setChatbotNames] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [homeUrl, setHomeUrl] = useState("Home URL");
  const [fetchedLinks, setFetchedLinks] = useState([]);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maxSelectedLinks] = useState(25);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let chatbotName = useSelector((state) => state.urlMessages.createdChatbot);

  useEffect(() => {
    fetchChatbotNames();
  }, []);

  const fetchChatbotNames = async () => {
    let home_url;
    let all_urls;
    if (selectedChatbot == "No Chatbot") {
      setHomeUrl("");
      setSelectedLinks([]);
      return
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/all_chatbots`
      );

      const chatbotNamesData = response.data.chatbot_names;
      const filteredChatbotNames = chatbotNamesData.filter(chatbot => response.data.chatbot_dict[chatbot] === userId);

      if (filteredChatbotNames.length === 0) {
        setChatbotNames(["No Chatbots"]);
        navigationHandler();
      }
      else {
        setChatbotNames([...filteredChatbotNames]);
        setSelectedChatbot(chatbotName);
      }

    } catch (error) {
      console.error("Error fetching chatbot names:", error);
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/modify_urls`,
        {
          chatbot_name: chatbotName,
        }
      );
      home_url = response.data.home_url || "";
      all_urls = response.data.all_urls || [];

      setHomeUrl(home_url);
      setSelectedLinks(all_urls);
    } catch (error) {
      console.log(error);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/all_urls`,
        {
          url: home_url,
        }
      );
      setFetchedLinks(response.data.all_urls);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  let obj = { chatbot_name: selectedChatbot };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };
  const deleteChatbotHandler = () => {
    if (selectedChatbot !== "") {
      openDeleteDialog();
    } else {
      toast.error("Please select chatbot!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
    }
  };
  const handleDeleteConfirmed = async () => {
    closeDeleteDialog();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/delete_chatbot`,
        obj
      );
      const updatedChatbotNames = chatbotNames.filter((name) => name !== selectedChatbot);
      if (updatedChatbotNames.length === 0) {
        setSelectedChatbot("No Chatbot");
        setChatbotNames(["No Chatbot"]);
        dispatch(setCreatedChatbot("No Chatbot"));
        chatbotName = "No Chatbot"; // Update chatbotName
      } else {
        setSelectedChatbot(updatedChatbotNames[0]);
        dispatch(setCreatedChatbot(updatedChatbotNames[0]));
        chatbotName = updatedChatbotNames[0]; // Update chatbotName
      }
      fetchChatbotNames();


      toast.success("Chatbot deleted successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (link) => {
    if (selectAllChecked) {
      setSelectedLinks([]);
      setSelectAllChecked(false);
    } else {
      if (selectedLinks.includes(link)) {
        setSelectedLinks(
          selectedLinks.filter((selectedLink) => selectedLink !== link)
        );
      } else {
        if (selectedLinks.length < maxSelectedLinks) {
          setSelectedLinks([...selectedLinks, link]);
        } else {
          // Display an error message when the limit is reached
          toast.error("You can select upto 25 URL’s for this Chatbot.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            theme: "colored",
          });
        }
      }
    }
  }

  const handleSelectAllChange = () => {
    if (fetchedLinks.length <= maxSelectedLinks) {
      setSelectedLinks([...fetchedLinks]);
      setSelectAllChecked(true);
    } else {
      // Display an error message when the limit is exceeded
      toast.error("You can select upto 25 URL’s for this Chatbot.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
    }
  }

  const handleNavigate = (e) => {
    navigate("/hip-connect/hip-create-chatbot");
  };

  const handleOnChange = async (e) => {
    if (chatbotNames.length === 0) {
      navigationHandler();
      return;
    }
    let home_url;
    let all_urls;
    const newSelectedChatbot = e.target.value;
    setSelectedChatbot(newSelectedChatbot);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/modify_urls`,
        {
          chatbot_name: newSelectedChatbot,
        }
      );
      home_url = response.data.home_url;
      all_urls = response.data.all_urls;

      setHomeUrl(home_url);
      setSelectedLinks(all_urls);
    } catch (error) {
      console.log(error);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/all_urls`,
        {
          url: home_url,
        }
      );
      setFetchedLinks(response.data.all_urls);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleCancelClick = () => {
    dispatch(setCreatedChatbot(selectedChatbot));
    navigate("/hip-connect/hip-connection-url");
  };

  const modifyChatbotHandler = async () => {
    if (selectedLinks.length === 0) {
      toast.error("Please Select links!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
      return;
    }

    const all_urls = selectedLinks;
    const name = selectedChatbot;
    setLoadingOverlay(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/selected_urls`,
        {
          url: homeUrl,
          all_urls: all_urls,
          name: name,
          userId: userId
        }
      );
      dispatch(setCreatedChatbot(name));
      setSelectAllChecked(false);
      setLoadingOverlay(false);
      toast.success("ChatBot modified!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
      setLoadingOverlay(false);
      setTimeout(() => {
        navigationHandler();
      }, 1000);
    } catch (error) {
      setLoadingOverlay(false);
      console.error("Error fetching links:", error);
      toast.error("Error in modifying chatbot!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        theme: "colored",
      });
    }
  };

  const navigationHandler = (event) => {
    navigate("../hip-connection-url");
  };

  const handleRefresh = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/interact-url/openai/schedule_chatbot`,
        {
          chatbot_name: selectedChatbot,
        }
      );
      if (response.data.message) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          theme: "colored",
        });
      } else if (response.data.error) {
        toast.error(response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="delete_chatbot_settings_outer_container">
      {loadingOverlay && (
        <div className="loading-overlay">
          <ReactLoading
            type={"spin"}
            color={"#fff"}
            height={"10%"}
            width={"10%"}
          />
        </div>
      )}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this chatbot?
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ marginLeft: "4vh" }}>
        <div className="delete_chatbot_settings_title">
          <div>Chatbot Settings</div>
        </div>
        <div className="url_delete_chatbot_name">CHATBOT NAME</div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title={selectedChatbot} placement="top-start">
            <select
              className="url_delete_dropdown"
              value={selectedChatbot}
              onChange={handleOnChange}
            >
              {chatbotNames.map((chatbotName, index) => (
                <option key={index} value={chatbotName}>
                  {chatbotName}
                </option>
              ))}
            </select>
          </Tooltip>
          <button
            className="add_new_button"
            onClick={handleNavigate}
            style={{
              backgroundImage: `url(${addChatbotIcon})`,
              backgroundPosition: "2vh 50%",
              backgroundSize: "20px",
              backgroundRepeat: "no-repeat",
              paddingLeft: "5vh",
            }}
          >
            Add New
          </button>
          <button
            className="refresh_button"
            onClick={handleRefresh}
            style={{
              backgroundImage: `url(${refreshIcon})`,
              backgroundPosition: "2vh 50%",
              backgroundSize: "20px",
              backgroundRepeat: "no-repeat",
              paddingLeft: "5vh",
            }}
          >
            Refresh data source
          </button>
        </div>

        <input type="text" className="delete_url_stored_container" value={homeUrl} />

        <div className="delete_url_fetched_links_container">
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "2.5vh" }}
          >
            <Checkbox
              size="small"
              className="url_delete_input_checkbox"
              checked={selectAllChecked}
              onChange={handleSelectAllChange}
            />

            <div style={{ fontSize: "14px", color: "#142952", fontWeight: "500" }}>Included Links</div>
            <div className="count">Fetched links : {fetchedLinks.length}</div>
            <div className="count">Selected links : {selectedLinks.length}</div>
          </div>

          <div className="delete_url_fetched_links_list">
            {isLoading ? (
              <ReactLoading
                type={"spokes"}
                color={"#0044CC"}
                height={"10%"}
                width={"10%"}
              />
            ) : (
              <div>
                {fetchedLinks.map((link, index) => (
                  <div key={index}>
                    <Checkbox
                      size="small"
                      className="select_url"
                      checked={selectedLinks.includes(link)} // Check if the link is in selectedLinks
                      onChange={() => handleCheckboxChange(link)}
                    />

                    {link}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "row",
              justifyContent: "flex-end",
              marginRight: "3vh",
            }}
          >
            <button
              className="delete_url_cancel_button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>

            <button
              className="delete_url_cancel_button"
              onClick={deleteChatbotHandler}
            >
              Delete
            </button>

            <button
              className="delete_url_modify_button"
              onClick={modifyChatbotHandler}
            >
              Modify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
