import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

function LinearProgressWithLabel({ value, color }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: "5px",
            width: "90px",
            "& .MuiLinearProgress-bar": {
              backgroundColor: color,
            },
          }}
        />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function LinearWithValueLabel({ percentage }) {
  const normalizedPercentage = Math.min(Math.max(percentage, 0), 100);

  let color;
  if (percentage >= 75) {
    color = "#097F54"; // First color from the array
  } else if (percentage >= 33) {
    color = "#25B771"; // Second color from the array
  } else {
    color = "#DA0000"; // Last color from the array
  }

  return <LinearProgressWithLabel value={normalizedPercentage} color={color} />;
}

LinearWithValueLabel.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default LinearWithValueLabel;
