import React, { useRef, useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import "./index.css";
import PatientResultJson from '../../SinglePatient.json';
import Back from '../../../assets/Icons/Back.svg';
import { useNavigate } from 'react-router-dom';
import ReactJson from 'react-json-view';
import { saveAs } from 'file-saver';
import jsonexport from 'jsonexport/dist';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function PatientResults() {

    const navigate = useNavigate();
    const copyRef = useRef(null);
    const Parameters = new URLSearchParams(window.location.search);
    const [patientResults, setPatientResults] = useState();
    const [isCollapse, setIsCollapse] = useState(true);
    const rootKeys = Object.keys(patientResults?.jsonData || {});

    useEffect(() => {
        setPatientResults(PatientResultJson.find((value) => Parameters.get("firstName")?.toLowerCase() === value.firstName?.toLowerCase() && Parameters.get("lastName")?.toLowerCase() === value.lastName?.toLowerCase() && Parameters.get("dob") === value.birthDate))
    }, [])

    const handleCopyClick = (text) => {
        // copyRef.current.select();
        navigator.clipboard.writeText(text);
    };

    const handleClickExpand = () => {
        setIsCollapse(false)
    };

    const handleClickCollapse = () => {
        setIsCollapse(true)
    };

    const handleClickExport = () => {
        jsonexport(patientResults?.jsonData, (err, csv) => {
            if (err) {
              console.error(err);
              return;
            }
      
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
            saveAs(blob, 'patientdata.csv');
          });
    };

    const handleClickDownload = () => {
        const jsonContent = JSON.stringify(patientResults?.jsonData, null, 2);
        const blob = new Blob([jsonContent], { type: 'application/json' });
        saveAs(blob, 'patientdata.json');
    };

    return (
        <div className="patient_result_main_container">
            <div className="patient_result_back_container" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <img src={Back} alt="" style={{ marginRight: '10px', width: '13%' }} />
                Back
            </div>
            <div>
                <Grid container spacing={2} className="patient_result_grid">
                    <Grid item xs={12} md={2} sm={12} lg={2} className="patient_result_sub_grid">
                        <Typography variant="subtitle1" className="patient_result_sub_heading">NAME </Typography>
                        <Typography variant="subtitle1" className="patient_result_sub_text">{`${patientResults?.firstName} ${patientResults?.lastName}`} </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={12} lg={2} className="patient_result_sub_grid">
                        <Typography variant="subtitle1" className="patient_result_sub_heading">GENDER </Typography>
                        <Typography variant="subtitle1" className="patient_result_sub_text">{patientResults?.gender} </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={12} lg={2} className="patient_result_sub_grid">
                        <Typography variant="subtitle1" className="patient_result_sub_heading">DATE OF BIRTH </Typography>
                        <Typography variant="subtitle1" className="patient_result_sub_text">{patientResults?.birthDate} </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={12} lg={2} className="patient_result_sub_grid">
                        <Typography variant="subtitle1" className="patient_result_sub_heading">DATE SOURCE </Typography>
                        <Typography variant="subtitle1" className="patient_result_sub_text">{patientResults?.dataSource} </Typography>
                    </Grid>
                </Grid>
                <div className="patient_result_sub_container">
                    <div className="patient_result_buttons">
                        <Button variant="contained" color="primary" onClick={handleClickExpand}>
                            Expand All
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleClickCollapse}>
                            Collapse All
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleClickExport}>
                            Export Data
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleClickDownload}>
                            Download Data
                        </Button>

                    </div>
                    <div className="patient_result_sub_container patient_result_json_data">
                        <ReactJson collapsed={isCollapse} displayDataTypes={false} displayObjectSize={false} name={false} src={patientResults?.jsonData || {}} />
                        <div className='patient_result_json_copy'>
                            <textarea ref={copyRef} value={JSON.stringify(patientResults?.jsonData, null, 2)} style={{ display: 'none' }} readOnly />
                            <button onClick={() => handleCopyClick(JSON.stringify(patientResults?.jsonData, null, 2))}>
                                <ContentCopyIcon />
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default PatientResults