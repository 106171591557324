import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Select, Typography, MenuItem, Button, FormControl, Box, FormHelperText, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import Checkbox from "@mui/material/Checkbox";
import CheckBoxNull from "../../../../assets/Icons/Checkboxnull.svg";
import CheckBoxTick from "../../../../assets/Icons/Checkbox_tick.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import GetCookie from '../../../../HIPCookies/getCookie';

function UserRoleToUserGroup() {

  const [active, setActive] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [selectAllEnabled, setSelectAllEnabled] = useState(true);
  const [clearAllEnabled, setClearAllEnabled] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState('');
  const [selectedUserRoleId, setSelectedUserRoleId] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;
  const userInfo = (JSON.parse(GetCookie('userInfo')) || {}).userId + ":" + (JSON.parse(GetCookie('userInfo')) || {}).userNameDisplay;

  useEffect(() => {
    fetchUserRoles();
    fetchUserGroups();
  }, []);

  useEffect(() => {
    getSelectedUserGroups();
  }, [selectedUserRole]);

  useEffect(() => {
    // Check if all user groups are selected
    const allUserGroupIds = userGroups.map((item) => item.id);
    const isAllSelected = selectedUserGroups.length === allUserGroupIds.length;
    // Enable/disable buttons accordingly
    setSelectAllEnabled(!isAllSelected);
    setClearAllEnabled(isAllSelected);
  }, [selectedUserGroups, userGroups]);

  // for handleSaveChanges()
  useEffect(() => {
    if (selectedUserRole) {
      const selectedUserRoleArr = userRoles.filter(
        (user) => user.displayName === selectedUserRole
      );
      if (selectedUserRoleArr.length > 0) {
        const selectedId = parseInt(selectedUserRoleArr[0].id);
        setSelectedUserRoleId(selectedId);
      }
    }
  }, [selectedUserRole, userRoles]);

  //popup function to be used during savechanges
  const functionOpenPopup = () => {
    if (selectedUserRole !== "") {
      setActive(true);
    }
    else {
      setActive(false);
    }
  }

  const closeDialogPopup = () => {
    setActive(false);
  };

  const fetchUserRoles = () => {
    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserRole`,
      {
        headers: {
          'Authorization': `Bearer ${jWTToken}`
        },
      })
      .then((response) => {
        setUserRoles(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
  }

  const fetchUserGroups = () => {
    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserGroup`,
      {
        headers: {
          'Authorization': `Bearer ${jWTToken}`
        },
      })
      .then((response) => {
        setUserGroups(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
  }

  // Function to chunk the securityGroups into rows of 4
  const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const handleCheckboxChange = (id) => {
    setSelectedUserGroups((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSelectChange = (e) => {
    setFormSubmitted(false);
    const selectedDisplayName = e.target.value;
    if (selectedDisplayName === 'Select') {
      // Reset the state when 'Select' option is selected
      setSelectedUserRole('');
      setSelectedUserGroups([]);
    } else {
      setSelectedUserRole(selectedDisplayName);
    }
  };

  const getSelectedUserGroups = () => {
    if (selectedUserRole) {
      const selectedUserRoleObject = userRoles.find(role => role.displayName === selectedUserRole);
      if (selectedUserRoleObject) {
        const selectedUserRoleId = selectedUserRoleObject.id;
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserGroup/RoleIds`,
          {
            headers: {
              'Authorization': `Bearer ${jWTToken}`,
              UserRoleIds: selectedUserRoleId
            }
          }
        )
          .then((response) => {
            // Automatically check the checkboxes of pre-assigned user groups
            if (response.data.length !== 0) {
              setSelectedUserGroups(response.data.map(userGroup => userGroup.id));
            }
            else {
              setSelectedUserGroups([]);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Invalid UserRole selected", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    } else {
      // Handle the case when no valid user role is selected
      setSelectedUserGroups([]);
    }
  };

  const handleSaveChanges = () => {
    // Check if a valid user role is selected
    if (!selectedUserRole) {
      toast.error("Please select a User Role", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const requestBody = {
      "userRoleName": selectedUserRole,
      "displayName": selectedUserRole,
      "LastUpdatedBy": userInfo,
    }

    const headers = {
      'Authorization': `Bearer ${jWTToken}`,
      UserGroupId: selectedUserGroups.length > 0 ? selectedUserGroups : [0],
      "relation": "true"
    }

    axios.put(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserRole/${selectedUserRoleId}`,
      requestBody,
      { headers: headers }
    )
      .then((response) => {
        if (response.status === 200 && response.data !== "Data already exist") {
          // Handle the response, e.g., show a success message
          toast.success(" User Groups Assigned Successfully to User Role", {
            position: toast.POSITION.TOP_CENTER
          })
          closeDialogPopup();
          setSelectedUserRole('');
          setSelectedUserGroups([]);
          setFormSubmitted(false); // Reset formSubmitted state here
          setSelectAllEnabled(false);
        }
        else {
          // Handle other response statuses or messages
          console.error(response.data); // Log the response data for further inspection
          toast.error("Error updating User Role. Please check the console for details.", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error(error); // Log the error for further inspection
        toast.error("Error updating UserRole", {
          position: toast.POSITION.TOP_CENTER,
        })
      })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
  }

  const handleSelectAll = () => {
    const allUserGroupIds = userGroups.map((item) => item.id);
    setSelectedUserGroups(allUserGroupIds);
  };

  const handleClearAll = () => {
    setSelectedUserGroups([]);
  };

  return (
    <form onSubmit={handleSubmit} className='security_role_container'>
      <Typography variant='h7' className='header_container'> Assign User Groups To User Role </Typography>
      <div className='security_role_form_container'>
        <Typography variant="h7" className="security_role_form_heading" > USER ROLE </Typography>
        <Select
          name="userRole"
          IconComponent={ExpandMoreIcon}
          className='security_role_select'
          fullWidth
          displayEmpty
          value={selectedUserRole || 'Select'}
          renderValue={(value) => (value.length !== 0 ? value : "Select")}
          onChange={handleSelectChange}
          error={formSubmitted && !selectedUserRole && selectedUserRole !== 'Select'}
        >
          <MenuItem className="select_content" value="Select">Select</MenuItem>
          {userRoles.map((item) => (
            <MenuItem className="select_content" key={item.id} value={item.displayName}> {item.displayName} </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: "#d32f2f" }}>{formSubmitted && !selectedUserRole && "Please select User Role"}</FormHelperText>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl className='roles_permissions_form_container' component="fieldset" variant="standard">
          {chunkArray(userGroups, 4).map((row, rowIndex) => (
            <div key={rowIndex} className="checkbox_row">
              {row.map((item) => (
                <label className="roles_permission_checkbox" key={item.id}>
                  <Checkbox
                    checked={selectedUserGroups.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                    checkedIcon={<img src={CheckBoxTick} alt='checkbox' />}
                    icon={<img src={CheckBoxNull} alt='checkbox' />}
                    color="primary"
                    inputProps={{ "aria-label": "checkbox" }}
                  />
                  {item.displayName}
                </label>
              ))}
            </div>
          ))}
        </FormControl>
      </Box>
      <Dialog className="roles_permission_dialog_box" open={active} onClose={closeDialogPopup} fullWidth >
        <CloseIcon onClick={closeDialogPopup} className="popup_close_icon" />
        <DialogTitle> {`Assign User Groups to User Role`} </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Do you want to update User Role : ${selectedUserRole}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="roles_permission_dialog_box_buttons">
          <Button onClick={handleSaveChanges} className="roles_permission_sub_button" > Yes </Button>
          <Button onClick={closeDialogPopup} className="roles_permission_cancel_btn">No </Button>
        </DialogActions>
      </Dialog>
      <FormControl className='buttons_form_container' >
        <Button type="button" className={`all_button ${!selectAllEnabled || selectedUserRole === "Select" || selectedUserRole === "" ? 'disabled-button' : ''}`}
          onClick={handleSelectAll}
          disabled={!selectAllEnabled || selectedUserRole === "Select" || selectedUserRole === ""} // Disable when not all are selected 
        >
          Select All
        </Button>
        <Button type="button" className={`all_button ${!clearAllEnabled ? 'disabled-button' : ''}`} onClick={handleClearAll}
          disabled={!clearAllEnabled} // Disable when not all are selected 
        >
          Clear All
        </Button>
        <Button type="submit" className='Save_changes_button' onClick={functionOpenPopup} >
          Save Changes
        </Button>
      </FormControl>
    </form>
  )
}

export default UserRoleToUserGroup;
