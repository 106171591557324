import { Tooltip, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../Redux/features/AlertsSlice";
import {
  getUploadedFilename,
  setBoundingBoxes,
  setClaimProcessMessage,
  setConfidenceScoreForExtractedFields,
  setExtractedInfo,
  setImageUrlList,
  setSelectedSection,
  setSelectedSectionImages,
} from "../../../Redux/features/DocumentsSlice";
import CollapsibleSection from "../CollapsableSection/CollapsibleSection";
import VitalsAccordion from "../VitalAccordian/VitalsAccordian";
import "./ShowSections.css";
import axios from "axios";
import GetCookie from "../../../HIPCookies/getCookie";

export function ShowSections({
  Con_Level,
  setCoArrow,
  FileData,
  File,
  page,
  setUpdatedFile,
  changePage,
  sectionList,
}) {
  const [loadingSection, setLoadingSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(-1);
  const dispatch = useDispatch();
  const [filename, setFilename] = useState(useSelector(getUploadedFilename));
  const userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;

  const handleSectionClick = async (sectionName) => {
    setLoadingSection(sectionName);
    dispatch(setClaimProcessMessage(sectionName))
    dispatch(setSelectedSection(sectionName))
    const value = 'All'
    dispatch(setFilter(value));

    try {
      const headers = {
        "Content-Type": "application/json",
        userId: userId, // Include the userId in the headers
      };

      const imagesResponse = await axios.post(
        `${process.env.REACT_APP_CONNECT}/med-review-process/get-section-images`,
        {
          section_name: sectionName,
          pdf_filename: filename, // Provide the PDF filename
        },
        { headers: headers } // Pass the headers object in the request
      );

      const imagesData = imagesResponse.data;
      if (imagesData.images) {
        dispatch(setSelectedSectionImages(imagesData.images));
      } else {
        console.error("Failed to fetch images from the API");
      }
    } catch (error) {
      console.error("API call for images failed", error);
    }

    try {
      const infoResponse = await axios.post(
        `${process.env.REACT_APP_CONNECT}/med-review-process/get-section-info`,
        {
          section_name: sectionName,
          pdf_filename: filename,
        },
        {
          headers: {
            "Content-Type": "application/json",
            userId: userId,
          },
        }
      );
      const infoData = infoResponse.data;

      if (infoData.extracted_info) {
        dispatch(setFilter("All"));
        dispatch(setExtractedInfo(infoData.extracted_info));
        if (infoData.bounding_boxes) {
          dispatch(setBoundingBoxes(infoData.bounding_boxes));
          const filteredData = infoData.bounding_boxes.map((item) => ({
            confidence: item.confidence,
            text: item.text,
          }));
          // Dispatch action to store the filtered list in Redux

          dispatch(setConfidenceScoreForExtractedFields(filteredData));
        }
      } else {
        console.error("Failed to fetch extracted info from API");
      }
    } catch (error) {
      dispatch(setExtractedInfo({}));
      console.error("API call for info failed", error);
    } finally {
      setLoadingSection(null);
    }

  };
  return (
    <>
      {/* Indication of Confidence level */}
      <div className="medical_review_section_list_doc_text_indication_container">
        <div className="medical_review_section_list_doc_text_indication_container_header">
          <span>Accuracy Level</span>
        </div>
        <div className="medical_review_doc_text_field_indicators">
          <div className="medical_review_doc_text_field_indicator_span">
            <Tooltip placement="top" title="80-100%">
              <div className="medical_review_indicator_high">
                <Typography className="medical_review_doc_acc_level"> &gt;80</Typography>
              </div>
            </Tooltip>

          </div>
          <div className="medical_review_doc_text_field_indicator_span">
            <Tooltip placement="top" title="60-80%">
              <div className="medical_review_indicator_medium">
                <Typography className="medical_review_doc_acc_level" style={{ color: "#000" }}>&gt;60</Typography>
              </div>
            </Tooltip>
          </div>
          <div className="medical_review_doc_text_field_indicator_span">
            <Tooltip placement="top" title="< 60%">
              <div className="medical_review_indicator_low">
                <Typography className="medical_review_doc_acc_level" style={{ color: "#000" }}>&gt;30</Typography>
              </div>
            </Tooltip>

          </div>
          <div className="medical_review_doc_text_field_indicator_span">
            <Tooltip placement="top" title="No Data Extracted">
              <div className="medical_review_indicator_null">
                <Typography className="medical_review_doc_acc_level" style={{ color: "#fff" }}>0</Typography>
              </div>
            </Tooltip>

          </div>
        </div>
      </div>

      {/*  Form for editing the data */}
      <div
        className="medical_review_section_list_doc_text_field_container"
        onScroll={() =>
          setCoArrow({
            isActive: false,
          })
        }
      >
        <div className="medical_review_section_list_doc_text_field_container">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            sectionList.map((sectionTitle, index) => {
              return (
                <CollapsibleSection
                  key={index}
                  title={sectionTitle}
                  isActive={index === activeSection}
                  isLoading={loadingSection === sectionTitle}
                  onToggle={() => {
                    if (index === activeSection) {
                      setActiveSection(-1); // Close the section if it's already active
                    } else {
                      setActiveSection(index); // Open the clicked section
                      handleSectionClick(sectionTitle);
                    }
                  }}
                >
                  <p>{sectionTitle}</p>
                </CollapsibleSection>
              );
            })
          )}
          {/* <VitalsAccordion /> */}

          <div className="medical_review_text_box_container">
            <label className="medical_review_text_box_label">Note</label>
            <textarea rows="4" cols="35" placeholder="Write a Note"></textarea>
          </div>
        </div>
      </div>
    </>
  );
}
